import { ReviewFieldDbParser } from "@redotech/redo-model/review/review-db-parser";
import { ReviewStatus } from "@redotech/redo-model/review/review-field-schema";
import { z } from "zod";

export const saveNewReviewSchema = {
  input: z.object({
    teamId: z.string(),
    status: z.nativeEnum(ReviewStatus),
    verifiedPurchase: z.boolean(),
    shopifyProductId: z.string(),
    customer: z.object({
      email: z.string(),
    }),
    reviewFields: z.array(ReviewFieldDbParser),
  }),
  output: z.literal("success"),
};
