export type Enableable<T> = {
  enabled: boolean;
} & T;

export type HexCode = `${string}`;

export interface FontStyleDefinition {
  fontSizePx: number;
  fontWeight: FontWeight;
}

export const fontWeights = ["regular", "semibold", "bold"] as const;
export type FontWeight = (typeof fontWeights)[number];

export const buttonHierarchies = ["primary", "secondary"] as const;
export type ButtonHierarchy = (typeof buttonHierarchies)[number];

export interface BorderStyleDefinition {
  cornerRadiusPx: number;
  stroke: BorderStrokeStyleDefinition;
}
export interface BorderStrokeStyleDefinition {
  color: HexCode;
  weightPx: number;
}

interface ElementStyleDefinition {
  backgroundColor: HexCode;
  textColor: HexCode;
  border: BorderStyleDefinition;
  paddingPx: number;
  fontReference: keyof BrandKit["font"]["hierarchy"];
}

export interface ButtonStyleDefinition extends ElementStyleDefinition {}

export interface InputStyleDefinition extends ElementStyleDefinition {
  errorColor: HexCode;
}

export interface ButtonOptions {
  primary: ButtonStyleDefinition;
  secondary: ButtonStyleDefinition;
}

export type Override<T extends ElementStyleDefinition> = Omit<
  T,
  "fontReference"
> & {
  font: FontStyleDefinition & { fontFamily: FontFamily };
};

export type ButtonOptionsOverride = {
  [K in keyof ButtonOptions]: Override<ButtonStyleDefinition>;
};

// TODO: custom fonts (email fonts should be unrelated, since they have unique constraints)
import { FontFamily } from "./email-builder";
export { FontFamily };

export const fontHierarchyKeys = ["h1", "h2", "h3", "body", "subtext"] as const;
export type FontHierarchyKey = (typeof fontHierarchyKeys)[number];

export interface BrandKit {
  colors: {
    background: HexCode;
    accent: HexCode;
  };
  font: {
    fontFamily: FontFamily;
    hierarchy: Record<FontHierarchyKey, FontStyleDefinition>;
  };
  inputs: InputStyleDefinition;
  buttons: ButtonOptions;
  /**
   * Instead of optional fields, empty strings are used to indicate no image.
   * (Makes it easier to work with the Inspector component)
   */
  images: {
    logoUrl: string;
    faviconUrl: string;
    bannerUrl: string;
    logoBannerUrl: string;
  };
}

export const defaultBrandKit: BrandKit = {
  colors: {
    background: "#ffffff",
    accent: "#000000",
  },
  font: {
    fontFamily: "Arial",
    hierarchy: {
      h1: { fontSizePx: 48, fontWeight: "bold" },
      h2: { fontSizePx: 36, fontWeight: "semibold" },
      h3: { fontSizePx: 30, fontWeight: "regular" },
      body: { fontSizePx: 16, fontWeight: "regular" },
      subtext: { fontSizePx: 14, fontWeight: "regular" },
    },
  },
  inputs: {
    backgroundColor: "#ffffff",
    errorColor: "#ff0000",
    textColor: "#000000",
    border: {
      cornerRadiusPx: 8,
      stroke: { color: "#000000", weightPx: 1 },
    },
    paddingPx: 8,
    fontReference: "body",
  },
  buttons: {
    primary: {
      backgroundColor: "#000000",
      textColor: "#ffffff",
      border: {
        cornerRadiusPx: 8,
        stroke: { color: "#000000", weightPx: 1 },
      },
      paddingPx: 8,
      fontReference: "body",
    },
    secondary: {
      backgroundColor: "#ffffff",
      textColor: "#000000",
      border: {
        cornerRadiusPx: 0,
        stroke: { color: "#000000", weightPx: 1 },
      },
      paddingPx: 8,
      fontReference: "body",
    },
  },
  images: {
    logoUrl: "",
    faviconUrl: "",
    bannerUrl: "",
    logoBannerUrl: "",
  },
};
