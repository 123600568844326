import * as amplitude from "@amplitude/analytics-browser";
import Gravatar from "@gravatar/js";
import { ClickAwayListener } from "@mui/material";
import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import {
  ConversationPlatform,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { Order } from "@redotech/redo-model/order";
import { Return } from "@redotech/redo-model/return";
import { Team } from "@redotech/redo-model/team";
import { Permission, permitted } from "@redotech/redo-model/user";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoList } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import ArrowUpRightSvg from "@redotech/redo-web/arbiter-icon/arrow-up-right_filled.svg";
import { Divider } from "@redotech/redo-web/divider";
import { Dropdown } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import { ExternalLink } from "@redotech/redo-web/link";
import ShopifyBagLogo from "@redotech/redo-web/shopify/bag-logo.svg";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { UserImage, UserImageSize } from "@redotech/redo-web/user-image";
import { filterTruthy, unique } from "@redotech/util/array";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { RedoMerchantClientContext } from "../../client/context";
import { updateConversation } from "../../client/conversations";
import { getCustomerSearch } from "../../client/shopify";
import { copyToClipboard } from "../../clipboard";
import { ShopifyCustomer } from "../create-conversation-modal";
import * as customerDetailsCss from "./customer-details.module.css";
import { CustomerTotals } from "./customer-totals";

const NAME_EMAIL_DELIMITER = " - ";

export const CustomerDetails = memo(function CustomerDetails({
  conversation,
  orders,
  returns,
  setActiveConversation,
}: {
  conversation: ExpandedConversation;
  orders: Order[] | undefined;
  returns: Return[] | undefined;
  setActiveConversation: (conversation: ExpandedConversation) => void;
}) {
  const customer = conversation.customer;
  const client = useRequiredContext(RedoMerchantClientContext);
  const team = useRequiredContext(TeamContext);
  const user = useContext(UserContext);

  const getGravatarUrl = (email: string) => {
    return Gravatar({
      email,
      defaultImage: "404",
      protocol: location.protocol === "https:" ? "https" : undefined,
      size: Math.ceil(devicePixelRatio * 32),
    });
  };

  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );
  const [editDropdownOpen, setEditDropdownOpen] = useState(false);
  const [firstName, setFirstName] = useState<string | null>();
  const [lastName, setLastName] = useState<string | null>();
  const canEditCustomer =
    !!user && permitted(user.permissions, Permission.EDIT_CUSTOMER);

  const [searchString, setSearchString] = useState<string | undefined>();
  const debouncedSearch = useDebounce(searchString, 500);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [customers, setCustomers] = useState<Record<string, ShopifyCustomer>>(
    {},
  );

  const gravatarUrl = useMemo(() => {
    if (!conversation?.customer?.email) {
      return null;
    }
    return Gravatar({
      email: conversation.customer?.email,
      defaultImage: "404",
      protocol: location.protocol === "https:" ? "https" : undefined,
      size: Math.ceil(devicePixelRatio * 32),
    });
  }, [devicePixelRatio, conversation?.customer?.email]);

  const [customersLoad, doCustomersLoad] = useTriggerLoad(async (signal) => {
    const searchTermPieces = (debouncedSearch || "").split(
      NAME_EMAIL_DELIMITER,
    );
    const searchTerm = searchTermPieces[searchTermPieces.length - 1];
    const customers = await getCustomerSearch(client, {
      search: searchTerm,
      signal,
    });
    setCustomersLoading(false);
    return customers;
  });
  useEffect(() => {
    if (searchString !== undefined) {
      setCustomersLoading(true);
      doCustomersLoad();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (customersLoad.value?.customers) {
      setCustomers(
        customersLoad.value.customers.reduce(
          (
            accumulator: Record<string, ShopifyCustomer>,
            customer: Record<string, any>,
          ) => {
            accumulator[customer.email] = {
              email: customer.email,
              firstName: customer.first_name,
              lastName: customer.last_name,
            };
            return accumulator;
          },
          {},
        ),
      );
    }
  }, [customersLoad.value]);

  const handleCustomerChange = async (newEmail: string) => {
    const newCustomer = {
      email: newEmail || undefined,
      firstName:
        firstName ||
        (newEmail ? customers[newEmail]?.firstName || undefined : undefined),
      lastName:
        lastName ||
        (newEmail ? customers[newEmail]?.lastName || undefined : undefined),
    };
    if (newEmail) {
      const result = await updateConversation(client, conversation, {
        customer: newCustomer,
      });
      amplitude.logEvent("update-conversationCustomer", {
        conversationId: conversation._id,
        channel: conversation.platform,
        customer: newCustomer?.email,
      });
      setActiveConversation({
        ...conversation,
        customer: result.data.customer,
      });
      setEditDropdownOpen(false);
      setFirstName(null);
      setLastName(null);
    }
  };

  const getCustomerDisplayName = (email: string) => {
    if (email === "none") {
      return "No customer";
    }
    if (email.includes(NAME_EMAIL_DELIMITER)) {
      return email;
    }
    if (email === customer?.email) {
      return `${customer.name}${NAME_EMAIL_DELIMITER}${customer.email}`;
    }
    if (!customers[email]) {
      return `New customer${NAME_EMAIL_DELIMITER}${email}`;
    }
    if (
      !customers[email]?.firstName &&
      !customers[email]?.lastName &&
      !customers[email]?.email
    ) {
      return "";
    }
    if (!customers[email]?.firstName && !customers[email]?.lastName) {
      return `Missing name${NAME_EMAIL_DELIMITER}${customers[email]?.email || ""}`;
    }
    return `${customers[email]?.firstName || ""} ${
      customers[email]?.lastName || ""
    }${NAME_EMAIL_DELIMITER}${customers[email]?.email || ""}`;
  };

  const prependCustomCustomerResult = (customerNames: string[]) => {
    if (searchString?.includes("@")) {
      return [searchString, ...customerNames];
    } else {
      return customerNames;
    }
  };

  function handleViewCustomerOnShopify(url: string): void {
    window.open(url, "_blank");
  }

  const [focusedIndex, setFocusedIndex] = useState<number | undefined>();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const closeDropdown = () => {
    setEditDropdownOpen(false);
    setSearchString("");
  };

  const customerInput = (
    <input
      autoFocus
      className={customerDetailsCss.input}
      onChange={(event) => setSearchString(event.target.value)}
      placeholder="Search by email, name, or order #..."
      ref={setInputRef}
      value={searchString}
    />
  );

  const shopifyUrl = shopifyCustomerLink(team, conversation, orders || []);

  return (
    <div className={customerDetailsCss.details}>
      <Flex dir="column" gap="xs">
        <ClickAwayListener
          onClickAway={() => {
            if (editDropdownOpen) {
              closeDropdown();
            }
          }}
        >
          <div>
            <Flex align="center" justify="space-between">
              <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
                Customer
              </Text>
            </Flex>
            <Flex align="center" gap="xs" ref={setDropdownAnchor}>
              <Tooltip
                className={
                  (customer?.name?.length || 0) <= 15
                    ? customerDetailsCss.hidden
                    : undefined
                }
                placement="top"
                title={customer?.name}
              >
                <div
                  className={customerDetailsCss.customerBadge}
                  onClick={() => {
                    if (!canEditCustomer) {
                      return;
                    }
                    amplitude.logEvent("view-updateCustomerModal", {
                      conversationId: conversation._id,
                      channel: conversation.platform,
                    });
                    setEditDropdownOpen(true);
                  }}
                >
                  <RedoBadge
                    avatar={{
                      alt: "Customer profile picture",
                      name: customer?.name,
                      imageUrl: gravatarUrl,
                    }}
                    color="gray"
                    size="sm"
                    text={customer?.name || "none"}
                  />
                </div>
              </Tooltip>
              <div>
                <Dropdown
                  anchor={dropdownAnchor}
                  fitToAnchor={false}
                  open={editDropdownOpen}
                >
                  <Flex
                    className={customerDetailsCss.dropdownContainer}
                    dir="column"
                    gap="sm"
                  >
                    {customerInput}
                    <Divider />

                    {customersLoading && <Text fontSize="xs">Loading...</Text>}
                    <RedoList
                      focusedIndex={focusedIndex}
                      isItemSelected={(item) => {
                        return item.value === customer?.email;
                      }}
                      items={prependCustomCustomerResult(
                        Object.keys(customers),
                      ).map((customer) => ({
                        value: customer,
                      }))}
                      itemSelected={async (item) => {
                        closeDropdown();
                        await handleCustomerChange(item.value);
                      }}
                      refToListenTo={inputRef}
                      setFocusedIndex={setFocusedIndex}
                      size={RedoListItemSize.SMALL}
                    >
                      {(option) => {
                        return (
                          <Tooltip
                            className={
                              getCustomerDisplayName(option.value)?.length <= 35
                                ? customerDetailsCss.hidden
                                : undefined
                            }
                            placement="top"
                            title={getCustomerDisplayName(option.value)}
                          >
                            <Flex className={customerDetailsCss.dropdownImage}>
                              <UserImage
                                alt="User profile picture"
                                imageUrl={getGravatarUrl(option.value || "")}
                                name={`${customers[option.value]?.firstName} ${customers[option.value]?.lastName}`}
                                size={UserImageSize.TINY}
                              />
                              <Text
                                fontSize="xs"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                              >
                                {getCustomerDisplayName(option.value)}
                              </Text>
                            </Flex>
                          </Tooltip>
                        );
                      }}
                    </RedoList>
                  </Flex>
                </Dropdown>
              </div>
              {team.settings.customerAccounts?.enabled &&
                customer?.customer &&
                customer.email && (
                  <RedoButton
                    hierarchy={RedoButtonHierarchy.TERTIARY}
                    IconLeading={ArrowUpRightSvg}
                    onClick={() => {
                      const customerDetailUrl = `${process.env.MERCHANT_APP_URL}/stores/${team._id}/customers/${customer.customer}`;
                      window.open(customerDetailUrl, "_blank");
                    }}
                    size={RedoButtonSize.EXTRA_SMALL}
                  />
                )}
            </Flex>
          </div>
        </ClickAwayListener>
      </Flex>
      <Flex dir="column" mt="sm">
        {customer?.email && (
          <div>
            <div className={customerDetailsCss.copyableField}>
              <div className={customerDetailsCss.email}>
                <Tooltip title={customer?.email ?? ""}>
                  <Text
                    fontSize="xs"
                    fontWeight="medium"
                    onClick={() => copyToClipboard(customer.email || "")}
                    textColor="tertiary"
                    textOverflow="ellipsis"
                  >
                    {customer?.email}
                  </Text>
                </Tooltip>
              </div>
            </div>
          </div>
        )}

        {customer?.phoneNumber && (
          <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
            {customer.phoneNumber}
          </Text>
        )}
        {[
          ConversationPlatform.INSTAGRAM,
          ConversationPlatform.INSTAGRAM_COMMENTS,
        ].includes(conversation.platform) &&
          customer?.instagram?.username && (
            <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
              <ExternalLink
                className={customerDetailsCss.username}
                showIcon={false}
                url={`https://instagram.com/${customer.instagram.username}`}
              >
                @{customer.instagram.username}
              </ExternalLink>
            </Text>
          )}
      </Flex>

      {(orders || returns) && (
        <CustomerTotals orders={orders} returns={returns} />
      )}
      {shopifyUrl && (
        <RedoButton
          hierarchy={RedoButtonHierarchy.SECONDARY}
          IconLeading={ShopifyBagLogo}
          onClick={() => handleViewCustomerOnShopify(shopifyUrl)}
          size={RedoButtonSize.EXTRA_SMALL}
          text="View on Shopify"
        />
      )}
    </div>
  );
});

function shopifyCustomerId(
  conversation: ExpandedConversation,
  orders: Order[],
): string | undefined {
  const customerIdFromConversation: string | undefined =
    conversation.customer?.shopifyCustomerId;
  if (customerIdFromConversation) {
    return customerIdFromConversation;
  }

  const customerIdFromOrders = unique(
    filterTruthy(orders?.map((order) => order.shopify.customer?.id)),
  );
  return customerIdFromOrders[0];
}

function shopifyCustomerLink(
  team: Team,
  conversation: ExpandedConversation,
  orders: Order[],
): string | undefined {
  const id = shopifyCustomerId(conversation, orders);
  if (!id) {
    return undefined;
  }
  const regexMatch = team.storeUrl?.match(/(.*)\.myshopify.com/);
  const storeName = regexMatch ? regexMatch[1] : undefined;
  if (!storeName) {
    return;
  }
  return `https://admin.shopify.com/store/${storeName}/customers/${id}`;
}
