// extracted by mini-css-extract-plugin
export var emailContainer = "_77-3";
export var emailContentPaddedContainer = "_77-9";
export var emailHeader = "_77-5";
export var emailInfoIcon = "_77-8";
export var emailShortenedSummary = "_77-c";
export var infoDropdown = "_77-0";
export var infoRowBoundedContent = "_77-b";
export var internal = "_77-4";
export var merchant = "_77-7";
export var normal = "_77-6";
export var replyCard = "_77-d";
export var subtleButton = "_77-1";
export var subtleButtonIconContainer = "_77-2";
export var toggleViewRepliesButton = "_77-a";
export var wrappingText = "_77-e";