// extracted by mini-css-extract-plugin
export var accent = "_2i-3";
export var add = "_2i-h";
export var addIcon = "_2i-i";
export var deleteIcon = "_2i-g";
export var disabled = "_2i-8";
export var ellipsis = "_2i-d";
export var error = "_2i-2";
export var form = "_2i-4";
export var hideFocus = "_2i-1";
export var icon = "_2i-6";
export var input = "_2i-c";
export var listItem = "_2i-f";
export var mediumHeight = "_2i-a";
export var noBorder = "_2i-7";
export var shortHeight = "_2i-9";
export var tallHeight = "_2i-b";
export var textInput = "_2i-0";
export var vertical = "_2i-e";
export var widget = "_2i-5";