// extracted by mini-css-extract-plugin
export var actionButton = "_74-1";
export var actions = "_74-0";
export var copyableField = "_74-2";
export var detailColumn = "_74-3";
export var detailRow = "_74-4";
export var dropdownTitle = "_74-5";
export var fulfillmentDetails = "_74-7";
export var fulfillments = "_74-6";
export var icon = "_74-8";
export var imgContainer = "_74-9";
export var lineItems = "_74-a";
export var link = "_74-p";
export var orderCard = "_74-k";
export var orderCollapseButton = "_74-l";
export var orderCollapseButtonIcon = "_74-m";
export var orderDetails = "_74-b";
export var orderPillsContainer = "_74-c";
export var pillWrapper = "_74-d";
export var product = "_74-e";
export var productDetails = "_74-g";
export var productTopDetails = "_74-h";
export var products = "_74-f";
export var strong = "_74-i";
export var tightText = "_74-o";
export var tooltipHidden = "_74-q";
export var variantQuantityRow = "_74-j";
export var wrappingText = "_74-n";