import PaperclipIcon from "@redotech/redo-web/icon-old/paperclip.svg";
import { ChangeEvent, memo, MouseEvent, useState } from "react";
import {
  RedoButton,
  RedoButtonSize,
} from "../arbiter-components/buttons/redo-button";
import * as quillToolbarUploadFileCss from "./quill-toolbar-upload-file.module.css";

export const QuillToolbarUploadFile = memo(function QuillToolbarUploadFile({
  handleUpload,
  clearInput,
}: {
  handleUpload: ({
    event,
  }: {
    event: ChangeEvent<HTMLInputElement>;
  }) => Promise<void>;
  clearInput: (e: MouseEvent<HTMLInputElement>) => void;
}) {
  const [fileUploadRef, setFileUploadRef] = useState<HTMLInputElement | null>(
    null,
  );

  return (
    <>
      <label htmlFor="file-upload" id="file-upload-label">
        <RedoButton
          IconLeading={PaperclipIcon}
          onClick={() => {
            fileUploadRef?.click();
          }}
          size={RedoButtonSize.SMALL}
        />
      </label>
      <input
        className={quillToolbarUploadFileCss.fileInput}
        id="file-upload"
        onChange={(e) => handleUpload({ event: e })}
        onClick={(e) => clearInput(e)}
        ref={setFileUploadRef}
        type="file"
      />
    </>
  );
});
