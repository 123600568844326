import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import {
  FilterOptionsV2,
  FiltersStatus,
} from "@redotech/redo-model/conversation-filters";
import { deepIntersectionEquals } from "@redotech/util/equal";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useState,
} from "react";
import { RedoMerchantClientContext } from "../../client/context";
import { getConversationsCounts } from "../../client/team";
import { MerchantAppEventServerContext } from "../../events/merchant-app-event-server-provider";
import { ActiveViewContext } from "./active-view-context";
import { FiltersContext } from "./filters-context";

export const ActiveViewConversationCountsContext = createContext<
  Record<string, number> | undefined
>({});

export const ActiveViewConversationCountsProvider = memo(
  function ConversationCountsProvider({
    children,
    blockCountRefreshes,
  }: {
    blockCountRefreshes: boolean;
    children: ReactNode | ReactNode[];
  }) {
    const client = useRequiredContext(RedoMerchantClientContext);
    const eventServer = useRequiredContext(MerchantAppEventServerContext);
    const activeFilters = useContext(FiltersContext);
    const activeView = useContext(ActiveViewContext);
    const activeViewName = activeView.name;

    useEffect(() => {
      const unlistenCallback = eventServer.subscribe(async () => {
        if (blockCountRefreshes) {
          return;
        }
        doConversationCountsLoad();
      });
      return () => unlistenCallback();
    }, [blockCountRefreshes]);

    useEffect(() => {
      doConversationCountsLoad();
    }, [activeFilters]);

    const [conversationCounts, setConversationCounts] = useState<
      Record<string, number>
    >({});

    const [_, doConversationCountsLoad] = useTriggerLoad(async (signal) => {
      const closedFilter = {
        ...activeFilters,
        status: FiltersStatus.CLOSED,
      };
      const openFilter = {
        ...activeFilters,
        status: FiltersStatus.OPEN,
      };
      const snoozedFilter = {
        ...activeFilters,
        status: FiltersStatus.SNOOZED,
      };

      const currentViewFilters: { name: string; filters: FilterOptionsV2 }[] = [
        {
          name: `${activeViewName}-closed`,
          filters: closedFilter,
        },
        {
          name: `${activeViewName}-open`,
          filters: openFilter,
        },
        {
          name: `${activeViewName}-snoozed`,
          filters: snoozedFilter,
        },
      ];

      const rawConversationCounts = await getConversationsCounts(client, {
        views: currentViewFilters,
      });

      const counts = {
        ...rawConversationCounts,
        [`${activeViewName}-all`]: Object.values(rawConversationCounts).reduce(
          (acc, val) => acc + val,
          0,
        ),
      };

      if (!deepIntersectionEquals(counts, conversationCounts)) {
        setConversationCounts(counts);
      }

      return counts;
    });

    return (
      <ActiveViewConversationCountsContext.Provider value={conversationCounts}>
        {children}
      </ActiveViewConversationCountsContext.Provider>
    );
  },
);
