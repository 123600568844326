// extracted by mini-css-extract-plugin
export var actionButton = "_7a-0";
export var bubble = "_7a-2";
export var bubbleContext = "_7a-6";
export var bubbleItems = "_7a-7";
export var customer = "_7a-3";
export var divider = "_7a-8";
export var fileList = "_7a-9";
export var infoAbove = "_7a-a";
export var internal = "_7a-4";
export var lightActionButton = "_7a-1";
export var link = "_7a-b";
export var merchant = "_7a-5";
export var message = "_7a-c";
export var profilePicture = "_7a-e";
export var quillToolbarContainer = "_7a-f";
export var senderDate = "_7a-g";
export var senderDateCustomer = "_7a-h";
export var senderName = "_7a-d";
export var time = "_7a-i";
export var uploadedImage = "_7a-j";
export var username = "_7a-k";
export var xsmall = "_7a-l";