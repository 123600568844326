import { ResponseLength } from "@redotech/redo-model/concierge-conversation/concierge-conversation-message-definition";
import { array, nativeEnum, object, string, z } from "zod";

export const assistantSettingsSchema = object({
  avatarUrl: string().max(500).optional(),
  name: string().max(100).optional(),
  toneOfVoice: string().max(500).optional(),
  writingExamples: array(string().max(1000)).optional(),
  brandDescription: string().max(1000).optional(),
  customerDescription: string().max(1000).optional(),
  outputLength: nativeEnum(ResponseLength).optional(),
});

export type ConciergeSettings = z.infer<typeof assistantSettingsSchema>;
