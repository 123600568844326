// extracted by mini-css-extract-plugin
export var danger = "_2t-6";
export var lightPink = "_2t-9";
export var medium = "_2t-i";
export var nearSquare = "_2t-l";
export var neutralBlue = "_2t-8";
export var neutralYellow = "_2t-7";
export var noCapitalize = "_2t-1";
export var normal = "_2t-2";
export var pill = "_2t-0";
export var primary = "_2t-3";
export var primaryLight = "_2t-4";
export var small = "_2t-j";
export var solidBlack = "_2t-h";
export var solidGreen = "_2t-c";
export var solidLightBlue = "_2t-e";
export var solidNavy = "_2t-d";
export var solidOrange = "_2t-g";
export var solidPink = "_2t-f";
export var solidRed = "_2t-a";
export var solidYellow = "_2t-b";
export var success = "_2t-5";
export var xsmall = "_2t-k";