import { ClickAwayListener } from "@mui/material";
import {
  FilterGroupFilterOption,
  WordsFilter,
} from "@redotech/redo-model/conversation-filters";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
  RedoFilterSection,
} from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import { ChipDelimiter, ChipInput } from "@redotech/redo-web/chip-input";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { Dropdown } from "@redotech/redo-web/dropdown";
import { memo, useState } from "react";
import * as conversationMentionsWordsFilterCss from "./conversation-mentions-words-filter.module.css";

export const ConversationMentionsWordsFilter = memo(
  function ConversationContainsWords({
    words,
    setWords,
    removeFilter,
  }: {
    words: WordsFilter;
    setWords: (filter: WordsFilter) => void;
    removeFilter(): void;
  }) {
    const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

    const query = <RedoFilterSection color="secondary" text="contains" />;

    const valueText = words.value
      ? words.value.length === 1
        ? "1 phrase"
        : `${words.value.length} phrases`
      : "...";

    const value = (
      <RedoFilterDropdownAnchor
        onClick={() => setValueDropdownOpen(!valueDropdownOpen)}
        ref={setValueRef}
        text={valueText}
        tooltip={words.value && words.value.join(", ")}
        weight="semibold"
      />
    );

    const [valueDropdownOpen, setValueDropdownOpen] = useState(false);

    const valueDropdown = valueDropdownOpen && (
      <ClickAwayListener onClickAway={() => setValueDropdownOpen(false)}>
        <Dropdown anchor={valueRef} fitToAnchor={false} open>
          <div className={conversationMentionsWordsFilterCss.wordContainer}>
            <ChipInput
              delimiter={ChipDelimiter.NEWLINE}
              showPlaceholderWithoutFocus
              value={words.value || []}
              valueChange={(words) => {
                if (words.length === 0) {
                  setWords({ value: null });
                } else {
                  setWords({ value: [...words] });
                }
              }}
            />
          </div>
        </Dropdown>
      </ClickAwayListener>
    );

    return (
      <>
        {valueDropdown}
        <RedoFilterGroup
          Icon={ConversationFilterOptionToIcon[FilterGroupFilterOption.WORDS]}
          propertyName={
            ConversationFilterOptionToName[FilterGroupFilterOption.WORDS]
          }
          query={query}
          removeFilter={removeFilter}
          value={value}
        />
      </>
    );
  },
);
