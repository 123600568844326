import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import {
  ConversationPlatform,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { FiltersStatus } from "@redotech/redo-model/conversation-filters";
import { SortDirection, TableSort } from "@redotech/redo-model/table";
import {
  Permission,
  GetUser as User,
  permitted,
} from "@redotech/redo-model/user";
import { alertOnFailure } from "@redotech/redo-web/alert";
import CheckSvg from "@redotech/redo-web/arbiter-icon/check_filled.svg";
import DotsHorizontalSvg from "@redotech/redo-web/arbiter-icon/dots-horizontal.svg";
import Edit04Icon from "@redotech/redo-web/arbiter-icon/edit-04.svg";
import StarsSvg from "@redotech/redo-web/arbiter-icon/stars-01_filled.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import {
  CardClickHandler,
  CardList,
  CardListRef,
} from "@redotech/redo-web/card-list";
import { DropdownOption } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import ArchiveIcon from "@redotech/redo-web/icon-old/archive.svg";
import EnvelopeIcon from "@redotech/redo-web/icon-old/mail.svg";
import MergeIcon from "@redotech/redo-web/icon-old/merge.svg";
import SnoozeClockIcon from "@redotech/redo-web/icon-old/snooze-clock.svg";
import SpamIcon from "@redotech/redo-web/icon-old/spam.svg";
import UndoArrow from "@redotech/redo-web/icon-old/undo-arrow.svg";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { idEqual } from "@redotech/util/equal";
import { sinkPromise } from "@redotech/util/promise";
import * as classNames from "classnames";
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { TeamContext } from "../app/team";
import { UserContext } from "../app/user";
import { RedoMerchantClientContext } from "../client/context";
import { MerchantAppEventServerContext } from "../events/merchant-app-event-server-provider";
import { ConversationContext } from "./action-panel/conversation-context";
import { ActiveConversationContext } from "./context/active-conversation-context";
import { ArchiveTicketModal } from "./conversation-actions/archive-ticket-modal";
import { ChangeSubjectModal } from "./conversation-actions/change-subject-modal";
import {
  CloseTicketModal,
  handleCloseTickets,
} from "./conversation-actions/close-ticket-modal";
import { MarkSpamModal } from "./conversation-actions/mark-spam-modal";
import { MergeModal } from "./conversation-actions/merge-modal";
import { ReopenTicketModal } from "./conversation-actions/reopen-ticket-modal";
import { SnoozeModal } from "./conversation-actions/snooze-modal";
import { ConversationContent } from "./conversation-content";
import { ConversationFetcher } from "./conversation-fetcher";
import * as conversationHeaderCss from "./conversation-header.module.css";
import { ConversationInfoCardRender } from "./conversation-info-card";
import {
  FiltersContext,
  SetFiltersContext,
} from "./conversations-table-filters/filters-context";
import {
  markConversationAsRead,
  markConversationAsUnread,
} from "./conversations/mark-conversation-read-manager";
import { MergeSuggestionFlow } from "./merge-suggestion/merge-suggestion-flow";
import { getMergeGroupForConversation } from "./merge-suggestion/utils";
import * as singleConversationViewCss from "./single-conversation-view.module.css";

export const SingleConversationView = memo(function SingleConversationView({
  activeConversation,
  setActiveConversation,
  fetcher,
  blockRefresh,
}: {
  activeConversation: ExpandedConversation;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  fetcher: ConversationFetcher;
  blockRefresh: boolean;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const user = useRequiredContext(UserContext);
  const team = useContext(TeamContext);
  const filters = useContext(FiltersContext);
  const setFilters = useContext(SetFiltersContext);

  const cardListRef = useRef<CardListRef<ExpandedConversation> | null>(null);

  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [reopenModalOpen, setReopenModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [snoozeModalOpen, setSnoozeModalOpen] = useState(false);
  const [markSpamModalOpen, setMarkSpamModalOpen] = useState(false);
  const [changeSubjectModalOpen, setChangeSubjectModalOpen] = useState(false);
  const [mergeSuggestionFlowState, setMergeSuggestionFlowState] = useState<
    "dialog" | "summaries" | "merge" | undefined
  >();
  const modalOpen =
    mergeModalOpen ||
    closeModalOpen ||
    reopenModalOpen ||
    archiveModalOpen ||
    snoozeModalOpen ||
    markSpamModalOpen;
  const [leftPanelOpen, setLeftPanelOpen] = useState(() => {
    const savedLeftPanelOpen = localStorage.getItem(
      "redo.support.conversation-detail.left-panel-open",
    );
    // Default to having the panel open when the localstorage hasn't previously been set.
    return savedLeftPanelOpen !== undefined
      ? savedLeftPanelOpen === "true"
      : true;
  });

  const handleSetLeftPanelOpen = (value: boolean) => {
    setLeftPanelOpen(value);
    localStorage.setItem(
      "redo.support.conversation-detail.left-panel-open",
      value ? "true" : "false",
    );
  };

  const [rightPanelOpen, setRightPanelOpen] = useState(true);
  const [conversationClosing, setConversationClosing] = useState(false);
  const cardListScrollAreaRef = useRef<HTMLDivElement>(null);
  const conversationDetailRef = useRef<HTMLDivElement>(null);

  const canCloseTicket =
    !!user && permitted(user.permissions, Permission.CLOSE_CONVERSATION);
  const canCreateReply =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);
  const canArchiveTicket =
    !!user && permitted(user.permissions, Permission.ARCHIVE_CONVERSATION);

  const [mergeSuggestionDismissed, setMergeSuggestionDismissed] =
    useState<boolean>(true);
  const [suggestedConversationsToMerge, setSuggestedConversationsToMerge] =
    useState<ExpandedConversation[] | undefined>();

  function resetMergeSuggestionFlow() {
    setMergeSuggestionFlowState(undefined);
    setSuggestedConversationsToMerge(undefined);
    setMergeSuggestionDismissed(true);
  }

  const eventServer = useRequiredContext(MerchantAppEventServerContext);

  useEffect(() => {
    const unlistenCallback = eventServer.subscribe(async () => {
      if (blockRefresh) {
        return;
      }
      sinkPromise(cardListRef.current?.refresh() ?? Promise.resolve());
    });
    return () => unlistenCallback();
  }, [blockRefresh]);

  /** When a new conversation is loaded or conversation status changes, fetch merge suggestions */
  useEffect(() => {
    if (
      activeConversation.status === "closed" ||
      !activeConversation.customer?.email
    ) {
      return;
    }
    const abortController = new AbortController();
    void alertOnFailure("Failed to fetch merge suggestions")(async () => {
      let mergeGroup;
      try {
        mergeGroup = (
          await getMergeGroupForConversation({
            client,
            conversationId: activeConversation._id,
            signal: abortController.signal,
          })
        ).data;
      } catch (e: any) {
        if (e.name === "CanceledError") {
          return;
        }
        throw e;
      }
      setMergeSuggestionDismissed(mergeGroup.dismissed);
      const suggestedConversations = mergeGroup.conversations.filter(
        (suggestedConversation) =>
          suggestedConversation._id !== activeConversation._id,
      );
      if (suggestedConversations.length > 0) {
        setSuggestedConversationsToMerge(suggestedConversations);
        if (!mergeGroup.dismissed) {
          setMergeSuggestionFlowState("dialog");
        }
      } else {
        setSuggestedConversationsToMerge(undefined);
      }
    });
    return () => abortController.abort();
  }, [activeConversation?._id, activeConversation?.status]);

  const onConversationsViewed = (conversation: ExpandedConversation) => {
    if (!team) {
      return;
    }
    void markConversationAsRead(client, conversation, team, user._id);
    return conversation;
  };

  const handleCardClick = useHandler<CardClickHandler<ExpandedConversation>>(
    // To keep conversation switching fast, don't await any network requests in this function.
    (record) => {
      const newRecord = onConversationsViewed(record);
      amplitude.logEvent("view-conversationFromCardList", {
        mode: "single",
        conversationId: newRecord?._id,
        teamId: team?._id,
        channels: [newRecord?.platform],
      });
      resetMergeSuggestionFlow();
      setActiveConversation(newRecord);
    },
  );

  const isConversationActive = (conversation: ExpandedConversation) => {
    return activeConversation?._id === conversation._id;
  };

  const handleMarkedUnread = async (
    conversationToMark: ExpandedConversation | undefined,
  ) => {
    if (conversationToMark && team) {
      await markConversationAsUnread(
        client,
        conversationToMark,
        team,
        user._id,
      );
      setActiveConversation(undefined);
    }
  };

  const onKeyDown = async (e: KeyboardEvent) => {
    if (modalOpen || conversationClosing) return;
    const prevConversation = cardListRef.current?.getPrevious(
      activeConversation,
      idEqual,
    );
    const nextConversation = cardListRef.current?.getNext(
      activeConversation,
      idEqual,
    );
    if (e.altKey) {
      switch (e.code) {
        case "KeyC": // Alt+C to close ticket
          e.preventDefault();
          await actions.doCloseTicketAction();
          break;

        case "KeyR": // Alt+R to reopen ticket
          e.preventDefault();
          actions.doReopenTicketAction();
          break;

        case "KeyS": // Alt+S to snooze ticket
          e.preventDefault();
          actions.doSnoozeTicketAction();
          break;

        case "ArrowUp": // Alt+Up to go to previous conversation
          e.preventDefault();
          prevConversation && setActiveConversation(prevConversation);
          break;

        case "ArrowDown": // Alt+Down to go to next conversation
          nextConversation && setActiveConversation(nextConversation);
          e.preventDefault();
          break;
      }
    }
  };

  const actionCleanupFn = () => {
    if (reopenModalOpen) {
      setFilters({
        ...filters,
        status: FiltersStatus.OPEN,
      });
    }
  };

  const actions = {
    doCloseTicketAction: async () => {
      if (!canCloseTicket || activeConversation?.status === "closed" || !team)
        return;
      if (
        localStorage.getItem("redo.doNotShowCloseTicketModal") === "true" ||
        activeConversation?.platform !== ConversationPlatform.REDO_CHAT
      ) {
        setConversationClosing(true);
        await handleCloseTickets({
          client,
          conversations: [activeConversation!],
          setActiveConversation,
          team,
          inDetailView: true,
          cardListRef,
        });
        setConversationClosing(false);
      } else {
        amplitude.logEvent("view-closeConversationModal", {
          mode: "single",
          conversationIds: [activeConversation?._id],
          channels: [activeConversation?.platform],
        });
        setCloseModalOpen(true);
      }
    },
    doReopenTicketAction: () => {
      if (!canCloseTicket || activeConversation?.status !== "closed") return;
      amplitude.logEvent("view-reopenConversationModal", {
        mode: "single",
        conversationIds: [activeConversation?._id],
        channels: [activeConversation?.platform],
      });
      setReopenModalOpen(true);
    },
    doSnoozeTicketAction: () => {
      if (!canCreateReply) return;
      amplitude.logEvent("view-snoozeConversationModal", {
        mode: "single",
        conversationIds: [activeConversation?._id],
        channels: [activeConversation?.platform],
      });
      setSnoozeModalOpen(true);
    },
    doArchiveTicketAction: () => {
      if (!canArchiveTicket) return;
      amplitude.logEvent("view-archiveConversationModal", {
        mode: "single",
        conversationIds: [activeConversation?._id],
        channels: [activeConversation?.platform],
      });
      setArchiveModalOpen(true);
    },
    doMarkUnreadTicketAction: async () => {
      if (
        !activeConversation?.messages.some(
          (message) => message.type === "customer",
        )
      )
        return;
      await handleMarkedUnread(activeConversation);
    },
    doMergeTicketAction: () => {
      if (!canCloseTicket) return;
      amplitude.logEvent("view-mergeConversationModal", {
        mode: "single",
        conversationIds: [activeConversation?._id],
        channels: [activeConversation?.platform],
      });
      setMergeModalOpen(true);
    },
    doMarkSpamTicketAction: () => {
      if (
        activeConversation?.platform !== ConversationPlatform.EMAIL ||
        !canCloseTicket
      )
        return;
      amplitude.logEvent("view-markSpamModal", {
        mode: "single",
        conversationId: activeConversation?._id,
      });
      setMarkSpamModalOpen(true);
    },
    doChangeSubjectAction: () => {
      if (
        activeConversation?.platform !== ConversationPlatform.EMAIL ||
        !canCreateReply
      )
        return;
      amplitude.logEvent("view-changeSubjectModal", {
        mode: "single",
        conversationId: activeConversation?._id,
      });
      setChangeSubjectModalOpen(true);
    },
  };

  const isMac = /mac/i.test(navigator.userAgent);

  const actionButtons = [
    {
      show: canCloseTicket,
      buttonComponent:
        activeConversation?.status !== "closed" ? (
          <Tooltip arrow key="close" title={isMac ? "Option+C" : "Alt+C"}>
            <Button
              className={conversationHeaderCss.headerButtons}
              onClick={actions.doCloseTicketAction}
              size={ButtonSize.NANO}
              theme={ButtonTheme.PRIMARY}
            >
              <Flex align="center" gap="xs" justify="center" wrap="nowrap">
                <Flex
                  align="center"
                  className={classNames(
                    conversationHeaderCss.buttonIconWrapper,
                    conversationHeaderCss.closeButton,
                  )}
                  justify="center"
                >
                  <CheckSvg />
                </Flex>
                <Text fontSize="xs" fontWeight="medium">
                  Close
                </Text>
              </Flex>
            </Button>
          </Tooltip>
        ) : (
          <Tooltip arrow key="reopen" title={isMac ? "Option+R" : "Alt+R"}>
            <Button
              className={conversationHeaderCss.headerButtons}
              onClick={actions.doReopenTicketAction}
              size={ButtonSize.NANO}
              theme={ButtonTheme.PRIMARY}
            >
              <div className={singleConversationViewCss.buttonContent}>
                <UndoArrow className={singleConversationViewCss.icon} />
                <Text fontSize="xs" fontWeight="medium">
                  Reopen
                </Text>
              </div>
            </Button>
          </Tooltip>
        ),
    },
    {
      show: canCreateReply,
      dropdownComponent: (
        <DropdownOption
          action={actions.doSnoozeTicketAction}
          key="snooze"
          tooltip={isMac ? "Option+S" : "Alt+S"}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <SnoozeClockIcon className={singleConversationViewCss.icon} />
            Snooze
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Tooltip arrow title={isMac ? "Option+S" : "Alt+S"}>
          <Button
            onClick={() => {
              amplitude.logEvent("view-snoozeConversationModal", {
                mode: "single",
                conversationIds: [activeConversation?._id],
                channels: [activeConversation?.platform],
              });
              setSnoozeModalOpen(true);
            }}
            size={ButtonSize.SMALL}
            theme={ButtonTheme.OUTLINED}
          >
            <div className={singleConversationViewCss.buttonContent}>
              <SnoozeClockIcon className={singleConversationViewCss.icon} />
              Snooze
            </div>
          </Button>
        </Tooltip>
      ),
    },
    {
      show: canArchiveTicket,
      dropdownComponent: (
        <DropdownOption action={actions.doArchiveTicketAction} key="archive">
          <div className={singleConversationViewCss.buttonContent}>
            <ArchiveIcon className={singleConversationViewCss.icon} />
            Delete
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={actions.doArchiveTicketAction}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <ArchiveIcon className={singleConversationViewCss.icon} />
            Delete
          </div>
        </Button>
      ),
    },
    {
      show: activeConversation?.messages.some(
        (message) => message.type === "customer",
      ),
      dropdownComponent: (
        <DropdownOption
          action={actions.doMarkUnreadTicketAction}
          key="markUnread"
        >
          <div className={singleConversationViewCss.buttonContent}>
            <EnvelopeIcon className={singleConversationViewCss.icon} />
            Mark unread
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={actions.doMarkUnreadTicketAction}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <EnvelopeIcon className={singleConversationViewCss.icon} />
            Mark unread
          </div>
        </Button>
      ),
    },
    {
      show:
        canCloseTicket &&
        activeConversation?.platform &&
        ![
          ConversationPlatform.INSTAGRAM_COMMENTS,
          ConversationPlatform.FACEBOOK_COMMENTS,
        ].includes(activeConversation.platform as ConversationPlatform),
      dropdownComponent: (
        <DropdownOption action={actions.doMergeTicketAction} key="merge">
          <div className={singleConversationViewCss.buttonContent}>
            <MergeIcon className={singleConversationViewCss.icon} />
            Merge
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={actions.doMergeTicketAction}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <MergeIcon className={singleConversationViewCss.icon} />
            Merge
          </div>
        </Button>
      ),
    },
    {
      show:
        activeConversation?.platform === ConversationPlatform.EMAIL &&
        canCloseTicket,
      dropdownComponent: (
        <DropdownOption action={actions.doMarkSpamTicketAction} key="markSpam">
          <div className={singleConversationViewCss.buttonContent}>
            <SpamIcon className={singleConversationViewCss.icon} />
            Mark spam
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={actions.doMarkSpamTicketAction}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <SpamIcon className={singleConversationViewCss.icon} />
            Mark spam
          </div>
        </Button>
      ),
    },
    {
      show:
        activeConversation?.platform === ConversationPlatform.EMAIL &&
        canCreateReply,
      dropdownComponent: (
        <DropdownOption
          action={actions.doChangeSubjectAction}
          key="changeSubject"
        >
          <div className={singleConversationViewCss.buttonContent}>
            <Edit04Icon className={singleConversationViewCss.icon} />
            Change subject
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={actions.doChangeSubjectAction}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <Edit04Icon className={singleConversationViewCss.icon} />
            Change subject
          </div>
        </Button>
      ),
    },
    {
      show:
        !!suggestedConversationsToMerge &&
        suggestedConversationsToMerge.length > 0 &&
        canCloseTicket &&
        activeConversation?.platform !==
          ConversationPlatform.INSTAGRAM_COMMENTS,
      dropdownComponent: (
        <DropdownOption
          action={() => setMergeSuggestionFlowState("summaries")}
          key="view-merge-suggestion"
        >
          <div className={singleConversationViewCss.buttonContent}>
            <StarsSvg className={singleConversationViewCss.icon} />
            View merge suggestion
          </div>
        </DropdownOption>
      ),
    },
  ];

  const enabledActionButtons = actionButtons.filter(
    (actionButton) => actionButton.show,
  );

  const actionsDropdown = (
    <>
      <div className={singleConversationViewCss.dropdownTitle}>
        Other actions
      </div>
      {enabledActionButtons
        .slice(1)
        .map((actionButton) => actionButton.dropdownComponent)}
    </>
  );

  const conversationActionButtons = (
    <div className={singleConversationViewCss.portalButtonsWrapper}>
      {enabledActionButtons.length > 1 && (
        <div className={conversationHeaderCss.threeDotsButton}>
          <ButtonDropdown
            dropdown={actionsDropdown}
            restrictDropdownSizeToParent={false}
            size={ButtonSize.NANO}
            theme={ButtonTheme.OUTLINED}
          >
            <Flex
              align="center"
              className={conversationHeaderCss.buttonIconWrapper}
              justify="center"
            >
              <DotsHorizontalSvg />
            </Flex>
          </ButtonDropdown>
        </div>
      )}
      {enabledActionButtons
        .slice(0, 1)
        .map((actionButton) => actionButton.buttonComponent)}
      {mergeModalOpen && (
        <MergeModal
          conversations={[activeConversation]}
          open={mergeModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setMergeModalOpen}
        />
      )}
      {closeModalOpen && (
        <CloseTicketModal
          cardListRef={cardListRef}
          cleanupFn={actionCleanupFn}
          conversations={[activeConversation]}
          inDetailView
          open={closeModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setCloseModalOpen}
        />
      )}
      {reopenModalOpen && (
        <ReopenTicketModal
          cleanupFn={actionCleanupFn}
          conversations={[activeConversation]}
          inDetailView
          open={reopenModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setReopenModalOpen}
        />
      )}
      {archiveModalOpen && (
        <ArchiveTicketModal
          cardListRef={cardListRef}
          cleanupFn={actionCleanupFn}
          conversations={[activeConversation]}
          inDetailView
          open={archiveModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setArchiveModalOpen}
        />
      )}
      {snoozeModalOpen && (
        <SnoozeModal
          cardListRef={cardListRef}
          cleanupFn={actionCleanupFn}
          conversations={[activeConversation]}
          inDetailView
          open={snoozeModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setSnoozeModalOpen}
        />
      )}
      {markSpamModalOpen && (
        <MarkSpamModal
          cardListRef={cardListRef}
          conversations={[activeConversation]}
          inDetailView
          open={markSpamModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setMarkSpamModalOpen}
        />
      )}
      {changeSubjectModalOpen && (
        <ChangeSubjectModal
          conversation={activeConversation}
          resolve={() => setChangeSubjectModalOpen(false)}
        />
      )}
    </div>
  );

  const sortDefault: TableSort = {
    direction: SortDirection.DESC,
    key: "lastResponse",
  };

  return (
    <ActiveConversationContext.Provider
      value={{
        div: conversationDetailRef,
        ticketActions: actions,
        conversationClosing,
        setConversationClosing,
        setActiveConversation,
        activeConversation,
      }}
    >
      <div
        className={classNames(singleConversationViewCss.wide)}
        onKeyDown={onKeyDown}
        ref={conversationDetailRef}
        tabIndex={0}
      >
        <div
          className={singleConversationViewCss.panel}
          style={!leftPanelOpen ? { display: "none" } : undefined}
        >
          <div className={singleConversationViewCss.tableSummaryContainer}>
            <div className={singleConversationViewCss.tableSummaryHeader}>
              <Flex align="center" justify="flex-start" pl="xl">
                <Text fontSize="sm" fontWeight="semibold" textColor="primary">
                  Tickets
                </Text>
              </Flex>
            </div>
            <div
              className={singleConversationViewCss.cardList}
              ref={cardListScrollAreaRef}
            >
              <CardList
                card={ConversationInfoCardRender}
                fetcher={fetcher}
                isActive={isConversationActive}
                onCardClick={handleCardClick}
                passThroughValues={filters}
                ref={cardListRef}
                scrollAreaRef={cardListScrollAreaRef}
                sortDefault={sortDefault}
              />
            </div>
          </div>
        </div>
        <div className={singleConversationViewCss.centerContent}>
          <div
            className={singleConversationViewCss.conversationDetailContainer}
          >
            <ConversationContent
              actionButtons={conversationActionButtons}
              cardListRef={cardListRef}
              conversation={activeConversation}
              leftPanelOpen={leftPanelOpen}
              rightPanelOpen={rightPanelOpen}
              setActiveConversation={setActiveConversation}
              setLeftPanelOpen={
                handleSetLeftPanelOpen as Dispatch<SetStateAction<boolean>>
              }
              setRightPanelOpen={setRightPanelOpen}
            />
          </div>
        </div>
        <div
          className={
            rightPanelOpen
              ? singleConversationViewCss.panel
              : singleConversationViewCss.hidden
          }
        >
          <div
            className={singleConversationViewCss.conversationContextContainer}
          >
            <ConversationContext
              conversation={activeConversation}
              setActiveConversation={setActiveConversation}
              setConversationAssignee={(user: User | null) => {
                setActiveConversation({
                  ...activeConversation,
                  assignee: user,
                });
              }}
            />
          </div>
        </div>
        {mergeSuggestionFlowState && suggestedConversationsToMerge && (
          <MergeSuggestionFlow
            activeConversation={activeConversation}
            alreadyDismissed={mergeSuggestionDismissed}
            initialStep={mergeSuggestionFlowState}
            onClose={() => setMergeSuggestionFlowState(undefined)}
            suggestedConversations={suggestedConversationsToMerge}
          />
        )}
      </div>
    </ActiveConversationContext.Provider>
  );
});
