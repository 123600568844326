import HelpIcon from "@redotech/redo-web/arbiter-icon/help-circle.svg";
import * as classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  HTMLInputTypeAttribute,
  JSXElementConstructor,
  memo,
  useEffect,
  useState,
} from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { SpacingValue } from "../../theme/box";
import { textClasses, TextProps, TextSizeValue } from "../../theme/typography";
import { Tooltip } from "../../tooltip/tooltip";
import * as redoTextInputCss from "./redo-text-input.module.css";

export enum RedoTextInputSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum RedoInputState {
  DEFAULT = "Default",
  DISABLED = "Disabled",
  ERROR = "Error",
  READONLY = "Readonly",
}

/**
 * @param inputTypeHint -- if you have one of these types of input attributes,
 * browser autofill and other helpful features will work better if you define it.
 *
 * @param required -- when used in a form, will prevent the form from submitting if the input is empty.
 *
 * @param dangerousStyleThatShouldOnlyBeUsedForMerchantBranding -- this prop should only be defined
 * when we want the input to follow a merchant's branding. Do not use it to style this component for a Redo use case.
 */
export interface RedoTextInputProps {
  value: string;
  setValue(value: string): void;
  placeholder?: string;
  size?: RedoTextInputSize;
  state?: RedoInputState;
  label?: string;
  description?: string;
  IconLeading?: JSXElementConstructor<any>;
  infoTooltip?: string;
  inputTypeHint?: HTMLInputTypeAttribute;
  required?: boolean;
  maxLength?: number;
  className?: string;
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
  name?: string;
}

export const RedoTextInput = memo(
  forwardRef(function RedoTextInput(
    {
      size = RedoTextInputSize.SMALL,
      value,
      placeholder,
      setValue,
      IconLeading,
      infoTooltip,
      inputTypeHint,
      label,
      description,
      state = RedoInputState.DEFAULT,
      className,
      required,
      maxLength,
      dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
      name,
    }: RedoTextInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    function handleInputContentChange(
      event: React.ChangeEvent<HTMLInputElement>,
    ) {
      setValue(event.target.value);
    }

    const descriptorFontSize = sizeToDescriptorTextProps[size];

    const error = state === RedoInputState.ERROR;
    const disabled = state === RedoInputState.DISABLED;
    const readonly = state === RedoInputState.READONLY;

    // Needed for readonly styles
    const [measureBox, setMeasureBox] = useState<HTMLDivElement | null>(null);
    const [textWidth, setTextWidth] = useState<number>(0);

    function recalculateInputWidth() {
      requestAnimationFrame(() => {
        if (!measureBox) {
          return;
        }
        const width = measureBox.offsetWidth;
        setTextWidth(width);
      });
    }

    useEffect(() => {
      if (readonly) {
        recalculateInputWidth();
      }
    }, [placeholder, value, measureBox, readonly]);

    return (
      <>
        <Flex bgColor="primary" dir="column" gap="sm">
          {label && <Text fontSize={descriptorFontSize}>{label}</Text>}
          <Flex
            align="center"
            as="label"
            className={classNames(
              disabled && redoTextInputCss.disabled,
              redoTextInputCss.inputWrapper,
              error && redoTextInputCss.error,
              sizeStyles[size],
              readonly && redoTextInputCss.readonly,
              className,
            )}
            px={readonly ? undefined : sizeToPx[size]}
            style={dangerousStyleThatShouldOnlyBeUsedForMerchantBranding}
          >
            {IconLeading && !readonly && (
              <Flex
                className={classNames(
                  sizeStyles[size],
                  redoTextInputCss.iconWrapper,
                )}
              >
                <IconLeading />
              </Flex>
            )}
            <input
              className={classNames(
                textClasses(sizeToInputTextProps[size]),
                redoTextInputCss.input,
              )}
              disabled={disabled || readonly}
              maxLength={maxLength}
              name={name}
              onChange={handleInputContentChange}
              placeholder={placeholder}
              ref={ref}
              required={required}
              style={readonly ? { width: textWidth + "px" } : {}}
              type={inputTypeHint}
              value={value}
            />

            {infoTooltip && (
              <Tooltip title={infoTooltip}>
                <Flex
                  className={classNames(
                    sizeStyles[size],
                    redoTextInputCss.iconWrapper,
                    error && redoTextInputCss.error,
                  )}
                >
                  <HelpIcon />
                </Flex>
              </Tooltip>
            )}
          </Flex>
          {description && (
            <Text
              fontSize={descriptorFontSize}
              textColor={error ? "error" : "tertiary"}
            >
              {description}
            </Text>
          )}
        </Flex>
        <Flex className={redoTextInputCss.measureBox}>
          <Text
            className={classNames(textClasses(sizeToInputTextProps[size]))}
            ref={setMeasureBox}
          >
            {value || placeholder}
          </Text>
        </Flex>
      </>
    );
  }),
);

const sizeStyles: Record<RedoTextInputSize, string> = {
  [RedoTextInputSize.SMALL]: redoTextInputCss.small,
  [RedoTextInputSize.MEDIUM]: redoTextInputCss.medium,
  [RedoTextInputSize.LARGE]: redoTextInputCss.large,
};

const sizeToInputTextProps: Record<RedoTextInputSize, TextProps> = {
  [RedoTextInputSize.SMALL]: { fontSize: "xs" },
  [RedoTextInputSize.MEDIUM]: { fontSize: "md" },
  [RedoTextInputSize.LARGE]: { fontSize: "md" },
};

const sizeToDescriptorTextProps: Record<RedoTextInputSize, TextSizeValue> = {
  [RedoTextInputSize.SMALL]: "xs",
  [RedoTextInputSize.MEDIUM]: "sm",
  [RedoTextInputSize.LARGE]: "sm",
};

const sizeToPx: Record<RedoTextInputSize, SpacingValue> = {
  [RedoTextInputSize.SMALL]: "md",
  [RedoTextInputSize.MEDIUM]: "lg",
  [RedoTextInputSize.LARGE]: "xl",
};
