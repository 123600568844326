import { ClickAwayListener } from "@mui/material";
import { genericMemo } from "@redotech/react-util/component";
import { ReactNode, useEffect, useState } from "react";
import { Dropdown } from "../../dropdown";
import { RedoList, RedoListItem } from "../list/redo-list";
import { RedoListItemSize } from "../list/redo-list-item";

export const RedoMultiSelectDropdown = genericMemo(
  function RedoMultiSelectDropdown<T>({
    dropdownButtonRef,
    options,
    setSelectedOptions,
    children,
    selectedOptions,
    keyFn,
    size,
  }: {
    dropdownButtonRef: HTMLButtonElement | null;
    options: RedoListItem<T>[];
    setSelectedOptions(value: RedoListItem<T>[]): void;
    children(item: RedoListItem<T>): ReactNode;
    selectedOptions: RedoListItem<T>[];
    keyFn?: (item: RedoListItem<T>) => string | number;
    size?: RedoListItemSize;
  }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    function itemsEqual(item1: RedoListItem<T>, item2: RedoListItem<T>) {
      if (keyFn) {
        return keyFn(item1) === keyFn(item2);
      }
      return item1.value === item2.value;
    }

    function isOptionSelected(item: RedoListItem<T>) {
      return selectedOptions.some((selectedItem) =>
        itemsEqual(item, selectedItem),
      );
    }

    const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
      undefined,
    );

    function toggleDropdownOpen() {
      setDropdownOpen(!dropdownOpen);
      setFocusedIndex(undefined);
    }

    useEffect(() => {
      if (!dropdownButtonRef) {
        return;
      }
      dropdownButtonRef.addEventListener("click", toggleDropdownOpen);
      return () => {
        dropdownButtonRef.removeEventListener("click", toggleDropdownOpen);
      };
    }, [dropdownButtonRef, setDropdownOpen, dropdownOpen]);

    function handleItemToggled(item: RedoListItem<T>) {
      const newSelectedOptions = isOptionSelected(item)
        ? selectedOptions.filter(
            (selectedItem) => !itemsEqual(selectedItem, item),
          )
        : [...selectedOptions, item];
      setSelectedOptions(newSelectedOptions);
    }

    return (
      <>
        {dropdownOpen && (
          <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
            <Dropdown anchor={dropdownButtonRef} fitToAnchor={false} open>
              <RedoList
                focusedIndex={focusedIndex}
                isItemSelected={isOptionSelected}
                items={options}
                itemSelected={handleItemToggled}
                keyFn={keyFn}
                refToListenTo={dropdownButtonRef}
                setFocusedIndex={setFocusedIndex}
                size={size}
              >
                {(item) => children(item)}
              </RedoList>
            </Dropdown>
          </ClickAwayListener>
        )}
      </>
    );
  },
);
