import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { ConciergeConversationMessageDbParser } from "./concierge-conversation-message-db-parser";
import {
  ContentType,
  SenderType,
} from "./concierge-conversation-message-definition";

export const CURRENT_CONVERSATION_VERSION = "v2";

export const ConciergeConversationDbParser = z
  .object({
    _id: zExt.objectId(),
    team: zExt.objectId(),
    email: z.string().optional(),
    messages: z.array(ConciergeConversationMessageDbParser),
    createdAt: z.date(),
    updatedAt: z.date(),
    version: z.string().default(CURRENT_CONVERSATION_VERSION),
  })
  .refine(
    (data) => {
      for (let i = 1; i < data.messages.length; i++) {
        const currentMessage = data.messages[i];
        const previousMessage = data.messages[i - 1];

        if (currentMessage.senderType === SenderType.TOOL) {
          if (previousMessage.contentType !== ContentType.TOOL_CALL) {
            return false;
          }
        }
      }
      return true;
    },
    {
      message:
        "Any message with senderType TOOL must be preceded by a message with contentType TOOL_CALL",
      path: ["messages"],
    },
  );

export type ConciergeConversation = z.infer<
  typeof ConciergeConversationDbParser
>;
