import { z } from "zod";
import { IReview, ReviewDbParser } from "./review-db-parser";
import { ReviewFieldSchemaType, ReviewRating } from "./review-field-schema";

export const reviewBodySchema = ReviewDbParser.extend({
  _id: z.string(),
  teamId: z.string(),
  rating: z.optional(z.nativeEnum(ReviewRating)),
});

export type ReviewBody = z.infer<typeof reviewBodySchema>;

export function reviewToReviewBodySchema(
  review: IReview,
): z.infer<typeof reviewBodySchema> {
  const reviewRating = review.reviewFields.find(
    (field) => field.fieldType === ReviewFieldSchemaType.REVIEW_RATING,
  );
  return {
    ...review,
    rating: reviewRating?.rating,
  };
}
