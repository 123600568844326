import {
  FilterOptionsV2,
  FiltersStatus,
  filterOptionsV2JsonFormat,
  getFiltersQueryParam,
} from "@redotech/redo-model/conversation-filters";
import { deepIntersectionEquals } from "@redotech/util/equal";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { ActiveViewContext } from "./active-view-context";

export const FiltersContext = createContext<FilterOptionsV2>({});

export const SetFiltersContext = createContext<
  (filters: FilterOptionsV2) => void
>(() => {});

export const FiltersProvider = memo(function FiltersProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const activeView = useContext(ActiveViewContext);

  const [filterAdjustments, setFilterAdjustments] = useState<
    FilterOptionsV2 | undefined
  >();

  const navigate = useNavigate();
  const location = useLocation();

  const baseFilters = useMemo(() => {
    return activeView.filters;
  }, [activeView]);

  const filters = useMemo(() => {
    return { ...baseFilters, ...filterAdjustments };
  }, [baseFilters, filterAdjustments]);

  useEffect(() => {
    const urlFilters = queryParamsToFilters(location.search);
    if (!deepIntersectionEquals(urlFilters, filterAdjustments)) {
      setFilterAdjustments(urlFilters);
    }
  }, [location]);

  const updateFilters = useMemo(
    () => (newFilters: FilterOptionsV2) => {
      _updateFilters(newFilters, baseFilters, navigate);
    },
    [baseFilters, navigate],
  );

  useEffect(() => {
    const urlFilters = queryParamsToFilters(location.search);
    if (!urlFilters?.status) {
      updateFilters({ ...filters, status: FiltersStatus.OPEN });
    }
  }, [activeView]);

  return (
    <FiltersContext.Provider value={filters}>
      <SetFiltersContext.Provider value={updateFilters}>
        {children}
      </SetFiltersContext.Provider>
    </FiltersContext.Provider>
  );
});

function _updateFilters(
  newFilters: FilterOptionsV2,
  baseFilters: FilterOptionsV2,
  navigate: NavigateFunction,
) {
  newFilters = scrubNullFiltersThatArentPresentOnTheBaseFilter(
    newFilters,
    baseFilters,
  );

  const searchParams = new URLSearchParams(location.search);

  if (deepIntersectionEquals(newFilters, baseFilters)) {
    searchParams.delete("filters");
  } else {
    const filterParam = getFiltersQueryParam(newFilters);
    if (filterParam) {
      searchParams.set("filters", filterParam);
    } else {
      searchParams.delete("filters");
    }
  }

  const newSearch = searchParams.toString();
  if (newSearch === location.search) {
    return;
  }

  navigate({
    search: searchParams.toString(),
  });
}

const queryParamsToFilters = (
  rawSearchString: string,
): FilterOptionsV2 | undefined => {
  const queryParams = new URLSearchParams(rawSearchString);
  const filters = queryParams.get("filters");
  if (!filters) {
    return undefined;
  }
  const rawJSON = JSON.parse(decodeURIComponent(filters));
  const parsedJSON = filterOptionsV2JsonFormat.read(rawJSON);
  return parsedJSON;
};

/**
 * When we are trying to tell if the filters differ from the base view,
 * we want to ignore null filters if they are already not present on the base view.
 */
export function scrubNullFiltersThatArentPresentOnTheBaseFilter(
  newFilters: FilterOptionsV2,
  baseFilters: FilterOptionsV2,
): FilterOptionsV2 {
  const newFiltersCopy: Record<string, any> = { ...newFilters };
  const baseFiltersCopy: Record<string, any> = { ...baseFilters };
  for (const key in newFiltersCopy) {
    if (newFiltersCopy[key] === null && !baseFiltersCopy[key]) {
      delete newFiltersCopy[key];
    }
  }
  return newFiltersCopy;
}
