// extracted by mini-css-extract-plugin
export var active = "_19-d";
export var blink = "_19-e";
export var container = "_19-5";
export var controls = "_19-0";
export var controlsRight = "_19-1";
export var fakeCursor = "_19-c";
export var hidden = "_19-b";
export var quillContainerSmall = "_19-7";
export var quillEditor = "_19-6";
export var quillEditorSmall = "_19-8";
export var quillFormatButtons = "_19-a";
export var quillToolbar = "_19-9";
export var removePadding = "_19-2";
export var state = "_19-3";
export var stateTitle = "_19-4";