import { emailRegex } from "@redotech/util/email";

export type Email = string;

/**
 * Un-validated email address and name type.
 */
export type EmailAddressInfo = {
  email?: Email | null;
  name?: string | null;
};

/**
 * Validated email addresses and optional name.
 */
export type EmailAddressInfoWithValidEmail = {
  email: Email;
} & EmailAddressInfo;

export type EmailSMTPMessageId = string;

/**
 * Email envelope info - an empty list means "don't send any cc / bcc / to", but undefined means "use the default by looking up from the conversation object"
 * If you pass lists to all 3, ensure at least one email is present in one of the lists.
 */
export type EmailEnvelopeInfo = {
  to?: EmailAddressInfoWithValidEmail[] | undefined;
  cc?: EmailAddressInfoWithValidEmail[] | undefined;
  bcc?: EmailAddressInfoWithValidEmail[] | undefined;
  from: EmailAddressInfoWithValidEmail;

  /**
   * Used for 2 things:
   * 1. for the inReplyTo header of this email
   * 2. to create the reply quote, by looking up the message
   * whose email's id matches this.
   *
   * If we make email reply threads editable at time of send, the entire
   * html including the reply will need to be passed here instead
   */
  inReplyTo?: EmailSMTPMessageId | undefined;
};

export type EmailDraftRecipientInfo = Omit<EmailEnvelopeInfo, "from">;

export enum EmailReplyType {
  REPLY = "Reply",
  REPLY_ALL = "Reply All",
  FORWARD = "Forward",
}

type EmailRecipients = Omit<EmailEnvelopeInfo, "from" | "inReplyTo">;

function countNRecipientEmails(
  emailEnvelopeInfo: EmailRecipients | undefined,
): number {
  return (
    (emailEnvelopeInfo?.to?.length ?? 0) +
    (emailEnvelopeInfo?.cc?.length ?? 0) +
    (emailEnvelopeInfo?.bcc?.length ?? 0)
  );
}

export function hasAtLeastOneRecipientEmail(
  emailEnvelopeInfo: Omit<EmailRecipients, "from" | "inReplyTo"> | undefined,
): boolean {
  return countNRecipientEmails(emailEnvelopeInfo) > 0;
}

export function hasExactlyOneRecipientEmail(
  emailEnvelopeInfo: EmailRecipients | undefined,
): boolean {
  return countNRecipientEmails(emailEnvelopeInfo) === 1;
}

/**
 * Returns the lowercased email if it matches the email regex, otherwise undefined.
 */
export const filterAndPreprocessOneEmail = (
  email: string | null | undefined,
): Email | undefined => {
  const match = email?.toLowerCase()?.match(emailRegex);
  return match?.[0];
};

/**
 * Ensure each email both is a valid email and is lowercased.
 */
export function filterAndPreprocessEmailInfo(
  emailAddressInfo: EmailAddressInfo[],
): EmailAddressInfoWithValidEmail[] {
  return emailAddressInfo
    .map((emailAddressInfo) => ({
      ...emailAddressInfo,
      email: filterAndPreprocessOneEmail(emailAddressInfo?.email),
    }))
    .filter(
      (emailAddressInfo) =>
        emailAddressInfo.email !== undefined && emailAddressInfo.email !== null,
    ) as EmailAddressInfoWithValidEmail[];
}

export function stringifyEmailRecipientsInfo(
  emailEnvelopeInfo: EmailRecipients,
): string {
  const to = emailEnvelopeInfo.to?.map((e) => e.email).join(", ");
  const cc = emailEnvelopeInfo.cc?.map((e) => e.email).join(", ");
  const bcc = emailEnvelopeInfo.bcc?.map((e) => e.email).join(", ");
  const formattedTo = to ? `to: ${to}` : "";
  const formattedCc = cc ? `cc: ${cc}` : "";
  const formattedBcc = bcc ? `bcc: ${bcc}` : "";
  return [formattedTo, formattedCc, formattedBcc].filter((e) => e).join(", ");
}

export const tryGetDisplayNameOfEmailer = (
  emailAddressInfo: EmailAddressInfo,
): string | undefined => {
  return emailAddressInfo.name || emailAddressInfo?.email?.split("@")?.[0];
};
