import { object, string } from "zod";
import { assistantSettingsSchema } from "./assistant-settings-schema";

export const getAssistantSettingsSchema = {
  input: object({
    teamId: string(),
  }),
  output: object({
    assistantSettings: assistantSettingsSchema.optional(),
  }),
};
