import { useHover } from "@redotech/react-util/hover";
import ThreeDotsHorizontalIcon from "@redotech/redo-web/arbiter-icon/dots-horizontal.svg";
import { Flex } from "@redotech/redo-web/flex";
import CheckIcon from "@redotech/redo-web/icon-old/check.svg";
import * as classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  memo,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { RedoButton, RedoButtonSize } from "../buttons/redo-button";
import { RedoCheckbox, RedoCheckboxSize } from "../checkbox/redo-checkbox";
import {
  RedoCommandMenu,
  RedoCommandMenuItem,
} from "../command-menu/redo-command-menu";
import * as redoListItemCss from "./redo-list-item.module.css";

export enum RedoListItemSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum RedoListItemVariant {
  CHECKMARK = "checkmark",
  CHECKBOX = "checkbox",
}

export type RedoListItemMenu = { onClick(): void } | RedoCommandMenuItem[];

export interface RedoListItemProps {
  children: ReactNode;
  size: RedoListItemSize;
  selectionVariant?: RedoListItemVariant;
  selected?: boolean;
  menu?: RedoListItemMenu;
  focused?: boolean;
  itemClicked(): void;
  onItemHovered(hovered: boolean): void;
}

export const RedoListItem = memo(
  forwardRef(function RedoListItem(
    {
      size,
      children,
      selectionVariant = RedoListItemVariant.CHECKMARK,
      selected,
      menu,
      focused: externallyFocused,
      itemClicked,
      onItemHovered,
    }: RedoListItemProps,
    ref: ForwardedRef<HTMLElement>,
  ) {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const isHovered = useHover(anchor);

    useEffect(() => {
      onItemHovered(isHovered);
    }, [isHovered]);

    if (Array.isArray(menu) && menu.length === 0) {
      menu = undefined;
    }

    function setAnchorRef(anchorRef: HTMLElement | null) {
      setAnchor(anchorRef);
      if (typeof ref === "function") {
        ref(anchorRef);
      } else if (ref) {
        ref.current = anchorRef;
      }
    }

    const [menuOpen, setMenuOpen] = useState(false);

    const focused = menuOpen || externallyFocused;

    const sizeMapping: Record<RedoListItemSize, RedoButtonSize> = {
      [RedoListItemSize.SMALL]: RedoButtonSize.EXTRA_SMALL,
      [RedoListItemSize.MEDIUM]: RedoButtonSize.SMALL,
      [RedoListItemSize.LARGE]: RedoButtonSize.REGULAR,
    };

    const listItemMenu = menu && focused && (
      <RedoListItemMenu
        menu={menu}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        size={sizeMapping[size]}
      />
    );

    const rightArea =
      focused && listItemMenu ? (
        listItemMenu
      ) : selected && selectionVariant === RedoListItemVariant.CHECKMARK ? (
        <Flex p="md">
          <CheckIcon />
        </Flex>
      ) : null;

    const leftArea =
      selectionVariant === RedoListItemVariant.CHECKBOX ? (
        <div>
          <RedoCheckbox
            setValue={() => itemClicked()}
            size={RedoCheckboxSize.SMALL}
            value={!!selected}
          />
        </div>
      ) : null;

    return (
      <Flex
        align="center"
        className={classNames(
          redoListItemCss.listItemContainer,
          (focused || selected) && redoListItemCss.focused,
          redoListItemSizeCss[size],
        )}
        gap="none"
        justify="space-between"
        radius={size === RedoListItemSize.SMALL ? "xs" : "md"}
        ref={setAnchorRef}
      >
        {leftArea && (
          <div className={redoListItemCss.leftAreaContainer}>{leftArea}</div>
        )}
        <Flex
          as="button"
          className={redoListItemCss.childrenContainer}
          onClick={(event) => {
            itemClicked();
          }}
          pl="xs"
          pr="md"
          py="md"
        >
          {children}
        </Flex>
        {rightArea && (
          <Flex className={redoListItemCss.rightArea}>{rightArea}</Flex>
        )}
      </Flex>
    );
  }),
);

const RedoListItemMenu = memo(function RedoListItemMenu({
  menu,
  menuOpen,
  setMenuOpen,
  size,
}: {
  menu: RedoListItemMenu;
  setMenuOpen(value: boolean): void;
  menuOpen: boolean;
  size: RedoButtonSize;
}) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  if (Array.isArray(menu)) {
    return (
      <>
        <RedoButton
          IconLeading={ThreeDotsHorizontalIcon}
          onClick={(event) => {
            setMenuOpen(!menuOpen);
          }}
          ref={setAnchor}
          size={RedoButtonSize.REGULAR}
        />
        <RedoCommandMenu
          anchor={anchor}
          items={menu}
          open={menuOpen}
          setOpen={setMenuOpen}
          size={size}
        />
      </>
    );
  } else {
    return (
      <RedoButton
        IconLeading={ThreeDotsHorizontalIcon}
        onClick={() => menu.onClick()}
        ref={setAnchor}
        size={size}
      />
    );
  }
});

const redoListItemSizeCss: Record<RedoListItemSize, string> = {
  [RedoListItemSize.SMALL]: redoListItemCss.small,
  [RedoListItemSize.MEDIUM]: redoListItemCss.medium,
  [RedoListItemSize.LARGE]: redoListItemCss.large,
};
