// extracted by mini-css-extract-plugin
export var box = "_2f-0";
export var check = "_2f-1";
export var checkboxButton = "_2f-6";
export var checkboxGroup = "_2f-5";
export var disabled = "_2f-7";
export var ghost = "_2f-9";
export var input = "_2f-2";
export var label = "_2f-4";
export var normal = "_2f-8";
export var uncheck = "_2f-3";