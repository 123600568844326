// extracted by mini-css-extract-plugin
export var colorSquare = "_7p-0";
export var danger = "_7p-5";
export var lightPink = "_7p-8";
export var neutralBlue = "_7p-7";
export var neutralYellow = "_7p-6";
export var normal = "_7p-1";
export var primary = "_7p-2";
export var primaryLight = "_7p-3";
export var solidBlack = "_7p-g";
export var solidGreen = "_7p-b";
export var solidLightBlue = "_7p-d";
export var solidNavy = "_7p-c";
export var solidOrange = "_7p-f";
export var solidPink = "_7p-e";
export var solidRed = "_7p-9";
export var solidYellow = "_7p-a";
export var success = "_7p-4";