// extracted by mini-css-extract-plugin
export var alignCenter = "_4m-b";
export var buttonContent = "_4m-0";
export var cardList = "_4m-1";
export var centerContent = "_4m-2";
export var chevron = "_4m-3";
export var closePanelButton = "_4m-h";
export var conversationDetailContainer = "_4m-4";
export var detailWrapper = "_4m-5";
export var dropdownButton = "_4m-c";
export var dropdownTitle = "_4m-7";
export var hidden = "_4m-8";
export var icon = "_4m-9";
export var panel = "_4m-g";
export var portalButtonsWrapper = "_4m-a";
export var tableSummaryContainer = "_4m-6";
export var tableSummaryHeader = "_4m-e";
export var threeDotsButton = "_4m-d";
export var wide = "_4m-f";