import * as amplitude from "@amplitude/analytics-browser";
import { Divider } from "@mui/material";
import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import {
  WidgetClient,
  getOrder as getOrderFromWidget,
  getProduct,
  submitReturn,
  uploadImages,
} from "@redotech/redo-api-client/widget";
import { Order } from "@redotech/redo-model/order";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { Product, ReturnType } from "@redotech/redo-model/return";
import {
  InputAnswer,
  InputPartType,
  Payer,
  ProvisionType,
  ReturnFlow,
  ReturnItem,
  ReturnRequest,
  ReturnRequestStatus,
} from "@redotech/redo-model/return-flow";
import { ReturnTotalsCalculator } from "@redotech/redo-model/return-totals-calculator";
import { Team } from "@redotech/redo-model/team";
import { GetUser } from "@redotech/redo-model/user";
import { AddressEditModal } from "@redotech/redo-web/address-edit-modal";
import { alertOnFailure } from "@redotech/redo-web/alert";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { RedoClientContext } from "@redotech/redo-web/client";
import { ContactInfoModal } from "@redotech/redo-web/contact-info-modal";
import { CurrencyContext } from "@redotech/redo-web/currency";
import Camera from "@redotech/redo-web/icon-old/camera.svg";
import ChevronLeftIcon from "@redotech/redo-web/icon-old/chevron-left.svg";
import EditPencilIcon from "@redotech/redo-web/icon-old/edit-pencil.svg";
import SearchIcon from "@redotech/redo-web/icon-old/search.svg";
import XIcon from "@redotech/redo-web/icon-old/x.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { Switch } from "@redotech/redo-web/switch";
import { TextInput } from "@redotech/redo-web/text-input";
import { nativeRandom, randomHexString } from "@redotech/util/random";
import { CanceledError } from "axios";
import * as capitalize from "lodash/capitalize";
import {
  Dispatch,
  SetStateAction,
  memo,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { TeamContext } from "../app/team";
import { UserContext } from "../app/user";
import { RedoMerchantClientContext } from "../client/context";
import { Address, ContactInfo, getOrders } from "../client/order";
import { approveReturn } from "../client/return";
import * as claimReturnSharedCss from "./claim-return-shared.module.css";
import { ApprovalProductInfo } from "./return-modals/approve-modal";
import { hasEditReturnPermissions } from "./util";

const defaultReturnTypeOptions = [
  {
    value: null,
    displayName: "Select method",
  },
  {
    value: "store_credit",
    displayName: "Store credit",
  },
  {
    value: "exchange",
    displayName: "Same item exchange",
  },
];

function getReturnTypeOptions(
  returnType: ReturnType,
  team: Team | undefined,
  user: GetUser | undefined,
) {
  const options = [...defaultReturnTypeOptions];

  if (hasEditReturnPermissions(team, user)) {
    options.push({
      value: "refund",
      displayName: "Refund",
    });
  }

  if (returnType === "warranty") {
    options.push({
      value: "repair",
      displayName: "Repair",
    });
  }
  return options;
}

function getClaimReasonOptions(
  reasonOptions: string[],
  returnType: ReturnType,
) {
  if (reasonOptions.length > 0) {
    return reasonOptions;
  }
  const defaultOptions = ["Damaged"];
  if (returnType === "claim") {
    defaultOptions.push("Never received the item");
  }
  return defaultOptions;
}

export interface MultipleChoiceQuestion {
  message: string;
  choices: string[];
}

// FIXME Similar, but not exactly the same as the MultipleChoiceAnswer type in redo/model/return
export interface MultipleChoiceAnswer {
  questionText: string;
  answer: string;
  reason: boolean;
}

export function getImageUrl(lineItem: any): string | undefined {
  const variant = lineItem.product?.variants.find(
    (variant: any) => variant.id === lineItem.variant_id,
  );
  if (!variant?.image_id) {
    return lineItem.image?.src ?? lineItem.images?.[0] ?? undefined;
  }
  const image = lineItem.product.images.find(
    (image: any) => image.id === variant.image_id,
  );
  return image?.src || null;
}

export const parseFlow = (flow: ReturnFlow | undefined) => {
  if (!flow?.steps.length) {
    return {
      reasonOptions: [],
      multipleChoiceQuestions: [],
    };
  }
  const reasonOptions = new Set<string>();
  const multipleChoiceQuestions: MultipleChoiceQuestion[] = [];
  // FIXME Exceeds the call stack if the flow has a loop
  const getReturnReasons = (currentReason: string, step: any) => {
    if (!step) {
      return;
    }
    switch (step.type) {
      case "submit_claim":
      case "reject":
      case "return":
      case "block":
        if (currentReason) {
          reasonOptions.add(currentReason);
        }
        break;
      case "multiple_choice":
        step.options.forEach((option: any) => {
          if (!step.reason) {
            getReturnReasons(`${currentReason}`, flow.steps[option.next]);
          } else if (!currentReason) {
            getReturnReasons(`${option.name}`, flow.steps[option.next]);
          } else {
            getReturnReasons(
              `${currentReason}-${option.name}`,
              flow.steps[option.next],
            );
          }
        });
        break;
      case "condition":
        getReturnReasons(currentReason, flow.steps[step.nextTrue]);
        getReturnReasons(currentReason, flow.steps[step.nextFalse]);
        break;
      default:
        // input and manual review
        getReturnReasons(currentReason, flow.steps[step.next]);
        break;
    }
  };

  // Gets all return reasons and nests them
  getReturnReasons("", flow.steps[0]);
  flow.steps.forEach((step: any) => {
    if (step.type === "multiple_choice" && !step.reason) {
      const duplicateQuestion = multipleChoiceQuestions.find(
        (question: MultipleChoiceQuestion) => question.message === step.message,
      );
      if (duplicateQuestion) {
        step.options.forEach((option: any) => {
          const index = multipleChoiceQuestions.findIndex(
            (question: MultipleChoiceQuestion) =>
              question.message === step.message,
          );
          if (!duplicateQuestion.choices.includes(option.name)) {
            multipleChoiceQuestions[index].choices.push(option.name);
          }
        });
      } else {
        const question = {
          message: step.message,
          choices: step.options.map((option: any) => option.name),
        };
        multipleChoiceQuestions.push(question);
      }
    }
  });
  return {
    reasonOptions: Array.from(reasonOptions),
    multipleChoiceQuestions,
  };
};

export const MerchantCreateReturnModal = memo(
  function MerchantCreateReturnModal({
    open,
    setOpen,
    returnType,
    relocationInfo,
  }: {
    open: boolean;
    setOpen: (open: boolean) => void;
    returnType: ReturnType;
    relocationInfo?: {
      preselectedOrder: Order;
      currentView?: string;
      returnQueryParams?: { [key: string]: string };
    };
  }) {
    const team = useContext(TeamContext);
    const user = useContext(UserContext);
    const [editAddressModalOpen, setAddressEditModalOpen] =
      useState<boolean>(false);
    const [contactEditModalOpen, setContactEditModalOpen] =
      useState<boolean>(false);
    const [orderNumber, setOrderNumber] = useState<string>(
      relocationInfo?.preselectedOrder
        ? relocationInfo.preselectedOrder.shopify.name
        : "",
    );
    const [submitPending, setSubmitPending] = useState<boolean>(false);
    const [approvalPending, setApprovalPending] = useState<boolean>(false);
    const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
    const [newReturnId, setNewReturnId] = useState<string | null>(null);
    const navigate = useNavigate();
    const client = useRequiredContext(RedoClientContext);
    const merchantClient = useRequiredContext(RedoMerchantClientContext);
    const [lineItemsSelected, setLineItemsSelected] = useState<any[]>([]);
    const [claimReason, setClaimReason] = useState<string | undefined>(
      undefined,
    );
    const [compensationMethod, setCompensationMethod] = useState<string | null>(
      null,
    );
    const [shouldShip, setShouldShip] = useState<boolean>(false);
    const [idempotencyKey, setIdempotencyKey] = useState<string>("");
    const [address, setAddress] = useState<Address | undefined>();

    const [contactInfo, setContactInfo] = useState<ContactInfo>({
      name: "",
      email: "",
      phone: "",
    });

    const [foundOrder, setFoundOrder] = useState<Order | null>(null);
    const [itemsNotReturned, setItemsNotReturned] = useState<any[]>([]);
    const [itemsAlreadyReturned, setItemsAlreadyReturned] = useState<any[]>([]);
    const [questionAnswers, setQuestionAnswers] = useState<any[]>([]);
    const [note, setNote] = useState<string>("");
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const returnFlow =
      returnType === "warranty"
        ? (team?.settings?.warrantyFlow as ReturnFlow | undefined)
        : (team?.settings?.claimFlow as ReturnFlow | undefined);
    const {
      reasonOptions,
      multipleChoiceQuestions: claimMultipleChoiceQuestions,
    } = parseFlow(returnFlow);
    const claimReasonOptions = getClaimReasonOptions(reasonOptions, returnType);
    const returnTypeOptions = getReturnTypeOptions(returnType, team, user);
    const isClaimOrWarranty =
      returnType === "claim" || returnType === "warranty";

    const [findOrdersLoad, findOrders] = useTriggerLoad(async (signal) => {
      if (team) {
        try {
          setFoundOrder(null);
          const { data } = await getOrders(merchantClient, {
            pageSize: 8,
            sort: undefined,
            signal,
            search: orderNumber,
          });

          if (!data.length) {
            return undefined;
          }

          if (data.length === 1) {
            setFoundOrder(data[0]);
            selectOrder();
          }

          return data;
        } catch (e: any) {
          if (e instanceof CanceledError) {
            return undefined;
          }
          console.error("Error finding orders", e);
          throw new Error("Error finding orders");
        }
      }
      return undefined;
    });

    const [selectOrderLoad, selectOrder] = useTriggerLoad(async (signal) => {
      if (team && foundOrder) {
        const widgetClient = new WidgetClient(client, team.widget_slug);

        const orderWithExtraInfo = (
          await getOrderFromWidget(widgetClient, foundOrder._id, { signal })
        ).order;
        const productIds: string[] = [];
        orderWithExtraInfo.shopify.line_items.forEach((item: any) => {
          if (!productIds.includes(item.product_id)) {
            productIds.push(item.product_id);
          }
        });

        // Retrieve products concurrently, add them to line items - this will allow us to display the correct product image
        const products: any[] = [];
        await Promise.all(
          productIds.map(async (productId: string) => {
            products.push(
              await getProduct(widgetClient, {
                productId: productId,
                signal,
              }),
            );
          }),
        );

        for (const lineItem of orderWithExtraInfo.shopify.line_items) {
          const product = products.find(
            (product) => product.id === lineItem.product_id,
          );
          lineItem.product = product;
          lineItem.discount_price = ReturnTotalsCalculator.getDiscountPrice(
            lineItem,
            orderWithExtraInfo,
          );
        }

        return orderWithExtraInfo;
      }
      return undefined;
    });

    const orderSelected = selectOrderLoad.value;

    useEffect(() => {
      // When order is selected prefill address and customer information
      if (orderSelected) {
        if (orderSelected.shopify.shipping_address) {
          setAddress({
            street1: orderSelected.shopify.shipping_address.address1,
            street2: orderSelected.shopify.shipping_address.address2,
            state: orderSelected.shopify.shipping_address.province_code,
            city: orderSelected.shopify.shipping_address.city,
            country: orderSelected.shopify.shipping_address.country_code,
            zip: orderSelected.shopify.shipping_address.zip,
          });
        }
        setContactInfo({
          name: orderSelected.customer_name,
          email: orderSelected.shopify.email,
          phone: orderSelected.shopify.shipping_address?.phone,
        });
        setIdempotencyKey(
          `${orderSelected._id}-${randomHexString(nativeRandom, 3)}`,
        );
        const itemsReturned: any[] = [];
        const itemsNotReturned_: any[] = [];
        orderSelected?.shopify.line_items.forEach((lineItem: any) => {
          if (lineItem.vendor === "re:do") {
            return;
          }
          for (let i = 0; i < lineItem.quantity; i++) {
            if (i < lineItem.returned_quantity) {
              itemsReturned.push(lineItem);
            } else {
              itemsNotReturned_.push(lineItem);
            }
          }
        });
        setItemsNotReturned(itemsNotReturned_);
        setItemsAlreadyReturned(itemsReturned);
      }
    }, [orderSelected]);

    useEffect(() => {
      if (orderNumber) {
        void findOrders();
      }
    }, [orderNumber]);

    const multipleOrdersFound =
      findOrdersLoad.value && findOrdersLoad.value.length > 1;
    const orderLoading = selectOrderLoad.pending;
    const findReturnError = selectOrderLoad.error;

    function renderOrderSelection(): JSX.Element | null {
      if (findOrdersLoad.value) {
        return (
          <div className={claimReturnSharedCss.orderSelection}>
            Select an order from the list below:
            {findOrdersLoad.value.map((order: any) => (
              <>
                <Divider />
                <Button
                  className={claimReturnSharedCss.orderButton}
                  key={order._id}
                  onClick={() => {
                    setFoundOrder(order);
                    selectOrder();
                  }}
                  theme={ButtonTheme.GHOST}
                >
                  <div className={claimReturnSharedCss.orderLabel}>
                    {`${order.shopify.name} - ${order.customer_name}`}
                  </div>
                </Button>
              </>
            ))}
          </div>
        );
      }
      return null;
    }

    const handleClose = () => {
      setOpen(false);
      setSuccessModalOpen(false);
      setSubmitPending(false);
      setLineItemsSelected([]);
      setOrderNumber("");
      setClaimReason(undefined);
      setFoundOrder(null);
    };

    const validAddress = () => {
      return (
        address?.street1 && address?.city && address?.country && address?.zip
      );
    };

    const isDisabled = () => {
      if (returnType === "claim") {
        return (
          !orderSelected ||
          !claimReason ||
          !lineItemsSelected.length ||
          !validAddress() ||
          !compensationMethod
        );
      } else {
        return !orderSelected;
      }
    };
    const { formatCurrency } = useContext(CurrencyContext);

    const renderLineItem = (lineItem: any, index: number) => {
      const isSelected = () => {
        return lineItemsSelected.some(
          (itemWithIndex) =>
            itemWithIndex.lineItem.id === lineItem.id &&
            itemWithIndex.index === index,
        );
      };

      return (
        <div className={claimReturnSharedCss.lineItemWrapper} key={index}>
          {isClaimOrWarranty && (
            <Checkbox
              onChange={() => {
                if (isSelected()) {
                  // Remove from selected
                  setLineItemsSelected(
                    lineItemsSelected.filter(
                      (itemWithIndex) =>
                        itemWithIndex.lineItem.id !== lineItem.id ||
                        itemWithIndex.index !== index,
                    ),
                  );
                } else {
                  // Add to selected
                  setLineItemsSelected([
                    ...lineItemsSelected,
                    { lineItem: lineItem, index: index },
                  ]);
                }
              }}
              value={isSelected()}
            />
          )}
          <img
            className={claimReturnSharedCss.lineItemImage}
            src={getImageUrl(lineItem)}
          />
          <div className={claimReturnSharedCss.itemDetails}>
            <div>{lineItem.title}</div>
            <div>{lineItem.variant_title}</div>
            <div>{formatCurrency(lineItem.discount_price)}</div>
          </div>
        </div>
      );
    };

    const renderAlreadyReturnedLineItem = (lineItem: any, index: number) => {
      return (
        <div
          className={claimReturnSharedCss.lineItemReturnedWrapper}
          key={index}
        >
          <img
            className={claimReturnSharedCss.lineItemImage}
            src={getImageUrl(lineItem)}
          />
          <div className={claimReturnSharedCss.itemDetails}>
            <div>{lineItem.title}</div>
            <div>{lineItem.variant_title}</div>
          </div>
        </div>
      );
    };

    const footer = isClaimOrWarranty ? (
      <div className={claimReturnSharedCss.modalFooter}>
        <Button onClick={() => handleClose()} theme={ButtonTheme.GHOST}>
          Cancel
        </Button>
        <Button
          disabled={isDisabled()}
          onClick={() => handleCreateClaim()}
          theme={ButtonTheme.OUTLINED}
        >
          Create {returnType === "claim" ? "claim" : "warranty claim"}
        </Button>
        <Button
          disabled={isDisabled()}
          onClick={() => handleCreateAndApprove()}
          theme={ButtonTheme.PRIMARY}
        >
          Create & approve
        </Button>
      </div>
    ) : (
      <div className={claimReturnSharedCss.modalFooter}>
        <Button onClick={() => handleClose()} theme={ButtonTheme.OUTLINED}>
          Cancel
        </Button>
        <Button
          disabled={isDisabled()}
          onClick={() => handleReturnSubmit()}
          theme={ButtonTheme.PRIMARY}
        >
          Continue
        </Button>
      </div>
    );

    const isAllSelected = () => {
      if (lineItemsSelected.length === 0) {
        return false;
      }
      return lineItemsSelected.length === itemsNotReturned.length;
    };

    const handleReturnSubmit = async () => {
      amplitude.logEvent("create-merchantReturn", {
        shopifyOrderId: orderSelected?.shopify_id,
      });
      if (relocationInfo?.returnQueryParams && relocationInfo.currentView) {
        navigate(
          `/stores/${team?._id}/returns/create/${orderSelected?._id}?${new URLSearchParams(
            relocationInfo.returnQueryParams,
          ).toString()}&supportView=${relocationInfo.currentView}`,
        );
      } else {
        navigate(`create/${orderSelected?._id}`);
      }
    };

    // Submits the return in the modal, only used by claims
    const handleClaimSubmit = async () => {
      const items: ReturnItem[] = [];
      if (orderSelected && team && address) {
        lineItemsSelected.forEach((item, index) => {
          const inputAnswers: InputAnswer[] = [
            {
              parts: [
                {
                  type: InputPartType.IMAGES,
                  message: "Images",
                  value: imageUrls.map((url) => new URL(url)),
                },
              ],
            },
            {
              parts: [
                {
                  type: InputPartType.TEXTAREA,
                  message: "Note",
                  value: note,
                },
              ],
            },
          ];
          const multipleChoiceAnswers: any[] = [];
          questionAnswers.forEach((answer: MultipleChoiceAnswer) => {
            multipleChoiceAnswers.push({
              questionText: answer.questionText,
              answer: answer.answer,
              reason: answer.reason,
            });
          });
          const returnItem: ReturnItem = {
            exchange:
              compensationMethod === "exchange"
                ? {
                    exchangeGroup: undefined,
                    newVariantId: item.lineItem.variant_id,
                    order: orderSelected._id,
                    accountForPriceDifference: false,
                    transferLineItemProperties: true,
                  }
                : null,
            inputAnswers: index === 0 ? inputAnswers : [],
            isManualReview: true,
            isFlagged: false, // manual review is higher priority than flagged
            multipleChoiceAnswers: index === 0 ? multipleChoiceAnswers : [],
            variantId: item.lineItem.variant_id,
            reason: claimReason ? claimReason : null,
            refund: compensationMethod === "refund",
            repair: compensationMethod === "repair",
            ship: shouldShip,
            priceAdjustment: "0",
            price: item.lineItem.price,
            productName: {
              title: item.lineItem.title,
              variantTitle: item.lineItem.variant_title,
            },
            lineItemId: item.lineItem.id,
            orderId: orderSelected._id,
          };
          items.push(returnItem);
        });
        const defaultLocation = team.settings.locations.find(
          (location) => !location.condition,
        );
        const request: ReturnRequest = {
          requester: {
            name: user?.name,
            isCustomer: false,
            email: user?.email,
          },
          charge: "0",
          discountAllocations: [],
          idempotencyKey: idempotencyKey,
          instantExchangePaymentToken: null,
          isFlatRate: false,
          items: items,
          itemShipments: null,
          label: { payer: Payer.MERCHANT, paymentToken: null },
          labelDeductedFromCredit: false,
          merchantAddress: defaultLocation?.address
            ? {
                ...defaultLocation.address,
                email: team.email,
              }
            : team.address
              ? {
                  ...team.address,
                  email: team.email,
                }
              : null,
          newItems: [],
          newOrderTaxes: "0",
          newOrderValue: "0",
          orderId: orderSelected._id,
          orderPaymentIntents: [],
          provision: ProvisionType.PROCESSED,
          refund: "0",
          returnCollectionHoldAmount: null,
          returnType: returnType,
          shippingAddress: {
            name: contactInfo.name,
            email: contactInfo.email,
            street1: address.street1,
            street2: address.street2,
            state: address.state,
            city: address.city,
            country: address.country,
            zip: address.zip,
            phone: contactInfo.phone,
            returnerName: contactInfo.name,
            returnerEmail: contactInfo.email,
          },
          shippingFee: "0", // No charge to customer for claims
          singleShippingFee: "0",
          status: ReturnRequestStatus.PENDING,
          totalStoreCredit: "0.00",
          inStoreReturn: false,
          isPosReturn: false,
        };

        const widgetClient = new WidgetClient(client, team.widget_slug);

        setSubmitPending(true);
        const response = await alertOnFailure("Failed to create return")(() =>
          submitReturn(widgetClient, request),
        ).finally(() => setSubmitPending(false));

        amplitude.logEvent("create-merchantClaim", {
          shopifyOrderId: orderSelected.shopify_id,
        });
        setNewReturnId(response.returnId);
        return response;
      }
    };

    const handleCreateClaim = async () => {
      const response = await handleClaimSubmit();
      if (response.returnId && team) {
        if (relocationInfo) {
          setOpen(false);
          setSuccessModalOpen(true);
        } else {
          if (returnType === "claim") {
            navigate(`/stores/${team._id}/claims/${response.returnId}`);
          } else {
            navigate(`/stores/${team._id}/warranties/${response.returnId}`);
          }
          handleClose();
        }
      }
    };

    const handleCreateAndApprove = async () => {
      setApprovalPending(true);
      const response = await handleClaimSubmit();
      if (
        response.returnId &&
        response.returnData &&
        response.returnData.status === "needs_review"
      ) {
        const products: ApprovalProductInfo[] =
          response.returnData.products.map((product: Product) => ({
            _id: product._id,
            grams: product.grams,
            shipBack: !product.green_return,
          }));
        await alertOnFailure("Failed to approve return")(() =>
          approveReturn(merchantClient, {
            customerNotes: note,
            returnId: response.returnId,
            products,
          }),
        );
      }
      setApprovalPending(false);

      if (response.returnId && team) {
        if (relocationInfo) {
          setOpen(false);
          setSuccessModalOpen(true);
        } else {
          if (returnType === "claim") {
            navigate(`/stores/${team._id}/claims/${response.returnId}`);
          } else {
            navigate(`/stores/${team._id}/warranties/${response.returnId}`);
          }
          handleClose();
        }
      }
    };

    return (
      <>
        <Modal
          footer={
            submitPending || approvalPending || !orderSelected
              ? undefined
              : footer
          }
          onClose={handleClose}
          open={open}
          size={isClaimOrWarranty ? ModalSize.MEDIUM : ModalSize.SMALL}
          title={
            submitPending
              ? "Submitting claim"
              : approvalPending
                ? "Approving claim"
                : `Start a ${returnType === "warranty" ? "warranty claim" : returnType}`
          }
        >
          <div className={claimReturnSharedCss.modalContent}>
            {submitPending || approvalPending ? (
              <div className={claimReturnSharedCss.animationContainer}>
                <LoadingRedoAnimation />
              </div>
            ) : (
              <>
                {!(relocationInfo?.preselectedOrder && foundOrder) && (
                  <LabeledInput
                    label={
                      orderLoading && relocationInfo?.preselectedOrder
                        ? "Searching for order"
                        : "Choose an order"
                    }
                  >
                    <form
                      onSubmit={async (e) => {
                        e.preventDefault();
                        await findOrders();
                      }}
                    >
                      <div className={claimReturnSharedCss.search}>
                        <TextInput
                          icon={
                            orderNumber ? (
                              <div className={claimReturnSharedCss.icon}>
                                <XIcon onClick={() => setOrderNumber("")} />
                              </div>
                            ) : (
                              <SearchIcon />
                            )
                          }
                          onChange={setOrderNumber}
                          placeholder="Enter order number"
                          value={orderNumber}
                        />
                        {orderLoading ? (
                          // FIXME Doesn't show loading animation on clicks until a page of results has already been loaded before
                          <div className={claimReturnSharedCss.spinner}>
                            <LoadingRedoAnimation />
                          </div>
                        ) : (
                          <Button
                            onClick={() => findOrders()}
                            size={ButtonSize.SMALL}
                            theme={ButtonTheme.PRIMARY}
                          >
                            Find
                          </Button>
                        )}
                      </div>
                      {findReturnError && (
                        <div className={claimReturnSharedCss.error}>
                          {typeof findReturnError === "string"
                            ? findReturnError
                            : "Error finding order"}
                        </div>
                      )}
                    </form>
                  </LabeledInput>
                )}
                {multipleOrdersFound && !foundOrder && renderOrderSelection()}
                {!selectOrderLoad.pending &&
                  foundOrder !== null &&
                  orderSelected && (
                    <>
                      <div className={claimReturnSharedCss.orderTitleContainer}>
                        <div className={claimReturnSharedCss.title}>
                          {multipleOrdersFound && (
                            <IconButton onClick={() => setFoundOrder(null)}>
                              <ChevronLeftIcon />
                            </IconButton>
                          )}{" "}
                          Order {orderSelected.shopify.name}{" "}
                          <ProtectionPill
                            order={orderSelected}
                            returnType={returnType}
                          />
                        </div>
                        <div>{orderSelected.customer_name}</div>
                      </div>
                      <div className={claimReturnSharedCss.selectionWrapper}>
                        {itemsNotReturned.length > 0 && (
                          <div
                            className={claimReturnSharedCss.itemSelectionHeader}
                          >
                            {isClaimOrWarranty && (
                              <>
                                <div>Select items</div>
                                <div
                                  className={
                                    claimReturnSharedCss.selectCheckbox
                                  }
                                >
                                  <Checkbox
                                    onChange={() => {
                                      if (!isAllSelected()) {
                                        // Add all items
                                        const lineItemsToAdd: any[] = [];
                                        itemsNotReturned.forEach(
                                          (lineItem: any, index: number) => {
                                            lineItemsToAdd.push({
                                              lineItem: lineItem,
                                              index: index,
                                            });
                                          },
                                        );
                                        setLineItemsSelected(lineItemsToAdd);
                                      } else {
                                        // Remove all items
                                        setLineItemsSelected([]);
                                      }
                                    }}
                                    value={isAllSelected()}
                                  />
                                  <div>Select all</div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        {itemsNotReturned.length > 0 &&
                          itemsNotReturned.map((lineItem: any, index: number) =>
                            renderLineItem(lineItem, index),
                          )}
                        {itemsAlreadyReturned.length > 0 && (
                          <>
                            <div className={claimReturnSharedCss.subheader}>
                              {`Items with an existing ${
                                team?.settings.packageProtection?.enabled
                                  ? "claim or"
                                  : ""
                              } return`}
                            </div>
                            {itemsAlreadyReturned.map(
                              (lineItem: any, index: number) =>
                                renderAlreadyReturnedLineItem(lineItem, index),
                            )}
                          </>
                        )}
                      </div>
                      {isClaimOrWarranty && (
                        <div className={claimReturnSharedCss.dropdowns}>
                          <LabeledInput
                            label={
                              <div className={claimReturnSharedCss.flex}>
                                Reason for{" "}
                                {returnType === "warranty"
                                  ? "warranty claim"
                                  : "claim"}
                                <div className={claimReturnSharedCss.error}>
                                  &nbsp;*
                                </div>
                              </div>
                            }
                          >
                            <SelectDropdown
                              options={claimReasonOptions}
                              placeholder="Select reason"
                              value={claimReason}
                              valueChange={(e) => setClaimReason(e)}
                            >
                              {(option) => option}
                            </SelectDropdown>
                          </LabeledInput>
                          <LabeledInput
                            label={
                              <div className={claimReturnSharedCss.flex}>
                                Compensation method{" "}
                                <div className={claimReturnSharedCss.error}>
                                  &nbsp;*
                                </div>
                              </div>
                            }
                          >
                            <SelectDropdown
                              options={returnTypeOptions}
                              placeholder="Select a return method"
                              value={returnTypeOptions.find(
                                (type) => compensationMethod === type.value,
                              )}
                              valueChange={(e) => {
                                setCompensationMethod(e?.value || null);
                              }}
                            >
                              {(option) => option.displayName}
                            </SelectDropdown>
                          </LabeledInput>
                          {claimMultipleChoiceQuestions.length > 0 &&
                            claimMultipleChoiceQuestions.map(
                              (
                                question: MultipleChoiceQuestion,
                                index: number,
                              ) => (
                                <LabeledInput
                                  key={index}
                                  label={question.message}
                                >
                                  <SelectDropdown
                                    options={question.choices}
                                    placeholder="Select answer"
                                    value={
                                      questionAnswers.find(
                                        (answer: MultipleChoiceAnswer) =>
                                          answer.questionText ===
                                          question.message,
                                      )?.answer || undefined
                                    }
                                    valueChange={(e: string) => {
                                      const tempAnswers = [...questionAnswers];
                                      const answerIndex = tempAnswers.findIndex(
                                        (answer: MultipleChoiceAnswer) =>
                                          answer.questionText ===
                                          question.message,
                                      );
                                      if (answerIndex > -1) {
                                        tempAnswers[answerIndex].answer = e;
                                      } else {
                                        tempAnswers.push({
                                          questionText: question.message,
                                          answer: e,
                                          reason: false,
                                        });
                                      }
                                      setQuestionAnswers(tempAnswers);
                                    }}
                                  >
                                    {(option) => option}
                                  </SelectDropdown>
                                </LabeledInput>
                              ),
                            )}
                          <LabeledInput label="Add a note">
                            <TextInput onChange={setNote} value={note} />
                          </LabeledInput>
                          <LabeledInput label="Add images">
                            <MultiImageUpload
                              imageUrls={imageUrls}
                              setImageUrls={setImageUrls}
                            />
                          </LabeledInput>
                          <LabeledInput label="Require customer to send item back?">
                            <Switch
                              onChange={() => setShouldShip(!shouldShip)}
                              value={shouldShip}
                            />
                          </LabeledInput>
                        </div>
                      )}
                      <div
                        className={
                          (returnType === "return" &&
                            claimReturnSharedCss.customerDetails) ||
                          undefined
                        }
                      >
                        <AddressDetails
                          address={address}
                          editAddressModalOpen={editAddressModalOpen}
                          returnType={returnType}
                          setAddress={setAddress}
                          setAddressEditModalOpen={setAddressEditModalOpen}
                        />
                        <ContactInfoDetails
                          contactEditModalOpen={contactEditModalOpen}
                          contactInfo={contactInfo}
                          returnType={returnType}
                          setContactEditModalOpen={setContactEditModalOpen}
                          setContactInfo={setContactInfo}
                        />
                      </div>
                    </>
                  )}
              </>
            )}
          </div>
        </Modal>
        {team && newReturnId && (
          <SuccessModal
            handleClose={handleClose}
            newReturnId={newReturnId}
            open={successModalOpen}
            returnType={returnType}
            teamId={team._id}
          />
        )}
      </>
    );
  },
);

const ProtectionPill = memo(function ProtectionPill({
  returnType,
  order,
}: {
  returnType: ReturnType;
  order: Order;
}) {
  if (returnType === "warranty") {
    return null;
  }

  const isProtected =
    returnType === "claim" ? order.packageProtected : order.protected;
  return (
    <Pill
      preventCapitalize
      size={PillSize.SMALL}
      theme={isProtected ? PillTheme.SUCCESS : PillTheme.DANGER}
    >
      {isProtected ? "Has protection" : "No protection"}
    </Pill>
  );
});

export const SuccessModal = memo(function SuccessModal({
  open,
  returnType,
  teamId,
  newReturnId,
  handleClose,
}: {
  open: boolean;
  returnType: ReturnType;
  teamId: string;
  newReturnId: string | null;
  handleClose: () => void;
}) {
  const navigate = useNavigate();
  const successFooter = (
    <div className={claimReturnSharedCss.modalFooter}>
      <Button
        onClick={() => {
          navigate(`/stores/${teamId}/claims/${newReturnId}`);
          handleClose();
        }}
        theme={ButtonTheme.OUTLINED}
      >
        Go to claim
      </Button>
      <Button
        onClick={() => {
          handleClose();
        }}
        theme={ButtonTheme.PRIMARY}
      >
        Stay
      </Button>
    </div>
  );

  return (
    <Modal
      footer={successFooter}
      onClose={handleClose}
      open={open}
      size={ModalSize.SMALL}
      title={`${capitalize(returnType)} successfully created`}
    >
      Would you like to view the new {returnType}?
    </Modal>
  );
});

export const AddressDetails = memo(function AddressDetails({
  address,
  editAddressModalOpen,
  setAddressEditModalOpen,
  setAddress,
  returnType,
}: {
  address?: Address;
  editAddressModalOpen: boolean;
  setAddressEditModalOpen: (open: boolean) => void;
  setAddress: (address: Address) => void;
  returnType: ReturnType;
}) {
  return (
    <div>
      <div className={claimReturnSharedCss.subheader}>Address</div>
      <div className={claimReturnSharedCss.customerInformationWrapper}>
        {address ? (
          <div>
            <div>{address.street1}</div>
            <div>{address.street2}</div>
            <div>
              {address.city}, {!!address.state && `${address.state}, `}
              {address.zip}, {address.country}
            </div>
          </div>
        ) : (
          "No address"
        )}
        {(returnType === "claim" || returnType === "warranty") && (
          <Button
            onClick={() => setAddressEditModalOpen(true)}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.OUTLINED}
          >
            <div className={claimReturnSharedCss.addEditButton}>
              <EditPencilIcon />
              Edit address
            </div>
          </Button>
        )}
      </div>
      <AddressEditModal
        initial={address}
        open={editAddressModalOpen}
        setAddress={setAddress}
        setOpen={setAddressEditModalOpen}
      />
    </div>
  );
});

export const ContactInfoDetails = memo(function ContactInfoDetails({
  contactInfo,
  contactEditModalOpen,
  setContactEditModalOpen,
  setContactInfo,
  returnType,
}: {
  contactInfo: ContactInfo;
  contactEditModalOpen: boolean;
  setContactEditModalOpen: (open: boolean) => void;
  setContactInfo: (contactInfo: ContactInfo) => void;
  returnType: ReturnType;
}) {
  return (
    <div>
      <div className={claimReturnSharedCss.subheader}>Contact information</div>
      <div className={claimReturnSharedCss.customerInformationWrapper}>
        <div>
          <div>{contactInfo.name}</div>
          <div>{contactInfo.email}</div>
          <div>{contactInfo.phone}</div>
        </div>
        {(returnType === "claim" || returnType === "warranty") && (
          <Button
            onClick={() => setContactEditModalOpen(true)}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.OUTLINED}
          >
            <div className={claimReturnSharedCss.addEditButton}>
              <EditPencilIcon />
              Edit contact
            </div>
          </Button>
        )}
      </div>
      <ContactInfoModal
        initial={contactInfo}
        open={contactEditModalOpen}
        setContactInfo={setContactInfo}
        setOpen={setContactEditModalOpen}
      />
    </div>
  );
});

export const MultiImageUpload = memo(function MultiImageUpload({
  imageUrls,
  setImageUrls,
}: {
  imageUrls: string[];
  setImageUrls: Dispatch<SetStateAction<string[]>>;
}) {
  const [files, setFiles] = useState<File[]>([]);
  const team = useContext(TeamContext);
  const client = useRequiredContext(RedoClientContext);
  const widgetClient = new WidgetClient(client, team?.widget_slug || "");

  const handleUpload = async (event: any) => {
    if (!event.target.files) {
      return;
    }

    const tempFiles = [...files];
    tempFiles.push(event.target.files[0]);
    setFiles(tempFiles);

    const form = new FormData();
    form.append("image", event.target.files[0]);
    const response = await uploadImages(widgetClient, form);
    const url = response.url;
    setImageUrls([...imageUrls, url]);
  };

  const handleRemove = (index: number) => {
    const tempFiles = [...files];
    tempFiles.splice(index, 1);
    setFiles(tempFiles);

    const tempUrls = [...imageUrls];
    tempUrls.splice(index, 1);
    setImageUrls(tempUrls);
  };

  const temporaryFileUrl = (file: Blob | MediaSource) => {
    return URL.createObjectURL(file);
  };

  const clearInput = (e: any) => {
    // This makes the onChange always get triggered.
    e.target.value = "";
  };

  return (
    <div className={claimReturnSharedCss.imagesWrapper}>
      {files.length > 0 &&
        files.map((file, fileIndex) => (
          <div
            className={claimReturnSharedCss.uploadedImageWrapper}
            key={fileIndex}
            onClick={() => handleRemove(fileIndex)}
          >
            <div className={claimReturnSharedCss.closeButton}>
              <XIcon style={{ color: "#565760" }} />
            </div>
            <img
              className={claimReturnSharedCss.uploadedImage}
              src={temporaryFileUrl(file)}
            />
          </div>
        ))}
      <label htmlFor="image-upload" id="image-upload-label">
        <div className={claimReturnSharedCss.imageButton}>
          <Camera />
        </div>
      </label>
      <input
        accept="image/jpeg, image/jpg, image/png"
        id="image-upload"
        onChange={(e) => handleUpload(e)}
        onClick={(e) => clearInput(e)}
        style={{ display: "none" }}
        type="file"
      />
    </div>
  );
});
