import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoClient } from "@redotech/redo-api-client";
import {
  ExpandedConversation,
  ExpandedConversationMessage,
  MessageVisibility,
  TICKET_CONVERTED_FROM_REDOCHAT_TO_EMAIL_MESSAGE,
} from "@redotech/redo-model/conversation";
import {
  EmailEnvelopeInfo,
  EmailReplyType,
  filterAndPreprocessEmailInfo,
  filterAndPreprocessOneEmail,
  hasExactlyOneRecipientEmail,
  tryGetDisplayNameOfEmailer,
} from "@redotech/redo-model/support/conversations/email-info";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { RedoClientContext } from "@redotech/redo-web/client";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { formatDateTime } from "@redotech/redo-web/time";
import { emailRegex } from "@redotech/util/email";
import * as capitalize from "lodash/capitalize";
import {
  Dispatch,
  Fragment,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { UserContext } from "../../app/user";
import { RedoMerchantClientContext } from "../../client/context";
import { getProfilePictures } from "../../client/conversations";
import { REDO_MERCHANT_SERVER_URL } from "../../config";
import { getPublicDraftReplyingToMessage } from "../message-input-utils";
import { SystemMessage } from "../system-message";
import { formatTypingNames } from "../typing";
import { getCustomerName, listen } from "../utils";
import * as conversationEmailMessagesViewCss from "./conversation-email-messages-view.module.css";
import { EmailMessage, MessageContent } from "./email-message";

const getConversationStream = (
  client: RedoClient,
  { conversationId, signal }: { conversationId: string; signal: AbortSignal },
) => {
  const url = `${REDO_MERCHANT_SERVER_URL}/conversations/${conversationId}/live`;
  return fetch(url, { signal, headers: client.authorization() });
};

/**
 * If the customer hasn't loaded yet, we need to try to grab the email from a customer message.
 */
const fallbackFromEmailWhenCustomerHasntLoaded = (
  conversation: ExpandedConversation,
) =>
  conversation.messages
    .filter((m) => m.type === "customer")
    .reverse()
    .find((m) => emailRegex.test(m.content))
    ?.content.match(emailRegex)?.[0];

const fallbackSenderFromConversation = (
  conversation: ExpandedConversation,
  message: ExpandedConversationMessage,
) => {
  if (message.email?.from?.email) {
    return message.email.from;
  }
  if (message.type === "customer") {
    return {
      email: message.customer?.email || "",
      name: message.customer?.name || "",
    };
  }
  return { email: conversation.email?.email };
};

const fallbackRecipientsFromConversation = (
  conversation: ExpandedConversation,
  message: ExpandedConversationMessage,
) => {
  if (message.email?.to?.length) {
    return message.email.to;
  }
  const sender = fallbackSenderFromConversation(conversation, message);
  const mainRecipient =
    message.type === "customer"
      ? { email: conversation.email?.email }
      : {
          email: conversation.customer?.email || "",
          name: message.customer?.name || "",
        };
  const filteredToRecipients = (conversation.toEmails || []).filter(
    (toEmail) =>
      toEmail.email !== sender.email && toEmail.email !== mainRecipient.email,
  );
  return [mainRecipient, ...filteredToRecipients];
};

const getEnvelopeInfoOfThisMessage = (
  conversation: ExpandedConversation,
  message: ExpandedConversationMessage,
): Required<Omit<EmailEnvelopeInfo, "inReplyTo">> => {
  // if the message contains a non-undefined value for each of
  // to, cc, bcc, from, return an object with those values
  const envelopeInfo =
    /**
     * Should we fall back to old behavior of using conversation-set emails?
     */
    message.email?.to !== undefined &&
    message.email?.cc !== undefined &&
    message.email?.bcc !== undefined &&
    message.email?.from !== undefined
      ? {
          to: filterAndPreprocessEmailInfo(message.email.to),
          cc: filterAndPreprocessEmailInfo(message.email.cc),
          bcc: filterAndPreprocessEmailInfo(message.email.bcc),
          from: filterAndPreprocessEmailInfo([message.email.from])?.[0],
        }
      : {
          to: filterAndPreprocessEmailInfo(
            fallbackRecipientsFromConversation(conversation, message),
          ),
          cc: filterAndPreprocessEmailInfo(
            conversation.ccEmails?.map((ccEmail) => ({ email: ccEmail })) ?? [],
          ),
          bcc: filterAndPreprocessEmailInfo(
            conversation.bccEmails?.map((bccEmail) => ({ email: bccEmail })) ??
              [],
          ),
          from: filterAndPreprocessEmailInfo([
            fallbackSenderFromConversation(conversation, message),
          ])?.[0],
        };

  /**
   * We really really need a "from" email at the very least.
   * So, we try fallbacks here, and even if validating fails for some strange reason,
   * just use the unvalidated email.
   */

  if (!envelopeInfo.from) {
    const unvalidatedFallbackFromEmail = {
      email:
        conversation.customer?.email ??
        fallbackFromEmailWhenCustomerHasntLoaded(conversation) ??
        "",
    };
    envelopeInfo.from = {
      email:
        filterAndPreprocessOneEmail(unvalidatedFallbackFromEmail.email) ??
        unvalidatedFallbackFromEmail.email,
    };
  }

  return envelopeInfo;
};

export const ConversationEmailMessagesView = memo(
  function ConversationEmailMessagesView({
    cardListRef,
    conversation,
    typing,
    setErrorMessage,
    setShowErrorMessage,
    setShowFullCommentThread,
    setActiveConversation,
    setTyping,
    showFullCommentThread,
    setLeftPanelOpen,
    shouldBlockDraftCreationWhileSendingEmail,
    setShouldBlockDraftCreationWhileSendingEmail,
  }: {
    cardListRef?: any;
    conversation: ExpandedConversation;
    typing: Record<string, Date>;
    setErrorMessage: Dispatch<SetStateAction<string>>;
    setShowErrorMessage: Dispatch<SetStateAction<boolean>>;
    setShowFullCommentThread: Dispatch<SetStateAction<boolean>>;
    setActiveConversation: (
      conversation: ExpandedConversation | undefined,
    ) => void;
    setTyping: Dispatch<SetStateAction<Record<string, Date>>>;
    showFullCommentThread: boolean;
    setLeftPanelOpen: Dispatch<SetStateAction<boolean>>;
    shouldBlockDraftCreationWhileSendingEmail: boolean;
    setShouldBlockDraftCreationWhileSendingEmail: Dispatch<
      SetStateAction<boolean>
    >;
  }) {
    const user = useContext(UserContext);
    const [retryCount, setRetryCount] = useState(0);
    const client = useRequiredContext(RedoClientContext);
    const merchantClient = useRequiredContext(RedoMerchantClientContext);

    const profilePicturesLoad = useLoad(async (signal) => {
      const profilePictures = await getProfilePictures(merchantClient, {
        conversationId: conversation._id,
        signal,
      });

      return profilePictures;
    }, []);

    const messages: ExpandedConversationMessage[] = conversation.messages;

    useEffect(() => {
      const abortController = new AbortController();
      (async () => {
        try {
          for await (const updatedConversation of listen({
            query: async () => {
              return await getConversationStream(client, {
                conversationId: conversation._id,
                signal: abortController.signal,
              });
            },
            loopCondition: !!conversation?._id,
            setErrorMessage,
            setShowErrorMessage,
          })) {
            setActiveConversation(
              updatedConversation as unknown as ExpandedConversation,
            );
          }
        } catch (e) {
          // Wait 5 seconds before trying again
          setTimeout(() => {
            // Continue polling for changes
            setRetryCount(retryCount + 1);
          }, 5000);
          if (abortController.signal.aborted) {
            return;
          }
          throw e;
        }
      })();
      return () =>
        abortController.abort("Conversation changed or component unmounted");
    }, [conversation._id, retryCount]);

    type MessagesChunk = ExpandedConversationMessage[];

    // Sort the messages by sentAt
    messages.sort((a, b) => {
      return new Date(a.sentAt).getTime() - new Date(b.sentAt).getTime();
    });

    // Group messages around system messages.
    const messageChunks: MessagesChunk[] = [];
    let currentChunk: MessagesChunk = [];
    for (const message of messages) {
      // We want to ignore draft messages when rendering the conversation
      if (message.draftInfo?.isDraft) {
        continue;
      }
      if (message.type === "system") {
        if (currentChunk.length > 0) {
          messageChunks.push(currentChunk);
          currentChunk = [];
          if (
            message.content === TICKET_CONVERTED_FROM_REDOCHAT_TO_EMAIL_MESSAGE
          ) {
            // if we converted from chat to email, break the previous chunk into one-message chunks
            // so merchants don't have to expand the boilerplate automated messages.
            const chunkToDecimate = messageChunks.pop();
            if (chunkToDecimate) {
              for (const messageToDecimate of chunkToDecimate) {
                messageChunks.push([messageToDecimate]);
              }
            }
          }
        }
        messageChunks.push([message]);
      } else {
        currentChunk.push(message);
      }
    }
    currentChunk.length > 0 && messageChunks.push(currentChunk);

    type ChunksToCollapse = boolean[];
    const [chunksUserHasCollapsed, setChunksUserHasCollapsed] =
      useState<ChunksToCollapse>(messageChunks.map(() => true));

    // Upon first render of conversation, set the messages to hide initially unless the message has a draft reply
    const N_MESSAGES_BEFORE_HIDING = 2;
    useLayoutEffect(() => {
      setChunksUserHasCollapsed(
        messageChunks.map((chunk) => {
          const shouldCollapse = chunk.length > N_MESSAGES_BEFORE_HIDING;
          const hasDraft = chunk.some((msg) =>
            getPublicDraftReplyingToMessage(conversation, msg),
          );
          return hasDraft ? false : shouldCollapse;
        }),
      );
    }, [conversation._id]);

    /**
     * Try grabbing the customer name, or at least the sender name / email, if it's not from a merchant.
     * If we only have the email, we'll just show the email without the domain.
     * If it is from a merchant, figure out which user it is from. If it's from the anonymous user, just say "Chat User".
     */
    const getSenderDisplayName = (
      conversation: ExpandedConversation,
      message: ExpandedConversationMessage,
      envelopeInfo: EmailEnvelopeInfo,
    ): string =>
      message.type !== "merchant"
        ? getCustomerName(message.customer, conversation) ||
          tryGetDisplayNameOfEmailer(envelopeInfo.from) ||
          "unknown sender"
        : message.user?._id === process.env.ANONYMOUS_USER_ID
          ? `${capitalize(conversation.platform)} User`
          : message.user?.name;

    // At the beginning of viewing the conversation, scroll to the bottom.
    const conversationMessagesScrollRef = useRef<HTMLDivElement>();
    useEffect(() => {
      setTimeout(() => {
        const scrollRef = conversationMessagesScrollRef.current;
        if (scrollRef) {
          scrollRef.scrollTop = scrollRef.scrollHeight;
        }
      }, 300);
    }, [conversation._id]);

    const otherUsersAreTyping = !!Object.keys(typing).filter(
      (name) => name !== user?.name,
    ).length;

    return (
      <div className={conversationEmailMessagesViewCss.messagesCard}>
        <Flex
          align="stretch"
          className={conversationEmailMessagesViewCss.messagesContainer}
          dir="column"
          grow={1}
          ref={conversationMessagesScrollRef}
        >
          <>
            {messageChunks.map((messageChunk, chunk_index) => (
              <Fragment key={`chunk${conversation._id}${chunk_index}`}>
                {messageChunk.map((message, message_in_chunk_index) => {
                  const envelopeInfo = getEnvelopeInfoOfThisMessage(
                    conversation,
                    message,
                  );
                  const messageContent: MessageContent = message.email?.htmlBody
                    ? {
                        type: "messageHtml",
                        messageHtml: message.email.htmlBody.replaceAll(
                          "<p></p>",
                          "<br/>",
                        ),
                      }
                    : {
                        type: "messageText",
                        messageText:
                          message.email?.textBody ||
                          message.content ||
                          "(empty message)",
                      };

                  /** Show the last message as expanded */
                  const shouldStartExpandedBecauseItsTheLastMessage =
                    chunk_index === messageChunks.length - 1 &&
                    message_in_chunk_index ===
                      messageChunks[chunk_index].length - 1;

                  /** Show the second-to-last message as expanded if the last message is a system message */
                  const shouldStartExpandedBecauseOfConversionToEmail =
                    chunk_index === messageChunks.length - 2 &&
                    messageChunks[chunk_index + 1].length === 1 &&
                    messageChunks[chunk_index + 1][0].type === "system" &&
                    messageChunks[chunk_index + 1][0].content ===
                      TICKET_CONVERTED_FROM_REDOCHAT_TO_EMAIL_MESSAGE;

                  // If we have a public draft saved, start the message expanded
                  const savedDraft = getPublicDraftReplyingToMessage(
                    conversation,
                    message,
                  );
                  const shouldStartExpandedBecauseOfDraft =
                    !!savedDraft &&
                    savedDraft.visibility === MessageVisibility.PUBLIC;

                  /** Under any of these circumstances, show the message */
                  const shouldStartExpanded =
                    shouldStartExpandedBecauseItsTheLastMessage ||
                    shouldStartExpandedBecauseOfConversionToEmail ||
                    shouldStartExpandedBecauseOfDraft;

                  /** We want a reply box to be open when it's time to respond to a customer. */
                  const whichDraftModeToStartWith: EmailReplyType | undefined =
                    shouldStartExpandedBecauseOfConversionToEmail
                      ? EmailReplyType.REPLY
                      : shouldStartExpanded && message.type === "customer"
                        ? /** if there's just one recipient in senderInfo's "to", use "Reply", otherwise use "Reply All" */
                          hasExactlyOneRecipientEmail(envelopeInfo) &&
                          envelopeInfo.to?.length
                          ? EmailReplyType.REPLY
                          : EmailReplyType.REPLY_ALL
                        : undefined;

                  return (
                    <Fragment
                      key={`message${conversation._id}${message._id}${message_in_chunk_index}`}
                    >
                      {chunksUserHasCollapsed[chunk_index] &&
                        messageChunk.length > N_MESSAGES_BEFORE_HIDING &&
                        message_in_chunk_index === messageChunk.length - 1 && (
                          <Flex
                            className={
                              conversationEmailMessagesViewCss.showMoreButtonContainer
                            }
                          >
                            {/* Special gmail-esque "show more" buttom that lives ontop of collapsed messages */}
                            <Button
                              className={
                                conversationEmailMessagesViewCss.showMoreButton
                              }
                              key={`showmore${chunk_index}`}
                              onClick={() => {
                                setChunksUserHasCollapsed(
                                  chunksUserHasCollapsed.map((val, index) =>
                                    index === chunk_index ? !val : val,
                                  ),
                                );
                              }}
                              size={ButtonSize.MEDIUM}
                              theme={ButtonTheme.OUTLINED}
                            >
                              <Flex align="center" justify="center">
                                <Text fontSize="md">
                                  {messageChunk.length -
                                    N_MESSAGES_BEFORE_HIDING}
                                </Text>
                              </Flex>
                            </Button>
                          </Flex>
                        )}
                      {(!chunksUserHasCollapsed[chunk_index] ||
                        [0, messageChunk.length - 1].includes(
                          message_in_chunk_index,
                        )) && (
                        <>
                          {message.type === "system" ? (
                            <Flex
                              className={
                                conversationEmailMessagesViewCss.systemMessageCardWrapper
                              }
                              p="md"
                              pb="3xl"
                              pt="2xl"
                            >
                              <SystemMessage message={message.content} />
                            </Flex>
                          ) : (
                            <EmailMessage
                              // If the message is hidden by the bubble, start it collapsed.
                              bcc={envelopeInfo.bcc}
                              cardListRef={cardListRef}
                              cc={envelopeInfo.cc}
                              conversation={conversation}
                              date={formatDateTime(
                                Temporal.Instant.from(message.sentAt),
                              )}
                              files={message.files}
                              from={envelopeInfo.from}
                              isUser={message.type === "merchant"}
                              message={message}
                              messageContent={messageContent}
                              profilePictures={
                                !profilePicturesLoad.pending &&
                                !profilePicturesLoad.error
                                  ? profilePicturesLoad.value
                                  : {}
                              }
                              setActiveConversation={setActiveConversation}
                              setErrorMessage={setErrorMessage}
                              setLeftPanelOpen={setLeftPanelOpen}
                              setShouldBlockDraftCreationWhileSendingEmail={
                                setShouldBlockDraftCreationWhileSendingEmail
                              }
                              setShowErrorMessage={setShowErrorMessage}
                              setShowFullCommentThread={
                                setShowFullCommentThread
                              }
                              setTyping={setTyping}
                              shouldBlockDraftCreationWhileSendingEmail={
                                shouldBlockDraftCreationWhileSendingEmail
                              }
                              showFullCommentThread={showFullCommentThread}
                              startCollapsed={!shouldStartExpanded}
                              subject={conversation.subject}
                              to={envelopeInfo.to}
                              typing={typing}
                              userAvatarImageUrl={
                                profilePicturesLoad.value?.[
                                  message?.customer
                                ] || ""
                              }
                              username={
                                getSenderDisplayName(
                                  conversation,
                                  message,
                                  envelopeInfo,
                                ) ?? "no user"
                              }
                              visibility={message.visibility}
                              whichDraftModeToStartWith={
                                whichDraftModeToStartWith
                              }
                            />
                          )}
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </Fragment>
            ))}
            <Flex>
              {otherUsersAreTyping && (
                <div
                  className={conversationEmailMessagesViewCss.typingIndicator}
                >
                  <p>{formatTypingNames(typing, user, conversation)}</p>
                </div>
              )}
            </Flex>
          </>
        </Flex>
      </div>
    );
  },
);
