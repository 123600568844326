import { useRequiredContext } from "@redotech/react-util/context";
import { filterOptionsV2JsonFormat } from "@redotech/redo-model/conversation-filters";
import { CreateViewBody } from "@redotech/redo-model/create-view-body";
import { UpdateViewBody } from "@redotech/redo-model/update-view-body";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoButtonDropdown } from "@redotech/redo-web/arbiter-components/buttons/redo-dropdown-button";
import {
  RedoCommandMenu,
  RedoCommandMenuItem,
} from "@redotech/redo-web/arbiter-components/command-menu/redo-command-menu";
import Edit04Icon from "@redotech/redo-web/arbiter-icon/edit-04.svg";
import { Flex } from "@redotech/redo-web/flex";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { isEnumValue } from "@redotech/util/enum";
import { sinkPromise } from "@redotech/util/promise";
import { memo, useContext, useMemo, useState } from "react";
import { UserContext } from "../../app/user";
import {
  ActiveViewContext,
  EditingViewContext,
  SetEditingViewContext,
  SystemView,
} from "../conversations-table-filters/active-view-context";
import { FiltersContext } from "../conversations-table-filters/filters-context";
import { SaveViewModal } from "../conversations-table-filters/save-view-modal";
import { DeleteViewModal } from "../delete-view-modal";

export const ConversationsTableHeaderViewManagement = memo(
  function ConversationsTableHeaderViewManagement({
    viewMeaningfullyDiffersFromBase,
    handleCreateView,
    handleUpdateView,
  }: {
    viewMeaningfullyDiffersFromBase: boolean;
    handleCreateView: (body: CreateViewBody) => Promise<void>;
    handleUpdateView: (view: UpdateViewBody, viewId: string) => Promise<void>;
  }) {
    const user = useRequiredContext(UserContext);
    const view = useContext(ActiveViewContext);
    const [deleteViewModalOpen, setDeleteViewModalOpen] = useState(false);
    const viewBeingEdited = useContext(EditingViewContext);
    const filters = useContext(FiltersContext);

    const viewIsSystemView = isEnumValue(view._id, SystemView);
    const setViewBeingEdited = useContext(SetEditingViewContext);

    const [editViewDropdownRef, setEditViewDropdownRef] =
      useState<HTMLElement | null>(null);
    const [editViewDropdownOpen, setEditViewDropdownOpen] = useState(false);

    const canEditSettings = permitted(
      user.permissions,
      Permission.EDIT_SETTINGS,
    );

    const [saveViewModalOpen, setSaveViewModalOpen] = useState(false);

    const deleteViewItem: RedoCommandMenuItem = useMemo(() => {
      return {
        Icon: TrashIcon,
        text: "Delete view",
        onClick: () => {
          setDeleteViewModalOpen(true);
        },
        theme: RedoButtonTheme.DESTRUCTIVE,
      };
    }, [setDeleteViewModalOpen]);

    const editViewItem: RedoCommandMenuItem = useMemo(() => {
      return {
        text: "Edit view",
        onClick: () => {
          setViewBeingEdited(true);
        },
        Icon: Edit04Icon,
      };
    }, [setViewBeingEdited]);

    const showUpdateViewButton =
      canEditSettings &&
      viewMeaningfullyDiffersFromBase &&
      !viewBeingEdited &&
      !viewIsSystemView;
    const showEditViewButton =
      !showUpdateViewButton &&
      canEditSettings &&
      !viewIsSystemView &&
      !viewBeingEdited;

    return (
      <>
        <Flex>
          {canEditSettings &&
            viewMeaningfullyDiffersFromBase &&
            !viewBeingEdited && (
              <RedoButton
                hierarchy={RedoButtonHierarchy.SECONDARY}
                onClick={() => setSaveViewModalOpen(true)}
                text="Save as new view"
              />
            )}
          {showUpdateViewButton && (
            <RedoButtonDropdown
              dropdownOpen={editViewDropdownOpen}
              onClick={() => {
                const updatedView: UpdateViewBody = {
                  name: view.name,
                  isPrivate: !!view.user,
                  filters: filterOptionsV2JsonFormat.write(filters),
                };
                sinkPromise(handleUpdateView(updatedView, view._id));
              }}
              ref={setEditViewDropdownRef}
              setDropdownOpen={setEditViewDropdownOpen}
              size={RedoButtonSize.SMALL}
              text="Update view"
            >
              <RedoCommandMenu
                anchor={editViewDropdownRef}
                items={[editViewItem, deleteViewItem]}
                open={editViewDropdownOpen}
                setOpen={setEditViewDropdownOpen}
              />
            </RedoButtonDropdown>
          )}

          {showEditViewButton && (
            <RedoButtonDropdown
              dropdownOpen={editViewDropdownOpen}
              onClick={() => setViewBeingEdited(true)}
              ref={setEditViewDropdownRef}
              setDropdownOpen={setEditViewDropdownOpen}
              size={RedoButtonSize.SMALL}
              text="Edit view"
            >
              <RedoCommandMenu
                anchor={editViewDropdownRef}
                items={[deleteViewItem]}
                open={editViewDropdownOpen}
                setOpen={setEditViewDropdownOpen}
              />
            </RedoButtonDropdown>
          )}
        </Flex>
        {saveViewModalOpen && (
          <SaveViewModal
            activeFilters={filters}
            cancel={() => setSaveViewModalOpen(false)}
            save={async (body) => {
              await handleCreateView(body);
              setSaveViewModalOpen(false);
            }}
          />
        )}
        {deleteViewModalOpen && (
          <DeleteViewModal
            open={deleteViewModalOpen}
            setOpen={(open) => {
              setDeleteViewModalOpen(open);
            }}
            view={view}
          />
        )}
      </>
    );
  },
);
