import { genericMemo, IterableMap } from "@redotech/react-util/component";
import { ArrowDown, ArrowUp, EnterKey } from "@redotech/web-util/key";
import * as React from "react";
import { ReactNode, useEffect } from "react";
import { Flex } from "../../flex";
import {
  RedoListItem,
  RedoListItemMenu,
  RedoListItemSize,
  RedoListItemVariant,
} from "./redo-list-item";
import * as redoListCss from "./redo-list.module.css";

export interface RedoListItem<T> {
  value: T;
  menu?: RedoListItemMenu;
}

export enum RedoListItemSelectedSource {
  Keyboard = "keyboard",
  Mouse = "mouse",
}

/**
 * @param refToListenTo -- this can be an item like a button or an input.
 * After the button is clicked or the input receives focus,
 * keyboard navigation will be enabled for the list.
 */
export interface RedoListProps<T> {
  items: RedoListItem<T>[];
  itemSelected(item: RedoListItem<T>, source: RedoListItemSelectedSource): void;
  refToListenTo: HTMLElement | null;
  focusedIndex: number | undefined;
  setFocusedIndex(index: number | undefined): void;
  selectionVariant?: RedoListItemVariant;
  children(item: RedoListItem<T>): ReactNode;
  isItemSelected?(item: RedoListItem<T>): boolean;
  keyFn?: (item: RedoListItem<T>, index: number) => string | number;
  size?: RedoListItemSize;
  containerClassName?: string;
}

export const RedoList = genericMemo(function RedoList<T>({
  size = RedoListItemSize.MEDIUM,
  items,
  itemSelected,
  isItemSelected = () => false,
  focusedIndex,
  setFocusedIndex,
  refToListenTo,
  children,
  selectionVariant = RedoListItemVariant.CHECKMARK,
  keyFn = (_, idx) => idx,
  containerClassName,
}: RedoListProps<T>) {
  function handleKeyPress(event: KeyboardEvent | React.KeyboardEvent) {
    if (event.key === EnterKey) {
      event.preventDefault();
      if (focusedIndex === undefined) {
        return;
      }
      itemSelected(items[focusedIndex], RedoListItemSelectedSource.Keyboard);
    } else if (event.key === ArrowDown) {
      event.preventDefault();
      const oldIndex = focusedIndex ?? -1;
      const newIndex = Math.min(oldIndex + 1, items.length - 1);
      setFocusedIndex(newIndex);
    } else if (event.key === ArrowUp) {
      event.preventDefault();
      const oldIndex = focusedIndex ?? -1;
      const newIndex = Math.max(oldIndex - 1, -1);
      if (newIndex === -1) {
        setFocusedIndex(undefined);
      } else {
        setFocusedIndex(newIndex);
      }
    }
  }

  useEffect(() => {
    refToListenTo?.addEventListener("keydown", handleKeyPress);
    return () => {
      refToListenTo?.removeEventListener("keydown", handleKeyPress);
    };
  }, [refToListenTo, handleKeyPress]);

  return (
    <div className={containerClassName}>
      <Flex
        className={redoListCss.childrenContainer}
        dir="column"
        onKeyDown={handleKeyPress}
        tabIndex={0}
      >
        <IterableMap items={items} keyFn={keyFn}>
          {(item, idx) => {
            const node = children(item);
            return (
              <RedoListItem
                focused={idx === focusedIndex}
                itemClicked={() =>
                  itemSelected(item, RedoListItemSelectedSource.Mouse)
                }
                menu={item.menu}
                onItemHovered={(hovered) => {
                  if (hovered) {
                    setFocusedIndex(idx);
                  } else if (focusedIndex === idx) {
                    setFocusedIndex(undefined);
                  }
                }}
                selected={isItemSelected(item)}
                selectionVariant={selectionVariant}
                size={size}
              >
                {node}
              </RedoListItem>
            );
          }}
        </IterableMap>
      </Flex>
    </div>
  );
});
