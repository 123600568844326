import { reviewBodySchema } from "@redotech/redo-model/review/review-body-schema";
import { ReviewStatus } from "@redotech/redo-model/review/review-field-schema";
import { z } from "zod";

export const getReviewsSchema = {
  input: z.object({
    teamId: z.string(),
    status: z.optional(z.nativeEnum(ReviewStatus)),
    verifiedPurchase: z.optional(z.boolean()),
    shopifyProductId: z.optional(z.string()),
  }),
  output: z.array(reviewBodySchema),
};
