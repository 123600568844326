import { ConversationStatus } from "@redotech/redo-model/conversation";
import { memo, ReactElement } from "react";
import {
  BadgeColor,
  BadgeSize,
  RedoBadge,
} from "../arbiter-components/badge/redo-badge";
import Asterisk02Svg from "../arbiter-icon/asterisk-02_filled.svg";
import ClockSnoozeSvg from "../arbiter-icon/clock-snooze_filled.svg";
import FolderCheckSvg from "../arbiter-icon/folder-check_filled.svg";

const StatusMappings: Record<
  ConversationStatus,
  [BadgeColor, (p: any) => ReactElement]
> = {
  [ConversationStatus.OPEN]: ["success", (p: any) => <Asterisk02Svg />],
  [ConversationStatus.CLOSED]: ["gray", (p: any) => <FolderCheckSvg />],
  [ConversationStatus.SNOOZED]: ["warning", (p: any) => <ClockSnoozeSvg />],
};

/**
 * Arbiter status badge:
 * https://www.figma.com/design/iZHj2I36zd9i8nRbWKw4ZK/%E2%9D%96-Arbiter?node-id=7852-62667&t=TcciBRu2ObN9VJRA-4
 */
export const RedoSupportStatusBadge = memo(function RedoSupportStatusBadge({
  status,
  size = "sm",
}: {
  status: ConversationStatus;
  size?: BadgeSize;
}) {
  const [color, iconSvg] = StatusMappings[status];

  return (
    <RedoBadge
      color={color}
      segmentLeading={{ type: "icon", Icon: iconSvg }}
      size={size}
      text={status
        .split("")
        .map((k, i) => (i === 0 ? k.toUpperCase() : k.toLowerCase()))
        .join("")}
      type="pillColor"
    />
  );
});
