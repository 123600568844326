import { ClickAwayListener } from "@mui/base";
import { Dropdown } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import { JSXElementConstructor, memo, MouseEvent } from "react";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "../buttons/redo-button";
import * as redoCommandMenuCss from "./redo-command-menu.module.css";

export interface RedoCommandMenuItem {
  Icon?: JSXElementConstructor<any>;
  text: string;
  onClick(event: MouseEvent<HTMLButtonElement>): void;
  theme?: RedoButtonTheme;
  size?: RedoButtonSize;
}

export const RedoCommandMenu = memo(function RedoCommandMenu({
  items,
  open,
  setOpen,
  anchor,
  size = RedoButtonSize.SMALL,
}: {
  items: RedoCommandMenuItem[];
  open: boolean;
  setOpen(value: boolean): void;
  anchor: HTMLElement | null;
  size?: RedoButtonSize;
}) {
  const atLeastOneIcon = items.some((item) => item.Icon !== undefined);

  const dropdown = (
    <Dropdown anchor={anchor} fitToAnchor={false} open={open}>
      <Flex dir="column">
        {items.map((item, idx) => {
          return (
            <RedoCommandMenuItem
              indentForIcon={atLeastOneIcon}
              item={item}
              itemClicked={() => setOpen(false)}
              key={idx}
              size={size}
            />
          );
        })}
      </Flex>
    </Dropdown>
  );

  return (
    <>
      {open && (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          {dropdown}
        </ClickAwayListener>
      )}
    </>
  );
});

/**
 * @param indentForIcon if at least one of the menu items has an icon,
 * we want to space all the buttons evenly
 */
const RedoCommandMenuItem = memo(function RedoCommandMenuItem({
  item,
  indentForIcon,
  itemClicked,
  size = RedoButtonSize.SMALL,
}: {
  item: RedoCommandMenuItem;
  indentForIcon: boolean;
  itemClicked(): void;
  size?: RedoButtonSize;
}) {
  const icon = item.Icon || (indentForIcon ? "placeholder" : undefined);

  return (
    <RedoButton
      centerItems={false}
      className={redoCommandMenuCss.dropdownButton}
      hierarchy={RedoButtonHierarchy.TERTIARY}
      IconLeading={icon}
      onClick={(event) => {
        itemClicked();
        item.onClick(event);
      }}
      size={size}
      text={item.text}
      theme={item.theme}
    />
  );
});
