// extracted by mini-css-extract-plugin
export var actionButton = "_6p-0";
export var assigneeDetails = "_6p-1";
export var assigneeInfo = "_6p-2";
export var assigneeProfile = "_6p-4";
export var buttonContent = "_6p-5";
export var ccEmails = "_6p-a";
export var ccEmailsSection = "_6p-9";
export var chatbotContainer = "_6p-6";
export var collapseContainer = "_6p-c";
export var copyableField = "_6p-d";
export var customerInfoTitle = "_6p-f";
export var detailRow = "_6p-h";
export var details = "_6p-e";
export var detailsText = "_6p-l";
export var disclaimer = "_6p-b";
export var editAssigneeForm = "_6p-j";
export var editAssigneeInfo = "_6p-3";
export var editAssigneeModalContainer = "_6p-i";
export var editButton = "_6p-g";
export var email = "_6p-7";
export var header = "_6p-m";
export var headerDivider = "_6p-n";
export var icon = "_6p-o";
export var instaDesc = "_6p-x";
export var ordersReturnsSection = "_6p-v";
export var postPreview = "_6p-w";
export var profilePicture = "_6p-p";
export var rightContent = "_6p-8";
export var saveButton = "_6p-k";
export var strong = "_6p-q";
export var tagsAndAssigneeContainer = "_6p-t";
export var username = "_6p-r";
export var withBottomBorder = "_6p-u";
export var wrapper = "_6p-s";
export var wrappingText = "_6p-y";