// extracted by mini-css-extract-plugin
export var createdDate = "_6q-1";
export var divider = "_6q-2";
export var headerDivider = "_6q-3";
export var messagesCard = "_6q-0";
export var messagesContainer = "_6q-6";
export var messagesContainerWrapper = "_6q-4";
export var showLeftPanelButton = "_6q-9";
export var topButtons = "_6q-8";
export var typingIndicator = "_6q-7";
export var withPadding = "_6q-5";