import {
  arrayJsonFormat,
  booleanJsonFormat,
  dateJsonFormat,
  JsonFormat,
  nullableJsonFormat,
  objectJsonFormat,
  stringEnumJsonFormat,
  stringJsonFormat,
  stringUnionJsonFormat,
  tupleJsonFormat,
  unionFormat,
} from "@redotech/json/format";
import { Tuple } from "@redotech/util/type";
import { ConversationPlatform } from "./conversation";
import {
  sortableConversationsTableColumns,
  SortableConversationTableColumn,
  SortDirection,
  TableSort,
} from "./table";

export interface FilterOptions {
  modified?: boolean;
  status?: FiltersStatus[];
  channels?: ConversationPlatform[];
  assignees?: (string | null)[];
  read?: boolean | null;
  createdDateStart?: string;
  createdDateEnd?: string;
  closedDateStart?: string;
  closedDateEnd?: string;
  lastResponseAtStart?: string;
  lastResponseAtEnd?: string;
  mentions?: string[];
  words?: string[];
  conversationTags?: string[];
  customerTags?: string[];
  search?: string;
  sort?: TableSort<SortableConversationTableColumn>;
  key?: SortableConversationTableColumn;
  direction?: SortDirection;
  customerEmail?: string | undefined;
}

export enum FilterGroupFilterOption {
  ASSIGNEES = "assignees",
  CHANNELS = "channels",
  CONVERSATION_TAGS = "conversationTags",
  CLOSED_DATE = "closedDate",
  MENTIONS = "mentions",
  CREATED_DATE = "createdDate",
  CUSTOMER_TAGS = "customerTags",
  LAST_RESPONSE_AT = "lastResponseAt",
  READ = "read",
  WORDS = "words",
}

export interface FilterOptionsV2 {
  status?: FiltersStatus | null;
  channels?: ChannelsFilter | null;
  assignees?: AssigneesFilter | null;
  read?: ReadStatusFilter | null;
  createdDate?: DateFilter | null;
  lastResponseAt?: DateFilter | null;
  closedDate?: DateFilter | null;
  conversationTags?: ConversationTagsFilter | null;
  words?: WordsFilter | null;
  customerTags?: CustomerTagsFilter | null;
  search?: string | null;
  sort?: TableSort<SortableConversationTableColumn> | null;
  customerEmail?: string | null;
  mentionsUsers?: MentionsUsersFilter | null;
  drafts?: boolean | null;
}

export interface ReadStatusFilter {
  value: boolean | null;
}

export interface WordsFilter {
  value: string[] | null;
}

export enum FiltersStatus {
  OPEN = "open",
  CLOSED = "closed",
  SNOOZED = "snoozed",
}

export enum ConversationTagFilterType {
  ALL_OF = "all_of",
  ANY_OF = "any_of",
  NONE_OF = "none_of",
}
export interface ConversationTagsFilter {
  tagNames: string[] | null;
  type: ConversationTagFilterType;
}

export enum CustomerTagsFilterType {
  ALL_OF = "all_of",
  ANY_OF = "any_of",
  NONE_OF = "none_of",
}

export interface CustomerTagsFilter {
  tagNames: string[] | null;
  type: CustomerTagsFilterType;
}

export enum ChannelFilterType {
  INCLUDES = "includes",
  EXCLUDES = "exlcudes",
}
export interface ChannelsFilter {
  channels: ConversationPlatform[] | null;
  type: ChannelFilterType;
}

export enum AssigneesFilterType {
  INCLUDES = "includes",
  EXCLUDES = "exlcudes",
}
export interface AssigneesFilter {
  assignees: (string | null)[] | null;
  type: AssigneesFilterType;
}

export interface MentionsUsersFilter {
  value: string[] | null;
}

export interface DateFilter {
  queryType: DateFilterQueryType;
  timeFrame: KnownDateFilterTimeFrame | null;
  customDate?: Date | Tuple<Date, 2>;
}
export enum DateFilterQueryType {
  WITHIN = "within",
  BEFORE = "before",
  AFTER = "after",
}
export enum KnownDateFilterTimeFrame {
  TODAY = "today",
  THIS_WEEK = "this week",
  LAST_WEEK = "last week",
  THIS_MONTH = "this month",
  LAST_MONTH = "last month",
  THIS_YEAR = "this year",
  LAST_YEAR = "last year",
  CUSTOM = "custom",
}

const customDateFormat = unionFormat<Date, Tuple<Date, 2>>(
  dateJsonFormat,
  tupleJsonFormat<Date, 2>(dateJsonFormat, 2),
  (value) => {
    if (Array.isArray(value)) {
      return tupleJsonFormat(dateJsonFormat, 2);
    }
    return dateJsonFormat;
  },
);

const dateFilterFormat = objectJsonFormat<DateFilter>(
  {
    queryType: stringEnumJsonFormat(DateFilterQueryType),
    timeFrame: nullableJsonFormat(
      stringEnumJsonFormat(KnownDateFilterTimeFrame),
    ),
  },
  { customDate: customDateFormat },
);

export const filterOptionsV2JsonFormat: JsonFormat<FilterOptionsV2> =
  objectJsonFormat(
    {},
    {
      status: nullableJsonFormat(stringEnumJsonFormat(FiltersStatus)),
      channels: nullableJsonFormat(
        objectJsonFormat<ChannelsFilter>(
          {
            channels: nullableJsonFormat(
              arrayJsonFormat(stringEnumJsonFormat(ConversationPlatform)),
            ),
            type: stringEnumJsonFormat(ChannelFilterType),
          },
          {},
        ),
      ),
      assignees: nullableJsonFormat(
        objectJsonFormat<AssigneesFilter>(
          {
            assignees: nullableJsonFormat(
              arrayJsonFormat(nullableJsonFormat(stringJsonFormat)),
            ),
            type: stringEnumJsonFormat(AssigneesFilterType),
          },
          {},
        ),
      ),
      read: nullableJsonFormat(
        objectJsonFormat<ReadStatusFilter>(
          { value: nullableJsonFormat(booleanJsonFormat) },
          {},
        ),
      ),
      createdDate: nullableJsonFormat(dateFilterFormat),
      lastResponseAt: nullableJsonFormat(dateFilterFormat),
      closedDate: nullableJsonFormat(dateFilterFormat),
      conversationTags: nullableJsonFormat(
        objectJsonFormat<ConversationTagsFilter>(
          {
            tagNames: nullableJsonFormat(arrayJsonFormat(stringJsonFormat)),
            type: stringEnumJsonFormat(ConversationTagFilterType),
          },
          {},
        ),
      ),
      words: nullableJsonFormat(
        objectJsonFormat<WordsFilter>(
          { value: nullableJsonFormat(arrayJsonFormat(stringJsonFormat)) },
          {},
        ),
      ),
      customerTags: nullableJsonFormat(
        objectJsonFormat<CustomerTagsFilter>(
          {
            tagNames: nullableJsonFormat(arrayJsonFormat(stringJsonFormat)),
            type: stringEnumJsonFormat(CustomerTagsFilterType),
          },
          {},
        ),
      ),
      search: nullableJsonFormat(stringJsonFormat),
      sort: nullableJsonFormat(
        objectJsonFormat<TableSort<SortableConversationTableColumn>>(
          {
            key: stringUnionJsonFormat(sortableConversationsTableColumns),
            direction: stringEnumJsonFormat(SortDirection),
          },
          {},
        ),
      ),
      customerEmail: nullableJsonFormat(stringJsonFormat),
      mentionsUsers: nullableJsonFormat(
        objectJsonFormat<MentionsUsersFilter>(
          {
            value: nullableJsonFormat(arrayJsonFormat(stringJsonFormat)),
          },
          {},
        ),
      ),
      drafts: nullableJsonFormat(booleanJsonFormat),
    },
  );

export function getFiltersQueryParam(
  filter: FilterOptionsV2,
): string | undefined {
  const filterJson = filterOptionsV2JsonFormat.write(filter);
  const stringified = JSON.stringify(filterJson);
  return encodeURIComponent(stringified);
}

/**
 * Filters out filters that are present, but unset (i.e. they have a value that is null)
 */
export function scrubUnsetFilters(filters: FilterOptionsV2): FilterOptionsV2 {
  const filtersCopy: Record<string, any> = { ...filters };
  for (const key in filtersCopy) {
    if (typeof filtersCopy[key] === "object") {
      const subObject = filtersCopy[key];

      for (const subKey in subObject) {
        if (subObject[subKey] === null) {
          delete filtersCopy[key];
          break;
        }
      }
    }
  }
  return filtersCopy;
}

function scrubExplicitlyNullFilters(filters: FilterOptionsV2): FilterOptionsV2 {
  const filtersCopy: Record<string, any> = { ...filters };
  for (const key in filtersCopy) {
    if (filtersCopy[key] === null) {
      delete filtersCopy[key];
    }
  }
  return filtersCopy;
}

export function scrubFilterFieldsThatArentStoredInTheDatabase(
  filters: FilterOptionsV2,
): FilterOptionsV2 {
  const filtersCopy: Record<string, any> = { ...filters };
  delete filtersCopy.search;
  delete filtersCopy.customerEmail;
  return filtersCopy;
}

function addOpenStatus(filters: FilterOptionsV2) {
  return {
    ...filters,
    status: FiltersStatus.OPEN,
  };
}

export function formatFiltersForDatabase(
  filters: FilterOptionsV2,
): FilterOptionsV2 {
  return scrubUnsetFilters(
    scrubExplicitlyNullFilters(
      scrubFilterFieldsThatArentStoredInTheDatabase(addOpenStatus(filters)),
    ),
  );
}

export function convertV2FiltersToV1(filters: FilterOptionsV2): FilterOptions {
  const createdDates = parseWithinDatesFromFilter(filters.createdDate);
  const closedDates = parseWithinDatesFromFilter(filters.closedDate);
  const lastResponseDates = parseWithinDatesFromFilter(filters.lastResponseAt);

  return {
    status: filters.status ? [filters.status] : undefined,
    channels: filters.channels?.channels || undefined,
    assignees: filters.assignees?.assignees || undefined,
    read: filters.read?.value,
    createdDateStart: createdDates[0],
    createdDateEnd: createdDates[1],
    closedDateStart: closedDates[0],
    closedDateEnd: closedDates[1],
    lastResponseAtStart: lastResponseDates[0],
    lastResponseAtEnd: lastResponseDates[1],
    mentions: filters.mentionsUsers?.value || undefined,
    words: filters.words?.value || undefined,
    conversationTags: filters.conversationTags?.tagNames || undefined,
    customerTags: filters.customerTags?.tagNames || undefined,
    search: filters.search || undefined,
    sort: filters.sort || undefined,
  };
}

function parseWithinDatesFromFilter(
  filter: DateFilter | undefined | null,
): string[] {
  if (!filter) {
    return [];
  }
  if (filter.queryType !== DateFilterQueryType.WITHIN) {
    return [];
  }
  if (!filter.customDate || !Array.isArray(filter.customDate)) {
    return [];
  }
  const [startDate, endDate] = filter.customDate;
  return [startDate.toISOString(), endDate.toISOString()];
}

export function convertFilterOptionsToV2(
  options: FilterOptions,
): FilterOptionsV2 {
  const convertLegacyDateFormatToDateFilter = (
    dateStart?: string,
    dateEnd?: string,
  ): DateFilter | undefined => {
    if (!dateStart || !dateEnd) {
      return undefined;
    }
    const startDate = new Date(dateStart);
    const endDate = new Date(dateEnd);
    return {
      queryType: DateFilterQueryType.WITHIN,
      timeFrame: KnownDateFilterTimeFrame.CUSTOM,
      customDate: [startDate, endDate],
    };
  };

  // Right now, for 'all', we provide all three statuses. This is the same as having no filter
  const status =
    options.status && options.status.length === 1
      ? options.status[0]
      : undefined;

  const sortKey = options.sort?.key || options.key;
  const sortDirection = options.sort?.direction || options.direction;

  const sort =
    sortKey && sortDirection
      ? { key: sortKey, direction: sortDirection }
      : undefined;

  return {
    status,
    channels:
      options.channels && options.channels.length > 0
        ? { channels: options.channels, type: ChannelFilterType.INCLUDES }
        : undefined,
    assignees:
      options.assignees && options.assignees.length > 0
        ? { assignees: options.assignees, type: AssigneesFilterType.INCLUDES }
        : undefined,
    read: options.read !== undefined ? { value: options.read } : undefined,
    createdDate: convertLegacyDateFormatToDateFilter(
      options.createdDateStart,
      options.createdDateEnd,
    ),
    lastResponseAt: convertLegacyDateFormatToDateFilter(
      options.lastResponseAtStart,
      options.lastResponseAtEnd,
    ),
    closedDate: convertLegacyDateFormatToDateFilter(
      options.closedDateStart,
      options.closedDateEnd,
    ),
    conversationTags:
      options.conversationTags && options.conversationTags.length > 0
        ? {
            tagNames: options.conversationTags,
            type: ConversationTagFilterType.ANY_OF,
          }
        : undefined,
    words:
      options.words && options.words.length > 0
        ? { value: options.words }
        : undefined,
    customerTags:
      options.customerTags && options.customerTags.length > 0
        ? {
            tagNames: options.customerTags,
            type: CustomerTagsFilterType.ANY_OF,
          }
        : undefined,
    search: options.search,
    sort: sort,
    customerEmail: options.customerEmail,
    mentionsUsers: options.mentions ? { value: options.mentions } : undefined,
  };
}
