// extracted by mini-css-extract-plugin
export var box = "_6x-1";
export var checked = "_6x-5";
export var descriptionSpacer = "_6x-9";
export var disabled = "_6x-4";
export var icon = "_6x-6";
export var input = "_6x-0";
export var labelSpacer = "_6x-8";
export var medium = "_6x-3";
export var small = "_6x-2";
export var text = "_6x-7";