import { PillTheme } from "@redotech/redo-model/pill-theme";
import {
  BadgeColor,
  RedoBadge,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import XIcon from "@redotech/redo-web/icon-old/x.svg";
import * as classNames from "classnames";
import { memo } from "react";
import * as conversationTagInputCss from "./conversation-tag-input.module.css";

interface ConversationTagLike {
  name: string;
  pillTheme: PillTheme;
}

export const ConversationTagPillThemes = [
  PillTheme.NORMAL,
  PillTheme.PRIMARY_LIGHT,
  PillTheme.LIGHT_PINK,
  PillTheme.DANGER,
  PillTheme.NEUTRAL_YELLOW,
  PillTheme.SUCCESS,
  PillTheme.NEUTRAL_BLUE,
];

export const BadgePillThemeToColorMapping: Record<PillTheme, BadgeColor> = {
  [PillTheme.NORMAL]: "gray",
  [PillTheme.PRIMARY_LIGHT]: "purple",
  [PillTheme.LIGHT_PINK]: "pink",
  [PillTheme.DANGER]: "orange",
  [PillTheme.NEUTRAL_YELLOW]: "warning",
  [PillTheme.SUCCESS]: "success",
  [PillTheme.NEUTRAL_BLUE]: "blueLight",
  [PillTheme.PRIMARY]: "purple",
  [PillTheme.SOLID_PINK]: "pink",
  [PillTheme.SOLID_RED]: "error",
  [PillTheme.SOLID_ORANGE]: "orange",
  [PillTheme.SOLID_YELLOW]: "warning",
  [PillTheme.SOLID_GREEN]: "success",
  [PillTheme.SOLID_LIGHT_BLUE]: "blue",
  [PillTheme.SOLID_NAVY]: "indigo",
  [PillTheme.SOLID_BLACK]: "black",
};

export const ConversationTagPill = memo(function ConversationTagPill({
  tag,
  xClicked,
  showOverflow = false,
  badgeSize = "sm",
}: {
  tag: ConversationTagLike;
  xClicked?(): void;
  showOverflow?: boolean;
  badgeSize?: "sm" | "xs";
}) {
  return (
    <div
      className={classNames(
        conversationTagInputCss.pillWrapper,
        showOverflow && conversationTagInputCss.showOverflow,
      )}
    >
      <RedoBadge
        color={BadgePillThemeToColorMapping[tag.pillTheme]}
        iconTrailing={
          xClicked
            ? { type: "icon", onClick: xClicked, Icon: XIcon }
            : undefined
        }
        size={badgeSize}
        text={tag.name}
      />
    </div>
  );
});
