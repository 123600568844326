// extracted by mini-css-extract-plugin
export var actionButton = "_51-t";
export var buttonContainer = "_51-0";
export var buttonContainer2 = "_51-1";
export var buttonSection = "_51-2";
export var details = "_51-3";
export var dropdownItem = "_51-5";
export var dropdownItemsContainer = "_51-4";
export var fileInput = "_51-r";
export var headerButton = "_51-6";
export var icon = "_51-7";
export var input = "_51-8";
export var macroListItem = "_51-9";
export var mainContent = "_51-a";
export var microText = "_51-b";
export var modal = "_51-w";
export var optionsButton = "_51-c";
export var paperclip = "_51-s";
export var pillContent = "_51-d";
export var preview = "_51-e";
export var preview2 = "_51-f";
export var quillEditor = "_51-q";
export var selectedMacro = "_51-g";
export var sidebar = "_51-h";
export var sidebarHeader = "_51-j";
export var sidebarList = "_51-i";
export var sidebarListItem = "_51-k";
export var sidebarSearch = "_51-l";
export var snoozeDurationWrapper = "_51-v";
export var statusPillContainer = "_51-m";
export var tagsContainer = "_51-n";
export var unselectedMacro = "_51-o";
export var variable = "_51-u";
export var wrapper = "_51-p";