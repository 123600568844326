import * as amplitude from "@amplitude/analytics-browser";
import { ClickAwayListener } from "@mui/material";
import { useHandler } from "@redotech/react-util/hook";
import { isControlKeyPressed } from "@redotech/react-util/html-events";
import { getSelectionDelta } from "@redotech/react-util/table-select";
import {
  ConversationPlatform,
  ExpandedConversation,
  textForDeletedFacebookComment,
} from "@redotech/redo-model/conversation";
import { FilterOptionsV2 } from "@redotech/redo-model/conversation-filters";
import {
  SortableConversationTableColumn,
  SortDirection,
  TableSort,
} from "@redotech/redo-model/table";
import { View } from "@redotech/redo-model/view";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { ButtonSize } from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import { CardClickHandler } from "@redotech/redo-web/card-list";
import { Checkbox } from "@redotech/redo-web/checkbox";
import ThreeDotsHorizontalIcon from "@redotech/redo-web/icon-old/three-dots-horizontal.svg";
import { RedoSupportChannelBadge } from "@redotech/redo-web/support/redo-support-channel-badge";
import {
  Column,
  ColumnAlignment,
  Table,
  TableRef,
} from "@redotech/redo-web/table";
import * as classNames from "classnames";
import { memo, useContext, useState } from "react";
import { ActiveUsers } from "../active-users";
import { ConversationFetcher } from "../conversation-fetcher";
import { ConversationTagPill } from "../conversation-tags/conversation-tag-pill";
import { getFirstDraftFromConversation } from "../message-input-utils";
import { getCustomerTitle, removeSimpleHTMLTags } from "../utils";
import { ConversationsTableSelectionContext } from "./conversations-table-selection";
import * as conversationsTableCss from "./conversations-table.module.css";

export interface ConversationTableDropdownAction {
  show: boolean;
  component: JSX.Element;
}

export type ConversationTableDropdownActions = (
  conversation: ExpandedConversation,
  setDropdownOpen: (open: boolean) => void,
) => ConversationTableDropdownAction[];

export const ConversationsTable = memo(function ConversationsTable({
  view,
  handleCardClick,
  fetcher,
  filters,
  tableRef,
  dropdownActions,
  onConversationsViewed,
  scrollAreaRef,
  showCustomerColumn = true,
  showMenuColumn = true,
  externalSetSort,
  externalSort,
}: {
  view?: View | undefined; // details for custom views
  handleCardClick?:
    | ((conversation: ExpandedConversation | undefined) => void)
    | undefined;
  fetcher: ConversationFetcher;
  filters: FilterOptionsV2;
  tableRef: React.MutableRefObject<TableRef<ExpandedConversation>>;
  dropdownActions?: ConversationTableDropdownActions | undefined;
  onConversationsViewed: (
    conversation: ExpandedConversation,
  ) => Promise<ExpandedConversation | undefined>;
  scrollAreaRef: React.MutableRefObject<any>;
  showCustomerColumn?: boolean | undefined;
  showMenuColumn?: boolean | undefined;
  externalSetSort?: (sort: TableSort<any>) => void;
  externalSort?: TableSort<any>;
}) {
  const sortDefault: TableSort<SortableConversationTableColumn> = view?.filters
    .sort || {
    direction: SortDirection.DESC,
    key: "lastResponse",
  };

  const conversationsTableSelection = useContext(
    ConversationsTableSelectionContext,
  );

  const onSelectAllCheckboxChange = (checked: boolean) => {
    if (!conversationsTableSelection) {
      return;
    }
    setSelectionSliceStart(undefined);
    setSelectionSliceEnd(undefined);
    if (checked) {
      amplitude.logEvent("selectAll-conversation", { view });
      conversationsTableSelection.selectAll();
    } else {
      amplitude.logEvent("deselectAll-conversation", { view });
      if (conversationsTableSelection.deselectedIds.length === 0) {
        conversationsTableSelection.clearSelection();
      }
    }
  };

  const [selectionSliceStart, setSelectionSliceStart] = useState<
    number | undefined
  >();

  const [selectionSliceEnd, setSelectionSliceEnd] = useState<
    number | undefined
  >();

  const onRowClick = useHandler<CardClickHandler<ExpandedConversation>>(
    async (record, mouseEvent, idx) => {
      if (!conversationsTableSelection) {
        const newRecord = await onConversationsViewed(record);
        handleCardClick?.(newRecord);
        return;
      }

      if (isControlKeyPressed(mouseEvent) || mouseEvent.shiftKey) {
        mouseEvent.stopPropagation();
      }

      if (!isControlKeyPressed(mouseEvent) && !mouseEvent.shiftKey) {
        const newRecord = await onConversationsViewed(record);
        handleCardClick?.(newRecord);
        return;
      }

      const { itemsToAdd, itemsToRemove } = getSelectionDelta({
        mouseEvent: mouseEvent,
        idx,
        items: tableRef.current.items,
        selectedItems: conversationsTableSelection.selectedRecords,
        deselectedItems: conversationsTableSelection.deselectedRecords,
        itemsEqual: (item1, item2) => item1._id === item2._id,
        multiSelectAnchorStart: selectionSliceStart,
        multiSelectAnchorEnd: selectionSliceEnd,
        setMultiSelectAnchorStart: setSelectionSliceStart,
        setMultiSelectAnchorEnd: setSelectionSliceEnd,
        selectAllMode: conversationsTableSelection.selectAllMode,
      });

      conversationsTableSelection.addToSelection(
        Array.from(itemsToAdd.map((item) => item._id)),
      );
      conversationsTableSelection.removeFromSelection(
        Array.from(itemsToRemove.map((item) => item._id)),
      );
    },
  );

  // conversation ID
  const [currentConversationHovered, setCurrentConversationHovered] = useState<
    string | undefined
  >();

  const onRowHovered = (conversation?: ExpandedConversation) => {
    setCurrentConversationHovered(conversation?._id);
  };

  const isIndeterminate = () => {
    if (!conversationsTableSelection) {
      return false;
    }

    const selectedIdsCount = conversationsTableSelection.selectedIds.length;

    const isIndeterminateOutsideOfSelectAllMode =
      !conversationsTableSelection.selectAllMode &&
      selectedIdsCount > 0 &&
      selectedIdsCount < conversationsTableSelection.totalConversations;

    const isIndeterminateInSelectAllMode =
      conversationsTableSelection.selectAllMode &&
      conversationsTableSelection.deselectedIds.length > 0;

    return (
      isIndeterminateOutsideOfSelectAllMode || isIndeterminateInSelectAllMode
    );
  };

  const selectionColumn: Column<ExpandedConversation> | undefined =
    conversationsTableSelection
      ? {
          alignment: ColumnAlignment.LEFT,
          key: "select",
          title:
            conversationsTableSelection.totalConversations !== 0 ? (
              <div className={conversationsTableCss.headerCheckboxContainer}>
                <Checkbox
                  clickStopPropagation
                  indeterminate={isIndeterminate()}
                  onChange={onSelectAllCheckboxChange}
                  value={
                    (conversationsTableSelection.selectAllMode &&
                      conversationsTableSelection.deselectedIds.length === 0) ||
                    conversationsTableSelection.selectedIds?.length ===
                      conversationsTableSelection.totalConversations
                  }
                />
              </div>
            ) : (
              ""
            ),
          Cell: (conversation, idx) => {
            const onCheckboxChange = (checked: boolean) => {
              setSelectionSliceStart(idx);
              setSelectionSliceEnd(undefined);
              if (checked) {
                conversationsTableSelection.addToSelection([conversation._id]);
              } else {
                conversationsTableSelection.removeFromSelection([
                  conversation._id,
                ]);
              }
            };

            const shouldShowCheckbox = () => {
              if (
                conversationsTableSelection &&
                (currentConversationHovered === conversation._id ||
                  conversationsTableSelection.selectedIds.length > 0 ||
                  conversationsTableSelection.selectAllMode)
              ) {
                return true;
              }
              return false;
            };

            const isChecked = conversationsTableSelection.selectAllMode
              ? !conversationsTableSelection.deselectedIds.some(
                  (deselectedId) => deselectedId === conversation._id,
                )
              : conversationsTableSelection.selectedIds.some(
                  (selectedId) => selectedId === conversation._id,
                );

            return (
              <div>
                {shouldShowCheckbox() ? (
                  <div
                    className={conversationsTableCss.checkboxContainer}
                    onClick={() => {
                      const checkbox = document.getElementById(
                        `${conversation._id}-checkbox`,
                      );
                      onCheckboxChange(
                        (checkbox as HTMLInputElement)?.checked || false,
                      );
                    }}
                  >
                    <Checkbox
                      clickStopPropagation
                      id={`${conversation._id}-checkbox`}
                      onChange={onCheckboxChange}
                      value={isChecked}
                    />
                  </div>
                ) : conversation.read ? null : (
                  <div className={conversationsTableCss.unread} />
                )}
              </div>
            );
          },
          onClick: (event, conversation) => {
            event.stopPropagation();
            const checkbox = document.getElementById(
              `${conversation._id}-checkbox`,
            );
            checkbox?.click();
          },
          width: 32,
        }
      : {
          alignment: ColumnAlignment.LEFT,
          key: "viewed",
          title: "",
          Cell: (conversation) => {
            return conversation.read ? null : (
              <div className={conversationsTableCss.unread} />
            );
          },
          width: 32,
        };

  const customerColumn: Column<ExpandedConversation> | undefined =
    showCustomerColumn
      ? {
          alignment: ColumnAlignment.LEFT,
          key: "customer",
          title: "Customer",
          Cell: (conversation) => {
            return (
              <div
                className={classNames(
                  conversationsTableCss.strong,
                  conversationsTableCss.oneLine,
                )}
              >
                {getCustomerTitle(conversation)}
              </div>
            );
          },
          width: 150,
          sort: SortDirection.ASC,
        }
      : undefined;

  const menuColumn: Column<ExpandedConversation> | undefined = showMenuColumn
    ? {
        alignment: ColumnAlignment.LEFT,
        key: "menu",
        title: "",
        Cell: (conversation) => {
          const [dropdownOpen, setDropdownOpen] = useState(false);
          const ticketActionsDropdown = (
            <>
              <div className={conversationsTableCss.dropdownTitle}>Actions</div>
              {dropdownActions
                ? dropdownActions(conversation, setDropdownOpen)
                    .filter((dropdownAction) => dropdownAction.show)
                    .map((dropdownAction) => dropdownAction.component)
                : null}
            </>
          );

          return (
            <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
              <div className={conversationsTableCss.menuContainer}>
                {/* Because we need to use stopPropagation to not move to the next page when menu is clicked we need to manually hide the menu */}
                <ButtonDropdown
                  clickStopPropagation
                  dropdown={ticketActionsDropdown}
                  open={dropdownOpen}
                  restrictDropdownSizeToParent={false}
                  setOpen={setDropdownOpen}
                  size={ButtonSize.MICRO}
                >
                  <ThreeDotsHorizontalIcon />
                </ButtonDropdown>
              </div>
            </ClickAwayListener>
          );
        },
        width: 60,
      }
    : undefined;

  const fullColumns: Column<ExpandedConversation>[] = [
    ...(selectionColumn ? [selectionColumn] : []),
    ...(customerColumn ? [customerColumn] : []),
    {
      alignment: ColumnAlignment.LEFT,
      key: "active-users",
      title: "",
      Cell: (conversation) => {
        return <ActiveUsers conversation={conversation} maxAvatars={2} />;
      },
      width: 75,
    },
    {
      alignment: ColumnAlignment.LEFT,
      key: "summary",
      title: "Summary",
      Cell: (conversation) => {
        let latestMessageContent = "No content";
        if (conversation.messages?.length > 0) {
          const lastMessage = conversation.messages.at(-1)!;
          latestMessageContent = lastMessage.content;
          if (lastMessage.facebook?.comment?.deleted) {
            latestMessageContent = textForDeletedFacebookComment;
          }
        }
        const contentLines =
          removeSimpleHTMLTags(latestMessageContent).split("\n");

        let latestDraftContent = "No content";
        const latestDraft = getFirstDraftFromConversation(conversation);
        if (latestDraft) {
          latestDraftContent = latestDraft.content;
        }
        const latestDraftLines =
          removeSimpleHTMLTags(latestDraftContent).split("\n");

        return (
          <div className={conversationsTableCss.summaryCell}>
            {conversation.platform === ConversationPlatform.EMAIL && (
              <div className={conversationsTableCss.oneLine}>
                <span className={conversationsTableCss.strong}>Subject:</span>{" "}
                {conversation.subject}
              </div>
            )}
            <div
              className={classNames(conversationsTableCss.gray, {
                [conversationsTableCss.oneLine]:
                  conversation.platform === ConversationPlatform.EMAIL,
                [conversationsTableCss.twoLines]:
                  conversation.platform !== ConversationPlatform.EMAIL,
              })}
            >
              {latestDraft && (
                <span>
                  <div
                    className={conversationsTableCss.draftReplyBadgeContainer}
                  >
                    <RedoBadge color="gray" size="xs" text="Draft" />
                  </div>
                </span>
              )}
              {latestDraft ? (
                <span className={conversationsTableCss.draftMessagePreview}>
                  {[...latestDraftLines].join(" ")}
                </span>
              ) : (
                [...contentLines].join(" ")
              )}
            </div>
          </div>
        );
      },
      width: 300,
      sort: SortDirection.ASC,
    },
    {
      alignment: ColumnAlignment.LEFT,
      key: "assignee",
      title: "Assignee",
      Cell: (conversation) => (
        <div className={conversationsTableCss.oneLine}>
          {conversation.assignee?.name || "-"}
        </div>
      ),
      width: 125,
      sort: SortDirection.ASC,
    },
    {
      alignment: ColumnAlignment.LEFT,
      key: "platform",
      title: "Platform",
      Cell: (conversation) => (
        <RedoSupportChannelBadge platform={conversation.platform} />
      ),
      width: 125,
      sort: SortDirection.ASC,
    },
    {
      alignment: ColumnAlignment.LEFT,
      key: "lastResponse",
      title: "Last response",
      Cell: (conversation) => {
        if (conversation.lastCustomerResponseAt) {
          return new Date(conversation.lastCustomerResponseAt).toLocaleString(
            [],
            {
              month: "short",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
            },
          );
        }
        return undefined;
      },
      width: 125,
      sort: SortDirection.DESC,
    },
    {
      alignment: ColumnAlignment.LEFT,
      key: "tags",
      title: "Tags",
      Cell: (conversation) => {
        return (
          <div className={conversationsTableCss.tagContainer}>
            {conversation.tagIds?.map((tag) => (
              <ConversationTagPill key={tag.name} showOverflow tag={tag} />
            ))}
          </div>
        );
      },
      width: 175,
    },
    ...(menuColumn ? [menuColumn] : []),
  ];

  return (
    <span className={conversationsTableCss.conversationsTableWrapper}>
      <Table
        columns={fullColumns}
        externalSetSort={externalSetSort}
        externalSort={externalSort}
        fetcher={fetcher}
        onRowClick={onRowClick}
        onRowHovered={onRowHovered}
        passThroughValues={filters}
        ref={tableRef}
        scrollAreaRef={scrollAreaRef}
        sortDefault={sortDefault}
      />
    </span>
  );
});
