import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoClient } from "@redotech/redo-api-client";
import {
  sendTyping,
  uploadFile,
} from "@redotech/redo-api-client/conversations";
import {
  ConversationPlatform,
  ExpandedConversation,
  ExpandedConversationMessage,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { Attachment } from "@redotech/redo-model/createconversationbody";
import { Macro, MacroStatusToSet } from "@redotech/redo-model/macro";
import ThreeDotsIcon from "@redotech/redo-web/arbiter-icon/dots-horizontal_filled.svg";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-03.svg";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import CircleSpinner from "@redotech/redo-web/circle-spinner.svg";
import { RedoClientContext } from "@redotech/redo-web/client";
import { Dropdown, DropdownOption } from "@redotech/redo-web/dropdown";
import AiIcon from "@redotech/redo-web/icon-old/ai.svg";
import ChatBubbleIcon from "@redotech/redo-web/icon-old/chat-bubble.svg";
import ChevronDownIcon from "@redotech/redo-web/icon-old/chevron-down.svg";
import LightningIcon from "@redotech/redo-web/icon-old/lightning.svg";
import NoteIcon from "@redotech/redo-web/icon-old/note.svg";
import PercentIcon from "@redotech/redo-web/icon-old/percent.svg";
import ShoppingBagIcon from "@redotech/redo-web/icon-old/shopping-bag.svg";
import StarsIcon from "@redotech/redo-web/icon-old/stars-01.svg";
import { QuillEditor } from "@redotech/redo-web/quill/quill-editor";
import * as quillEditorCss from "@redotech/redo-web/quill/quill-editor.module.css";
import Quill from "quill";
import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { UserContext } from "../app/user";
import {
  deleteMessageDraft,
  getConversation,
  sendMessageDraft,
  upsertMessageDraft,
} from "../client/conversations";
import { getMacros, replaceMacroVariables } from "../client/macro";
import { REDO_API_URL } from "../config";
import { MacroModal } from "./macros/macro-modal";
import {
  clearColoringAtCursor,
  clearFormattingFromMacroAutomationsText,
  formatWithInserts,
} from "./macros/quill-macro-utils";
import * as messageInputCss from "./message-input.module.css";
import {
  canSendMetaMessage,
  closeTicket,
  doFileDrop,
  getLastCustomerDirectMessage,
  listen,
} from "./utils";
const Delta = Quill.import("delta");
// Styles for quill editor
import * as amplitude from "@amplitude/analytics-browser";
import { JsonObject } from "@redotech/json/json";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { conversationFileUploadErrorMessages } from "@redotech/redo-model/support/conversations/conversation-file-upload-error";
import { teamCanUseAi } from "@redotech/redo-model/team";
import { Permission, permitted } from "@redotech/redo-model/user";
import { toast } from "@redotech/redo-web/alert";
import { Flex } from "@redotech/redo-web/flex";
import CheckIcon from "@redotech/redo-web/icon-old/check.svg";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { QuillToolbarOptions } from "@redotech/redo-web/quill/quill-toolbar-options";
import { Switch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { unique } from "@redotech/util/array";
import { assertNever } from "@redotech/util/type";
import * as classNames from "classnames";
import * as capitalize from "lodash/capitalize";
import { Delta as DeltaType, EmitterSource } from "quill/core";
import "quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { stringSimilarity } from "string-similarity-js";
import { TeamContext } from "../app/team";
import { RedoMerchantClientContext } from "../client/context";
import { getSupportAiResponse } from "../client/support-ai";
import { ActiveConversationContext } from "./context/active-conversation-context";
import { ShopifyProduct } from "./create-order";
import {
  MacroAutomations,
  performMacroAutomations,
} from "./macros/perform-macro-automations";

import {
  EmailDraftState,
  InDraftingEmailState,
} from "./conversation-email-view/email-draft-state";

import { ClickAwayListener } from "@mui/base";
import {
  EmailReplyType,
  hasAtLeastOneRecipientEmail,
  stringifyEmailRecipientsInfo,
} from "@redotech/redo-model/support/conversations/email-info";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoButtonDropdown } from "@redotech/redo-web/arbiter-components/buttons/redo-dropdown-button";
import ChevronDownSvg from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import CornerUpLeftSvg from "@redotech/redo-web/arbiter-icon/corner-up-left_filled.svg";
import Send01Svg from "@redotech/redo-web/arbiter-icon/send-01.svg";
import StickerSquareSvg from "@redotech/redo-web/arbiter-icon/sticker-square.svg";
import { Divider } from "@redotech/redo-web/divider";
import { idEqual } from "@redotech/util/equal";
import { AxiosError } from "axios";
import { QuillAttachmentCarousel } from "../../../web/src/quill/quill-attachment-carousel";
import { QuillToolbarUploadFile } from "../../../web/src/quill/quill-toolbar-upload-file";
import { AlertContext } from "../app/alert";
import { EditRecipientsModal } from "./action-panel/edit-recipients-modal";
import {
  MacroAutomationsList,
  macroAutomationsNonEmpty,
} from "./macros/macro-automations-list";
import {
  filesToAttachments,
  getInternalDraftFromConversation,
  getPublicDraftReplyingToMessage,
  getSavedRecipientsInfo,
  numRecipients,
  sameAttachments,
  sameRecipients,
} from "./message-input-utils";

import { useDebounce } from "usehooks-ts";
import {
  ACTIVE_CONVERSATION_QUERY_PARAMETER,
  getQueryParameter,
} from "./query-parameters";
import {
  AutocompleteType,
  SupportMessageAutocomplete,
} from "./support-message-autocomplete";

const getTypingStream = (
  client: RedoClient,
  { conversationId, signal }: { conversationId: string; signal: AbortSignal },
) => {
  const url = `${REDO_API_URL}/conversations/${conversationId}/typing`;
  return fetch(url, { signal, headers: client.authorization() });
};

type OtherHotkeyType = "sendReply" | "sendReplyAndClose" | "addInternalNote";

export const MessageInput = memo(function MessageInput({
  conversation,
  setActiveConversation,
  cardListRef,
  setErrorMessage,
  setShowErrorMessage,
  setTyping,
  rightPanelOpen = true,
  showFullCommentThread,
  setShowFullCommentThread,
  emailDraftProps = undefined,
  messageIdForKey = undefined,
  shouldBlockDraftCreationWhileSendingEmail,
  setShouldBlockDraftCreationWhileSendingEmail,
}: {
  conversation: ExpandedConversation;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  cardListRef?: any;
  setErrorMessage: (message: string) => void;
  setShowErrorMessage: (show: boolean) => void;
  setTyping: Dispatch<SetStateAction<Record<string, Date>>>;
  rightPanelOpen?: boolean;
  showFullCommentThread: boolean;
  setShowFullCommentThread: Dispatch<SetStateAction<boolean>>;

  emailDraftProps?:
    | {
        draftInfo: InDraftingEmailState;
        handleSetReplyDraft: Dispatch<SetStateAction<EmailDraftState>>;
        setTriggerReinitalizeDraft: Dispatch<SetStateAction<boolean>>;
        shouldPopupRecipientsModalBecauseForwardButtonClicked: boolean;
        setShouldPopupRecipientsModalBecauseForwardButtonClicked: Dispatch<
          SetStateAction<boolean>
        >;
      }
    | undefined;
  messageIdForKey?: string | undefined;
  shouldBlockDraftCreationWhileSendingEmail?: boolean;
  setShouldBlockDraftCreationWhileSendingEmail: Dispatch<
    SetStateAction<boolean>
  >;
}) {
  const navigate = useNavigate();
  const team = useRequiredContext(TeamContext);
  const { addNewAlert } = useContext(AlertContext);
  const {
    div: conversationDetailRef,
    ticketActions,
    conversationClosing,
    setConversationClosing,
  } = useContext(ActiveConversationContext);

  const [visibility, setVisibility] = useState<MessageVisibility>(
    MessageVisibility.PUBLIC,
  );
  const [replyPending, setReplyPending] = useState(false);
  const [replyAndClosePending, setReplyAndClosePending] = useState(false);
  const [sendTypingCooldown, setSendTypingCooldown] = useState(false);
  const apiClient = useRequiredContext(RedoClientContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const user = useContext(UserContext);
  const [typingRetryCount, setTypingRetryCount] = useState(0);
  const [macroModalOpen, setMacroModalOpen] = useState(false);
  const [draftAttachments, setDraftAttachments] = useState<Attachment[]>([]);
  const [discountCodesInMessage, setDiscountCodesInMessage] = useState<
    string[]
  >([]);
  const [productsInMessage, setProductsInMessage] = useState<ShopifyProduct[]>(
    [],
  );

  const [macrosLoad, doMacrosLoad] = useTriggerLoad(async (signal) => {
    const macros = await getMacros(client, { signal });
    return macros.data;
  });

  const [submitDisabledBecauseOfQuill, setSubmitDisabledBecauseOfQuill] =
    useState<boolean>(false);

  const submitDisabled = useMemo(() => {
    const disabledBecauseOfSMSOptOut =
      conversation.platform === ConversationPlatform.SMS &&
      !!conversation.customer?.supportCommunicationConsent?.textMessages
        .optOutDate;

    return submitDisabledBecauseOfQuill || disabledBecauseOfSMSOptOut;
  }, [submitDisabledBecauseOfQuill, conversation]);

  const setEmojiPickerOpenRef = useRef<Dispatch<SetStateAction<boolean>>>();
  const [quill, setQuill] = useState<Quill | null>(null);

  const cursorIndexRef = useRef<number | undefined>(undefined);
  const [aiSuggestionPending, setAiSuggestionPending] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const canReply =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);
  const canUseMacro =
    !!user && permitted(user.permissions, Permission.USE_MACRO);

  const signatureMarker = "\u200B";
  const [htmlToPasteSignature, setHtmlToPasteSignature] = useState<
    string | undefined
  >(undefined);
  const [macroAutomations, setMacroAutomations] = useState<MacroAutomations>({
    statusToSet: undefined,
    snoozeDuration: undefined,
    tagsToAdd: undefined,
    emailSubjectToChange: undefined,
    shouldAddNote: undefined,
    noteToAddContent: undefined,
    noteToAddHtmlContent: undefined,
    noteToAddUsersMentioned: undefined,
  });

  const [pickingReplyType, setPickingReplyType] = useState(false);

  const canSendAsMetaMessage = canSendMetaMessage(conversation);

  const metaSendType = useMemo(() => {
    if (
      [ConversationPlatform.FACEBOOK, ConversationPlatform.INSTAGRAM].includes(
        conversation.platform,
      )
    ) {
      if (canSendAsMetaMessage) {
        return "message";
      } else {
        return "forbidden";
      }
    } else {
      return "none";
    }
  }, [conversation, canSendAsMetaMessage]);

  const sendMessageDisabled = useMemo<boolean>(() => {
    const shouldBlockSendBecauseNoRecipients =
      emailDraftProps?.draftInfo.draft &&
      !hasAtLeastOneRecipientEmail(
        emailDraftProps?.draftInfo.draft.recipientsInfo,
      );

    return !!(
      submitDisabled ||
      replyPending ||
      replyAndClosePending ||
      (visibility === MessageVisibility.PUBLIC &&
        (metaSendType === "forbidden" || conversationClosing)) ||
      shouldBlockSendBecauseNoRecipients
    );
  }, [
    submitDisabled,
    replyPending,
    replyAndClosePending,
    visibility,
    conversationClosing,
  ]);

  const lastMerchantMessage = conversation.messages
    .filter((message) => message.type === "merchant")
    .reverse()[0];
  const privateReplyLimitation =
    metaSendType === "forbidden" &&
    !!lastMerchantMessage?.instagram?.privateRepliedComment;
  const lastCustomerDirectMessage = getLastCustomerDirectMessage(conversation);

  const [visibilitySetByDraftOnce, setVisibilitySetByDraftOnce] =
    useState(false);

  // If we have a draft, we want to show that
  // This hook checks if we have internal or public drafts, and sets the visibility accordingly, giving priority to public drafts to be shown over internal drafts
  useEffect(() => {
    const hasInternalDraft = !!getInternalDraftFromConversation(conversation);
    const hasPublicDraft = !!getPublicDraftReplyingToMessage(
      conversation,
      messageReplyingTo,
    );

    /*
    Only set the visibility when the parent component is conversation-content.tsx (emailDraftProps is undefined when the parent is conversation-content.tsx)
    This is because we don't want to affect all of the email mesage drafts, but still want to affect the internal note draft on an email conversation
    Only do this once per page load
    */
    if (
      !emailDraftProps &&
      hasInternalDraft &&
      !hasPublicDraft &&
      !deleteLoading &&
      !visibilitySetByDraftOnce
    ) {
      setVisibilitySetByDraftOnce(true);
      setVisibility(MessageVisibility.INTERNAL);
    }
  }, [conversation]);

  const messageReplyingTo = useMemo(() => {
    return (
      conversation.messages.find(
        (message) => message._id === messageIdForKey,
      ) || conversation.messages.at(-1)! // Conversations must have at least one message
    );
  }, [conversation.messages, messageIdForKey]);

  useEffect(() => {
    const replyingPermitted =
      !!user && permitted(user.permissions, Permission.CREATE_REPLY);
    if (!replyingPermitted) {
      setVisibility(MessageVisibility.INTERNAL);
    }
  }, [user]);

  useEffect(() => {
    const signatureToUse =
      user?.emailSignature && user?.usePersonalSignature
        ? user.emailSignature
        : team.settings.support?.emailSignature;
    if (
      conversation.platform === ConversationPlatform.EMAIL &&
      signatureToUse &&
      visibility === MessageVisibility.PUBLIC
    ) {
      // We use unicode character \u200B (zero-width space) to mark the start of the signature.
      setHtmlToPasteSignature(`<br><br><p>&#x200B;--</p>${signatureToUse}`);
    } else {
      setHtmlToPasteSignature(undefined);
    }
  }, [team, conversation.platform, visibility]);

  useEffect(() => {
    // If it was just set to true, send that we're typing
    if (user && sendTypingCooldown) {
      setTimeout(() => {
        setSendTypingCooldown(false);
      }, 2000);
      void sendTyping(apiClient, {
        conversationId: conversation._id,
        id: user._id,
        visibility,
      });
    }
  }, [sendTypingCooldown]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        for await (const typingEvent of listen({
          query: async () => {
            return await getTypingStream(apiClient, {
              conversationId: conversation._id,
              signal: abortController.signal,
            });
          },
          loopCondition: !!conversation?._id,
          setErrorMessage,
          setShowErrorMessage,
        })) {
          const typingEventJson = typingEvent as unknown as JsonObject;
          if (typingEventJson?.name) {
            const name: string = typingEventJson.name as string;
            const expire = new Date();
            expire.setSeconds(expire.getSeconds() + 4);
            setTyping((oldTyping) => {
              setTimeout(() => {
                clearTyping();
              }, 4000);
              return {
                ...oldTyping,
                [name]: expire,
              };
            });
          }
        }
      } catch (e) {
        // Wait 5 seconds before trying again
        setTimeout(() => {
          // Continue polling for changes
          setTypingRetryCount(typingRetryCount + 1);
        }, 5000);
        if (abortController.signal.aborted) {
          return;
        }
        throw e;
      }
    })();
    return () => abortController.abort();
  }, [conversation._id, typingRetryCount]);

  useEffect(() => {
    if (!quill) {
      return;
    }

    setShowSignature(false);
    void loadDraftOrAiResponseIntoUI();
    const textChangeCallback = (_: any, __: any, source: string) => {
      // We only want to trigger the text state update if the text change is from the user
      // Otherwise, the text change is from ai generation, and we don't want to save that as the message draft
      if (source === Quill.sources.USER) {
        requestSaveDraft();
      }
    };
    quill.on(Quill.events.TEXT_CHANGE, textChangeCallback);
    return () => {
      quill.off(Quill.events.TEXT_CHANGE, textChangeCallback);
    };
  }, [conversation._id, quill]);

  useEffect(() => {
    // If visibility was specified in the url, set it accordingly.
    const searchParams = new URLSearchParams(location.search);
    const visibilityParam = searchParams.get("visibility");
    if (["public", "internal"].includes(visibilityParam || "")) {
      setVisibility(visibilityParam as MessageVisibility);
      searchParams.delete("visibility");
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }

    doMacrosLoad();
  }, []);

  useEffect(() => {
    if (!quill) {
      return;
    }
    formatWithInserts({
      quill,
      visibility,
      team,
      discountCodesInMessage,
      productsInMessage,
    });
  }, [visibility]);

  useEffect(() => {
    if (
      metaSendType === "forbidden" &&
      visibility === MessageVisibility.PUBLIC
    ) {
      conversationDetailRef?.current?.focus();
    } else {
      quill?.focus();
    }
  }, [quill, metaSendType]);

  useEffect(() => {
    if (!quill) {
      return;
    }

    const quillPosition = quill.getSelection()?.index;
    if (visibility === MessageVisibility.INTERNAL) {
      const signatureIndex = quill.getText().indexOf(signatureMarker);
      if (signatureIndex !== -1 && htmlToPasteSignature) {
        let text = quill.getText();
        text = quill.getText().substring(0, signatureIndex) + "\n";
        // Remove up to two newlines from the end of the text, leaving at least one.
        let i = 2;
        while (text.endsWith("\n\n") && i > 0) {
          text = text.substring(0, text.length - 1);
          i--;
        }
        quill.setText(text, Quill.sources.SILENT);
      }
    }
    if (quillPosition !== undefined) {
      quill.setSelection(quillPosition, 0);
    }
  }, [team, visibility, htmlToPasteSignature]);

  useEffect(() => {
    if (!quill) {
      return;
    }
    const signatureIndex = quill.getText().indexOf(signatureMarker);
    if (showSignature && signatureIndex === -1 && htmlToPasteSignature) {
      quill.clipboard.dangerouslyPasteHTML(
        quill.getLength() - 1,
        htmlToPasteSignature,
      );
    } else if (!showSignature && signatureIndex !== -1) {
      let text = quill.getText();
      text = quill.getText().substring(0, signatureIndex) + "\n";
      // Remove up to two newlines from the end of the text, leaving at least one.
      let i = 2;
      while (text.endsWith("\n\n") && i > 0) {
        text = text.substring(0, text.length - 1);
        i--;
      }
      quill.setText(text, Quill.sources.SILENT);
    }
  }, [showSignature]);

  useEffect(() => {
    if (!quill) {
      return;
    }

    const formatInsertsCallback = (
      delta: DeltaType,
      oldDelta: DeltaType,
      source: EmitterSource,
    ) => {
      if (!delta.ops.some((op) => !!op.insert || !!op.delete)) {
        return;
      }
      formatWithInserts({
        quill,
        visibility,
        team,
        discountCodesInMessage,
        productsInMessage,
      });
    };

    quill.on(Quill.events.TEXT_CHANGE, formatInsertsCallback);

    return () => {
      quill.off(Quill.events.TEXT_CHANGE, formatInsertsCallback);
    };
  }, [quill, visibility, productsInMessage, discountCodesInMessage]);

  /**
   * Autocomplete management that doesn't live in SupportMessageAutocomplete
   *
   * TODO make as much of this as possible live in SupportMessageAutocomplete.
   */
  const [triggerOpenAutocompleteMenu, setTriggerOpenAutocompleteMenu] =
    useState<AutocompleteType | undefined>(undefined);
  const [autocompleteVisible, setAutocompleteVisible] = useState(false);
  const [textToInsert, setTextToInsert] = useState<string | undefined>();
  const [usersMentionedInMessage, setUsersMentionedInMessage] = useState<
    { name: string; id: string }[]
  >([]);

  /**
   * Different from @see {maybePerformCharacterHotkeyAction} because
   * the maybePerformCharacterHotkeyAction actually pastes a character into the editor in additon to performing
   * the side effect. This function only performs the side effect.
   */
  function maybePerformControlHotkeyAction(event: KeyboardEvent) {
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }
    if (event.key === "2") {
      setTriggerOpenAutocompleteMenu(AutocompleteType.MENTION);
      event.preventDefault();
    }
    if (event.key === "5") {
      setTriggerOpenAutocompleteMenu(AutocompleteType.DISCOUNT_CODE);
      event.preventDefault();
    }
    if (event.key === "6") {
      setMacroModalOpen(true);
      event.preventDefault();
    }
    if (event.key === "7") {
      setTriggerOpenAutocompleteMenu(AutocompleteType.PRODUCT);
      event.preventDefault();
    }
  }

  const clearTyping = () => {
    const now = new Date();
    setTyping((oldTyping) => {
      if (oldTyping) {
        return Object.keys(oldTyping).reduce((accumulator, key) => {
          if (oldTyping[key] > now) {
            return {
              ...accumulator,
              [key]: oldTyping[key],
            };
          } else {
            return accumulator;
          }
        }, {});
      } else {
        return {};
      }
    });
  };

  const closeEmailDraft = () => {
    if (emailDraftProps) {
      emailDraftProps.handleSetReplyDraft({ status: "noDraft" });
    }
  };

  const savedDraft = useMemo(() => {
    if (visibility === MessageVisibility.INTERNAL) {
      return getInternalDraftFromConversation(conversation);
    } else {
      return getPublicDraftReplyingToMessage(conversation, messageReplyingTo);
    }
  }, [conversation, messageReplyingTo, visibility]);

  // Toggling the visibility only applies to non email conversations
  // We want to update the draft when we toggle the visibility
  useEffect(() => {
    if (conversation.platform !== ConversationPlatform.EMAIL) {
      void loadDraftOrAiResponseIntoUI();
    }
  }, [visibility]);

  const setActiveConversationIfStillOnSameConversation = (
    nextConversation: ExpandedConversation,
    preventUpdateActiveConversation?: boolean,
  ) => {
    const locationActiveConversationId = getQueryParameter(
      location,
      ACTIVE_CONVERSATION_QUERY_PARAMETER,
    );

    if (
      !preventUpdateActiveConversation &&
      locationActiveConversationId === conversation._id
    ) {
      setActiveConversation(nextConversation);
    }
  };

  // Given an updated redo message, replace the current draft message in the conversation or add the new one
  const addOrReplaceDraftMessage = (
    updatedMessage: ExpandedConversationMessage,
  ) => {
    const newMessages = conversation.messages;
    const currentDraftMessage = conversation.messages.find(
      (m) => m._id === updatedMessage._id,
    );
    if (currentDraftMessage) {
      newMessages.splice(
        newMessages.indexOf(currentDraftMessage),
        1,
        updatedMessage,
      );
    } else {
      newMessages.push(updatedMessage);
    }
    setActiveConversationIfStillOnSameConversation({
      ...conversation,
      messages: newMessages,
    });
  };

  const loadDraftOrAiResponseIntoUI = async () => {
    const attachments: Attachment[] = filesToAttachments(savedDraft);

    setDraftAttachments(attachments);
    setInitialDraftAttachmentsLoaded(true);

    if (conversation.platform === ConversationPlatform.EMAIL) {
      const draftHtmlBody =
        savedDraft?.draftInfo?.emailDraftInfo?.draftHtmlBody || "";
      quill?.clipboard.dangerouslyPasteHTML(0, draftHtmlBody);
    } else {
      quill?.setText(savedDraft?.content || "", Quill.sources.SILENT);
    }

    if (!quill) {
      return;
    }

    // if the quill is empty at this point, there was no draft. Let's try
    // to add in the AI generation.
    // (empty quill has a newline character)
    if (quill.getText().length > 1) {
      return;
    }

    if (conversation.currentAiResponse) {
      quill.setText(conversation.currentAiResponse, Quill.sources.API);
    } else if (
      messageReplyingTo.aiGenerations &&
      messageReplyingTo.aiGenerations.length > 0
    ) {
      quill.setText(
        messageReplyingTo.aiGenerations.at(-1)!.text,
        Quill.sources.API,
      );
    } else {
      quill.setText("", Quill.sources.SILENT);
    }
  };

  // We want to keep track of this so that when we want to perform another operation on the draft, we wait for the previous operation to complete
  // Not a bulletproof strategy if multiple people are editing the draft at once, but it's a good solution for now
  const saveDraftPromise = useRef<
    Promise<ExpandedConversationMessage | null> | undefined
  >(undefined);

  // This is slightly different than the normal saveDraft function
  // Before sending, we want to replace variables with formatted HTML and add the signature
  const handleSaveDraftRightBeforeSending =
    async (): Promise<ExpandedConversationMessage | null> => {
      // These checks are a bit redundant given the checks in handleMessageSend, but it's better to be safe
      // Note that we leave out the check for shouldBlockDraftCreationWhileSendingEmail because that is set in handleMessageSend
      if (
        !quill ||
        (visibility === MessageVisibility.PUBLIC &&
          metaSendType === "forbidden") ||
        replyPending ||
        replyAndClosePending ||
        conversationClosing
      ) {
        return null;
      }

      let text = quill.getText();
      let html = quill.getSemanticHTML();

      const signatureIndex = quill.getText().indexOf(signatureMarker);
      // Only include the signature when saving the draft immediately before sending
      if (htmlToPasteSignature && signatureIndex === -1 && !showSignature) {
        html += htmlToPasteSignature;
      }

      if (discountCodesInMessage.length > 0) {
        discountCodesInMessage.forEach((discountCode) => {
          // Remove % sign before each discount code
          text = text.replaceAll(`%${discountCode}`, discountCode);
          html = html.replaceAll(`%${discountCode}`, discountCode);
        });
      }

      if (productsInMessage.length > 0) {
        productsInMessage.forEach((product) => {
          // Remove & sign before each product
          const pdpUrl = `https://${team?.storeUrl}/products/${product.handle}`;
          text = text.replaceAll(
            `&${product.title}`,
            `${product.title} - ${pdpUrl}`,
          );
          html = html.replaceAll(
            `&amp;${product.title}`,
            `<a href="${pdpUrl}" style="color: #FAA7E0; background-color: #851651; padding: 4px !important; border-radius: 4px;">${product.title}</a>`,
          );
        });
      }

      try {
        const draftMessage = await upsertMessageDraft(client, {
          conversationId: conversation._id,
          message: text,
          usersMentioned: usersMentionedInMessage.map((u) => u.id),
          htmlBody: html,
          visibility,
          attachments: draftAttachments,
          emailEnvelopeInfo: emailDraftProps?.draftInfo.draft.recipientsInfo,
          replyType: emailDraftProps?.draftInfo?.mode || EmailReplyType.REPLY,
        });

        // Optimistically update the draft message in the UI
        addOrReplaceDraftMessage(draftMessage);

        return draftMessage;
      } catch (e) {
        console.error(e);
        setErrorMessage("Failed to save message draft");
        setShowErrorMessage(true);
        return null;
      }
    };

  const [saveDraftLoading, setSaveDraftLoading] = useState(false);

  const handleSaveDraftDuringNormalEditing =
    async (): Promise<ExpandedConversationMessage | null> => {
      if (
        !quill ||
        (visibility === MessageVisibility.PUBLIC &&
          metaSendType === "forbidden") ||
        replyPending ||
        replyAndClosePending ||
        conversationClosing ||
        shouldBlockDraftCreationWhileSendingEmail ||
        deleteLoading
      ) {
        return null;
      }

      setSaveDraftLoading(true);

      const text = quill.getText();
      const html = quill.getSemanticHTML();

      try {
        const draftMessage = await upsertMessageDraft(client, {
          conversationId: conversation._id,
          message: text,
          usersMentioned: usersMentionedInMessage.map((u) => u.id),
          htmlBody: html,
          visibility,
          attachments: draftAttachments,
          emailEnvelopeInfo: emailDraftProps?.draftInfo.draft.recipientsInfo,
          replyType: emailDraftProps?.draftInfo?.mode || EmailReplyType.REPLY,
        });

        // Optimistically update the draft message in the UI
        addOrReplaceDraftMessage(draftMessage);

        setSaveDraftLoading(false);
        return draftMessage;
      } catch (e) {
        console.error(e);
        setErrorMessage("Failed to save message draft");
        setShowErrorMessage(true);
        setSaveDraftLoading(false);
        return null;
      }
    };

  const handleMessageSend: (
    event: React.ChangeEvent<{ value?: unknown }>,
    alertTitle?: string,
    preventUpdateActiveConversation?: boolean,
  ) => Promise<boolean> = useHandler(
    async (
      e,
      alertMessage = "Message sent",
      preventUpdateActiveConversation = false,
    ) => {
      if (
        !quill ||
        (visibility === MessageVisibility.PUBLIC &&
          metaSendType === "forbidden") ||
        replyPending ||
        replyAndClosePending ||
        conversationClosing ||
        shouldBlockDraftCreationWhileSendingEmail
      ) {
        return false;
      }

      // Define what we should do no matter what when this function finishes
      // Some paths will do more (like close the draft, update the active conversation, etc.)
      // But all paths should do this
      const cleanupSaveDraft = () => {
        setReplyPending(false);
        setShouldBlockDraftCreationWhileSendingEmail(false);
        setShowSignature(false);
        quill.enable();
      };

      // Set the state needed for sending a message
      // Don't allow users to create new drafts while this is in progress
      setShouldBlockDraftCreationWhileSendingEmail(true);
      setPickingEmailSendType(false);
      setReplyPending(true);
      quill.disable();

      const originalHtml = quill.getSemanticHTML();
      const text = quill.getText();

      e.preventDefault();
      try {
        // If we are currently saving the draft, wait for it to complete before proceeding
        await saveDraftPromise.current;

        // Keep a reference to the old draft message in case they cancel the send
        const oldDraftMessage = savedDraft;

        // Save the draft one last time - unlike normal draft saves,replace variables and add the signature
        const updatedDraftMessage = await handleSaveDraftRightBeforeSending();

        closeEmailDraft();

        if (!updatedDraftMessage) {
          cleanupSaveDraft();
          return false;
        }

        let addInternalNoteMacroAutomationDraft:
          | ExpandedConversationMessage
          | undefined;
        if (macroAutomations.shouldAddNote) {
          addInternalNoteMacroAutomationDraft = await upsertMessageDraft(
            client,
            {
              conversationId: conversation._id,
              message: macroAutomations.noteToAddContent || "",
              usersMentioned: macroAutomations.noteToAddUsersMentioned || [],
              htmlBody: macroAutomations.noteToAddHtmlContent || "",
              visibility: MessageVisibility.INTERNAL,
              attachments: [],
            },
          );
        }

        // Optimistically update the message so that it's no longer a draft
        if (updatedDraftMessage.draftInfo) {
          updatedDraftMessage.draftInfo.isDraft = false;
          addOrReplaceDraftMessage(updatedDraftMessage);
        }

        // Optimistically update the macro automation draft so that it's no longer a draft
        if (
          addInternalNoteMacroAutomationDraft &&
          addInternalNoteMacroAutomationDraft.draftInfo
        ) {
          addInternalNoteMacroAutomationDraft.draftInfo.isDraft = false;
          addOrReplaceDraftMessage(addInternalNoteMacroAutomationDraft);
        }

        const handleSendCallback = async () => {
          try {
            const conversationAfterMacros = await performMacroAutomations(
              client,
              macroAutomations,
              conversation,
              team,
              addInternalNoteMacroAutomationDraft?._id,
            );
            if (conversationAfterMacros) {
              setMacroAutomations({
                statusToSet: undefined,
                snoozeDuration: undefined,
                tagsToAdd: undefined,
                emailSubjectToChange: undefined,
                shouldAddNote: undefined,
                noteToAddContent: undefined,
                noteToAddHtmlContent: undefined,
                noteToAddUsersMentioned: undefined,
              });

              setActiveConversationIfStillOnSameConversation(
                conversationAfterMacros,
                preventUpdateActiveConversation,
              );
            }

            const abortController = new AbortController();

            const conversationAfterSending = await sendMessageDraft(client, {
              conversationId: conversation._id,
              redoDraftMessageId: updatedDraftMessage._id,
              signal: abortController.signal,
            });

            if (evaluateResponseSimilarityToAiResponse(text)) {
              amplitude.logEvent("sent-ai-response", {
                conversationId: conversation._id,
                channel: conversation.platform,
                visibility,
              });
            }

            amplitude.logEvent("create-conversationMessage", {
              conversationId: conversation._id,
              channel: conversation.platform,
              visibility,
            });

            // Reset the message input
            setDraftAttachments([]);
            quill.setText("", Quill.sources.SILENT);

            setActiveConversationIfStillOnSameConversation(
              conversationAfterSending,
              preventUpdateActiveConversation,
            );
          } catch (e) {
            console.error(e);
          } finally {
            cleanupSaveDraft();
          }
        };

        const shouldDelay =
          visibility === MessageVisibility.PUBLIC &&
          conversation.platform === ConversationPlatform.EMAIL;

        if (shouldDelay) {
          addNewAlert({
            title: alertMessage,
            type: "info",
            primaryButtonText: "Undo",
            primaryButtonAction: async () => {
              try {
                // Revert the draft message to the old one
                if (oldDraftMessage) {
                  await upsertMessageDraft(client, {
                    conversationId: conversation._id,
                    message: oldDraftMessage.content,
                    usersMentioned: usersMentionedInMessage.map((u) => u.id),
                    htmlBody:
                      oldDraftMessage.draftInfo?.emailDraftInfo
                        ?.draftHtmlBody || "",
                    visibility: oldDraftMessage.visibility,
                    attachments: draftAttachments,
                    emailEnvelopeInfo:
                      oldDraftMessage.draftInfo?.emailDraftInfo,
                    replyType:
                      oldDraftMessage.draftInfo?.emailDraftInfo?.replyType,
                  });
                }

                if (addInternalNoteMacroAutomationDraft) {
                  await deleteMessageDraft(client, {
                    conversationId: conversation._id,
                    messageId: addInternalNoteMacroAutomationDraft._id,
                  });
                }

                const newConversation = await getConversation(client, {
                  conversationId: conversation._id,
                });

                emailDraftProps?.setTriggerReinitalizeDraft(true);

                setActiveConversationIfStillOnSameConversation(
                  newConversation,
                  preventUpdateActiveConversation,
                );
              } catch (e) {
                console.error(e);
              } finally {
                cleanupSaveDraft();
              }
            },
            primaryButtonCancelsAction: true,
            canceledDescription: "Sending undone",
            secondsToLive: 5,
            actionCallback: handleSendCallback,
          });
        } else {
          await handleSendCallback();
        }
      } catch (e) {
        console.error(e);

        if (
          e instanceof AxiosError &&
          e.response?.status === 403 &&
          conversation.platform === ConversationPlatform.POSTSCRIPT
        ) {
          setErrorMessage(
            "Failed to send message. Customer is no longer a Postscript subscriber",
          );
        } else {
          setErrorMessage("Failed to send message");
        }
        setShowErrorMessage(true);
        setReplyPending(false);

        quill.clipboard.dangerouslyPasteHTML(originalHtml);
        quill.enable();
        return false;
      }
      return true;
    },
  );

  const handleSendAndClose: (
    event: React.ChangeEvent<{ value?: unknown }>,
  ) => void = useHandler(async (e) => {
    setConversationClosing(true);
    setReplyAndClosePending(true);
    if (await handleMessageSend(e, "Message sent and ticket closed", true)) {
      // Close the conversation directly
      closeTicket(
        client,
        conversation,
        setActiveConversation,
        team,
        true,
        cardListRef?.current.getNext(conversation, idEqual) ||
          cardListRef?.current.getPrevious(conversation, idEqual),
      );
      amplitude.logEvent("reply-and-close-conversation", {
        mode: "single",
        conversationIds: [conversation._id],
        channels: [conversation.platform],
      });

      closeEmailDraft();
    }
    setReplyAndClosePending(false);
    setConversationClosing(false);
  });

  function addAttachments(attachments: Attachment[]) {
    setDraftAttachments((oldDraftAttachments) => {
      const uniqueAttachments = unique(
        [...attachments, ...oldDraftAttachments],
        (item) => item.url,
      );
      return uniqueAttachments;
    });
  }

  const handleUpload = async ({
    event,
    file,
  }: {
    event?: ChangeEvent<HTMLInputElement>;
    file?: File;
  }) => {
    const fileToUpload = file || event?.target?.files?.[0];
    if (!fileToUpload) {
      return;
    }

    const form = new FormData();
    form.append("file", fileToUpload);
    form.append("fileName", fileToUpload.name);
    const response = await uploadFile(apiClient, form);
    if (response.success) {
      amplitude.logEvent("create-attachment", {
        channel: conversation.platform,
        file: fileToUpload.name,
      });
      const newAttachment = { ...response.body };
      addAttachments([newAttachment]);
    } else {
      toast(conversationFileUploadErrorMessages[response.error], {
        variant: "error",
      });
    }
  };

  const removeFileFromDrafts = (url: string) => {
    setDraftAttachments((oldDraftAttachments) => {
      return oldDraftAttachments.filter((attachment) => attachment.url !== url);
    });
  };

  const clearInput = (e: any) => {
    // This makes the onChange always get triggered.
    e.target.value = "";
  };

  const [macroToInsert, setMacroToInsert] = useState<Macro | undefined>();

  useEffect(() => {
    if (macroToInsert) {
      const doSetMacro = async () => {
        await setMacro(macroToInsert);
        setMacroToInsert(undefined);
      };
      doSetMacro().catch((e) => {
        setErrorMessage("Failed to insert template.");
        setShowErrorMessage(true);
      });
    }
  }, [macroToInsert]);

  const setMacro = async (macro: Macro) => {
    let htmlContentWithVariablesReplaced;
    const contentWithVariablesReplaced = await replaceMacroVariables(client, {
      message: macro.content,
      email: conversation.customer.email,
      firstName: conversation.customer.firstName,
      lastName: conversation.customer.lastName,
      fullName: conversation.customer.name,
      agentFirstName: user?.firstName || "",
      agentLastName: user?.lastName || "",
      agentFullName: user?.name,
    });
    if (macro.htmlContent) {
      htmlContentWithVariablesReplaced = await replaceMacroVariables(client, {
        message: macro.htmlContent,
        email: conversation.customer.email,
        firstName: conversation.customer.firstName,
        lastName: conversation.customer.lastName,
        fullName: conversation.customer.name,
        agentFirstName: user?.firstName || "",
        agentLastName: user?.lastName || "",
        agentFullName: user?.name,
      });
    }
    amplitude.logEvent("use-macro", { name: macro.name });

    if (macro.attachments) {
      addAttachments(macro.attachments);
    }

    if (!contentWithVariablesReplaced.valuesExistForAllVariables) {
      setErrorMessage(
        "Some variables in the template didn't have a value. Defaults were used.",
      );
      setShowErrorMessage(true);
    }
    if (quill) {
      const indexToInsert = cursorIndexRef.current ?? 0;
      if (
        conversation.platform === ConversationPlatform.EMAIL &&
        htmlContentWithVariablesReplaced
      ) {
        quill.clipboard.dangerouslyPasteHTML(
          indexToInsert,
          htmlContentWithVariablesReplaced.message,
        );
      } else {
        quill.insertText(indexToInsert, contentWithVariablesReplaced.message);
      }

      /* Macro variables have a unique purple-on-purple color scheme. This resets the color and background color of the macro variables to the default. */
      clearFormattingFromMacroAutomationsText(quill);
    }

    let subbedNoteToAddContent: string | undefined = undefined;
    let subbedNoteToAddHtmlContent: string | undefined = undefined;
    if (macro.shouldAddNote) {
      if (macro.noteToAddContent) {
        /** We want to perform macro substitutions on the note content */
        const { message, valuesExistForAllVariables } =
          await replaceMacroVariables(client, {
            message: macro.noteToAddContent ?? "",
            email: conversation.customer.email,
            firstName: conversation.customer.firstName,
            lastName: conversation.customer.lastName,
            fullName: conversation.customer.name,
            agentFirstName: user?.firstName || "",
            agentLastName: user?.lastName || "",
          });
        if (!valuesExistForAllVariables) {
          setErrorMessage(
            "Some variables in the note didn't have a value. Defaults were used.",
          );
          setShowErrorMessage(true);
        }
        subbedNoteToAddContent = message;
      }
      if (macro.noteToAddHtmlContent) {
        const { message, valuesExistForAllVariables } =
          await replaceMacroVariables(client, {
            message: macro.noteToAddHtmlContent ?? "",
            email: conversation.customer.email,
            firstName: conversation.customer.firstName,
            lastName: conversation.customer.lastName,
            fullName: conversation.customer.name,
            agentFirstName: user?.firstName || "",
            agentLastName: user?.lastName || "",
          });
        if (!valuesExistForAllVariables) {
          setErrorMessage(
            "Some variables in the note didn't have a value. Defaults were used.",
          );
          setShowErrorMessage(true);
        }
        subbedNoteToAddHtmlContent = message;

        subbedNoteToAddHtmlContent = applyFormattingToNote(
          subbedNoteToAddHtmlContent,
        );
      }
    }

    setMacroAutomations((oldMacroAutomations) => {
      let statusToSet = oldMacroAutomations.statusToSet;
      let snoozeDuration = oldMacroAutomations.snoozeDuration;
      let tagsToAdd = oldMacroAutomations.tagsToAdd;
      let emailSubjectToChange = oldMacroAutomations.emailSubjectToChange;
      let shouldAddNote = oldMacroAutomations.shouldAddNote;
      let noteToAddContent = oldMacroAutomations.noteToAddContent;
      let noteToAddHtmlContent = oldMacroAutomations.noteToAddHtmlContent;
      let noteToAddUsersMentioned = oldMacroAutomations.noteToAddUsersMentioned;

      if (macro.statusToSet === "snoozed" && !!macro.snoozeDuration) {
        statusToSet = MacroStatusToSet.SNOOZED;
        snoozeDuration = macro.snoozeDuration;
      } else {
        statusToSet = macro.statusToSet;
        snoozeDuration = undefined;
      }

      if (tagsToAdd?.length) {
        macro.tagsToAdd?.forEach((tag) => {
          if (!tagsToAdd!.includes(tag)) {
            tagsToAdd!.push(tag);
          }
        });
      } else if (macro.tagsToAdd?.length) {
        tagsToAdd = macro.tagsToAdd;
      }

      if (macro.emailSubjectToSet !== emailSubjectToChange) {
        emailSubjectToChange = macro.emailSubjectToSet;
      }

      if (macro.shouldAddNote !== shouldAddNote) {
        shouldAddNote = macro.shouldAddNote;
      }

      if (subbedNoteToAddContent !== noteToAddContent) {
        noteToAddContent = subbedNoteToAddContent;
      }
      if (subbedNoteToAddHtmlContent !== noteToAddHtmlContent) {
        noteToAddHtmlContent = subbedNoteToAddHtmlContent;
      }

      /** Use same logic as tagsToAdd but for users mentioned */
      if (macro.noteToAddUsersMentioned?.length) {
        if (!noteToAddUsersMentioned) {
          noteToAddUsersMentioned = [];
        }
        macro.noteToAddUsersMentioned?.forEach((user) => {
          if (!noteToAddUsersMentioned!.includes(user)) {
            noteToAddUsersMentioned!.push(user);
          }
        });
      }

      return {
        statusToSet,
        snoozeDuration,
        tagsToAdd,
        emailSubjectToChange,
        shouldAddNote,
        noteToAddContent,
        noteToAddHtmlContent,
        noteToAddUsersMentioned,
      };
    });
  };

  const applyFormattingToNote = (noteToAddHtmlContent?: string) => {
    if (!noteToAddHtmlContent) {
      return;
    }
    const tempQuill = new Quill(document.createElement("div"));
    tempQuill.clipboard.dangerouslyPasteHTML(0, noteToAddHtmlContent);
    tempQuill.removeFormat(0, noteToAddHtmlContent.length);
    formatWithInserts({
      quill: tempQuill,
      visibility: MessageVisibility.INTERNAL,
      team,
      discountCodesInMessage,
      productsInMessage,
    });
    // set blank formatting at cursor
    clearColoringAtCursor(tempQuill, tempQuill.getText().length);

    return tempQuill.getSemanticHTML();
  };

  const handleTextChange = () => {
    // If a mention was removed don't keep it in the list of users mentioned
    const text = quill?.getText();
    for (const user of usersMentionedInMessage) {
      if (!text?.includes(`@${user.name}`)) {
        setUsersMentionedInMessage(
          usersMentionedInMessage.filter((u) => u.id !== user.id),
        );
      }
    }
  };

  const [customerRequestedAiResponse, setCustomerRequestedAiResponse] =
    useState<string>("");

  const handleAiSuggestion = async () => {
    if (quill) {
      setAiSuggestionPending(true);
      const aiSuggestion = await getSupportAiResponse(client, {
        type: "conversationReplySuggestion",
        conversationId: conversation._id,
        predMessageId: messageIdForKey,
      });
      if (aiSuggestion?.text) {
        const indexToInsert = cursorIndexRef.current ?? 0;
        quill.insertText(indexToInsert, aiSuggestion.text);
        setCustomerRequestedAiResponse(aiSuggestion.text);
        const message = conversation.messages.find(
          (message) => message._id === messageIdForKey,
        );
        if (message) {
          message.aiGenerations = [
            ...(message.aiGenerations || []),
            { text: aiSuggestion.text },
          ];
        }
        amplitude.logEvent("generate-copilotSuggestion", {
          conversationId: conversation._id,
          cost: aiSuggestion.cost,
        });
      }
      setAiSuggestionPending(false);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (conversationClosing) return;
    if ((e.metaKey || e.ctrlKey) && e.key === "Enter" && !sendMessageDisabled) {
      if (e.altKey) {
        // Ctrl+Alt+Enter to reply and snooze
        ticketActions?.doSnoozeTicketAction();
        void handleMessageSend(e);
        e.preventDefault();
      } else if (e.shiftKey) {
        // Ctrl+Shift+Enter to reply and close
        void handleSendAndClose(e);
        e.preventDefault();
      } else {
        // Ctrl+Enter to reply
        e.preventDefault();
        void handleMessageSend(e);
      }
    } else if (!sendTypingCooldown) {
      setSendTypingCooldown(true);
    }
  };

  const isMac = /mac/i.test(navigator.userAgent);

  const evaluateResponseSimilarityToAiResponse = (currentText: string) => {
    // Checks if the current response is similar to our ai generated response, if it is return true indicating they are using an ai generated response, otherwise false
    if (!quill) {
      return false;
    }
    if (
      customerRequestedAiResponse ||
      conversation.currentAiResponse ||
      messageReplyingTo.aiGenerations
    ) {
      const scores = [];
      if (conversation.currentAiResponse) {
        scores.push(
          stringSimilarity(currentText, conversation.currentAiResponse),
        );
      }
      if (customerRequestedAiResponse) {
        scores.push(stringSimilarity(currentText, customerRequestedAiResponse));
      }
      if (messageReplyingTo.aiGenerations) {
        scores.push(
          ...messageReplyingTo.aiGenerations.map((generation) =>
            stringSimilarity(currentText, generation.text),
          ),
        );
      }
      if (scores.some((score) => score > 0.8)) {
        return true;
      }
    }
    return false;
  };

  const [pickReplyButtonRef, setPickReplyButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const handlePickReplyType = (mode: EmailReplyType) => {
    emailDraftProps?.handleSetReplyDraft(
      (di) => ({ ...di, mode }) as EmailDraftState,
    );
    if (mode === "Forward") {
      setEditingCCBCC(true);
    }
    setPickingReplyType(false);
  };

  /** If we open a fresh editor for email replies and it's in forward mode, start with picker for forwarding */
  const [editingCCBCC, setEditingCCBCC] = useState(
    emailDraftProps?.draftInfo?.mode === "Forward" &&
      emailDraftProps?.shouldPopupRecipientsModalBecauseForwardButtonClicked,
  );

  const [emailSendButtonRef, setEmailSendButtonRef] =
    useState<HTMLButtonElement | null>(null);
  const [pickingEmailSendType, setPickingEmailSendType] = useState(false);
  const [emailSendType, setEmailSendType] = useState<"Send" | "Send and Close">(
    "Send",
  );

  const handleSetEmailSendType = (
    state: SetStateAction<"Send" | "Send and Close">,
  ) => {
    setPickingEmailSendType((t) => false);
    setEmailSendType(state);
  };

  const [deleteLoading, setDeleteLoading] = useState(false);

  const requestSaveDraft = () => {
    setSaveDraftDebounce(Symbol());
  };

  // A lot of things can request a draft to save, so we debounce it and only save if it's been more than 500ms since the last request
  const [saveDraftDebounce, setSaveDraftDebounce] = useState(Symbol());
  const debouncedSaveDraft = useDebounce(saveDraftDebounce, 500);

  // Make sure to wait for the previous save to complete before starting a new save
  useEffect(() => {
    if (saveDraftPromise.current) {
      saveDraftPromise.current
        .then(() => {
          saveDraftPromise.current = handleSaveDraftDuringNormalEditing();
        })
        .catch((e) => {
          console.error("Error saving draft", e);
        });
    } else {
      saveDraftPromise.current = handleSaveDraftDuringNormalEditing();
    }
  }, [debouncedSaveDraft]);

  // We don't want to save the draft the first time we load the attachments from the backend
  const [initialDraftAttachmentsLoaded, setInitialDraftAttachmentsLoaded] =
    useState(false);
  useEffect(() => {
    const savedUrls = savedDraft?.files?.map((file) => file.url) || [];
    const draftUrls = draftAttachments?.map((file) => file.url) || [];
    if (
      initialDraftAttachmentsLoaded &&
      !sameAttachments(savedUrls, draftUrls)
    ) {
      requestSaveDraft();
    }
  }, [draftAttachments]);

  useEffect(() => {
    // Don't save the draft just because the reply type changed
    // We only create the draft when the attachments or text changes
    // So make sure we have a draft saved already
    if (!savedDraft) {
      return;
    }
    const mode = emailDraftProps?.draftInfo?.mode;
    if (!!mode && mode !== savedDraft?.draftInfo?.emailDraftInfo?.replyType) {
      requestSaveDraft();
    }
  }, [emailDraftProps?.draftInfo?.mode]);

  useEffect(() => {
    // Don't save the draft just because the recipients info changed
    // We only create the draft when the attachments or text changes
    if (!savedDraft) {
      return;
    }

    // If the recipient info is not yet defined or there are no recipients, we don't need to update the draft
    const recipientInfo = emailDraftProps?.draftInfo?.draft?.recipientsInfo;
    if (!recipientInfo || numRecipients(recipientInfo) === 0) {
      return;
    }

    const savedRecipientsInfo = getSavedRecipientsInfo(savedDraft);
    const hasChanged = !sameRecipients(recipientInfo, savedRecipientsInfo);

    if (hasChanged) {
      requestSaveDraft();
    }
  }, [
    emailDraftProps?.draftInfo?.draft?.recipientsInfo.bcc,
    emailDraftProps?.draftInfo?.draft?.recipientsInfo.cc,
    emailDraftProps?.draftInfo?.draft?.recipientsInfo.to,
  ]);

  const handleDeleteDraft = async () => {
    // If there isn't actually a draft, then we should just clear the editor and return
    // This is only really relevant if we fill the editor with an AI generated response
    // We don't save drafts for AI generated responses until the user makes an edit - this is a requirement of the feature
    if (!savedDraft && !saveDraftPromise.current) {
      setDraftAttachments([]);
      quill?.setText("", Quill.sources.SILENT);
      closeEmailDraft();
      return;
    }

    quill?.disable();
    setDeleteLoading(true);
    try {
      if (savedDraft?._id) {
        const abortController = new AbortController();
        await saveDraftPromise.current;
        const resultConversation = await deleteMessageDraft(client, {
          conversationId: conversation._id,
          messageId: savedDraft._id,
          signal: abortController.signal,
        });
        if (resultConversation) {
          amplitude.logEvent("create-conversationMessage", {
            conversationId: conversation._id,
            channel: conversation.platform,
            visibility,
          });
          setDraftAttachments([]);
          setActiveConversationIfStillOnSameConversation(resultConversation);
          quill?.setText("", Quill.sources.SILENT);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setDeleteLoading(false);
    quill?.enable();
    closeEmailDraft();
  };

  const toolbarSelector =
    messageIdForKey !== undefined
      ? `redo-quill-toolbar-${messageIdForKey}`
      : "toolbar";

  const sholdShowTemplateMacroButton =
    canUseMacro && metaSendType !== "forbidden";

  const shouldAllowEditingMessage =
    visibility === MessageVisibility.INTERNAL || metaSendType !== "forbidden";

  const shouldUseEmailConversationLayout = !!emailDraftProps;

  const deleteDraftButton = (onClickAddedAction?: () => void) => {
    return (
      <Tooltip arrow title="Discard draft">
        <span>
          <RedoButton
            disabled={deleteLoading || saveDraftLoading}
            hierarchy={RedoButtonHierarchy.TERTIARY}
            IconLeading={deleteLoading ? CircleSpinner : TrashIcon}
            onClick={() => {
              onClickAddedAction?.();
              void handleDeleteDraft();
            }}
            size={RedoButtonSize.REGULAR}
          />
        </span>
      </Tooltip>
    );
  };

  const toolbarElement = (
    <Flex
      align="flex-end"
      className={messageInputCss.wrappingText}
      flex="1 1 0%"
      justify="space-between"
      p="sm"
      pb="xs"
    >
      <Flex
        align="center"
        className={messageInputCss.wrappingtext}
        flex="1 1 0%"
        gap="xs"
        pl="sm"
      >
        {sholdShowTemplateMacroButton && (
          <Tooltip
            arrow
            title={getHotkeyTooltip(AutocompleteType.MACRO, isMac)}
          >
            {/** Wrap with span for tooltip events to work https://v4.mui.com/components/tooltips/#disabled-elements */}
            <span>
              <RedoButton
                disabled={macrosLoad.pending}
                hierarchy={RedoButtonHierarchy.TERTIARY}
                IconLeading={LightningIcon}
                onClick={() => {
                  amplitude.logEvent("view-macroModal", {
                    conversationId: conversation._id,
                    channel: conversation.platform,
                  });
                  setMacroModalOpen(true);
                }}
                size={RedoButtonSize.SMALL}
              />
            </span>
          </Tooltip>
        )}
        {(visibility === MessageVisibility.INTERNAL ||
          metaSendType !== "forbidden") && (
          <div className={quillEditorCss.quillToolbar} id={toolbarSelector}>
            <QuillToolbarOptions
              emailMode={conversation.platform === ConversationPlatform.EMAIL}
            >
              <>
                <QuillToolbarUploadFile
                  clearInput={clearInput}
                  handleUpload={handleUpload}
                />
                <Tooltip
                  arrow
                  title={getHotkeyTooltip(
                    AutocompleteType.DISCOUNT_CODE,
                    isMac,
                  )}
                >
                  <IconButton
                    onClick={() => {
                      setTriggerOpenAutocompleteMenu(
                        AutocompleteType.DISCOUNT_CODE,
                      );
                    }}
                  >
                    <div className={messageInputCss.actionButton}>
                      <PercentIcon className={messageInputCss.actionIcon} />
                    </div>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  arrow
                  title={getHotkeyTooltip(AutocompleteType.PRODUCT, isMac)}
                >
                  <IconButton
                    onClick={() => {
                      setTriggerOpenAutocompleteMenu(AutocompleteType.PRODUCT);
                    }}
                  >
                    <div className={messageInputCss.actionButton}>
                      <ShoppingBagIcon className={messageInputCss.actionIcon} />
                    </div>
                  </IconButton>
                </Tooltip>
              </>
            </QuillToolbarOptions>

            {teamCanUseAi(team) && (
              <div className={quillEditorCss.quillFormatButtons}>
                {aiSuggestionPending ? (
                  <CircleSpinner className={messageInputCss.spinner} />
                ) : (
                  <IconButton onClick={handleAiSuggestion}>
                    <div className={messageInputCss.actionButton}>
                      <AiIcon classname={messageInputCss.actionIcon} />
                    </div>
                  </IconButton>
                )}
              </div>
            )}
          </div>
        )}
      </Flex>
      <Flex align="flex-end">
        <>
          {shouldUseEmailConversationLayout && (
            <Flex>
              {deleteDraftButton()}
              <ClickAwayListener
                onClickAway={() => setPickingEmailSendType(false)}
              >
                <span>
                  <Tooltip
                    arrow
                    title={getHotkeyTooltip(
                      emailSendType === "Send"
                        ? "sendReply"
                        : "sendReplyAndClose",
                      isMac,
                    )}
                  >
                    <span>
                      <RedoButtonDropdown
                        disabled={
                          submitDisabled ||
                          metaSendType === "forbidden" ||
                          replyPending ||
                          replyAndClosePending ||
                          conversationClosing ||
                          shouldBlockDraftCreationWhileSendingEmail ||
                          deleteLoading ||
                          !hasAtLeastOneRecipientEmail(
                            emailDraftProps.draftInfo?.draft?.recipientsInfo,
                          )
                        }
                        dropdownOpen={pickingEmailSendType}
                        IconLeading={
                          replyPending ||
                          replyAndClosePending ||
                          conversationClosing
                            ? CircleSpinner
                            : Send01Svg
                        }
                        onClick={
                          emailSendType === "Send"
                            ? handleMessageSend
                            : handleSendAndClose
                        }
                        ref={setEmailSendButtonRef}
                        setDropdownOpen={() => {
                          setPickingEmailSendType(
                            (pickingEmailSendType) => !pickingEmailSendType,
                          );
                        }}
                        size={RedoButtonSize.REGULAR}
                        text={
                          replyPending || replyAndClosePending
                            ? "Sending..."
                            : conversationClosing
                              ? "Closing..."
                              : emailSendType
                        }
                      >
                        <Dropdown
                          anchor={emailSendButtonRef}
                          fitToAnchor={false}
                          open={pickingEmailSendType}
                        >
                          <DropdownOption
                            action={() => handleSetEmailSendType("Send")}
                          >
                            Send
                          </DropdownOption>
                          <DropdownOption
                            action={() =>
                              handleSetEmailSendType("Send and Close")
                            }
                          >
                            Send and Close
                          </DropdownOption>
                        </Dropdown>
                      </RedoButtonDropdown>
                    </span>
                  </Tooltip>
                </span>
              </ClickAwayListener>
            </Flex>
          )}

          {!shouldUseEmailConversationLayout && (
            <Flex p="md">
              <div
                className={messageInputCss.replyButtons}
                style={{ marginRight: rightPanelOpen ? "0px" : "64px" }}
              >
                {visibility === MessageVisibility.PUBLIC ? (
                  <Flex>
                    {deleteDraftButton()}
                    <Tooltip arrow title={getHotkeyTooltip("sendReply", isMac)}>
                      <span>
                        <Button
                          disabled={
                            submitDisabled ||
                            metaSendType === "forbidden" ||
                            replyPending ||
                            replyAndClosePending ||
                            conversationClosing ||
                            shouldBlockDraftCreationWhileSendingEmail
                          }
                          onClick={handleMessageSend}
                          pending={replyPending && !replyAndClosePending}
                          size={ButtonSize.MICRO}
                          theme={ButtonTheme.PRIMARY}
                        >
                          <Text fontSize="sm" fontWeight="semibold">
                            Reply
                          </Text>
                        </Button>
                      </span>
                    </Tooltip>
                  </Flex>
                ) : (
                  <>
                    {deleteDraftButton(() =>
                      setVisibility(MessageVisibility.PUBLIC),
                    )}
                    <Tooltip
                      arrow
                      title={getHotkeyTooltip("addInternalNote", isMac)}
                    >
                      <Button
                        disabled={
                          submitDisabled ||
                          replyPending ||
                          replyAndClosePending ||
                          shouldBlockDraftCreationWhileSendingEmail
                        }
                        onClick={
                          macroAutomations.statusToSet === "closed"
                            ? handleSendAndClose
                            : handleMessageSend
                        }
                        pending={replyPending && !replyAndClosePending}
                        size={ButtonSize.MICRO}
                        theme={ButtonTheme.WARNING}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          Add note
                        </Text>
                      </Button>
                    </Tooltip>
                  </>
                )}
                {visibility === MessageVisibility.PUBLIC && (
                  <Tooltip
                    arrow
                    title={getHotkeyTooltip("sendReplyAndClose", isMac)}
                  >
                    <span>
                      <Button
                        disabled={
                          submitDisabled ||
                          metaSendType === "forbidden" ||
                          replyPending ||
                          replyAndClosePending ||
                          conversationClosing ||
                          shouldBlockDraftCreationWhileSendingEmail
                        }
                        onClick={handleSendAndClose}
                        pending={replyAndClosePending}
                        size={ButtonSize.MICRO}
                        theme={ButtonTheme.OUTLINED}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          Reply & close
                        </Text>
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </div>
            </Flex>
          )}
        </>
      </Flex>
    </Flex>
  );

  if (
    conversation.platform === ConversationPlatform.EMAIL &&
    !shouldUseEmailConversationLayout &&
    visibility !== MessageVisibility.INTERNAL
  ) {
    return (
      <Flex justify="flex-end" p="lg">
        <RedoButton
          disabled={deleteLoading}
          hierarchy={RedoButtonHierarchy.SECONDARY}
          IconLeading={StickerSquareSvg}
          onClick={() => setVisibility(MessageVisibility.INTERNAL)}
          size={RedoButtonSize.REGULAR}
          text="Add a note"
        />
      </Flex>
    );
  }

  const writingInternalNoteOnEmailPlatform =
    conversation.platform === ConversationPlatform.EMAIL &&
    visibility === MessageVisibility.INTERNAL &&
    !emailDraftProps;

  const selectReplyNoteOrDropdown = (
    <>
      {writingInternalNoteOnEmailPlatform ? (
        <>
          {/* For spacing the button correctly like non-email platforms which use "space-between" */}
          <Flex> </Flex>

          {!shouldUseEmailConversationLayout && (
            <RedoButton
              hierarchy={RedoButtonHierarchy.TERTIARY}
              onClick={() => setVisibility(MessageVisibility.PUBLIC)}
              size={RedoButtonSize.REGULAR}
              text="Close note"
            />
          )}
        </>
      ) : (
        !shouldUseEmailConversationLayout && (
          <ButtonDropdown
            dropdown={
              <>
                {canReply && (
                  <DropdownOption
                    action={() => setVisibility(MessageVisibility.PUBLIC)}
                  >
                    <div className={messageInputCss.actionButtonContainer}>
                      <div
                        className={classNames(
                          messageInputCss.actionButton,
                          visibility === MessageVisibility.PUBLIC &&
                            messageInputCss.bold,
                        )}
                      >
                        <ChatBubbleIcon className={messageInputCss.icon} />
                        Reply
                      </div>
                      {visibility === MessageVisibility.PUBLIC && (
                        <CheckIcon className={messageInputCss.checkIcon} />
                      )}
                    </div>
                  </DropdownOption>
                )}
                <DropdownOption
                  action={() => setVisibility(MessageVisibility.INTERNAL)}
                >
                  <div className={messageInputCss.actionButtonContainer}>
                    <div
                      className={classNames(
                        messageInputCss.actionButton,
                        visibility === MessageVisibility.INTERNAL &&
                          messageInputCss.bold,
                      )}
                    >
                      <NoteIcon className={messageInputCss.icon} />
                      Internal note
                    </div>
                    {visibility === MessageVisibility.INTERNAL && (
                      <CheckIcon className={messageInputCss.checkIcon} />
                    )}
                  </div>
                </DropdownOption>
              </>
            }
            restrictDropdownSizeToParent={false}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.GHOST}
          >
            <div className={messageInputCss.actionButton}>
              {visibility === MessageVisibility.PUBLIC ? (
                <>
                  <ChatBubbleIcon className={messageInputCss.icon} />
                  Reply
                  <ChevronDownIcon className={messageInputCss.icon} />
                </>
              ) : (
                <>
                  <NoteIcon className={messageInputCss.icon} />
                  Internal note
                  <ChevronDownIcon className={messageInputCss.icon} />
                </>
              )}
            </div>
          </ButtonDropdown>
        )
      )}
    </>
  );

  const shouldShowMacroAutomationsSection =
    macroAutomationsNonEmpty(macroAutomations);

  return (
    <>
      <div
        className={classNames(messageInputCss.messageInputCard, {
          [messageInputCss.writingInternalNoteTopBorder]:
            writingInternalNoteOnEmailPlatform,
        })}
      >
        <div className={messageInputCss.aboveInput}>
          {selectReplyNoteOrDropdown}

          {((conversation.platform ===
            ConversationPlatform.INSTAGRAM_COMMENTS &&
            !!conversation.instagramCommentThread) ||
            (conversation.platform === ConversationPlatform.FACEBOOK_COMMENTS &&
              !!conversation.facebookCommentThread)) && (
            <div className={messageInputCss.commentThreadSwitch}>
              <Switch
                onChange={(value) => setShowFullCommentThread(value)}
                value={showFullCommentThread}
              />
              <p>Show full comment thread</p>
            </div>
          )}
          {evaluateResponseSimilarityToAiResponse(quill?.getText() || "") && (
            <div>
              <Pill size={PillSize.NEAR_SQUARE} theme={PillTheme.PRIMARY_LIGHT}>
                <div className={messageInputCss.aiInfoContainer}>
                  <StarsIcon className={messageInputCss.aiInfoContainer} />
                  <div className={messageInputCss.aiInfoContainer}>
                    AI generated response
                  </div>
                </div>
              </Pill>
            </div>
          )}
        </div>
        <div
          className={messageInputCss.editorContainer}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (
              (e.code === "Escape" ||
                (e.code === "Backspace" && !textToInsert)) &&
              autocompleteVisible
            ) {
              if (!quill) {
                return;
              }
              e.preventDefault();
              setTextToInsert(undefined);
              setTriggerOpenAutocompleteMenu(undefined);
              setAutocompleteVisible(false);
            }
          }}
        >
          {
            <div style={{ position: "relative" }}>
              <form
                className={
                  visibility === MessageVisibility.INTERNAL
                    ? messageInputCss.messageInputFormInternal
                    : messageInputCss.messageInputForm
                }
                onKeyDown={onKeyDown}
              >
                {shouldUseEmailConversationLayout && (
                  <Flex
                    align="center"
                    className={classNames(messageInputCss.wrappingText)}
                    p="md"
                  >
                    <ClickAwayListener
                      onClickAway={() => setPickingReplyType(false)}
                    >
                      <div>
                        <RedoButton
                          hierarchy={RedoButtonHierarchy.TERTIARY}
                          IconLeading={CornerUpLeftSvg}
                          IconTrailing={ChevronDownSvg}
                          onClick={(e) => setPickingReplyType((v) => !v)}
                          ref={setPickReplyButtonRef}
                          size={RedoButtonSize.REGULAR}
                          text={emailDraftProps.draftInfo.mode}
                        />
                        <Dropdown
                          anchor={pickReplyButtonRef}
                          fitToAnchor={false}
                          open={pickingReplyType}
                        >
                          <DropdownOption
                            action={() =>
                              handlePickReplyType(EmailReplyType.REPLY)
                            }
                          >
                            Reply
                          </DropdownOption>
                          <DropdownOption
                            action={() =>
                              handlePickReplyType(EmailReplyType.REPLY_ALL)
                            }
                          >
                            Reply All
                          </DropdownOption>
                          <DropdownOption
                            action={() =>
                              handlePickReplyType(EmailReplyType.FORWARD)
                            }
                          >
                            Forward
                          </DropdownOption>
                        </Dropdown>
                      </div>
                    </ClickAwayListener>
                    <Flex
                      align="center"
                      className={messageInputCss.wrappingText}
                      gap="2xl"
                      justify="space-between"
                    >
                      <Flex
                        align="center"
                        className={classNames(
                          messageInputCss.wrappingText,
                          messageInputCss.clickingRecipientBar,
                        )}
                        onClick={() => setEditingCCBCC(true)}
                      >
                        <Text
                          fontSize="md"
                          overflow="hidden"
                          textColor="primary"
                          textOverflow="ellipsis"
                        >
                          {stringifyEmailRecipientsInfo(
                            emailDraftProps?.draftInfo.draft.recipientsInfo,
                          ) ?? ""}
                        </Text>
                      </Flex>
                      <Flex align="center">
                        <RedoButton
                          hierarchy={RedoButtonHierarchy.TERTIARY}
                          onClick={() => setEditingCCBCC(true)}
                          size={RedoButtonSize.EXTRA_SMALL}
                          text="Edit recipients"
                        />
                        <EditRecipientsModal
                          conversation={conversation}
                          emailDraft={emailDraftProps.draftInfo}
                          isOpen={editingCCBCC}
                          onClose={() => setEditingCCBCC(false)}
                          setEmailDraft={emailDraftProps.handleSetReplyDraft}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                <Flex
                  className={messageInputCss.editor}
                  {...(!shouldUseEmailConversationLayout && { pt: "xl" })}
                >
                  <div
                    className={classNames(quillEditorCss.quillContainer)}
                    onDrop={(e) => doFileDrop(e, handleUpload)}
                    onKeyDown={maybePerformControlHotkeyAction}
                  >
                    <QuillEditor
                      cursorIndexRef={cursorIndexRef}
                      defaultValue={new Delta().insert("")}
                      disabled={submitDisabled}
                      editorClassName={quillEditorCss.quillEditor}
                      onTextChange={handleTextChange}
                      placeholder={
                        [
                          ConversationPlatform.INSTAGRAM_COMMENTS,
                          ConversationPlatform.FACEBOOK_COMMENTS,
                        ].includes(conversation.platform)
                          ? "Post a comment reply..."
                          : "Start typing..."
                      }
                      readOnly={!shouldAllowEditingMessage}
                      ref={setQuill}
                      setDisabled={setSubmitDisabledBecauseOfQuill}
                      setEmojiPickerOpenRef={setEmojiPickerOpenRef}
                      toolbar={toolbarSelector}
                    />
                  </div>
                  {htmlToPasteSignature && (
                    <Button
                      className={messageInputCss.showSignatureButton}
                      onClick={() => setShowSignature(!showSignature)}
                      size={ButtonSize.MICRO}
                      theme={ButtonTheme.OUTLINED}
                    >
                      <ThreeDotsIcon />
                    </Button>
                  )}
                </Flex>
                {shouldAllowEditingMessage && toolbarElement}
                <QuillAttachmentCarousel
                  attachments={draftAttachments}
                  removeFileFromDrafts={removeFileFromDrafts}
                />
              </form>
              {/* Hide autocomplete for mentions when it's not an internal note */}
              <SupportMessageAutocomplete
                client={client}
                discountCodesInMessage={discountCodesInMessage}
                messageVisibility={visibility}
                productsInMessage={productsInMessage}
                quill={quill}
                setDiscountCodesInMessage={setDiscountCodesInMessage}
                setProductsInMessage={setProductsInMessage}
                setShouldOpenFromExternalTrigger={
                  setTriggerOpenAutocompleteMenu
                }
                setTextToInsert={setTextToInsert}
                setUsersMentionedInMessage={setUsersMentionedInMessage}
                setVisible={setAutocompleteVisible}
                shouldOpenFromExternalTrigger={triggerOpenAutocompleteMenu}
                team={team}
                textToInsert={textToInsert}
                usersMentionedInMessage={usersMentionedInMessage}
                visible={autocompleteVisible}
              />
            </div>
          }
        </div>
        {metaSendType === "forbidden" &&
          visibility === MessageVisibility.PUBLIC && (
            <div className={messageInputCss.warning}>
              {privateReplyLimitation
                ? lastCustomerDirectMessage
                  ? `Cannot send message. Can't send messages through ${capitalize(conversation.platform)}'s API 7 days after the most recent message, and Instagram does not allow sending more than one private reply to a comment until the customer responds.`
                  : "Instagram does not allow sending more than one private reply to a comment until the customer responds."
                : `Can't send messages through ${capitalize(conversation.platform)}'s API 7 days after the most recent customer message.`}
            </div>
          )}

        {shouldShowMacroAutomationsSection && (
          <>
            <Flex align="flex-start" dir="column">
              <p>Automations</p>
              <MacroAutomationsList
                macroAutomations={macroAutomations}
                platform={conversation.platform}
                setMacroAutomations={setMacroAutomations}
              />
            </Flex>
            <Divider />
          </>
        )}
      </div>
      <MacroModal
        key={macrosLoad?.value?.length}
        macros={macrosLoad.value}
        open={macroModalOpen}
        refreshMacros={doMacrosLoad}
        setMacro={setMacro}
        setOpen={setMacroModalOpen}
      />
    </>
  );
});

function controlHotkeyTooltip({
  action,
  code,
  name,
  isMac,
}: {
  action: string | undefined;
  code: string;
  name: string;
  isMac: boolean;
}): string {
  const controlKey = isMac ? "⌘" : "Ctrl";

  return `${action ?? ""}${action ? " " : ""}${name} (${controlKey}+${code})`;
}

function getHotkeyTooltip(
  type: AutocompleteType | OtherHotkeyType,
  isMac: boolean,
): string {
  switch (type) {
    case AutocompleteType.DISCOUNT_CODE:
      return controlHotkeyTooltip({
        action: "Insert",
        code: "5",
        name: "discount code",
        isMac,
      });
    case AutocompleteType.MACRO:
      return controlHotkeyTooltip({
        action: "Insert",
        code: "6",
        name: "template",
        isMac,
      });
    case AutocompleteType.PRODUCT:
      return controlHotkeyTooltip({
        action: "Insert",
        code: "7",
        name: "product",
        isMac,
      });
    case AutocompleteType.MENTION:
      return "Insert mention (@)";
    case "sendReply":
      return controlHotkeyTooltip({
        action: undefined,
        code: "Enter",
        name: "Send reply",
        isMac,
      });
    case "sendReplyAndClose":
      return controlHotkeyTooltip({
        action: undefined,
        code: "Shift+Enter",
        name: "Send reply and close",
        isMac,
      });
    case "addInternalNote":
      return controlHotkeyTooltip({
        action: undefined,
        code: "Enter",
        name: "Add internal note",
        isMac,
      });
    default:
      return assertNever(type);
  }
}
