// extracted by mini-css-extract-plugin
export var aboveInput = "_5o-0";
export var actionButton = "_5o-4";
export var actionButtonContainer = "_5o-1";
export var actionIcon = "_5o-5";
export var aiInfoContainer = "_5o-2";
export var bold = "_5o-3";
export var checkIcon = "_5o-6";
export var clickingRecipientBar = "_5o-y";
export var commentThreadSwitch = "_5o-7";
export var draftImage = "_5o-8";
export var draftImageContainer = "_5o-9";
export var draftImages = "_5o-b";
export var editButton = "_5o-d";
export var editor = "_5o-c";
export var editorContainer = "_5o-v";
export var headerDivider = "_5o-e";
export var icon = "_5o-f";
export var info = "_5o-g";
export var internal = "_5o-k";
export var internalDivider = "_5o-h";
export var internalMessage = "_5o-i";
export var messageInputCard = "_5o-j";
export var messageInputForm = "_5o-l";
export var messageInputFormInternal = "_5o-m";
export var options = "_5o-n";
export var publicMessageCustomer = "_5o-p";
export var publicMessageMerchant = "_5o-o";
export var removeImageButton = "_5o-a";
export var replyButtons = "_5o-q";
export var showSignatureButton = "_5o-r";
export var spinner = "_5o-s";
export var visibilitySelector = "_5o-t";
export var warning = "_5o-u";
export var wrappingText = "_5o-w";
export var writingInternalNoteTopBorder = "_5o-x";