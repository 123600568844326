import { MessageVisibility } from "@redotech/redo-model/conversation";
import { Team } from "@redotech/redo-model/team";
import { GetUser } from "@redotech/redo-model/user";
import Quill from "quill";
import { ShopifyProduct } from "../create-order";

/* Edit history of Quill editor by find-replace on attributes. Used to clear the formatting of the macro variables. */
const findReplaceQuillInsertAttribute = (
  quill: Quill,
  attrName: string,
  attrValueToMatch: string | any,
  attrValueToSub: string | any,
) => {
  const content = quill.getContents();
  const ops = content.ops;

  for (let i = 0; i < ops.length; i++) {
    const op = ops[i];
    if (typeof op.insert === "string") {
      const attributes = op.attributes;

      if (attributes && attributes[attrName] === attrValueToMatch) {
        attributes[attrName] = attrValueToSub;
      }
    }
  }

  quill.setContents(quill.getContents());
};

export const clearFormattingFromMacroAutomationsText = (quill: Quill) => {
  findReplaceQuillInsertAttribute(quill, "color", "#5f45e2", "black");
  findReplaceQuillInsertAttribute(quill, "background", "#ebe9fd", "inherit");
};

export const clearColoringAtCursor = (
  quill: Quill,
  newCursorPosition: number,
) => {
  quill.setSelection(newCursorPosition, 0);
  quill.format("color", false);
  quill.format("background", false);
};

export const formatWithInserts = ({
  quill,
  visibility,
  team,
  discountCodesInMessage,
  productsInMessage,
}: {
  quill: Quill;
  visibility: MessageVisibility;
  team: Team;
  discountCodesInMessage: string[];
  productsInMessage: ShopifyProduct[];
}) => {
  const userNames =
    team?.users.map((user) => (user.user as GetUser).name.toLowerCase()) || [];
  const text = quill.getText().toLowerCase();

  const defaultFormat = {
    color: "black",
    background: "transparent",
  };

  // Formats the message for discount codes and products
  const handleFormat = (
    color: string,
    background: string,
    searchCharacter: string,
    wordsToFormat: string[],
  ) => {
    for (const word of wordsToFormat) {
      const searchString = `${searchCharacter}${word.toLowerCase()}`;
      let atIndex = text.indexOf(`${searchCharacter}${word.toLowerCase()}`);
      while (atIndex !== -1) {
        // Clear all formatting for the search string
        quill.removeFormat(atIndex, searchString.length);

        // Format the color and background
        quill.formatText(atIndex, searchString.length, {
          color,
          background,
        });

        // Get the format of the last character before the search string
        // If the search string is at the beginning of the message, use the default format
        const lastFormat =
          atIndex > 0 ? quill.getFormat(atIndex - 1) : defaultFormat;

        // Create the styles going forward. We want all of the last styles, and if the last styles did not include a color or background, we want to use the default format so that we override the values from the formatting of our search string
        const stylesAfterSearchString = {
          ...lastFormat,
          color: lastFormat.color || defaultFormat.color,
          background: lastFormat.background || defaultFormat.background,
        };

        const endIndex = atIndex + searchString.length;
        quill.formatText(endIndex, 1, stylesAfterSearchString);

        atIndex = text.indexOf(searchString, atIndex + 1);
      }
    }
  };
  // dark green, light green
  handleFormat("#067647", "#DCFAE6", "%", discountCodesInMessage);
  // dark pink, light pink
  handleFormat(
    "#C11574",
    "#FCE7F6",
    "&",
    productsInMessage.map((product) => product.title),
  );
  // Format for mentions
  if (visibility === MessageVisibility.INTERNAL) {
    handleFormat("#194185", "#84CAFF", "@", userNames);
  }
};
