import Picker from "@emoji-mart/react";
import { Popper } from "@mui/base/Popper/Popper";
import { PopperOwnProps } from "@mui/base/Popper/Popper.types";
import { ComponentType, memo, useEffect } from "react";
import { Theme } from "./theme-provider";
// These emoji types are taken from @types/emoji-mart. For some
// reason, I couldn't import and use Picker with types.
type EmojiSkin = 1 | 2 | 3 | 4 | 5 | 6;
export interface BaseEmoji {
  id: string;
  name: string;
  colons: string;
  /** Reverse mapping to keyof emoticons */
  emoticons: string[];
  unified: string;
  skin: EmojiSkin | null;
  native: string;
}

export interface CustomEmoji {
  // id is overridden by short_names[0]
  id?: string | undefined;
  // colons is overridden by :id:
  colons?: string | undefined;
  name: string;
  /** Must contain at least one name. The first name is used as the unique id. */
  short_names: string[];
  emoticons?: string[] | undefined;
  keywords?: string[] | undefined;
  imageUrl: string;
}

// Type info for the Picker props is taken from the README for emoji-mart.
// https://github.com/missive/emoji-mart?tab=readme-ov-file#options--props
type EmojiCategory =
  | "frequent"
  | "people"
  | "nature"
  | "foods"
  | "activity"
  | "places"
  | "objects"
  | "symbols"
  | "flags";
type EmojiLocale =
  | "en"
  | "ar"
  | "be"
  | "cs"
  | "de"
  | "es"
  | "fa"
  | "fi"
  | "fr"
  | "hi"
  | "it"
  | "ja"
  | "ko"
  | "nl"
  | "pl"
  | "pt"
  | "ru"
  | "sa"
  | "tr"
  | "uk"
  | "vi"
  | "zh";
type EmojiSet = "native" | "apple" | "google" | "twitter" | "facebook";
type PickerProps = {
  /** Data to use for the picker */
  data?: object;
  /** Localization data to use for the picker */
  i18n?: object;
  /** Categories to show in the picker. Order is respected. */
  categories?: EmojiCategory[];
  /** You can use custom emojis by providing an array of categorie
   *  and their emojis. Emojis also support multiple skin tones and can be GIFs or SVGs. */
  custom?: CustomEmoji[];
  /** Callback when an emoji is selected */
  onEmojiSelect?: (emoji: BaseEmoji | CustomEmoji) => void;
  /** Callback when a click outside of the picker happens */
  onClickOutside?: () => void;
  /** Callback when the Add custom emoji button is clicked. The
   *  button will only be displayed if this callback is provided.
   *  It is displayed when search returns no results. */
  onAddCustomEmoji?: (emoji: CustomEmoji) => void;
  /** Whether the picker should automatically focus on the search input */
  autoFocus?: boolean;
  /** You can use custom category icons by providing an object with
   *  the category name as key and the icon as value. Currently
   *  supported formats are svg string and src. */
  categoryIcons?: {
    [key in EmojiCategory]?: { svg: string } | { src: string };
  };
  /** Whether the picker should calculate `perLine` dynamically based
   * on the width of `<em-emoji-picker>`. When enabled, `perLine` is ignored */
  dynamicWidth?: boolean;
  /** An array of color that affects the hover background color */
  emojiButtonColors?: string[];
  /** The radius of the emoji buttons */
  emojiButtonRadius?: string;
  /** The size of the emoji buttons */
  emojiButtonSize?: number;
  /** The size of the emojis (inside the buttons) */
  emojiSize?: number;
  /** The version of the emoji data to use. Latest version supported
   * in `@emoji-mart/data` is currently 14 */
  emojiVersion?: string;
  /** List of emoji IDs that will be excluded from the picker */
  exceptEmojis?: string[];
  /** The type of icons to use for the picker. `outline` with light
   *  theme and `solid` with dark theme. */
  icons?: "auto" | "outline" | "solid";
  /** The locale to use for the picker */
  locale?: EmojiLocale;
  /** The maximum number of frequent rows to show. `0` will disable
   *  frequent category */
  maxFrequentRows?: number;
  /** The position of the navigation bar */
  navPosition?: "top" | "bottom" | "none";
  /** Whether to show country flags or not. If not provided, this is
   *  handled automatically (Windows doesn’t support country flags) */
  noCountryFlags?: boolean;
  /** The id of the emoji to use for the no results emoji */
  noResultsEmoji?: string;
  /** The number of emojis to show per line */
  perLine?: number;
  /** The id of the emoji to use for the preview when not hovering
   *  any emoji. `point_up` when preview position is bottom and
   * `point_down` when preview position is top. */
  previewEmoji?: string;
  /** The position of the preview */
  previewPosition?: "top" | "bottom" | "none";
  /** The position of the search input */
  searchPosition?: "sticky" | "static" | "none";
  /** The set of emojis to use for the picker. `native` being the
   *  most performant, others rely on spritesheets. */
  set?: EmojiSet;
  /** The emojis' skin tone */
  skin?: EmojiSkin;
  /** The position of the skin tone selector */
  skinTonePosition?: "preview" | "search" | "none";
  /** The color theme of the picker */
  theme?: "auto" | "light" | "dark";
  /** A function that returns the URL of the spritesheet to use
   *  for the picker. It should be compatible with the data provided. */
  getSpritesheetURL?: () => string;
};
type TypedPicker = ComponentType<PickerProps>;
const TypedPicker: TypedPicker = Picker;

export type EmojiPickerCloseCause = "clickOutside" | "select" | "escape";

export type EmojiPickerProps = {
  handleEmojiSelected: (emoji: BaseEmoji | CustomEmoji) => void;
  theme: Theme;
  anchor: HTMLElement | null;
  onClose: (cause: EmojiPickerCloseCause) => void;
  open: boolean;
  closeOnSelect?: boolean;
  placement?: PopperOwnProps["placement"];
};

export const EmojiPicker = memo(function EmojiPicker({
  handleEmojiSelected,
  theme,
  anchor,
  onClose,
  open,
  closeOnSelect = true,
  placement,
}: EmojiPickerProps) {
  function onEmojiSelect(emoji: BaseEmoji | CustomEmoji) {
    handleEmojiSelected(emoji);
    if (closeOnSelect) {
      onClose("select");
    }
  }

  useEffect(() => {
    if (!open) return;
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose("escape");
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [open]);

  return (
    <Popper anchorEl={anchor} open={open} placement={placement || "auto"}>
      {/* ~0.5MB of Emoji data is fetched from https://cdn.jsdelivr.net/npm/@emoji-mart/data@latest/sets/15/native.json
       *  when the Picker is first opened. The alternative is to import the data from @emoji-mart/data
       *  and pass it to the Picker as a property, including it in the bundle.
       */}
      <TypedPicker
        autoFocus
        onClickOutside={() => onClose("clickOutside")}
        onEmojiSelect={onEmojiSelect}
        previewEmoji="none"
        theme={theme === "system" ? "auto" : theme}
      />
    </Popper>
  );
});
