import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { LoadState } from "@redotech/react-util/load";
import {
  ConversationTagWithId,
  ExpandedConversation,
  getConversationStatus,
} from "@redotech/redo-model/conversation";
import { Order } from "@redotech/redo-model/order";
import { UpdateConversationBody } from "@redotech/redo-model/updateconversationbody";
import {
  Permission,
  permitted,
  GetUser as User,
} from "@redotech/redo-model/user";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import ArrowUpRightSvg from "@redotech/redo-web/arbiter-icon/arrow-up-right_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import { ImageLightbox } from "@redotech/redo-web/image-lightbox";
import { ExternalLink } from "@redotech/redo-web/link";
import { RedoSupportChannelBadge } from "@redotech/redo-web/support/redo-support-channel-badge";
import { RedoSupportStatusBadge } from "@redotech/redo-web/support/redo-support-status-badge";
import { Text } from "@redotech/redo-web/text";
import { CartData } from "@redotech/shopify-storefront";
import { memo, useContext, useEffect, useState } from "react";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { RedoMerchantClientContext } from "../../client/context";
import { updateConversation } from "../../client/conversations";
import { CartDetails } from "../action-panel/cart-details";
import * as conversationContextCss from "../action-panel/conversation-context.module.css";
import { OrderDetails } from "../action-panel/order-details/order-details";
import { ReturnDetails } from "../action-panel/return-details";
import { ConversationTagInput } from "../conversation-tags/conversation-tag-input";
import { GenerateAiSummaryButton } from "../generate-ai-summary-button";
import { AssigneeDetails } from "./assignee-details";
import { CustomerDetails } from "./customer-details";

export const ConversationDetailsPanel = memo(function ConversationDetailsPanel({
  conversation,
  setConversationAssignee,
  setActiveConversation,
  inTab,
  ordersLoad,
  doOrdersLoad,
  returnsLoad,
  doReturnsLoad,
  cartsLoad,
  doCartsLoad,
}: {
  conversation: ExpandedConversation;
  setConversationAssignee: (assignee: any) => void;
  setActiveConversation: (conversation: ExpandedConversation) => void;
  inTab: boolean;
  ordersLoad: LoadState<any>;
  doOrdersLoad: () => void;
  returnsLoad: LoadState<any>;
  doReturnsLoad: () => void;
  cartsLoad: LoadState<CartData[] | undefined>;
  doCartsLoad: () => void;
}) {
  const team = useRequiredContext(TeamContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const user = useContext(UserContext);

  const [orderNeedingAction, setOrderNeedingAction] = useState<
    Order | undefined
  >(undefined);
  const [imageLightboxOpen, setImageLightboxOpen] = useState(false);
  const [currentlyCreatingOrder, setCurrentlyCreatingOrder] = useState(false);
  const [conversationTagsToShow, setConversationTagsToShow] = useState<
    ConversationTagWithId[]
  >([]);

  useEffect(() => {
    setConversationTagsToShow(conversation.tagIds || []);
  }, [conversation]);

  async function updateConversationTags(updatedTags: ConversationTagWithId[]) {
    setConversationTagsToShow(updatedTags);

    const body: UpdateConversationBody = {
      tags: updatedTags,
    };

    const response = await updateConversation(client, conversation, body);

    setActiveConversation(response.data);
  }

  const canManageOrder =
    !!user && permitted(user.permissions, Permission.MANAGE_ORDER);

  const handleCreateOrder = async () => {
    amplitude.logEvent("view-createOrder");
    setCurrentlyCreatingOrder(true);
    let customerId = "";
    if (ordersLoad?.value?.length) {
      customerId = ordersLoad?.value?.[0].shopify.customer.id;
    }
    window.open(
      `https://${team.storeUrl}/admin/draft_orders/new${customerId ? `?customerId=${customerId}` : ""}`,
      "_blank",
    );
    setCurrentlyCreatingOrder(false);
  };

  const handleUpdateConversationDetails = async ({
    assignee,
  }: {
    assignee: User | null;
  }) => {
    amplitude.logEvent("update-conversationAssignee", {
      mode: "single",
      conversationIds: [conversation._id],
      channels: [conversation.platform],
      assignee: assignee?._id,
    });
    await alertOnFailure("Unable to change assignee")(async () => {
      await updateConversation(client, conversation, {
        assignee: assignee ? assignee._id : null,
      });
      setConversationAssignee(assignee);
    });
  };

  return (
    <Flex
      className={inTab ? undefined : conversationContextCss.rightContent}
      dir="column"
      w="full"
    >
      {!inTab && (
        <Flex
          align="center"
          className={conversationContextCss.header}
          pl="xl"
          shrink={0}
        >
          <Text fontSize="sm" fontWeight="semibold" textColor="primary">
            Details
          </Text>
        </Flex>
      )}
      <Flex
        className={conversationContextCss.tagsAndAssigneeContainer}
        dir="column"
        p="xl"
      >
        <Flex dir="column" gap="xl">
          <GenerateAiSummaryButton conversation={conversation} />
          <Flex gap="md" wrap="wrap">
            <RedoSupportChannelBadge
              platform={conversation.platform}
              size="xs"
            />
            <RedoSupportStatusBadge
              size="xs"
              status={getConversationStatus(conversation)}
            />
          </Flex>
          {conversation.instagramCommentThread && (
            <Flex>
              <Flex className={conversationContextCss.instagramImageContainer}>
                {conversation.instagramCommentThread?.mediaPreviewUrl && (
                  <>
                    <div
                      className={conversationContextCss.postPreview}
                      onClick={() => setImageLightboxOpen(true)}
                    >
                      <img
                        alt="Instagram post"
                        src={
                          conversation.instagramCommentThread.mediaPreviewUrl
                        }
                      />
                    </div>
                    <ImageLightbox
                      imageSrc={
                        conversation.instagramCommentThread.mediaPreviewUrl
                      }
                      onClose={() => setImageLightboxOpen(false)}
                      open={imageLightboxOpen}
                    />
                  </>
                )}
              </Flex>
              <Flex
                className={conversationContextCss.wrappingText}
                dir="column"
              >
                <ExternalLink
                  newTab
                  showIcon={false}
                  url={
                    conversation.instagramCommentThread?.permalink ?? undefined
                  }
                >
                  <RedoBadge
                    color="gray"
                    iconTrailing={{ type: "icon", Icon: ArrowUpRightSvg }}
                    size="xs"
                    text="See post"
                  />
                </ExternalLink>
              </Flex>
            </Flex>
          )}
          {conversation.facebookCommentThread && (
            <ExternalLink
              newTab
              showIcon={false}
              url={conversation.facebookCommentThread?.permalink ?? undefined}
            >
              <RedoBadge
                color="gray"
                iconTrailing={{ type: "icon", Icon: ArrowUpRightSvg }}
                size="xs"
                text="See post"
              />
            </ExternalLink>
          )}
          <Flex gap="md" wrap="wrap">
            <ConversationTagInput
              currentTags={conversationTagsToShow}
              setCurrentTags={(tags) => updateConversationTags(tags)}
              showAddButtonToEnterInput
              showBorder={false}
            />
          </Flex>
        </Flex>

        <Flex dir="column">
          <AssigneeDetails
            conversation={conversation}
            updateConversationDetails={handleUpdateConversationDetails}
          />
        </Flex>
      </Flex>
      <div className={conversationContextCss.wrapper}>
        <div className={conversationContextCss.assigneeDetails} />

        <CustomerDetails
          conversation={conversation}
          orders={ordersLoad.value}
          returns={returnsLoad.value}
          setActiveConversation={setActiveConversation}
        />
      </div>
      <Flex
        className={conversationContextCss.ordersReturnsSection}
        dir="column"
      >
        <Flex align="center" justify="space-between">
          <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
            Order history
          </Text>
          <div className={conversationContextCss.actionButton}>
            <RedoButton
              disabled={!canManageOrder}
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconLeading={PlusIcon}
              onClick={async () => {
                await handleCreateOrder();
              }}
              pending={canManageOrder && currentlyCreatingOrder}
              size={RedoButtonSize.EXTRA_SMALL}
            />
          </div>
        </Flex>
        {/* Whenever the conversation is updated, and we hear about that from the listen endpoint,
            we get the latest conversation.  We should update that to also grab the latest cart(s)
            for the customers. So the customer object should probably have an array of cart IDs.
            That way we can just look at conversation.carts here and display that info. */}
        {cartsLoad.value?.length && <CartDetails carts={cartsLoad.value} />}
        <ReturnDetails
          pending={returnsLoad.pending}
          reloadReturns={doReturnsLoad}
          returns={returnsLoad.value}
        />
        <OrderDetails
          orderNeedingAction={orderNeedingAction}
          orders={ordersLoad.value}
          pending={ordersLoad.pending}
          reloadOrders={doOrdersLoad}
          setOrderNeedingAction={setOrderNeedingAction}
        />
      </Flex>
    </Flex>
  );
});
