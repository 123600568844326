import { UploadedFile } from "./file/uploaded-file";
export interface Macro {
  _id: string;
  team: any;
  name: string;
  content: string;
  htmlContent: string;
  tagsToAdd?: string[];
  statusToSet?: MacroStatusToSet;
  emailSubjectToSet?: string | undefined;
  snoozeDuration?: SnoozeDuration;
  attachments?: UploadedFile[];
  shouldAddNote?: boolean;
  noteToAddContent?: string;
  noteToAddHtmlContent?: string;
  /** User IDs */
  noteToAddUsersMentioned?: string[];
}

/**
 * Macro status to set is NOT the same as
 * the conversation status field.
 *
 * The conversation is either open or closed.
 * Snoozed logic is handled by the snoozedUntil field.
 */
export enum MacroStatusToSet {
  OPEN = "open",
  SNOOZED = "snoozed",
  CLOSED = "closed",
}

export enum SnoozeDuration {
  SAME_DAY = "same_day",
  ONE_DAY = "one_day",
  TWO_DAYS = "two_days",
  SATURDAY = "saturday",
  MONDAY = "monday",
}

export interface MacroVariable {
  id: string;
  variable: string;
  displayName: string;
}

export const MACRO_VARIABLES: MacroVariable[] = [
  {
    id: "currentDate",
    variable: "current_date",
    displayName: "Current Date",
  },
  {
    id: "customerFirstName",
    variable: "customer_first_name",
    displayName: "Customer First Name",
  },
  {
    id: "customerFullName",
    variable: "customer_full_name",
    displayName: "Customer Full Name",
  },
  {
    id: "customerLastName",
    variable: "customer_last_name",
    displayName: "Customer Last Name",
  },
  {
    id: "agentFirstName",
    variable: "agent_first_name",
    displayName: "Agent First Name",
  },
  {
    id: "agentFullName",
    variable: "agent_full_name",
    displayName: "Agent Full Name",
  },
  {
    id: "agentLastName",
    variable: "agent_last_name",
    displayName: "Agent Last Name",
  },
  {
    id: "recentClaimCreatedDate",
    variable: "recent_claim_created_date",
    displayName: "Recent Claim Created Date",
  },
  {
    id: "recentClaimProcessedDate",
    variable: "recent_claim_processed_date",
    displayName: "Recent Claim Processed Date",
  },
  {
    id: "recentClaimStatus",
    variable: "recent_claim_status",
    displayName: "Recent Claim Status",
  },
  {
    id: "recentOrderDate",
    variable: "recent_order_date",
    displayName: "Recent Order Date",
  },
  {
    id: "recentOrderDeliveryStatus",
    variable: "recent_order_delivery_status",
    displayName: "Recent Order Delivery Status",
  },
  {
    id: "recentOrderFulfillmentStatus",
    variable: "recent_order_fulfillment_status",
    displayName: "Recent Order Fulfillment Status",
  },
  {
    id: "recentReturnStatus",
    variable: "recent_return_status",
    displayName: "Recent Return Status",
  },
  {
    id: "recentOrderPrice",
    variable: "recent_order_price",
    displayName: "Recent Order Price",
  },
  {
    id: "recentOrderReturnedDate",
    variable: "recent_order_returned_date",
    displayName: "Recent Order Returned Date",
  },
  {
    id: "recentOrderShippingAddress",
    variable: "recent_order_shipping_address",
    displayName: "Recent Order Shipping Address",
  },
  {
    id: "recentOrderShippingDate",
    variable: "recent_order_shipping_date",
    displayName: "Recent Order Shipping Date",
  },
  {
    id: "recentOrderTrackingNumber",
    variable: "recent_order_tracking_number",
    displayName: "Recent Order Tracking Number",
  },
  {
    id: "recentOrderTrackingURL",
    variable: "recent_order_tracking_url_link",
    displayName: "Recent Order Tracking URL Link",
  },
  // Order name is the prefixed order number. We originally offered recent order number,
  // so for backwards compatibility, the variable name is the same
  {
    id: "recentOrderName",
    variable: "recent_order_number",
    displayName: "Recent Order Number",
  },
  {
    id: "recentReturnCreatedDate",
    variable: "recent_return_created_date",
    displayName: "Recent Return Created Date",
  },
  {
    id: "recentReturnProcessedDate",
    variable: "recent_return_processed_date",
    displayName: "Recent Return Processed Date",
  },
  {
    id: "storeName",
    variable: "store_name",
    displayName: "Store Name",
  },
];

export const macroVariableNames = () => {
  return MACRO_VARIABLES.map((macroVariable) => macroVariable.variable);
};

export const locateVariables = (text: string) => {
  const variableNames = [];
  for (const name of macroVariableNames()) {
    if (text.includes(name)) {
      variableNames.push(name);
    }
  }
  return variableNames;
};

export const getVariableInfo = (variable: string) => {
  const matchingVariables = MACRO_VARIABLES.filter(
    (macroVariable) => macroVariable.variable === variable,
  );
  return matchingVariables.length ? matchingVariables[0] : null;
};
