// extracted by mini-css-extract-plugin
export var border = "_45-5";
export var button = "_45-f";
export var center = "_45-7";
export var centered = "_45-3";
export var closeButton = "_45-9";
export var disabledWrapper = "_45-g";
export var error = "_45-j";
export var fill = "_45-c";
export var footer = "_45-b";
export var footerAction = "_45-d";
export var footerButtons = "_45-e";
export var header = "_45-4";
export var icon = "_45-i";
export var info = "_45-m";
export var inline = "_45-6";
export var large = "_45-2";
export var main = "_45-a";
export var modal = "_45-0";
export var none = "_45-n";
export var small = "_45-1";
export var success = "_45-l";
export var tight = "_45-h";
export var titleIcon = "_45-8";
export var warn = "_45-k";