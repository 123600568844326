import { useRequiredContext } from "@redotech/react-util/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { TeamConversationActivity } from "@redotech/redo-model/conversation-activity";
import { RenderedTeam } from "@redotech/redo-model/team";
import * as clone from "lodash/clone";
import {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { TeamContext } from "../app/team";
import { UserContext } from "../app/user";
import { UserCacheContext } from "../app/user-cache";
import { ViewsContext } from "../app/views";
import { RedoMerchantClientContext } from "../client/context";
import { getConversation } from "../client/conversations";
import { ConversationFetcher } from "./conversation-fetcher";
import { ActiveViewContextProvider } from "./conversations-table-filters/active-view-context";
import { ActiveViewConversationCountsProvider } from "./conversations-table-filters/conversation-counts-context";
import { FiltersProvider } from "./conversations-table-filters/filters-context";
import { ConversationsTableSelection } from "./conversations-table/conversations-table-selection";
import { FullConversationsTable } from "./conversations-table/full-conversations-table";
import {
  ACTIVE_CONVERSATION_QUERY_PARAMETER,
  getQueryParameter,
} from "./query-parameters";
import { SingleConversationView } from "./single-conversation-view";
import {
  getConversationActivityStream as getTeamConversationActivityStream,
  listen,
  sendConversationActivityUpdate,
} from "./utils";

export const Support = memo(function Support() {
  const team = useContext(TeamContext);
  const user = useContext(UserContext);
  const views = useContext(ViewsContext);
  if (team && user && views) {
    return <SupportComponent />;
  } else {
    return null;
  }
});

export const TeamConversationActivityContext =
  createContext<TeamConversationActivity>({});

const SupportComponent = memo(function SupportComponent() {
  const navigate: NavigateFunction = useNavigate();
  const team = useRequiredContext(TeamContext) as RenderedTeam;
  const user = useRequiredContext(UserContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const userCache = useContext(UserCacheContext);
  // The conversation that is currently being viewed in the detail view
  const [activeConversation, setActiveConversationState] =
    useState<ExpandedConversation>();
  const [prevConversation, setPrevConversation] =
    useState<ExpandedConversation>();
  const activeConversationRef = useRef<ExpandedConversation>();
  const [pageLoaded, setPageLoaded] = useState(false);

  const [blockRefresh, setBlockRefresh] = useState(false);

  const [teamConversationActivity, setTeamConversationActivity] =
    useState<TeamConversationActivity>({});
  const teamConversationActivityRef = useRef<TeamConversationActivity>({});

  useEffect(() => {
    activeConversationRef.current = activeConversation;
  }, [activeConversation]);

  useEffect(() => {
    teamConversationActivityRef.current = teamConversationActivity;
  }, [teamConversationActivity]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        for await (const data of listen({
          query: async () => {
            return await getTeamConversationActivityStream({
              authorization: client.authorization() as {
                Authorization: string;
              },
              signal: abortController.signal,
            });
          },
          loopCondition: true,
        })) {
          const newTeamConversationActivity: TeamConversationActivity =
            data as any;
          setTeamConversationActivity(newTeamConversationActivity);
        }
      } catch (e) {
        if (abortController.signal.aborted) {
          return;
        }
        throw e;
      }
    })();
    return () => {
      if (team.users.map((user) => user.user._id).includes(user._id))
        void sendConversationActivityUpdate({
          authorization: client.authorization() as {
            Authorization: string;
          },
          prevConversationId: activeConversationRef.current?._id,
        });
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (team.users.map((user) => user.user._id).includes(user._id)) {
      if (!activeConversation) return;
      const abortController = new AbortController();
      const sendUpdate = async ({
        prevConversationId,
      }: {
        prevConversationId?: string;
      } = {}) => {
        await sendConversationActivityUpdate({
          authorization: client.authorization() as {
            Authorization: string;
          },
          activeConversationId: activeConversation._id,
          prevConversationId,
          signal: abortController.signal,
        });
      };

      // Optimistically update team conversation activity
      const newTeamConversationActivity = clone(
        teamConversationActivityRef.current,
      );
      // Remove from old conversation
      if (
        prevConversation &&
        newTeamConversationActivity[prevConversation._id]
      ) {
        newTeamConversationActivity[prevConversation._id].viewing =
          newTeamConversationActivity[prevConversation._id].viewing.filter(
            (userId) => userId !== user._id,
          );
        newTeamConversationActivity[prevConversation._id].typing =
          newTeamConversationActivity[prevConversation._id].typing.filter(
            (userId) => userId !== user._id,
          );
        if (
          newTeamConversationActivity[prevConversation._id].viewing.length ===
            0 &&
          newTeamConversationActivity[prevConversation._id].typing.length === 0
        ) {
          delete newTeamConversationActivity[prevConversation._id];
        }
      }
      // Add to new conversation
      if (!newTeamConversationActivity[activeConversation._id]) {
        newTeamConversationActivity[activeConversation._id] = {
          viewing: [],
          typing: [],
        };
      }
      const newViewing = [
        ...(newTeamConversationActivity[activeConversation._id].viewing || []),
        user._id,
      ];
      newTeamConversationActivity[activeConversation._id] = {
        viewing: [...newViewing],
        typing: newTeamConversationActivity[activeConversation._id].typing,
      };
      setTeamConversationActivity(newTeamConversationActivity);

      setPrevConversation(activeConversation);
      void sendUpdate({ prevConversationId: prevConversation?._id });
      const interval = setInterval(sendUpdate, 5 * 1000);
      return () => {
        clearInterval(interval);
        abortController.abort("activeConversation changed");
      };
    }
    return;
  }, [activeConversation?._id]);

  useEffect(() => {
    // Handle the case where we went back to the view without clicking the back button
    // (i.e. by clicking the view in the navigation sidebar)
    if (!location.search && activeConversation) {
      void setActiveConversation(undefined);
    }

    const activeConversationId = getQueryParameter(
      location,
      ACTIVE_CONVERSATION_QUERY_PARAMETER,
    );
    if (
      activeConversationId &&
      activeConversation?._id !== activeConversationId
    ) {
      const updateActiveConversation = async () => {
        const conversation = await getConversation(client, {
          conversationId: activeConversationId,
        });
        await setActiveConversation(conversation);
      };
      void updateActiveConversation();
    }
  }, [location.pathname, location.search]);

  const fetcher: ConversationFetcher = useMemo(
    () => new ConversationFetcher(client, userCache, () => setPageLoaded(true)),
    [client],
  );

  const setActiveConversation = async (
    conversation: ExpandedConversation | undefined,
  ) => {
    navigateToConversationIfNeeded(activeConversation, conversation);
    setActiveConversationState(conversation);
  };

  const navigateToConversationIfNeeded = (
    activeConversation: ExpandedConversation | undefined,
    newConversation: ExpandedConversation | undefined,
  ): void => {
    if (activeConversation?._id === newConversation?._id) {
      return;
    }
    const locationActiveConversationId = getQueryParameter(
      location,
      ACTIVE_CONVERSATION_QUERY_PARAMETER,
    );
    if (newConversation?._id === locationActiveConversationId) {
      return;
    }
    const searchParams = new URLSearchParams(location.search);

    if (newConversation?._id) {
      searchParams.set(
        ACTIVE_CONVERSATION_QUERY_PARAMETER,
        newConversation._id,
      );
      navigate(`${location.pathname}?${searchParams}`);
    } else {
      searchParams.delete(ACTIVE_CONVERSATION_QUERY_PARAMETER);
      navigate(`${location.pathname}?${searchParams}`);
    }
  };

  if (!team.settings.support) {
    navigate(`/stores/${team._id}/summary`);
  }

  return user ? (
    <ActiveViewContextProvider>
      <FiltersProvider>
        <ActiveViewConversationCountsProvider
          blockCountRefreshes={blockRefresh}
        >
          <TeamConversationActivityContext.Provider
            value={teamConversationActivity}
          >
            {activeConversation ? (
              <SingleConversationView
                activeConversation={activeConversation}
                blockRefresh={blockRefresh}
                fetcher={fetcher}
                setActiveConversation={setActiveConversation}
              />
            ) : (
              <ConversationsTableSelection fetcher={fetcher}>
                <FullConversationsTable
                  blockRefresh={blockRefresh}
                  fetcher={fetcher}
                  pageLoaded={pageLoaded}
                  setActiveConversation={setActiveConversation}
                  setBlockRefresh={setBlockRefresh}
                />
              </ConversationsTableSelection>
            )}
          </TeamConversationActivityContext.Provider>
        </ActiveViewConversationCountsProvider>
      </FiltersProvider>
    </ActiveViewContextProvider>
  ) : (
    <div />
  );
});
