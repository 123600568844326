// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_7j-0";
export var footer = "_7j-2";
export var infoSection = "_7j-3";
export var infoSectionContainer = "_7j-4";
export var infoSectionTitleRow = "_7j-5";
export var itemDetails = "_7j-6";
export var line = "_7j-7";
export var lineItemImage = "_7j-1";
export var lineItems = "_7j-8";
export var modalContent = "_7j-9";
export var multiLineInfo = "_7j-a";
export var orderName = "_7j-b";
export var strong = "_7j-c";