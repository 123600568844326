// extracted by mini-css-extract-plugin
export var container = "_55-0";
export var content = "_55-3";
export var customerTitle = "_55-4";
export var duration = "_55-5";
export var empty = "_55-6";
export var gray = "_55-7";
export var header = "_55-8";
export var info = "_55-9";
export var loading = "_55-2";
export var loadingCard = "_55-a";
export var oneLine = "_55-b";
export var personName = "_55-f";
export var pillContainer = "_55-c";
export var selected = "_55-1";
export var text = "_55-d";
export var twoLines = "_55-e";
export var unread = "_55-g";
export var unreadIndicator = "_55-h";