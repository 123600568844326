import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { Order } from "@redotech/redo-model/order";
import { ReturnType } from "@redotech/redo-model/return";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import {
  RedoCommandMenu,
  RedoCommandMenuItem,
} from "@redotech/redo-web/arbiter-components/command-menu/redo-command-menu";
import RefundIcon from "@redotech/redo-web/arbiter-icon/coins-hand.svg";
import ReturnIcon from "@redotech/redo-web/arbiter-icon/corner-up-left_filled.svg";
import DotsHorizontalSvg from "@redotech/redo-web/arbiter-icon/dots-horizontal.svg";
import { Flex } from "@redotech/redo-web/flex";
import CopyIcon from "@redotech/redo-web/icon-old/copy.svg";
import EditPencilIcon from "@redotech/redo-web/icon-old/edit-pencil2.svg";
import WarningShieldIcon from "@redotech/redo-web/icon-old/warning-shield.svg";
import XIcon from "@redotech/redo-web/icon-old/x-big.svg";
import { ReactNode, memo, useState } from "react";
import { TeamContext } from "../../../app/team";
import * as orderDetailsCss from "./order-details.module.css";

export const OrderDetailsOrderActions = memo(function OrderDetailsOrderActions({
  order,
  setCreateType,
  setOrderNeedingAction,
  setCreateClaimModalOpen,
  setEditOrderModalOpen,
  setDuplicateOrderModalOpen,
  setCancelOrderModalOpen,
  setRefundOrderModalOpen,
}: {
  order: Order;
  setCreateType: (type: ReturnType) => void;
  setOrderNeedingAction: (order: Order) => void;
  setCreateClaimModalOpen: (open: boolean) => void;
  setEditOrderModalOpen: (open: boolean) => void;
  setDuplicateOrderModalOpen: (open: boolean) => void;
  setCancelOrderModalOpen: (open: boolean) => void;
  setRefundOrderModalOpen: (open: boolean) => void;
}) {
  const team = useRequiredContext(TeamContext);

  const [actionsDropdownOpen, setActionsDropdownOpen] = useState(false);
  const [actionsDropdownAnchor, setActionsDropdownAnchor] =
    useState<HTMLElement | null>(null);

  const onCreateReturnClick = (order: Order) => {
    amplitude.logEvent("view-createReturnModal", {
      shopifyOrderId: order.shopify_id,
    });
    setOrderNeedingAction(order);
    setCreateType("return");
    setCreateClaimModalOpen(true);
  };

  const onCreateClaimClick = (order: Order) => {
    amplitude.logEvent("view-createClaimModal", {
      shopifyOrderId: order.shopify_id,
    });
    setOrderNeedingAction(order);
    setCreateType("claim");
    setCreateClaimModalOpen(true);
  };

  const onEditOrderClick = (order: Order) => {
    amplitude.logEvent("view-updateOrderModal", {
      shopifyOrderId: order.shopify_id,
    });
    setOrderNeedingAction(order);
    setEditOrderModalOpen(true);
  };

  const onDuplicateClick = (order: Order) => {
    amplitude.logEvent("view-duplicateOrderModal", {
      shopifyOrderId: order.shopify_id,
    });
    setOrderNeedingAction(order);
    setDuplicateOrderModalOpen(true);
  };

  const onCancelOrderClick = (order: Order) => {
    amplitude.logEvent("view-cancelOrderModal", {
      shopifyOrderId: order.shopify_id,
    });
    setOrderNeedingAction(order);
    setCancelOrderModalOpen(true);
  };

  const onRefundClick = (order: Order) => {
    amplitude.logEvent("view-refundOrderModal", {
      shopifyOrderId: order.shopify_id,
    });
    setOrderNeedingAction(order);
    setRefundOrderModalOpen(true);
  };

  const unfulfilled =
    (!order.shopify?.fulfillment_status ||
      order.shopify.fulfillment_status.toLowerCase() === "unfulfilled") &&
    order.shopify.fulfillments?.length === 0;
  const closed = order.shopify.closed_at !== null;
  const cancelled = order.shopify.cancelled_at !== null;
  const availableActions: RedoCommandMenuItem[] = [];
  // The order actions are pushed determines which show and which are in the dropdown
  // TODO: uncomment when finished
  if (unfulfilled && !closed && !cancelled) {
    availableActions.push({
      Icon: EditPencilIcon,
      text: "Edit order",
      onClick: () => onEditOrderClick(order),
    });
  }
  if (unfulfilled && !closed && !cancelled) {
    availableActions.push({
      Icon: XIcon,
      text: "Cancel",
      onClick: () => onCancelOrderClick(order),
    });
  }
  if (!unfulfilled && !cancelled && team.settings.returns?.enabled) {
    availableActions.push({
      Icon: ReturnIcon,
      text: "Create return",
      onClick: () => onCreateReturnClick(order),
    });
  }
  if (!unfulfilled && !cancelled && team.settings.packageProtection?.enabled) {
    availableActions.push({
      Icon: WarningShieldIcon,
      text: "Create claim",
      onClick: () => onCreateClaimClick(order),
    });
  }
  availableActions.push({
    Icon: CopyIcon,
    text: "Duplicate",
    onClick: () => onDuplicateClick(order),
  });
  availableActions.push({
    Icon: RefundIcon,
    text: "Refund",
    onClick: () => onRefundClick(order),
  });

  const actionsToShow: ReactNode[] = [];
  const dropdownActions: RedoCommandMenuItem[] = [];

  availableActions.forEach((action, index) => {
    dropdownActions.push({
      Icon: action.Icon,
      text: action.text,
      onClick: action.onClick,
      size: RedoButtonSize.SMALL,
    });
  });
  actionsToShow.push(
    <Flex>
      <RedoCommandMenu
        anchor={actionsDropdownAnchor}
        items={dropdownActions}
        open={actionsDropdownOpen}
        setOpen={setActionsDropdownOpen}
      />
      <RedoButton
        hierarchy={RedoButtonHierarchy.TERTIARY}
        IconLeading={DotsHorizontalSvg}
        onClick={() => setActionsDropdownOpen(!actionsDropdownOpen)}
        ref={setActionsDropdownAnchor}
        size={RedoButtonSize.EXTRA_SMALL}
      />
    </Flex>,
  );

  return actionsToShow.length > 0 ? (
    <div className={orderDetailsCss.actions}>{actionsToShow}</div>
  ) : null;
});
