import * as amplitude from "@amplitude/analytics-browser";
import { Currency, isCurrency } from "@redotech/money/currencies";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { Team } from "@redotech/redo-model/team";
import {
  CURRENCY_FORMAT,
  CurrencyContext,
  DEFAULT_CURRENCY,
  default_format_Currency,
} from "@redotech/redo-web/currency";
import { ShopifyStorefrontClient } from "@redotech/shopify-storefront";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getCurrencyExchangeRatesMerchantAPI } from "../api";
import { RedoMerchantClientContext } from "../client/context";
import { getConversationTagsOfTeam } from "../client/conversation-tags";
import { getCurrentTeam } from "../client/team";
import { getUser } from "../client/user";
import { AuthContext } from "./auth";
import {
  ConversationTagsContext,
  ReloadConversationTagsContext,
} from "./conversation-tags-context";
import { ReloadUserContext, UserContext } from "./user";

export const TeamContext = createContext<
  | (Team & {
      platform: "shopify" | "commerce-cloud" | "commentsold";
      updatePermissionsLink?: string;
    })
  | undefined
>(undefined);

export const ReloadTeamContext = createContext<(() => void) | undefined>(
  undefined,
);

export const StorefrontClientContext = createContext<
  ShopifyStorefrontClient | undefined
>(undefined);

export const TeamProvider = memo(function TeamProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const auth = useContext(AuthContext);

  const [teamTrigger, setTeamTrigger] = useState(Symbol());
  const teamLoad = useLoad(
    (signal) =>
      auth ? getCurrentTeam(client, { signal }) : Promise.resolve(undefined),
    [auth, client, teamTrigger],
  );

  const [userTrigger, setUserTrigger] = useState(Symbol());
  const userLoad = useLoad(
    (signal) => {
      const userToReturn = auth
        ? getUser(client, { signal })
        : Promise.resolve(undefined);
      return userToReturn;
    },
    [auth, client, userTrigger],
  );

  const [conversationTagsTrigger, setConversationTagsTrigger] =
    useState(Symbol());
  const conversationTagsLoad = useLoad(
    async (signal) => {
      const teamId = teamLoad.value?.team?._id;
      const teamTags = await (teamId
        ? getConversationTagsOfTeam(client, { teamId }, signal)
        : Promise.resolve([]));
      const tagIds = teamTags.map((tag) => {
        return {
          tagId: tag._id,
          name: tag.name,
          pillTheme: tag.pillTheme,
          source: tag.source,
        };
      });
      return tagIds;
    },
    [auth, client, conversationTagsTrigger, teamLoad],
  );

  const [storefrontClient, setStorefrontClient] =
    useState<ShopifyStorefrontClient>();

  const reloadTeam = useHandler(() => setTeamTrigger(Symbol()));
  const reloadUser = useHandler(() => setUserTrigger(Symbol()));
  const reloadConversationTags = useHandler(() =>
    setConversationTagsTrigger(Symbol()),
  );

  const userID = userLoad.value?._id;
  useEffect(() => {
    amplitude.setUserId(userID);
  }, [userID]);

  const userEmail = userLoad.value?.email;
  useEffect(() => {
    if (typeof userEmail === "string") {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set("email", userEmail);
      amplitude.identify(identifyEvent);
    }
  }, [userEmail]);

  const [currency, setCurrency] = useState<Currency>(DEFAULT_CURRENCY);

  const currencyLoad = useLoad(async () => {
    let usdRatesPromise;
    const shopCurrencyStr: Currency =
      teamLoad.value?.team._shopify.currency || Currency.USD;

    const shopCurrency =
      shopCurrencyStr && isCurrency(shopCurrencyStr || Currency.USD)
        ? shopCurrencyStr
        : Currency.USD;

    const formatCurrency = (amount: number) =>
      CURRENCY_FORMAT(shopCurrency).format(amount);

    if (shopCurrency !== Currency.USD && teamLoad.value?.team.widget_slug) {
      usdRatesPromise = getCurrencyExchangeRatesMerchantAPI(
        teamLoad.value?.team.widget_slug,
        Currency.USD,
      );
    }

    const usdRates = usdRatesPromise ? (await usdRatesPromise).data : undefined;
    const formatCurrencyFromUSD = usdRates
      ? (amount: number) => {
          return CURRENCY_FORMAT(shopCurrency).format(
            amount * usdRates[shopCurrency],
          );
        }
      : (amount: number) => CURRENCY_FORMAT(Currency.USD).format(amount);

    return {
      currency: shopCurrency,
      formatCurrency,
      formatCurrencyFromUSD,
    };
  }, [teamLoad]);

  const teamID = teamLoad.value?.team._id;
  useEffect(() => {
    if (teamID) {
      amplitude.setGroup("team", teamID);
      if (
        teamLoad.value!.team.storeUrl &&
        teamLoad.value!.team.storefrontAccessToken
      ) {
        setStorefrontClient(
          new ShopifyStorefrontClient(
            teamLoad.value!.team.storeUrl,
            teamLoad.value!.team.storefrontAccessToken,
          ),
        );
      }
    }
  }, [teamID]);

  const team = useMemo(
    () =>
      teamLoad.value?.team
        ? {
            ...teamLoad.value.team,
            platform: teamLoad.value.platform,
            updatePermissionsLink: teamLoad.value?.updatePermissionsLink,
          }
        : undefined,
    [teamLoad.value],
  );

  return (
    <TeamContext.Provider value={team}>
      <ReloadTeamContext.Provider value={reloadTeam}>
        <ConversationTagsContext.Provider
          value={conversationTagsLoad.value || []}
        >
          <ReloadConversationTagsContext.Provider
            value={reloadConversationTags}
          >
            <CurrencyContext.Provider
              value={{
                currency:
                  teamLoad.value?.team._shopify.currency ||
                  currencyLoad.value?.currency ||
                  currency,
                formatCurrency:
                  currencyLoad.value?.formatCurrency || default_format_Currency,
                setCurrency,
                formatCurrencyFromUSD:
                  currencyLoad.value?.formatCurrencyFromUSD ||
                  default_format_Currency,
              }}
            >
              <UserContext.Provider value={userLoad.value}>
                <ReloadUserContext.Provider value={reloadUser}>
                  <StorefrontClientContext.Provider value={storefrontClient}>
                    {children}
                  </StorefrontClientContext.Provider>
                </ReloadUserContext.Provider>
              </UserContext.Provider>
            </CurrencyContext.Provider>
          </ReloadConversationTagsContext.Provider>
        </ConversationTagsContext.Provider>
      </ReloadTeamContext.Provider>
    </TeamContext.Provider>
  );
});

export const TeamAndUserRequired = memo(function TeamAndUserRequired({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const team = useContext(TeamContext);
  const user = useContext(UserContext);

  if (!team || !user) {
    return null;
  } else {
    return <>{children}</>;
  }
});
