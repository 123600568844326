import { z } from "zod";

export const getMarketingSubscriberCountsSchema = {
  input: z.null(),
  output: z.object({
    smsSubscriberCount: z.number(),
    emailSubscriberCount: z.number(),
    totalSubscriberCount: z.number(),
  }),
};
