// extracted by mini-css-extract-plugin
export var copyableField = "_76-4";
export var customerBadge = "_76-0";
export var customerInfoTitle = "_76-2";
export var details = "_76-1";
export var dropdownContainer = "_76-5";
export var dropdownImage = "_76-6";
export var editAssigneeForm = "_76-a";
export var editButton = "_76-3";
export var email = "_76-7";
export var hidden = "_76-d";
export var input = "_76-8";
export var saveButton = "_76-b";
export var shopifyIcon = "_76-c";
export var username = "_76-9";