// extracted by mini-css-extract-plugin
export var brand = "_4s-4";
export var compact = "_4s-7";
export var extraSmall = "_4s-8";
export var icon = "_4s-0";
export var large = "_4s-1";
export var medium = "_4s-2";
export var outline = "_4s-9";
export var primaryDark = "_4s-5";
export var primaryLight = "_4s-3";
export var primaryModern = "_4s-6";