import {
  ConversationPlatform,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { MacroStatusToSet, SnoozeDuration } from "@redotech/redo-model/macro";
import { Team } from "@redotech/redo-model/team";
import { RedoMerchantClient } from "../../client";
import { updateConversation } from "../../client/conversations";
import { AddInternalNoteAutomation } from "./macro-automations/add-internal-note";

export interface MacroAutomations {
  statusToSet?: MacroStatusToSet;
  snoozeDuration?: SnoozeDuration;
  tagsToAdd?: string[];
  emailSubjectToChange?: string;
  shouldAddNote?: boolean;
  noteToAddContent?: string;
  noteToAddHtmlContent?: string;
  /** User IDs */
  noteToAddUsersMentioned?: string[];
}

/**
 * If no automations were run, returns undefined
 */
export async function performMacroAutomations(
  client: RedoMerchantClient,
  automations: MacroAutomations,
  conversation: ExpandedConversation,
  team: Team,
  redoDraftMessageId?: string,
): Promise<ExpandedConversation | undefined> {
  if (
    !automations.emailSubjectToChange &&
    !automations.statusToSet &&
    !automations.tagsToAdd &&
    !automations.shouldAddNote
  ) {
    return undefined;
  }

  let newTags: string[] | undefined = undefined;
  if (automations.tagsToAdd?.length) {
    newTags = conversation.tagIds?.map((tag) => tag.name) || [];
    automations.tagsToAdd.forEach((tag) => {
      if (!newTags!.includes(tag)) {
        newTags!.push(tag);
      }
    });
  }

  let newStatus: "open" | "closed" | undefined = undefined;
  if (automations.statusToSet) {
    if (automations.statusToSet !== "snoozed") {
      newStatus = automations.statusToSet;
    }
  }

  let newSubject: string | undefined = undefined;
  if (
    automations.emailSubjectToChange &&
    conversation.platform === ConversationPlatform.EMAIL
  ) {
    newSubject = automations.emailSubjectToChange;
  }

  if (automations.shouldAddNote && redoDraftMessageId) {
    await AddInternalNoteAutomation.perform({
      client,
      conversationId: conversation._id,
      shouldAddNote: automations.shouldAddNote || false,
      redoDraftMessageId: redoDraftMessageId,
    });
  }

  let snoozedUntil: string | undefined = undefined;
  if (automations.snoozeDuration && automations.statusToSet === "snoozed") {
    switch (automations.snoozeDuration) {
      case SnoozeDuration.SAME_DAY: {
        const today = new Date();
        today.setHours(18, 0, 0);
        snoozedUntil = today.toISOString();
        break;
      }
      case SnoozeDuration.ONE_DAY: {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(8, 0, 0);
        snoozedUntil = tomorrow.toISOString();
        break;
      }
      case SnoozeDuration.TWO_DAYS: {
        const twoDays = new Date();
        twoDays.setDate(twoDays.getDate() + 2);
        twoDays.setHours(8, 0, 0);
        snoozedUntil = twoDays.toISOString();
        break;
      }
      case SnoozeDuration.SATURDAY: {
        const nextSaturday = new Date();
        nextSaturday.setDate(
          nextSaturday.getDate() + ((6 + 7 - nextSaturday.getDay()) % 7 || 7),
        );
        nextSaturday.setHours(8, 0, 0);
        snoozedUntil = nextSaturday.toISOString();
        break;
      }
      case SnoozeDuration.MONDAY: {
        const nextMonday = new Date();
        nextMonday.setDate(
          nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7),
        );
        nextMonday.setHours(8, 0, 0);
        snoozedUntil = nextMonday.toISOString();
        break;
      }
    }
  }

  const response = await updateConversation(client, conversation, {
    tags: newTags?.map((tag) => ({ name: tag })) || undefined,
    snoozedUntil,
    status: newStatus,
    subject: newSubject,
  });
  return response.data;
}
