import {
  JsonFormat,
  objectJsonFormat,
  stringJsonFormat,
} from "@redotech/json/format";
import {
  FilterOptionsV2,
  filterOptionsV2JsonFormat,
} from "./conversation-filters";

export interface View {
  _id: string;
  team: string;
  name: string;
  filters: FilterOptionsV2;
  user?: string;
}

export const viewJsonFormat: JsonFormat<View> = objectJsonFormat(
  {
    _id: stringJsonFormat,
    team: stringJsonFormat,
    name: stringJsonFormat,
    filters: filterOptionsV2JsonFormat,
  },
  { user: stringJsonFormat },
);
