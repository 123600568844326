import { RouteObject } from "react-router-dom";

const fullscreen = (url: string) => {
  return url.includes("builder");
};

export const marketingRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Marketing", breadcrumbNavigate: false },
    lazy: async () => {
      const { Page } = await import("../app/page");
      return {
        element: <Page hideHeader={fullscreen} hideNavbar={fullscreen} />,
      };
    },
    children: [
      {
        path: "signups",
        handle: { breadcrumb: "Sign-ups" },
        children: [
          {
            index: true,
            async lazy() {
              const { MarketingSignups } = await import(
                "./signups/signups-page"
              );
              return { Component: MarketingSignups };
            },
          },
          {
            path: "builder/:signupFormId",
            handle: { breadcrumb: "Sign-up Builder" },
            async lazy() {
              const { MarketingSignupBuilder } = await import(
                "./signups/builder"
              );
              return { Component: MarketingSignupBuilder };
            },
          },
        ],
      },
    ],
  },
];
