// extracted by mini-css-extract-plugin
export var chip = "_2a-a";
export var chipLabel = "_2a-b";
export var close = "_2a-8";
export var closeIcon = "_2a-9";
export var container = "_2a-3";
export var error = "_2a-7";
export var infoTooltip = "_2a-2";
export var innerContainer = "_2a-1";
export var input = "_2a-c";
export var medium = "_2a-6";
export var outerContainer = "_2a-0";
export var small = "_2a-5";
export var xSmall = "_2a-4";