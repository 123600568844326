// extracted by mini-css-extract-plugin
export var addButton = "_4w-0";
export var border = "_4w-8";
export var check = "_4w-h";
export var danger = "_4w-6";
export var dropdownContainer = "_4w-1";
export var editModalBody = "_4w-j";
export var footerContainer = "_4w-i";
export var fullWidth = "_4w-a";
export var hide = "_4w-9";
export var icon = "_4w-3";
export var input = "_4w-7";
export var inputFlex = "_4w-b";
export var inputWrapper = "_4w-c";
export var manualFocus = "_4w-5";
export var optionButton = "_4w-4";
export var pillWrapper = "_4w-e";
export var pointer = "_4w-2";
export var showOverflow = "_4w-f";
export var tagsFlex = "_4w-d";
export var xButton = "_4w-g";