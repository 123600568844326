import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import {
  scrubFilterFieldsThatArentStoredInTheDatabase,
  scrubUnsetFilters,
} from "@redotech/redo-model/conversation-filters";
import { CreateViewBody } from "@redotech/redo-model/create-view-body";
import { UpdateViewBody } from "@redotech/redo-model/update-view-body";
import { Flex } from "@redotech/redo-web/flex";
import { HeaderOverrideContext } from "@redotech/redo-web/page";
import { TableRef } from "@redotech/redo-web/table";
import { deepIntersectionEquals } from "@redotech/util/equal";
import * as classNames from "classnames";
import { memo, MutableRefObject, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { TeamContext } from "../../app/team";
import { ReloadUserContext } from "../../app/user";
import { ReloadViewsContext, ViewsContext } from "../../app/views";
import { RedoMerchantClientContext } from "../../client/context";
import { createView, updateView } from "../../client/view";
import { ActiveConversationsTableFilters } from "../conversations-table-filters/active-conversations-table-filters";
import {
  ActiveViewContext,
  EditingViewContext,
  SetEditingViewContext,
  SystemView,
} from "../conversations-table-filters/active-view-context";
import { FilterEditorPageHeader } from "../conversations-table-filters/filter-editor-page-header";
import { FiltersContext } from "../conversations-table-filters/filters-context";
import { ConversationsTableHeaderFilterControls } from "./conversations-table-header-filter-controls";
import { ConversationsTableHeaderSelectedTicketActions } from "./conversations-table-header-selected-ticket-actions";
import { ConversationsTableHeaderViewManagement } from "./conversations-table-header-view-management";
import * as conversationsTableCss from "./conversations-table.module.css";

export const ConversationsTableHeader = memo(function ConversationsTableHeader({
  pageLoaded,
  setActiveConversation,
  setBlockRefresh,
  tableRef,
  scrolled,
}: {
  pageLoaded: boolean;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  setBlockRefresh: (block: boolean) => void;
  tableRef: MutableRefObject<TableRef<ExpandedConversation>>;
  scrolled: { top: boolean };
}) {
  const headerPortal = useContext(HeaderOverrideContext);
  const teamAndUserViews = useRequiredContext(ViewsContext);
  const viewBeingEdited = useContext(EditingViewContext);
  const setViewBeingEdited = useContext(SetEditingViewContext);
  const filters = useContext(FiltersContext);
  const view = useContext(ActiveViewContext);
  const team = useContext(TeamContext);
  const navigate = useNavigate();
  const client = useRequiredContext(RedoMerchantClientContext);
  const reloadViews = useContext(ReloadViewsContext);
  const reloadUser = useRequiredContext(ReloadUserContext);

  function exitViewEditor() {
    const isNew = view._id === SystemView.CREATE;

    if (isNew) {
      navigate(`/stores/${team?._id}/support/table/all`);
    }
    setViewBeingEdited(false);
  }

  useEffect(() => {
    setViewBeingEdited(false);
  }, [view]);

  useEffect(() => {
    if (viewBeingEdited) {
      headerPortal(
        <FilterEditorPageHeader
          activeFilters={filters}
          activeView={view}
          cancel={exitViewEditor}
          save={async (updateBody) => handleUpdateView(updateBody, view._id)}
          userViews={[
            ...(teamAndUserViews.private || []),
            ...(teamAndUserViews.team || []),
          ]}
        />,
      );
    } else {
      headerPortal(null);
    }
    return () => headerPortal(null);
  }, [
    headerPortal,
    viewBeingEdited,
    filters,
    exitViewEditor,
    teamAndUserViews,
    handleUpdateView,
  ]);

  async function handleUpdateView(view: UpdateViewBody, viewId: string) {
    if (viewId === SystemView.CREATE) {
      return handleCreateView(view);
    }

    amplitude.logEvent("update-view");

    await updateView(client, { body: view, viewId });

    reloadViews && reloadViews();
    reloadUser();
    setViewBeingEdited(false);

    const encodedViewName = encodeURIComponent(view.name);

    navigate(`/stores/${team?._id}/support/table/${encodedViewName}`);
  }

  async function handleCreateView(body: CreateViewBody) {
    amplitude.logEvent("create-view");
    await createView(client, body);

    reloadViews && reloadViews();
    reloadUser();

    const encodedViewName = encodeURIComponent(body.name);

    navigate(`/stores/${team?._id}/support/table/${encodedViewName}`);
  }

  const viewMeaningfullyDiffersFromBase = useMemo(() => {
    const scrubbedUnsetFilter = scrubFilterFieldsThatArentStoredInTheDatabase(
      scrubUnsetFilters(filters),
    );
    const storedViewFilters = scrubFilterFieldsThatArentStoredInTheDatabase(
      view.filters,
    );
    return !deepIntersectionEquals(storedViewFilters, scrubbedUnsetFilter);
  }, [view, filters]);

  return (
    <div
      className={classNames(conversationsTableCss.contentHeader, {
        [conversationsTableCss.scrolledTop]: scrolled.top,
      })}
    >
      <Flex dir="column" gap="xl">
        <Flex justify="space-between">
          <ConversationsTableHeaderFilterControls />
          <ConversationsTableHeaderSelectedTicketActions
            pageLoaded={pageLoaded}
            setActiveConversation={setActiveConversation}
            setBlockRefresh={setBlockRefresh}
            tableRef={tableRef}
          />
        </Flex>
        <Flex justify="space-between">
          <ActiveConversationsTableFilters
            viewMeaningfullyDiffersFromBase={viewMeaningfullyDiffersFromBase}
          />
          <ConversationsTableHeaderViewManagement
            handleCreateView={handleCreateView}
            handleUpdateView={handleUpdateView}
            viewMeaningfullyDiffersFromBase={viewMeaningfullyDiffersFromBase}
          />
        </Flex>
      </Flex>
    </div>
  );
});
