import FaceSmile from "@redotech/redo-web/arbiter-icon/face-smile.svg";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { memo, ReactElement } from "react";
import { IconButton } from "../button";
import { Flex } from "../flex";

/**
 * Across the usage of our quill editors, we commonly provide these options. Any that are specific
 * can be passed in as children.
 */
export const QuillToolbarOptions = memo(function QuillToolbarOptions({
  emailMode,
  children,
}: {
  emailMode: boolean;
  children?: ReactElement | ReactElement[];
}) {
  /* We were previously conditionally rendering these, but when switching from a non-email ticket to an email ticket, the icons within the buttons were not loaded. */
  const emailOnlyDisplayStyle = emailMode
    ? undefined
    : {
        display: "none",
      };
  const isMac = /mac/i.test(navigator.userAgent);
  return (
    <Flex align="center" wrap="wrap">
      <span className="ql-formats">
        <select className="ql-font" style={emailOnlyDisplayStyle}>
          <option value="sans-serif">Sans Serif</option>
          <option value="serif">Serif</option>
          <option value="monospace">Monospace</option>
          <option value="arial">Arial</option>
          <option value="courier-new">Courier New</option>
          <option value="georgia">Georgia</option>
          <option value="lucida-sans">Lucida Sans</option>
          <option value="tahoma">Tahoma</option>
          <option value="times-new-roman">Times New Roman</option>
          <option value="verdana">Verdana</option>
        </select>
      </span>
      <select
        className="ql-size"
        defaultValue="16px"
        style={emailOnlyDisplayStyle}
      >
        <option value="12px">Small</option>
        <option value="16px">Normal</option>
        <option value="24px">Large</option>
        <option value="36px">Huge</option>
      </select>
      <select className="ql-color" style={emailOnlyDisplayStyle} />
      <button className="ql-bold" style={emailOnlyDisplayStyle} />
      <button className="ql-italic" style={emailOnlyDisplayStyle} />
      <button className="ql-underline" style={emailOnlyDisplayStyle} />
      <button className="ql-strike" style={emailOnlyDisplayStyle} />
      <select className="ql-align" style={emailOnlyDisplayStyle}>
        <option value="" />
        <option value="center" />
        <option value="right" />
        <option value="justify" />
      </select>

      <button className="ql-link" style={emailOnlyDisplayStyle} />
      <span className="ql-formats">
        <button
          className="ql-list"
          style={emailOnlyDisplayStyle}
          value="ordered"
        />
        <button
          className="ql-list"
          style={emailOnlyDisplayStyle}
          value="bullet"
        />
        <button
          className="ql-indent"
          style={emailOnlyDisplayStyle}
          value="-1"
        />
        <button
          className="ql-indent"
          style={emailOnlyDisplayStyle}
          value="+1"
        />
      </span>
      <button className="ql-image" style={emailOnlyDisplayStyle} />
      <Tooltip arrow title={`Insert emoji (${isMac ? "⌘" : "Ctrl"}+3)`}>
        <IconButton className="rql-emoji">
          <FaceSmile />
        </IconButton>
      </Tooltip>
      {children && children}
    </Flex>
  );
});
