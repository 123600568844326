import {
  FilterGroupFilterOption,
  ReadStatusFilter as ReadStatusFilterI,
} from "@redotech/redo-model/conversation-filters";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
  RedoFilterSection,
} from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown.tsx/redo-single-select-dropdown";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";

export const ReadStatusFilter = memo(function ConversationTagFilter({
  readStatus,
  setReadStatus,
  removeFilter,
}: {
  readStatus: ReadStatusFilterI;
  setReadStatus: (status: ReadStatusFilterI) => void;
  removeFilter(): void;
}) {
  const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

  const query = <RedoFilterSection color="secondary" text="is" />;

  const friendlyValue = (read: boolean | null) => {
    if (read === null) {
      return "...";
    } else if (read) {
      return "Read";
    } else {
      return "Unread";
    }
  };

  const value = (
    <RedoFilterDropdownAnchor
      ref={setValueRef}
      text={friendlyValue(readStatus.value)}
      weight="semibold"
    />
  );

  const valueDropdown = (
    <RedoSingleSelectDropdown
      dropdownButtonRef={valueRef}
      options={[{ value: true }, { value: false }]}
      optionSelected={(read) => setReadStatus({ value: read.value })}
      selectedItem={
        readStatus && readStatus.value !== null
          ? { value: readStatus.value }
          : undefined
      }
      size={RedoListItemSize.SMALL}
    >
      {(item) => (
        <Text
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {friendlyValue(item.value)}
        </Text>
      )}
    </RedoSingleSelectDropdown>
  );

  return (
    <>
      {valueDropdown}
      <RedoFilterGroup
        Icon={ConversationFilterOptionToIcon[FilterGroupFilterOption.READ]}
        propertyName={
          ConversationFilterOptionToName[FilterGroupFilterOption.READ]
        }
        query={query}
        removeFilter={removeFilter}
        value={value}
      />
    </>
  );
});
