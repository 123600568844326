// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_6g-e";
export var baseCustomerActivityCardArrowRightContainer = "_6g-d";
export var baseCustomerActivityCardContainer = "_6g-b";
export var baseCustomerActivityCardContainerHovered = "_6g-c";
export var customerActivityDataClaimIcon = "_6g-7";
export var customerActivityDataClaimIconContainer = "_6g-6";
export var customerActivityDataConversationIcon = "_6g-5";
export var customerActivityDataConversationIconContainer = "_6g-4";
export var customerActivityDataOrderIcon = "_6g-1";
export var customerActivityDataOrderIconContainer = "_6g-0";
export var customerActivityDataReturnIcon = "_6g-3";
export var customerActivityDataReturnIconContainer = "_6g-2";
export var customerActivityShoppingIconContainer = "_6g-8";
export var messageBubble = "_6g-9";
export var messageBubbleText = "_6g-a";