import { useRequiredContext } from "@redotech/react-util/context";
import {
  AssigneesFilterType,
  ConversationTagFilterType,
  FiltersStatus,
} from "@redotech/redo-model/conversation-filters";
import { SortDirection } from "@redotech/redo-model/table";
import { View } from "@redotech/redo-model/view";
import { deepIntersectionEquals } from "@redotech/util/equal";
import {
  createContext,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Location, useLocation } from "react-router-dom";
import { UserContext } from "../../app/user";
import { ViewsContext } from "../../app/views";

/**
 * @param CREATE is a fake view used to create a view.
 * It needs to be namespace reserved because of the way our URLs work at the moment.
 */
export enum SystemView {
  ALL = "all",
  ASSIGNED = "assigned",
  SENT = "sent",
  SPAM = "spam",
  DRAFTS = "drafts",
  CREATE = "create",
  MENTIONS = "mentions",
}

const StaticSystemViews: Record<
  Exclude<SystemView, SystemView.ASSIGNED | SystemView.MENTIONS>,
  View
> = {
  [SystemView.ALL]: {
    _id: "all",
    team: "",
    name: SystemView.ALL,
    filters: {
      status: FiltersStatus.OPEN,
    },
  },
  [SystemView.SENT]: {
    _id: "sent",
    team: "",
    name: SystemView.SENT,
    filters: {
      status: FiltersStatus.OPEN,
      sort: {
        key: "lastMerchantResponse",
        direction: SortDirection.DESC,
      },
    },
  },
  [SystemView.SPAM]: {
    _id: "spam",
    team: "",
    name: SystemView.SPAM,
    filters: {
      status: FiltersStatus.OPEN,
      conversationTags: {
        tagNames: ["spam"],
        type: ConversationTagFilterType.ANY_OF,
      },
    },
  },
  [SystemView.DRAFTS]: {
    _id: "drafts",
    team: "",
    name: SystemView.DRAFTS,
    filters: {
      drafts: true,
    },
  },
  [SystemView.CREATE]: {
    _id: "create",
    team: "",
    name: SystemView.CREATE,
    filters: {},
  },
};

export const ActiveViewContext = createContext<View>(
  StaticSystemViews[SystemView.ALL],
);

export const SetEditingViewContext = createContext<
  Dispatch<SetStateAction<boolean>>
>(() => {});
export const EditingViewContext = createContext<boolean>(false);

export const ActiveViewContextProvider = memo(
  function ActiveViewContextProvider({
    children,
  }: {
    children: ReactNode | ReactNode[];
  }) {
    const user = useRequiredContext(UserContext);
    const views = useRequiredContext(ViewsContext);
    const location: Location = useLocation();

    const [editingView, setEditingView] = useState<boolean>(false);

    const assignedView: View = {
      _id: "assigned",
      team: "",
      name: SystemView.ASSIGNED,
      filters: {
        assignees: {
          assignees: [user._id],
          type: AssigneesFilterType.INCLUDES,
        },
        status: FiltersStatus.OPEN,
      },
    };

    const mentionsView: View = {
      _id: "mentions",
      team: "",
      name: SystemView.MENTIONS,
      filters: {
        mentionsUsers: {
          value: [user._id],
        },
        status: FiltersStatus.OPEN,
      },
    };

    const [currentView, setCurrentView] = useState<View>(
      getViewFromLocation(location, [
        assignedView,
        mentionsView,
        ...(views.private || []),
        ...(views.team || []),
      ]),
    );

    useEffect(() => {
      if (currentView._id === SystemView.CREATE) {
        setEditingView(true);
      }
    }, [currentView]);

    useEffect(() => {
      const newViewToSet = getViewFromLocation(location, [
        assignedView,
        mentionsView,
        ...(views.private || []),
        ...(views.team || []),
      ]);

      if (deepIntersectionEquals(newViewToSet, currentView)) {
        return;
      }

      setCurrentView(newViewToSet);
    }, [location, views, currentView]);

    return (
      <EditingViewContext.Provider value={editingView}>
        <SetEditingViewContext.Provider value={setEditingView}>
          <ActiveViewContext.Provider value={currentView}>
            {children}
          </ActiveViewContext.Provider>
        </SetEditingViewContext.Provider>
      </EditingViewContext.Provider>
    );
  },
);

function getViewFromLocation(location: Location, viewOptions: View[]): View {
  const viewNameMatch = location.pathname.match(/\/support\/table\/(.*)/);
  const rawViewName = viewNameMatch ? viewNameMatch[1] : "";
  const decodedViewName = decodeURIComponent(rawViewName);
  const view = [...viewOptions, ...Object.values(StaticSystemViews)].find(
    (view) => view.name === decodedViewName,
  );

  if (!view) {
    console.log("Cold not determine view from url. Using default view");
  }

  const newViewToSet = view || StaticSystemViews[SystemView.ALL];

  if (!newViewToSet.filters) {
    newViewToSet.filters = {};
  }
  if (!newViewToSet.filters.sort) {
    newViewToSet.filters.sort = {
      key: "lastResponse",
      direction: SortDirection.DESC,
    };
  }

  return { ...newViewToSet };
}
