// extracted by mini-css-extract-plugin
export var active = "_7b-b";
export var arrow = "_7b-8";
export var arrowContainer = "_7b-4";
export var container = "_7b-0";
export var disabled = "_7b-5";
export var dot = "_7b-a";
export var dotContainer = "_7b-9";
export var leftArrowContainer = "_7b-6 _7b-4";
export var rightArrowContainer = "_7b-7 _7b-4";
export var slide = "_7b-3";
export var sliderContainer = "_7b-1";
export var sliderOverflow = "_7b-2";