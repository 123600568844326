import { buttonHierarchies, fontWeights } from "@redotech/redo-model/brand-kit";
import { BaseElementType } from "@redotech/redo-model/content-builder";
import { FONT_FAMILIES } from "@redotech/redo-model/email-builder";
import { buttonActions, inputTypes } from "@redotech/redo-model/signup-form";
import { z } from "zod";

export const RichTextElementSchema = z.object({
  id: z.number(),
  type: z.literal(BaseElementType.RICH_TEXT),
  html: z.string(),
  fontFamily: z.enum(FONT_FAMILIES),
  fontWeight: z.enum(fontWeights),
  fontSizePx: z.number(),
  textColor: z.string(),
  linkColor: z.string(),
});

export const ButtonElementSchema = z.object({
  type: z.literal(BaseElementType.BUTTON),
  hierarchy: z.enum(buttonHierarchies),
  text: z.string(),
  action: z.enum(buttonActions),
});

export const InputElementSchema = z.object({
  type: z.literal(BaseElementType.INPUT),
  inputType: z.enum(inputTypes),
  placeholderText: z.string(),
});

export const ImageElementSchema = z.object({
  type: z.literal(BaseElementType.IMAGE),
  url: z.string(),
});
