export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type NullishPartial<T> = {
  [P in keyof T]?: T[P] | null;
};

export type Optional<T> = T | undefined | null;

export function isString(v: unknown): v is string {
  return typeof v === "string";
}

export function assertNever<T>(value: never): T {
  throw new Error(`Unexpected value: ${value}`);
}

export function isObject(v: unknown): v is Record<string, unknown> {
  return typeof v === "object" && v !== null;
}

export type Tuple<T, TLength extends number> = [T, ...T[]] & {
  length: TLength;
};

/**
 * Lets the compiler know that the value is unused but we are ok with that.
 */
export function sinkValue<T>(value: T): void {
  // noop
}

type BuildRangeTuple<
  Current extends [...number[]],
  Count extends number,
> = Current["length"] extends Count
  ? Current
  : BuildRangeTuple<[number, ...Current], Count>;

type RangeTuple<Count extends number> = BuildRangeTuple<[], Count>;

type BuildRange<
  Current extends number,
  End extends number,
  Accu extends [...number[]],
> = Accu["length"] extends End
  ? Current
  : BuildRange<Current | Accu["length"], End, [number, ...Accu]>;

export type NumberRange<
  StartInclusive extends number,
  EndExclusive extends number,
> = BuildRange<StartInclusive, EndExclusive, RangeTuple<StartInclusive>>;
