// extracted by mini-css-extract-plugin
export var buttons = "_43-0";
export var buttonsActive = "_43-h";
export var cancelConfirm = "_43-1";
export var closeButton = "_43-2";
export var column = "_43-3";
export var container = "_43-4";
export var detailHeader = "_43-6";
export var detailTitle = "_43-7";
export var detailsContent = "_43-5";
export var header = "_43-8";
export var headerWithClose = "_43-9";
export var icon = "_43-a";
export var infoIcon = "_43-c";
export var informationContainer = "_43-b";
export var sideDrawer = "_43-d";
export var sideDrawerOpen = "_43-e";
export var stickyFooter = "_43-f";
export var typeOption = "_43-g";