import { z } from "zod";
import { formBodySchema } from "./form-body-schema";

export const getFormByIdSchema = {
  input: z.object({
    formId: z.string(),
    teamId: z.string(),
  }),
  output: formBodySchema,
};
