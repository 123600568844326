// extracted by mini-css-extract-plugin
export var acitonButtons = "_5r-0";
export var block = "_5r-1";
export var blockContent = "_5r-7";
export var blockDescription = "_5r-8";
export var blockIcon = "_5r-9";
export var blockTitle = "_5r-6";
export var border = "_5r-a";
export var center = "_5r-k";
export var edge = "_5r-b";
export var edgeLabel = "_5r-c";
export var edgePath = "_5r-d";
export var error = "_5r-e";
export var flowchart = "_5r-f";
export var flowchartContent = "_5r-g";
export var highlighted = "_5r-3";
export var hover = "_5r-4";
export var icon = "_5r-h";
export var left = "_5r-l";
export var navButton = "_5r-i";
export var navButtons = "_5r-j";
export var right = "_5r-m";
export var selected = "_5r-2";
export var small = "_5r-5";