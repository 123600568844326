import { FilterGroupFilterOption } from "@redotech/redo-model/conversation-filters";
import {
  RedoButton,
  RedoButtonHierarchy,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { ConversationTableDateFilterGroup } from "@redotech/redo-web/conversation-filters/conversation-date-filter-group";
import { Flex } from "@redotech/redo-web/flex";
import { memo, useContext } from "react";
import * as activeConversationsTableFiltersCss from "./active-conversations-table-filters.css";
import { ActiveViewContext, EditingViewContext } from "./active-view-context";
import { AssigneesFilter } from "./assignees-filter";
import { ChannelsFilter } from "./channels-filter";
import { ConversationMentionsUsersFilter } from "./conversation-mentions-users-filter";
import { ConversationMentionsWordsFilter } from "./conversation-mentions-words-filter";
import { ConversationTagFilter } from "./conversation-tag-filter";
import { CustomerTagsFilter } from "./customer-tags-filter";
import { FiltersContext, SetFiltersContext } from "./filters-context";
import { ReadStatusFilter } from "./read-status-filter";

export const ActiveConversationsTableFilters = memo(
  function ActiveConversationsTableFilters({
    viewMeaningfullyDiffersFromBase,
  }: {
    viewMeaningfullyDiffersFromBase: boolean;
  }) {
    const filters = useContext(FiltersContext);
    const setFilters = useContext(SetFiltersContext);
    const view = useContext(ActiveViewContext);
    const viewBeingEdited = useContext(EditingViewContext);

    return (
      <Flex
        className={activeConversationsTableFiltersCss.filtersContainer}
        wrap="wrap"
      >
        {filters.conversationTags && (
          <ConversationTagFilter
            filter={filters.conversationTags}
            removeFilter={() => {
              setFilters({
                ...filters,
                conversationTags: null,
              });
            }}
            setFilter={(filter) => {
              setFilters({
                ...filters,
                conversationTags: filter,
              });
            }}
          />
        )}
        {filters.channels && (
          <ChannelsFilter
            filter={filters.channels}
            removeFilter={() => {
              setFilters({
                ...filters,
                channels: null,
              });
            }}
            setFilter={(filter) => {
              setFilters({
                ...filters,
                channels: filter,
              });
            }}
          />
        )}
        {filters.customerTags && (
          <CustomerTagsFilter
            filter={filters.customerTags}
            removeFilter={() => {
              setFilters({
                ...filters,
                customerTags: null,
              });
            }}
            setFilter={(filter) => {
              setFilters({
                ...filters,
                customerTags: filter,
              });
            }}
          />
        )}
        {filters.assignees && (
          <AssigneesFilter
            filter={filters.assignees}
            removeFilter={() => {
              setFilters({
                ...filters,
                assignees: null,
              });
            }}
            setFilter={(assignees) => {
              setFilters({
                ...filters,
                assignees,
              });
            }}
          />
        )}
        {filters.read != undefined && (
          <ReadStatusFilter
            readStatus={filters.read}
            removeFilter={() => {
              setFilters({
                ...filters,
                read: null,
              });
            }}
            setReadStatus={(status) => {
              setFilters({
                ...filters,
                read: status,
              });
            }}
          />
        )}
        {filters.createdDate && (
          <ConversationTableDateFilterGroup
            dateFilter={filters.createdDate}
            filterOption={FilterGroupFilterOption.CREATED_DATE}
            removeFilter={() => {
              setFilters({
                ...filters,
                createdDate: null,
              });
            }}
            setDateFilter={(filter) => {
              setFilters({
                ...filters,
                createdDate: filter,
              });
            }}
          />
        )}
        {filters.closedDate && (
          <ConversationTableDateFilterGroup
            dateFilter={filters.closedDate}
            filterOption={FilterGroupFilterOption.CLOSED_DATE}
            removeFilter={() => {
              setFilters({
                ...filters,
                closedDate: null,
              });
            }}
            setDateFilter={(filter) => {
              setFilters({
                ...filters,
                closedDate: filter,
              });
            }}
          />
        )}
        {filters.lastResponseAt && (
          <ConversationTableDateFilterGroup
            dateFilter={filters.lastResponseAt}
            filterOption={FilterGroupFilterOption.LAST_RESPONSE_AT}
            removeFilter={() => {
              setFilters({
                ...filters,
                lastResponseAt: null,
              });
            }}
            setDateFilter={(filter) => {
              setFilters({
                ...filters,
                lastResponseAt: filter,
              });
            }}
          />
        )}
        {filters.mentionsUsers && (
          <ConversationMentionsUsersFilter
            filter={filters.mentionsUsers}
            removeFilter={() => {
              setFilters({
                ...filters,
                mentionsUsers: null,
              });
            }}
            setFilter={(filter) => {
              setFilters({
                ...filters,
                mentionsUsers: filter,
              });
            }}
          />
        )}
        {filters.words && (
          <ConversationMentionsWordsFilter
            removeFilter={() => {
              setFilters({
                ...filters,
                words: null,
              });
            }}
            setWords={(words) => {
              setFilters({
                ...filters,
                words,
              });
            }}
            words={filters.words}
          />
        )}
        {viewMeaningfullyDiffersFromBase && !viewBeingEdited && (
          <RedoButton
            hierarchy={RedoButtonHierarchy.TERTIARY}
            onClick={() => {
              setFilters(view.filters);
            }}
            text="Reset filters"
          />
        )}
      </Flex>
    );
  },
);
