import { Json } from "@redotech/json/json";
import {
  FilterOptionsV2,
  filterOptionsV2JsonFormat,
} from "./conversation-filters";

interface SerializedViewFilterPair {
  viewName: string;
  filters: Json;
}

export interface SerializedGetConversationCountsBody {
  views: SerializedViewFilterPair[];
}

interface ViewFilterPair {
  viewName: string;
  filters: FilterOptionsV2;
}

export interface GetConversationCountsBody {
  views: ViewFilterPair[];
}

export function deserializeGetConversationCountsBody(
  body: SerializedGetConversationCountsBody,
): GetConversationCountsBody {
  return {
    views: body.views.map((filterPair) => ({
      viewName: filterPair.viewName,
      filters: filterOptionsV2JsonFormat.read(filterPair.filters),
    })),
  };
}

export function serializeGetConversationCountsBody(
  body: GetConversationCountsBody,
): SerializedGetConversationCountsBody {
  return {
    views: body.views.map((filterPair) => ({
      viewName: filterPair.viewName,
      filters: filterOptionsV2JsonFormat.write(filterPair.filters),
    })),
  };
}
