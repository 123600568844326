import { array, boolean, object, string, infer as zInfer } from "zod";

const SeoParser = object({
  title: string().optional(),
  description: string().optional(),
});

const ProductVariantParser = object({
  id: string(),
  gid: string(),
  availableForSale: boolean(),
  price: object({
    amount: string(),
    currencyCode: string(),
  }),
  title: string(),
});

export const ProductDetailsParser = object({
  id: string(),
  userSelectedVariantId: string(),
  title: string(),
  description: string(),
  seo: SeoParser.optional(),
  variants: object({
    nodes: array(ProductVariantParser),
  }),
  url: string().optional(),
});

export type ProductVariant = zInfer<typeof ProductVariantParser>;
export type ProductDetails = zInfer<typeof ProductDetailsParser>;
