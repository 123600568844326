import { object, string } from "zod";
import { assistantSettingsSchema } from "./assistant-settings-schema";

export const updateAssistantSettingsSchema = {
  input: object({
    teamId: string(),
    settings: assistantSettingsSchema,
  }),
  output: object({
    assistantSettings: assistantSettingsSchema.optional(),
  }),
};
