// extracted by mini-css-extract-plugin
export var animationContainer = "_67-0";
export var conversationContainer = "_67-1";
export var detailsContent = "_67-2";
export var message = "_67-3";
export var modalContent = "_67-4";
export var modalFooter = "_67-6";
export var modalHeader = "_67-5";
export var name = "_67-7";
export var optionContainer = "_67-8";
export var optionTitle = "_67-9";
export var pillContainer = "_67-a";
export var selectedConversationsContainer = "_67-b";
export var selectedOptionContainer = "_67-c";