import { ConversationTagWithId } from "@redotech/redo-model/conversation";
import {
  ConversationTagFilterType,
  ConversationTagsFilter,
  FilterGroupFilterOption,
} from "@redotech/redo-model/conversation-filters";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoMultiSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown.tsx/redo-multi-select-dropdown";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown.tsx/redo-single-select-dropdown";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useContext, useMemo, useState } from "react";
import { ConversationTagsContext } from "../../app/conversation-tags-context";
import { ConversationTagPill } from "../conversation-tags/conversation-tag-pill";

export const ConversationTagFilter = memo(function ConversationTagFilter({
  filter,
  removeFilter,
  setFilter,
}: {
  filter: ConversationTagsFilter;
  removeFilter(): void;
  setFilter(filter: ConversationTagsFilter): void;
}) {
  const allTags = useContext(ConversationTagsContext);

  const conversationTagFilterType = filter.type;

  const rawConversationTags = new Set(filter.tagNames);
  const selectedTags = allTags.filter((tag) =>
    rawConversationTags.has(tag.name),
  );

  const [queryRef, setQueryRef] = useState<HTMLButtonElement | null>(null);
  const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);
  const query = (
    <RedoFilterDropdownAnchor
      ref={setQueryRef}
      text={tagFilterTypeToText[conversationTagFilterType]}
    />
  );
  const queryDropdown = (
    <RedoSingleSelectDropdown
      dropdownButtonRef={queryRef}
      options={tagQueryOptions}
      optionSelected={(type) => setFilter({ ...filter, type: type.value })}
      selectedItem={tagQueryOptions.find(
        (item) => item.value === conversationTagFilterType,
      )}
      size={RedoListItemSize.SMALL}
    >
      {(item) => (
        <Text
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {tagFilterTypeToText[item.value]}
        </Text>
      )}
    </RedoSingleSelectDropdown>
  );

  const valueText = filter.tagNames
    ? selectedTags.length === 1
      ? "1 Tag"
      : `${selectedTags.length} Tags`
    : "...";

  const value = (
    <RedoFilterDropdownAnchor
      ref={setValueRef}
      text={valueText}
      tooltip={
        selectedTags.length > 0 && (
          <Flex wrap="wrap">
            {selectedTags.map((tag) => (
              <ConversationTagPill key={tag.name} tag={tag} />
            ))}
          </Flex>
        )
      }
      weight="semibold"
    />
  );

  const allTagItems = useMemo<RedoListItem<ConversationTagWithId>[]>(
    () =>
      allTags.map((tag) => ({
        value: tag,
      })),
    [allTags],
  );

  const selectedTagItems: RedoListItem<ConversationTagWithId>[] =
    selectedTags.map((tag) => ({
      value: tag,
    }));

  const valueDropdown = (
    <RedoMultiSelectDropdown
      dropdownButtonRef={valueRef}
      options={allTagItems}
      selectedOptions={selectedTagItems}
      setSelectedOptions={(items) => {
        if (items.length === 0) {
          setFilter({
            ...filter,
            tagNames: null,
          });
        } else {
          setFilter({
            ...filter,
            tagNames: items.map((item) => item.value.name),
          });
        }
      }}
      size={RedoListItemSize.SMALL}
    >
      {(item) => <ConversationTagPill tag={item.value} />}
    </RedoMultiSelectDropdown>
  );

  return (
    <>
      {valueDropdown}
      {queryDropdown}
      <RedoFilterGroup
        Icon={
          ConversationFilterOptionToIcon[
            FilterGroupFilterOption.CONVERSATION_TAGS
          ]
        }
        propertyName={
          ConversationFilterOptionToName[
            FilterGroupFilterOption.CONVERSATION_TAGS
          ]
        }
        query={query}
        removeFilter={removeFilter}
        value={value}
      />
    </>
  );
});

const tagFilterTypeToText: Record<ConversationTagFilterType, string> = {
  [ConversationTagFilterType.ALL_OF]: "have all of",
  [ConversationTagFilterType.ANY_OF]: "include any of",
  [ConversationTagFilterType.NONE_OF]: "include none of",
};

const tagQueryOptions: RedoListItem<ConversationTagFilterType>[] = [
  {
    value: ConversationTagFilterType.ALL_OF,
  },
  {
    value: ConversationTagFilterType.ANY_OF,
  },
  { value: ConversationTagFilterType.NONE_OF },
];
