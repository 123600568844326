// extracted by mini-css-extract-plugin
export var actionButton = "_71-1";
export var actions = "_71-0";
export var copyableField = "_71-2";
export var icon = "_71-3";
export var overflowVisible = "_71-4";
export var pillWrapper = "_71-5";
export var productDetails = "_71-7";
export var productPillsContainer = "_71-8";
export var productTopDetails = "_71-9";
export var products = "_71-6";
export var returnDetails = "_71-a";
export var strong = "_71-b";
export var variantQuantityRow = "_71-c";