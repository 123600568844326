import { useTriggerLoad } from "@redotech/react-util/load";
import { MessageVisibility } from "@redotech/redo-model/conversation";
import { Team } from "@redotech/redo-model/team";
import { GetUser } from "@redotech/redo-model/user";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { filterTruthy } from "@redotech/util/array";
import Quill from "quill";
import { Delta as DeltaType, EmitterSource } from "quill/core";
import { memo, useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { RedoMerchantClient } from "../client";
import { searchDiscountCodes, searchProducts } from "../client/shopify";
import { ShopifyProduct } from "./create-order";
import {
  clearColoringAtCursor,
  formatWithInserts,
} from "./macros/quill-macro-utils";

interface SupportMessageAutocompleteProps {
  team: Team | null;

  /**
   * To allow the autocomplete to be opened, we don't conditionally render it.
   * So, the visible prop is used to control the visibility of the autocomplete.
   */
  visible: boolean;
  setVisible: (visible: boolean) => void;
  quill: Quill | null;
  client: RedoMerchantClient;
  messageVisibility: MessageVisibility;
  productsInMessage: ShopifyProduct[];
  setProductsInMessage: (products: ShopifyProduct[]) => void;
  discountCodesInMessage: string[];
  setDiscountCodesInMessage: (codes: string[]) => void;
  usersMentionedInMessage: { name: string; id: string }[];
  setUsersMentionedInMessage: (users: { name: string; id: string }[]) => void;
  textToInsert: string | undefined;
  setTextToInsert: (text: string | undefined) => void;
  shouldOpenFromExternalTrigger: AutocompleteType | undefined;
  setShouldOpenFromExternalTrigger: (
    type: AutocompleteType | undefined,
  ) => void;
}

const handleMentionAutocompleteChange = ({
  team,
  value,
  valueToPaste,
  usersMentionedInMessage,
  setUsersMentionedInMessage,
  setValueToPaste,
}: {
  team: Team | null | undefined;
  value: string | null;
  valueToPaste: string | undefined;
  /** User IDs */
  usersMentionedInMessage: { name: string; id: string }[];
  setUsersMentionedInMessage: (users: { name: string; id: string }[]) => void;
  setValueToPaste: (value: string | undefined) => void;
}) => {
  const user = team?.users.find((user) => (user.user as GetUser)._id === value);
  if (user) {
    setUsersMentionedInMessage([
      ...usersMentionedInMessage,
      { name: (user.user as GetUser).name, id: (user.user as GetUser)._id },
    ]);
    valueToPaste = `${(user.user as GetUser).name}`;
  }
  setValueToPaste(valueToPaste);
};

export enum AutocompleteType {
  MENTION = "mention",
  DISCOUNT_CODE = "discountCode",
  PRODUCT = "product",
  MACRO = "macro",
}
/**
 * @ is not included because it is a shift key instead of a control key -- it actually gets typed
 */
const autoCompleteTypeToKey: Record<AutocompleteType, string> = {
  [AutocompleteType.MENTION]: "",
  [AutocompleteType.DISCOUNT_CODE]: "%",
  [AutocompleteType.PRODUCT]: "&",
  [AutocompleteType.MACRO]: "#",
};

const characterToAutoCompleteType: Record<string, AutocompleteType> = {
  "@": AutocompleteType.MENTION,
};

export const SupportMessageAutocomplete = memo(
  function SupportMessageAutocomplete({
    team,
    visible,
    setVisible,
    quill,
    client,
    messageVisibility,
    productsInMessage,
    setProductsInMessage,
    discountCodesInMessage,
    setDiscountCodesInMessage,
    usersMentionedInMessage,
    setUsersMentionedInMessage,
    shouldOpenFromExternalTrigger,
    setShouldOpenFromExternalTrigger,
    textToInsert,
    setTextToInsert,
  }: SupportMessageAutocompleteProps) {
    const [shouldInsertText, setShouldInsertText] = useState(false);

    const [autocompleteInfo, setAutocompleteInfo] = useState<{
      top: number;
      left: number;
      indexOfSymbol: number;
      type: AutocompleteType;
    }>({
      top: 0,
      left: 0,
      indexOfSymbol: 0,
      type: AutocompleteType.MENTION,
    });

    const [discountCodeSearch, doDiscountCodeSearch] = useTriggerLoad(
      async (signal) => {
        const responseData = await searchDiscountCodes(client, {
          search: textToInsert!,
          signal,
        });
        return responseData.discountCodes;
      },
    );
    const [productsSearch, doProductsSearch] = useTriggerLoad(
      async (signal) => {
        const responseData = await searchProducts(client, {
          search: textToInsert!,
          signal,
        });
        return responseData.products;
      },
    );

    useEffect(() => {
      if (visible) {
        const input = document.getElementById("autocomplete-input");
        if (input) {
          input.focus();
        }
      }
    }, [visible]);

    const debouncedSearch = useDebounce(textToInsert, 500);
    useEffect(() => {
      if (
        textToInsert &&
        !shouldInsertText &&
        autocompleteInfo.type === AutocompleteType.DISCOUNT_CODE
      ) {
        // Search for discount codes
        doDiscountCodeSearch();
      }
      if (
        textToInsert &&
        !shouldInsertText &&
        autocompleteInfo.type === AutocompleteType.PRODUCT
      ) {
        // Search for products
        doProductsSearch();
      }
    }, [debouncedSearch]);

    useEffect(() => {
      if (!textToInsert || !shouldInsertText) {
        return;
      }

      if (!quill) {
        return;
      }

      quill.insertText(autocompleteInfo.indexOfSymbol, textToInsert);

      setTextToInsert(undefined);
      setShouldInsertText(false);
      closeAutoComplete();

      quill.setSelection(autocompleteInfo.indexOfSymbol + textToInsert.length);
    }, [textToInsert, shouldInsertText]);

    useEffect(() => {
      if (!quill) {
        return;
      }
      const characterHotkeyCallback = (
        delta: DeltaType,
        oldDelta: DeltaType,
        source: EmitterSource,
      ) => {
        if (source === "user") {
          maybePerformCharacterHotkeyAction(delta);
        }
      };

      quill.on(Quill.events.TEXT_CHANGE, characterHotkeyCallback);
      return () => {
        quill.off(Quill.events.TEXT_CHANGE, characterHotkeyCallback);
      };
    }, [quill]);

    /** Apply formatting to users mentioned in message if applicable */
    useEffect(() => {
      if (quill && team) {
        formatWithInserts({
          quill,
          visibility: messageVisibility,
          team,
          discountCodesInMessage: [],
          productsInMessage: [],
        });
        clearColoringAtCursor(quill, quill.getText().length);
      }
    }, [usersMentionedInMessage]);

    function maybePerformCharacterHotkeyAction(delta: DeltaType) {
      const autoCompleteToOpen: AutocompleteType | undefined = filterTruthy(
        delta.ops.map(
          (op) =>
            typeof op.insert === "string" &&
            characterToAutoCompleteType[op.insert],
        ),
      )[0];

      if (autoCompleteToOpen) {
        handleOpenHotkeyMenu(autoCompleteToOpen, { offsetLineStarts: true });
      }
    }

    /**
     * For hotkeys where we actually type a character, we need some 'off by one' logic
     * to paste the autocomplete content correctly
     */
    const handleOpenHotkeyMenu = (
      type: AutocompleteType,
      options?: { offsetLineStarts: boolean },
    ) => {
      if (!quill) {
        return;
      }

      if (
        messageVisibility !== MessageVisibility.INTERNAL &&
        type === AutocompleteType.MENTION
      ) {
        return;
      }
      const range = quill.getSelection();
      if (!range) {
        return;
      }

      let cursorPosition = range.index;

      const needsOffset = ["", "\n"].includes(
        quill.getText(cursorPosition - 1, 1),
      );
      if (options?.offsetLineStarts && needsOffset) {
        cursorPosition += 1;
      }

      const bounds = quill.getBounds(cursorPosition);
      if (!bounds) {
        return;
      }
      setAutocompleteInfo({
        top: bounds.top + 22,
        left: bounds.left + 4,
        indexOfSymbol: cursorPosition,
        type,
      });
      setVisible(true);
    };

    useEffect(() => {
      if (shouldOpenFromExternalTrigger) {
        handleOpenHotkeyMenu(shouldOpenFromExternalTrigger, {
          offsetLineStarts: true,
        });
        setShouldOpenFromExternalTrigger(undefined);
      }
    }, [shouldOpenFromExternalTrigger]);

    const closeAutoComplete = () => {
      setAutocompleteInfo({
        top: 0,
        left: 0,
        indexOfSymbol: 0,
        type: AutocompleteType.MENTION,
      });
      setVisible(false);
      if (quill) {
        quill.focus();
      }
    };

    const getAutocompleteOptions = (): string[] => {
      if (!team) {
        return [];
      }
      switch (autocompleteInfo.type) {
        case AutocompleteType.MENTION:
          return team.users.map((user) => (user.user as GetUser)._id);
        case AutocompleteType.DISCOUNT_CODE:
          return (
            discountCodeSearch.value?.map((discountCode) => discountCode) || []
          );
        case AutocompleteType.PRODUCT:
          return (
            productsSearch.value?.map((product: any) => product.title) || []
          );
        default:
          return [];
      }
    };

    const getPlaceHolderText = () => {
      switch (autocompleteInfo.type) {
        case AutocompleteType.MENTION:
          return "Search users...";
        case AutocompleteType.DISCOUNT_CODE:
          return "Search discount codes...";
        case AutocompleteType.PRODUCT:
          return "Search products...";
      }
      return "Search...";
    };

    const getAutocompleteNoOptionsText = () => {
      if (autocompleteInfo.type === AutocompleteType.DISCOUNT_CODE) {
        return discountCodeSearch.pending
          ? "Searching..."
          : "No discount codes found";
      } else if (autocompleteInfo.type === AutocompleteType.PRODUCT) {
        return productsSearch.pending ? "Searching..." : "No products found";
      }
      return "No options";
    };
    const handleAutocompleteChange = (
      value: string | null,
      type: AutocompleteType,
    ) => {
      if (!value) {
        closeAutoComplete();
        return;
      }
      const specialKey = autoCompleteTypeToKey[type];

      let valueToPaste = value ? `${specialKey}${value}` : undefined;

      if (autocompleteInfo.type === AutocompleteType.MENTION) {
        handleMentionAutocompleteChange({
          team,
          value,
          valueToPaste,
          usersMentionedInMessage,
          setUsersMentionedInMessage,
          setValueToPaste: (value) => {
            valueToPaste = value;
          },
        });
      } else if (autocompleteInfo.type === AutocompleteType.DISCOUNT_CODE) {
        setDiscountCodesInMessage([...discountCodesInMessage, value!]);
      } else if (autocompleteInfo.type === AutocompleteType.PRODUCT) {
        const currentProduct = productsSearch.value?.find(
          (product: ShopifyProduct) => product.title === value!,
        );
        if (currentProduct) {
          setProductsInMessage([...productsInMessage, currentProduct]);
        }
      }

      setTextToInsert(valueToPaste);

      setShouldInsertText(true);
    };

    if (!visible) {
      return null;
    }

    return (
      <Autocomplete
        getLabel={(option) => {
          const user = team?.users.find(
            (user) => (user.user as GetUser)._id === option,
          );
          return user ? (user.user as GetUser).name : option;
        }}
        id="autocomplete-input"
        noOptionsText={getAutocompleteNoOptionsText()}
        onInputChange={(event, newInputValue) => {
          if (event.type === "change") {
            if (!quill) {
              return;
            }
            setTextToInsert(newInputValue);
          } else if (event.type === "blur") {
            setShouldInsertText(true);
          }
        }}
        options={getAutocompleteOptions()}
        placeholder={getPlaceHolderText()}
        style={{
          position: "absolute",
          top: autocompleteInfo.top,
          left: autocompleteInfo.left,
          width: "250px",
          zIndex: 1000,
        }}
        valueChange={(submittedValue) =>
          handleAutocompleteChange(submittedValue, autocompleteInfo.type)
        }
      />
    );
  },
);
