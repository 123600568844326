import { useRequiredContext } from "@redotech/react-util/context";
import {
  FilterGroupFilterOption,
  MentionsUsersFilter,
} from "@redotech/redo-model/conversation-filters";
import { GetUser } from "@redotech/redo-model/user";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
  RedoFilterSection,
} from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoMultiSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown.tsx/redo-multi-select-dropdown";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useMemo, useState } from "react";
import { TeamContext } from "../../app/team";

type MentionableUser = {
  id: string;
  name: string;
};

export const ConversationMentionsUsersFilter = memo(
  function ConversationMentionsUsersFilter({
    filter,
    setFilter,
    removeFilter,
  }: {
    filter: MentionsUsersFilter;
    setFilter(filter: MentionsUsersFilter): void;
    removeFilter(): void;
  }) {
    const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

    const team = useRequiredContext(TeamContext);

    const { value: userIds } = filter;

    const teamUsers = team.users.map((user) => user.user as GetUser);

    const assigneesOptions: MentionableUser[] = useMemo(
      () => [
        ...teamUsers
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((user) => ({
            id: user._id,
            name: user.name,
          })),
      ],
      [team],
    );

    const query = <RedoFilterSection color="secondary" text="include" />;

    const valueText = userIds
      ? userIds.length === 1
        ? "1 User"
        : ` ${userIds.length} Users`
      : "...";

    const value = (
      <RedoFilterDropdownAnchor
        color="secondary"
        ref={setValueRef}
        text={valueText}
        tooltip={
          userIds && (
            <Flex dir="column">
              {userIds.map((userId) => (
                <Text key={userId}>
                  {userToFriendlyUser(userId, teamUsers)}
                </Text>
              ))}
            </Flex>
          )
        }
      />
    );

    const userListOptions: RedoListItem<MentionableUser>[] =
      assigneesOptions.map((assignee) => {
        return {
          value: assignee,
        };
      });

    const selectedUsers: RedoListItem<MentionableUser>[] =
      userListOptions.filter((userItem) =>
        userIds?.includes(userItem.value.id),
      );

    const valueDropdown = (
      <RedoMultiSelectDropdown
        dropdownButtonRef={valueRef}
        options={userListOptions}
        selectedOptions={selectedUsers}
        setSelectedOptions={(users) => {
          if (users.length === 0) {
            setFilter({
              value: null,
            });
          } else {
            const ids = users.map((user) => user.value.id);
            setFilter({
              value: ids,
            });
          }
        }}
        size={RedoListItemSize.SMALL}
      >
        {(item) => (
          <Text
            fontSize="sm"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {item.value.name}
          </Text>
        )}
      </RedoMultiSelectDropdown>
    );

    return (
      <>
        {valueDropdown}
        <RedoFilterGroup
          Icon={
            ConversationFilterOptionToIcon[FilterGroupFilterOption.MENTIONS]
          }
          propertyName={
            ConversationFilterOptionToName[FilterGroupFilterOption.MENTIONS]
          }
          query={query}
          removeFilter={removeFilter}
          value={value}
        />
      </>
    );
  },
);

function userToFriendlyUser(userId: string, teamUsers: GetUser[]): string {
  return teamUsers.find((user) => user._id === userId)?.name || "Unknown user";
}
