import * as amplitude from "@amplitude/analytics-browser";
import {
  AssigneesFilterType,
  ChannelFilterType,
  ConversationTagFilterType,
  CustomerTagsFilterType,
  DateFilterQueryType,
  FilterGroupFilterOption,
  FiltersStatus,
} from "@redotech/redo-model/conversation-filters";
import {
  RedoButton,
  RedoButtonHierarchy,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoMultiSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown.tsx/redo-multi-select-dropdown";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown.tsx/redo-single-select-dropdown";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import FilterLinesIcon from "@redotech/redo-web/arbiter-icon/filter-lines_filled.svg";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { assertNever } from "@redotech/util/type";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { ActiveViewContext } from "../conversations-table-filters/active-view-context";
import { ActiveViewConversationCountsContext } from "../conversations-table-filters/conversation-counts-context";
import {
  FiltersContext,
  SetFiltersContext as UpdateFiltersContext,
} from "../conversations-table-filters/filters-context";
import * as conversationsTableHeaderFilterControls from "./conversations-table-header-filter-controls.module.css";

export const ConversationsTableHeaderFilterControls = memo(
  function ConversationsTableHeaderFilterControls() {
    const [searchString, setSearchString] = useState<string | undefined>(
      undefined,
    );

    const location = useLocation();
    const navigate = useNavigate();

    const conversationCounts = useContext(ActiveViewConversationCountsContext);

    const debouncedSearch = useDebounce(searchString, 500);

    const updateFilters = useContext(UpdateFiltersContext);
    const currentFilters = useContext(FiltersContext);
    const activeView = useContext(ActiveViewContext);

    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);

      const initialSearch = urlParams.get("initialSearch");
      if (initialSearch) {
        urlParams.delete("initialSearch");
        navigate({
          search: urlParams.toString(),
        });
        setSearchString(initialSearch);
      }
    }, [location]);

    useEffect(() => {
      amplitude.logEvent("search-conversation", {
        mode: "view",
        searchString,
        activeView,
      });
      if (currentFilters.search !== debouncedSearch) {
        updateFilters({ ...currentFilters, search: debouncedSearch });
      }
    }, [debouncedSearch, updateFilters, currentFilters]);

    const [filtersDropdownButtonRef, setFiltersDropdownButtonRef] =
      useState<HTMLButtonElement | null>(null);

    const [statusFilterDropdownRef, setStatusFilterDropdownRef] =
      useState<HTMLButtonElement | null>(null);

    function handleToggledFiltersChange(
      filters: RedoListItem<FilterGroupFilterOption>[],
    ) {
      const oldFilters = new Set(selectedOptions.map((option) => option.value));

      const newFilters = new Set(filters.map((option) => option.value));

      const delta: FilterGroupFilterOption[] = [];

      // Items that were added
      filters.forEach((filter) => {
        if (!oldFilters.has(filter.value)) {
          delta.push(filter.value);
        }
      });

      // Items that were removed
      oldFilters.forEach((filter) => {
        if (!newFilters.has(filter)) {
          delta.push(filter);
        }
      });

      delta.forEach((filter) => {
        toggleSingleFilter(filter);
      });
    }

    function toggleSingleFilter(filter: FilterGroupFilterOption) {
      switch (filter) {
        case FilterGroupFilterOption.ASSIGNEES:
          if (currentFilters.assignees) {
            updateFilters({ ...currentFilters, assignees: null });
          } else {
            updateFilters({
              ...currentFilters,
              assignees: {
                assignees: null,
                type: AssigneesFilterType.INCLUDES,
              },
            });
          }
          break;
        case FilterGroupFilterOption.CHANNELS:
          if (currentFilters.channels) {
            updateFilters({ ...currentFilters, channels: null });
          } else {
            updateFilters({
              ...currentFilters,
              channels: { channels: null, type: ChannelFilterType.INCLUDES },
            });
          }
          break;
        case FilterGroupFilterOption.READ:
          if (currentFilters.read) {
            updateFilters({ ...currentFilters, read: null });
          } else {
            updateFilters({ ...currentFilters, read: { value: null } });
          }
          break;
        case FilterGroupFilterOption.CONVERSATION_TAGS:
          if (currentFilters.conversationTags) {
            updateFilters({ ...currentFilters, conversationTags: null });
          } else {
            updateFilters({
              ...currentFilters,
              conversationTags: {
                tagNames: null,
                type: ConversationTagFilterType.ANY_OF,
              },
            });
          }
          break;
        case FilterGroupFilterOption.WORDS:
          if (currentFilters.words) {
            updateFilters({ ...currentFilters, words: null });
          } else {
            updateFilters({ ...currentFilters, words: { value: null } });
          }
          break;
        case FilterGroupFilterOption.CUSTOMER_TAGS:
          if (currentFilters.customerTags) {
            updateFilters({ ...currentFilters, customerTags: null });
          } else {
            updateFilters({
              ...currentFilters,
              customerTags: {
                tagNames: null,
                type: CustomerTagsFilterType.ANY_OF,
              },
            });
          }
          break;
        case FilterGroupFilterOption.CREATED_DATE:
          if (currentFilters.createdDate) {
            updateFilters({ ...currentFilters, createdDate: null });
          } else {
            updateFilters({
              ...currentFilters,
              createdDate: {
                queryType: DateFilterQueryType.WITHIN,
                timeFrame: null,
              },
            });
          }
          break;
        case FilterGroupFilterOption.LAST_RESPONSE_AT:
          if (currentFilters.lastResponseAt) {
            updateFilters({ ...currentFilters, lastResponseAt: null });
          } else {
            updateFilters({
              ...currentFilters,
              lastResponseAt: {
                queryType: DateFilterQueryType.WITHIN,
                timeFrame: null,
              },
            });
          }
          break;
        case FilterGroupFilterOption.CLOSED_DATE:
          if (currentFilters.closedDate) {
            updateFilters({ ...currentFilters, closedDate: null });
          } else {
            updateFilters({
              ...currentFilters,
              closedDate: {
                queryType: DateFilterQueryType.WITHIN,
                timeFrame: null,
              },
            });
          }
          break;
        case FilterGroupFilterOption.MENTIONS:
          if (currentFilters.mentionsUsers) {
            updateFilters({ ...currentFilters, mentionsUsers: null });
          } else {
            updateFilters({
              ...currentFilters,
              mentionsUsers: { value: null },
            });
          }
          break;
        default:
          assertNever(filter);
      }
    }

    const options: RedoListItem<FilterGroupFilterOption>[] = Object.values(
      FilterGroupFilterOption,
    ).map((value) => ({ value }));

    const selectedOptions = useMemo(() => {
      const filters: FilterGroupFilterOption[] = [];

      Object.values(FilterGroupFilterOption).forEach((value) => {
        switch (value) {
          case FilterGroupFilterOption.ASSIGNEES:
            if (currentFilters.assignees) {
              filters.push(value);
            }
            break;
          case FilterGroupFilterOption.CHANNELS:
            if (currentFilters.channels) {
              filters.push(value);
            }
            break;
          case FilterGroupFilterOption.READ:
            if (currentFilters.read) {
              filters.push(value);
            }
            break;
          case FilterGroupFilterOption.CONVERSATION_TAGS:
            if (currentFilters.conversationTags) {
              filters.push(value);
            }
            break;
          case FilterGroupFilterOption.WORDS:
            if (currentFilters.words) {
              filters.push(value);
            }
            break;
          case FilterGroupFilterOption.CUSTOMER_TAGS:
            if (currentFilters.customerTags) {
              filters.push(value);
            }
            break;
          case FilterGroupFilterOption.CREATED_DATE:
            if (currentFilters.createdDate) {
              filters.push(value);
            }
            break;
          case FilterGroupFilterOption.LAST_RESPONSE_AT:
            if (currentFilters.lastResponseAt) {
              filters.push(value);
            }
            break;
          case FilterGroupFilterOption.CLOSED_DATE:
            if (currentFilters.closedDate) {
              filters.push(value);
            }
            break;
          case FilterGroupFilterOption.MENTIONS:
            if (currentFilters.mentionsUsers) {
              filters.push(value);
            }
            break;

          default:
            assertNever(value);
        }
      });

      return filters.map((value) => ({ value }));
    }, [currentFilters]);

    const filtersDropdown = (
      <RedoMultiSelectDropdown
        dropdownButtonRef={filtersDropdownButtonRef}
        options={options}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleToggledFiltersChange}
        size={RedoListItemSize.SMALL}
      >
        {(option) => {
          const Icon = ConversationFilterOptionToIcon[option.value];
          return (
            <Flex align="center">
              <Flex
                className={
                  conversationsTableHeaderFilterControls.filterDropdownIcon
                }
              >
                <Icon />
              </Flex>
              <Text fontSize="xs">
                {ConversationFilterOptionToName[option.value]}
              </Text>
            </Flex>
          );
        }}
      </RedoMultiSelectDropdown>
    );

    const statusOptions: RedoListItem<FiltersStatus | "all">[] = [
      { value: FiltersStatus.OPEN },
      { value: FiltersStatus.CLOSED },
      { value: FiltersStatus.SNOOZED },
      { value: "all" },
    ];

    const statusDropdown = (
      <RedoSingleSelectDropdown
        dropdownButtonRef={statusFilterDropdownRef}
        options={statusOptions}
        optionSelected={(status: RedoListItem<FiltersStatus | "all">) => {
          updateFilters({
            ...currentFilters,
            status: status.value === "all" ? null : status.value,
          });
        }}
        selectedItem={
          currentFilters.status
            ? { value: currentFilters.status }
            : { value: "all" }
        }
        size={RedoListItemSize.SMALL}
      >
        {(option: RedoListItem<FiltersStatus | "all">) => {
          return <Text fontSize="xs">{getItemDisplay(option.value)}</Text>;
        }}
      </RedoSingleSelectDropdown>
    );

    function getItemDisplay(status: FiltersStatus | "all"): string {
      const count = conversationCounts
        ? conversationCounts[`${activeView.name}-${status}`] ?? 0
        : 0;
      if (status === "all") {
        return `All (${count})`;
      } else if (status === FiltersStatus.OPEN) {
        return `Open (${count})`;
      } else if (status === FiltersStatus.CLOSED) {
        return `Closed (${count})`;
      } else if (status === FiltersStatus.SNOOZED) {
        return `Snoozed (${count})`;
      } else {
        return assertNever(status);
      }
    }

    return (
      <>
        {filtersDropdown}
        {statusDropdown}
        <Flex dir="column" gap="lg">
          <Flex gap="md">
            <RedoTextInput
              placeholder="Search"
              setValue={setSearchString}
              value={searchString || ""}
            />
            <RedoButton
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconLeading={FilterLinesIcon}
              onClick={() => {}}
              ref={setFiltersDropdownButtonRef}
            />
            <RedoButton
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconTrailing={ChevronDownIcon}
              onClick={() => {}}
              ref={setStatusFilterDropdownRef}
              text={getItemDisplay(currentFilters.status || "all")}
            />
          </Flex>
        </Flex>
      </>
    );
  },
);
