import {
  ActionType,
  ContentType,
} from "@redotech/redo-model/concierge-conversation/concierge-conversation-message-definition";
import { array, coerce, nativeEnum, number, object, record, string } from "zod";

export const getConciergeConversationMetricsSchema = {
  input: object({
    teamId: string(),
    startDate: coerce.date().optional(),
    endDate: coerce.date().optional(),
  }).strict(),
  output: object({
    totalCustomerMessages: number(),
    totalConversations: number(),
    groupedCustomerActions: array(
      object({
        contentType: nativeEnum(ContentType),
        actionType: nativeEnum(ActionType),
        count: number(),
      }),
    ),
    conversationCustomerActions: record(
      string(),
      array(
        object({
          contentType: nativeEnum(ContentType),
          actionType: nativeEnum(ActionType),
          count: number(),
        }),
      ),
    ),
  }),
};
