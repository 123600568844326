import { ButtonHierarchy, FontWeight } from "./brand-kit";
import { FontFamily } from "./email-builder";
import { ButtonAction, InputType } from "./signup-form";

export enum BaseElementType {
  RICH_TEXT = "rich-text",
  BUTTON = "button",
  INPUT = "input",
  IMAGE = "image",
}

export interface RichTextElement {
  id: number;
  type: BaseElementType.RICH_TEXT;
  html: string;
  fontFamily: FontFamily;
  fontWeight: FontWeight;
  fontSizePx: number;
  textColor: string;
  linkColor: string;
}

export interface ButtonElement {
  type: BaseElementType.BUTTON;
  hierarchy: ButtonHierarchy;
  text: string;
  action: ButtonAction;
}

export interface InputElement {
  type: BaseElementType.INPUT;
  inputType: InputType;
  placeholderText: string;
}

export interface ImageElement {
  type: BaseElementType.IMAGE;
  url: string;
}
