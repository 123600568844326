// extracted by mini-css-extract-plugin
export var createdDate = "_6o-1";
export var divider = "_6o-2";
export var headerDivider = "_6o-3";
export var messagesCard = "_6o-0";
export var messagesContainer = "_6o-7";
export var messagesContainerWrapper = "_6o-4";
export var pointerCapturingButtonBoxes = "_6o-b";
export var showLeftPanelButton = "_6o-a";
export var showMoreButton = "_6o-d";
export var showMoreButtonContainer = "_6o-c";
export var summaryTitle = "_6o-6";
export var systemMessageCardWrapper = "_6o-e";
export var topButtons = "_6o-9";
export var typingIndicator = "_6o-8";
export var withPadding = "_6o-5";