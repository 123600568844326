import { useRequiredContext } from "@redotech/react-util/context";
import * as classNames from "classnames";
import Quill from "quill";
import QuillResizeImage from "quill-resize-image";
import { Delta, Range } from "quill/core";
import * as React from "react";
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  BaseEmoji,
  CustomEmoji,
  EmojiPicker,
  EmojiPickerCloseCause,
} from "../emoji-picker";
import { ThemeContext } from "../theme-provider";
import * as quillEditorCss from "./quill-editor.module.css";
import { QuillLink } from "./quill-link";

export const quillFakeCursorPadding = 2;

export type OnEditorChangeArgs =
  | ["text-change", Delta, Delta, string]
  | ["selection-change", Range, Range, string];

export type QuillEditorProps = {
  /** Either the toolbar element's id (without #) or an HTMLDivElement
   * referring to the toolbar directly. It's useful to pass as an element
   * to allow Quill to reinitialize the toolbar when the toolbar re-renders.
   */
  toolbar?: string | HTMLDivElement;
  readOnly?: boolean;
  defaultValue?: any;

  /** Called when the text changes. https://quilljs.com/docs/api#text-change */
  onTextChange?: (delta: Delta, oldContents: Delta, source: string) => void;

  /** Called when the selection changes. https://quilljs.com/docs/api#selection-change */
  onSelectionChange?: (...args: any[]) => void;

  /** Called when the selection or text changes, even if they changed silently,
   * as in the case of the selection-change event fired after a text-change.
   * https://quilljs.com/docs/api#editor-change */
  onEditorChange?: (args: OnEditorChangeArgs) => void;

  placeholder?: string;
  disabled?: boolean;
  setDisabled?: (disabled: boolean) => void;

  cursorIndexRef?: React.MutableRefObject<number | undefined>;
  setEmojiPickerOpenRef?: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<boolean>> | undefined
  >;

  /** HTML element to anchor the emoji picker to. If not provided, the emoji picker will be anchored
   * to the cursor if opened via hotkey and to the emoji button if opened via click.
   */
  emojiPickerAnchorOverride?: HTMLElement | null;

  /**
   * To make font size portable, you may want the output of the editor to be inline styles instead of classes.
   * Everything except the settings -> email builder's text field works this way.
   */
  inlineFontSizeStyles?: boolean;

  /**
   * Class name to apply to the editor container.
   */
  editorClassName?: string;
};

// quill editor v2 internally displays all lists as <ol> elements, then converts them to <ul> when we callGetSemanticHTML()
// when we plug them back into quill after saving them to the db, we need to convert back to <ol> elements with data-list="bullet" attribute
// see https://github.com/slab/quill/issues/3957
export function convertToQuillHTML(html: string) {
  const container = document.createElement("div");
  container.innerHTML = html;
  const ulElements = container.querySelectorAll("ul");
  ulElements.forEach((ul) => {
    const ol = document.createElement("ol");
    const liElements = ul.querySelectorAll("li");
    liElements.forEach((li) => {
      const newLi = document.createElement("li");
      newLi.setAttribute("data-list", "bullet");
      newLi.innerHTML = li.innerHTML;
      ol.appendChild(newLi);
    });
    ul.replaceWith(ol);
  });
  return container.innerHTML;
}

/**
 * The text area alongside a quill instance that fired text changed callbacks.
 * Customize your editor's behavior by grabbing the quill instance from the ref.
 */
export const QuillEditor = forwardRef<Quill, QuillEditorProps>(
  function QuillEditor(
    {
      toolbar,
      readOnly,
      defaultValue,
      onTextChange,
      onSelectionChange,
      onEditorChange,
      placeholder,
      disabled,
      setDisabled,

      cursorIndexRef,
      setEmojiPickerOpenRef,

      inlineFontSizeStyles: inlineFontStyles = true,
      editorClassName,
      emojiPickerAnchorOverride,
    }: QuillEditorProps,
    ref: ForwardedRef<Quill>,
  ) {
    const containerRef = useRef(null);
    const fakeCursorRef = useRef<HTMLDivElement>(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const onEditorChangeRef = useRef(onEditorChange);

    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    setEmojiPickerOpenRef &&
      (setEmojiPickerOpenRef.current = setEmojiPickerOpen);
    const emojiButtonRef = useRef<HTMLButtonElement | null>(null);
    const [emojiPickerOpenedWithHotkey, setEmojiPickerOpenedWithHotkey] =
      useState(false);
    const { theme } = useRequiredContext(ThemeContext);

    const [quill, setQuill] = useState<Quill | null>(null);
    const [cursorIndex, setCursorIndex] = useState(0);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
      onEditorChangeRef.current = onEditorChange;
    });

    useEffect(() => {
      if (containerRef.current == null) {
        return;
      }
      const container = containerRef.current as HTMLElement;
      const Font: any = Quill.import("formats/font");
      Font.whitelist = [
        "monospace",
        "serif",
        "sans-serif",
        "arial",
        "courier-new",
        "georgia",
        "lucida-sans",
        "tahoma",
        "times-new-roman",
        "verdana",
      ];
      Quill.register(Font, true);

      // Use inline styling for font size instead of classes
      if (inlineFontStyles) {
        const SizeStyle: any = Quill.import("attributors/style/size");
        SizeStyle.whitelist = ["12px", "16px", "24px", "36px"];
        Quill.register(SizeStyle, true);
      }

      Quill.register("modules/resize", QuillResizeImage as any);
      Quill.register(QuillLink, true);

      const quill = new Quill(container, {
        placeholder: readOnly ? "" : placeholder || "",
        theme: "snow",
        readOnly: !!readOnly,
        modules: {
          toolbar: !toolbar
            ? false
            : typeof toolbar === "string"
              ? {
                  container: `#${toolbar}`,
                }
              : toolbar,
          resize: {
            locale: {},
          },
          clipboard: {
            matchers: [
              [
                "img",
                (node: HTMLImageElement, delta: Delta) => {
                  if (node.style.width) {
                    delta.ops[0].attributes = delta.ops[0].attributes || {};
                    delta.ops[0].attributes.width = node.style.width;
                  }
                  if (node.style.height) {
                    delta.ops[0].attributes = delta.ops[0].attributes || {};
                    delta.ops[0].attributes.height = node.style.height;
                  }
                  return delta;
                },
              ],
            ],
          },
        },
      });
      setQuill(quill);
      cursorIndexRef && (cursorIndexRef.current = 0);

      if (ref) {
        if (typeof ref === "function") {
          ref(quill);
        } else {
          ref.current = quill;
        }
      }

      const toolbarElement =
        typeof toolbar === "string"
          ? document.querySelector(`#${toolbar}`)
          : toolbar;
      const emojiButton = toolbar
        ? toolbarElement?.querySelector(`.rql-emoji`)
        : undefined;
      if (emojiButton) {
        emojiButtonRef.current = emojiButton as HTMLButtonElement;
        emojiButton.addEventListener("click", () =>
          setEmojiPickerOpen((emojiPickerOpen) => {
            if (!emojiPickerOpen) setEmojiPickerOpenedWithHotkey(false);
            return !emojiPickerOpen;
          }),
        );
      }

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      quill.on(Quill.events.EDITOR_CHANGE, (...args) => {
        handleEditorChange(args);
        onEditorChangeRef.current?.(args);
        if (setDisabled) {
          setDisabled(quill.getLength() <= 1);
        }
      });

      return () => {
        if (typeof ref === "function") {
          ref(null);
        } else if (ref) {
          ref.current = null;
        }
        container.innerHTML = "";
        quill.off(Quill.events.TEXT_CHANGE);
        quill.off(Quill.events.SELECTION_CHANGE);
        quill.off(Quill.events.EDITOR_CHANGE);
      };
    }, [ref, readOnly, toolbar]);

    function handleEditorChange([name, ...rest]: OnEditorChangeArgs) {
      if (name === "selection-change") {
        const range = rest[0] as Range;
        if (range?.index !== undefined) {
          cursorIndexRef && (cursorIndexRef.current = range.index);
          setCursorIndex(range.index);
        }
      }
    }

    function handleEmojiSelected(emoji: BaseEmoji | CustomEmoji) {
      if (!("native" in emoji)) return; // Rule out CustomEmoji

      if (!quill) return;
      const selection = quill.getSelection();

      if (selection && selection.length) {
        quill.deleteText(cursorIndex, selection.length);
      }
      quill.insertText(cursorIndex, emoji.native, Quill.sources.USER);

      const newCursorIndex = cursorIndex + emoji.native.length;
      quill.setSelection(newCursorIndex, 0);
      cursorIndexRef && (cursorIndexRef.current = newCursorIndex);
      setCursorIndex(newCursorIndex);
    }

    function handleHotkey(event: React.KeyboardEvent) {
      if (!event.ctrlKey && !event.metaKey) {
        return;
      }
      if (event.key === "3") {
        setEmojiPickerOpenedWithHotkey(true);
        setEmojiPickerOpen(true);
        event.preventDefault();
      }
    }

    function closeEmojiPicker(cause: EmojiPickerCloseCause) {
      setEmojiPickerOpenedWithHotkey(false);
      setEmojiPickerOpen(false);
      if (cause !== "clickOutside") {
        quill?.focus();
      }
    }

    return (
      <div className={quillEditorCss.container}>
        <div
          className={classNames(quillEditorCss.fakeCursor, {
            [quillEditorCss.active]: emojiPickerOpen,
          })}
          ref={fakeCursorRef}
          style={{
            padding: quillFakeCursorPadding + "px",
            left:
              (quill?.getBounds(cursorIndex)?.left || 0) -
              quillFakeCursorPadding,
            top:
              (quill?.getBounds(cursorIndex)?.top || 0) -
              quillFakeCursorPadding,
            width: 1 + quillFakeCursorPadding * 2 + "px",
            height:
              (quill?.getBounds(cursorIndex)?.height || 0) +
              quillFakeCursorPadding * 2 +
              "px",
          }}
        />
        <EmojiPicker
          anchor={
            emojiPickerAnchorOverride ||
            (emojiPickerOpenedWithHotkey
              ? fakeCursorRef.current
              : emojiButtonRef.current)
          }
          handleEmojiSelected={handleEmojiSelected}
          onClose={closeEmojiPicker}
          open={emojiPickerOpen}
          theme={theme}
        />
        <div
          className={editorClassName}
          onKeyDown={handleHotkey}
          ref={containerRef}
        />
      </div>
    );
  },
);
