import type { Jsonified } from "@redotech/json/json";
import type { ObjectId } from "bson";
import type { ConversationTagSource } from "./conversation-tags/conversation-tag-record";
import type { PillTheme } from "./pill-theme";
import type { EmailReplyType } from "./support/conversations/email-info";

export interface ConversationTag {
  name: string;
  pillTheme?: PillTheme;
}

export interface ConversationTagWithId {
  tagId: string;
  name: string;
  pillTheme: PillTheme;
  source: ConversationTagSource;
}

export interface BackendConversationFile {
  _id?: ObjectId;
  url: string;
  description?: string | null;
  mimeType?: string | null;
  originalContentId?: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface ConversationFile {
  _id: string;
  url: string;
  description?: string | undefined;
  mimeType?: string | undefined;
  originalContentId?: string | undefined;
  createdAt: string;
  updatedAt: string;
}

export interface InstagramCommentInfo {
  commentId?: string;
  mediaId?: string;
  username?: string;
  igScopedUserId?: string;
  hasBeenRepliedTo?: boolean;
}

export interface FacebookCommentInfo {
  commentId?: string;
  postId?: string;
  facebookName?: string;
  pageScopedId?: string;
  hasBeenRepliedTo?: boolean;
  deleted?: boolean;
}

export const textForDeletedFacebookComment = "<Message deleted in Facebook>";

export interface InstagramCommentThreadInfo {
  topLevelCommentId: string;
  mediaId: string;
  mediaPreviewUrl?: string;
  permalink?: string;
  redoMessageIdOfRepliedComment?: string;
  redoConversationIdOfRepliedComment?: string;
}

export interface FacebookCommentThreadInfo {
  topLevelCommentId: string;
  postId: string;
  permalink: string;
  redoMessageIdOfRepliedComment?: string;
  redoConversationIdOfRepliedComment?: string;
}

export interface ConversationMessageEmailData {
  textBody: string;
  htmlBody: string;
  messageId?: string;
  gmailMessageId: string;
  outlookMessageId: string;
  from: { email: string; name: string };
  to: [{ email: string; name: string }];
  cc?: [{ email?: string; name?: string }];
  bcc?: [{ email?: string; name?: string }];
}

export interface ConversationEmailData {
  kind: string;
  email: string;
  gmailThreadId: string;
  outlookConversationId: string;
}

export interface ConversationMessageInstagramData {
  messageId?: string;
  repliedStoryUrl?: string;
  mentionedStoryUrl?: string;
  comment?: InstagramCommentInfo;
  privateRepliedComment?: {
    commentId: string;
    commentText: string;
    permalink?: string;
  };
}

export interface ConversationMessageFacebookData {
  messageId?: string;
  comment?: FacebookCommentInfo;
  privateRepliedComment?: {
    commentId: string;
    commentText: string;
    permalink?: string;
  };
}

export enum MessageVisibility {
  PUBLIC = "public",
  INTERNAL = "internal",
}

export interface ConversationMessageDocument {
  _id: ObjectId;
  aiUsedOnTicketAt: Date;
  aiMessageSuggestionAddedToBilling: boolean;
  customer: any;
  user: ObjectId;
  type: "merchant" | "customer" | "system";
  visibility: MessageVisibility;
  content: string;
  chatFlow?: {
    htmlContent?: string;
  };
  sentAt: Date;
  createdAt: Date;
  updatedAt: Date;
  files: [ConversationFile];
  email?: ConversationMessageEmailData;
  instagram?: ConversationMessageInstagramData;
  facebook?: ConversationMessageFacebookData;
  externalOrigin?: boolean;
  orderList?: boolean;
  aiGenerations?: { text: string }[];
  draftInfo?: {
    emailDraftInfo: {
      inReplyTo: string;
      replyType: EmailReplyType;
      draftHtmlBody?: string;
    };
    isDraft: boolean;
  };
}

export type ConversationMessage = Jsonified<ConversationMessageDocument>;

export type ExpandedConversationMessageDoc = Omit<
  ConversationMessageDocument,
  "user"
> & { user: any };

/** Same as ConversationMessages except that user is of type any */
export type ExpandedConversationMessage =
  Jsonified<ExpandedConversationMessageDoc>;

export enum ConversationPlatform {
  EMAIL = "email",
  REDO_CHAT = "redoChat",
  FACEBOOK = "facebook",
  FACEBOOK_COMMENTS = "facebookComments",
  INSTAGRAM = "instagram",
  INSTAGRAM_COMMENTS = "instagramComments",
  SMS = "sms",
  ATTENTIVE = "attentive",
  POSTSCRIPT = "postscript",
}

export const ActiveConversationPlatforms = [
  ConversationPlatform.EMAIL,
  ConversationPlatform.REDO_CHAT,
  ConversationPlatform.FACEBOOK,
  ConversationPlatform.FACEBOOK_COMMENTS,
  ConversationPlatform.INSTAGRAM,
  ConversationPlatform.INSTAGRAM_COMMENTS,
  ConversationPlatform.ATTENTIVE,
  ConversationPlatform.POSTSCRIPT,
];

export const ConversationPlatformString: Record<ConversationPlatform, string> =
  {
    [ConversationPlatform.EMAIL]: "Email",
    [ConversationPlatform.REDO_CHAT]: "Chat",
    [ConversationPlatform.FACEBOOK]: "Facebook Messenger",
    [ConversationPlatform.FACEBOOK_COMMENTS]: "Facebook comments",
    [ConversationPlatform.INSTAGRAM]: "Instagram DM",
    [ConversationPlatform.INSTAGRAM_COMMENTS]: "Instagram comments",
    [ConversationPlatform.SMS]: "SMS",
    [ConversationPlatform.ATTENTIVE]: "Attentive",
    [ConversationPlatform.POSTSCRIPT]: "Postscript",
  };

export enum ConversationStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  SNOOZED = "SNOOZED",
}

function isSnoozed(snoozedUntil: string | undefined): boolean {
  if (snoozedUntil) {
    const snoozeDate = new Date(snoozedUntil);
    const now = new Date();
    return now < snoozeDate;
  } else {
    return false;
  }
}

export function getConversationStatus(
  conversation: Conversation | ExpandedConversation,
) {
  if (conversation.status === "closed") {
    return ConversationStatus.CLOSED;
  } else if (isSnoozed(conversation.snoozedUntil)) {
    return ConversationStatus.SNOOZED;
  } else {
    return ConversationStatus.OPEN;
  }
}

interface ConversationCustomerInstagram {
  igScopedUserId: string;
  username?: string;
  name?: string;
  profilePic?: string;
}

interface ConversationCustomerFacebook {
  pageScopedId: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  profilePic?: string;
}

interface CustomerSupportCommunicationConsent {
  textMessages: {
    optInDate?: string;
    optOutDate?: string;
  };
}

export interface ConversationCustomer {
  customer: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  shopifyCustomerId?: string;
  image?: { url: string };
  phone?: string;
  instagram?: ConversationCustomerInstagram;
  facebook?: ConversationCustomerFacebook;
  supportCommunicationConsent?: CustomerSupportCommunicationConsent;
}

export interface ConversationDocument {
  _id: string;
  emailMessageId: string;
  ticketNumber: number;
  platform: ConversationPlatform;
  originalPlatform?: ConversationPlatform;
  email?: ConversationEmailData;
  team: any;
  aiUsedOnTicketAt: Date;
  aiMessageSuggestionAddedToBilling: boolean;
  customer: ConversationCustomer;
  assignee: any;
  status: string;
  lastResponseAt: Date;
  lastCustomerResponseAt: Date;
  lastMerchantResponseAt?: Date;
  lastKnownStepId?: number | null;
  snoozedUntil?: Date;
  expiredAt?: Date;
  tagIds?: [ConversationTagWithId];
  files: [ConversationFile];
  messages: [ConversationMessage, ...ConversationMessage[]];
  createdAt: Date;
  updatedAt: Date;
  closedAt: Date;
  subject: string;
  ccEmails?: [string];
  bccEmails?: [string];
  toEmails?: [{ email: string; name: string }];
  archived: boolean;
  archivedAt: Date;
  toObject?: Function;
  carts: string[];
  embedding: number[];
  offeringConvertToEmail?: boolean; // undefined means not offered, true means currently offering, false means previously offered
  instagramCommentThread?: InstagramCommentThreadInfo;
  facebookCommentThread?: FacebookCommentThreadInfo;
  currentAiResponse: string;
  read?: boolean;
}

export interface ChatInfo {
  token?: string;
  conversationId?: string;
  csatSurveyCollected?: boolean;
  csatRating?: number;
  csatComment?: string;
  csatEditExpiresAt?: string; // ISO string
  messages?: {
    content: string;
    chatFlow?: {
      htmlContent?: string;
    };
    type: string;
    sentAt: string;
    orderList?: boolean;
    draftInfo?: {
      isDraft: boolean;
    };
  }[];
  currentChatFlowStep?: number | null;
  chatClosed?: boolean;
  teamId?: string;
  newChat?: boolean;
  collectEmail?: boolean;
  fourDigitCodeId?: string;
  email?: string;
  offeringConvertToEmail?: boolean;
}

export type Conversation = Jsonified<ConversationDocument>;

/** Same as Conversation except that message.user is of type any */
export type ExpandedConversation = Omit<Conversation, "messages"> & {
  messages: [ExpandedConversationMessage];
};

/**
 * This string is used as a system message, and as a logical condition to make the UI show a special case of
 * redochat -> email conversion.
 * Changing this will inhibit old chat->email conversion UI from showing the special case,
 * but won't break the ticket conversion UI entirely.
 */
export const TICKET_CONVERTED_FROM_REDOCHAT_TO_EMAIL_MESSAGE =
  "Ticket converted from RedoChat to email";
