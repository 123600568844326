// extracted by mini-css-extract-plugin
export var active = "_2p-3";
export var activeWrapper = "_2p-0";
export var button = "_2p-1";
export var collapsed = "_2p-i";
export var divider = "_2p-7";
export var dividerSection = "_2p-6";
export var dropdown = "_2p-8";
export var dropdownChevron = "_2p-a";
export var expand = "_2p-9";
export var fullscreen = "_2p-r";
export var icon = "_2p-n";
export var label = "_2p-5 _16-0";
export var labelWithBubble = "_2p-p _16-0";
export var logo = "_2p-j";
export var logoSection = "_2p-m";
export var main = "_2p-q";
export var menu = "_2p-b";
export var menuHeader = "_2p-c _16-0";
export var menuItem = "_2p-d _16-0";
export var nav = "_2p-e";
export var noBackground = "_2p-2";
export var option = "_2p-4";
export var options = "_2p-g";
export var optionsContainer = "_2p-f";
export var page = "_2p-h";
export var rotate = "_2p-o";
export var section = "_2p-k";
export var settings = "_2p-t";
export var subitem = "_2p-s";
export var subitemActive = "_2p-x";
export var subitemLabel = "_2p-w";
export var subitemLine = "_2p-v";
export var subitemLineContainer = "_2p-u";
export var subsection = "_2p-l";