import { z } from "zod";

export const formBodySchema = z.object({
  _id: z.string(),
  teamId: z.string(),
  formBuilderSettings: z.object({
    formTitle: z.string(),
    formDescription: z.string().optional(),
    formType: z.string(),
    formAssignments: z.array(z.string()).optional(),
  }),
});
