import { z } from "zod";

export const updateFormSchema = {
  input: z.object({
    _id: z.string(),
    teamId: z.string(),
    formBuilderSettings: z.object({
      formTitle: z.string(),
      formDescription: z.string().optional(),
      formType: z.string().optional(),
      formAssignments: z.array(z.string()).optional(),
    }),
  }),
  output: z.literal("success"),
};
