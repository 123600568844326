import { z } from "zod";
import { zExt } from "../common/zod-util";
import {
  ReviewFieldSchemaType,
  ReviewRating,
  ReviewStatus,
} from "./review-field-schema";

const REVIEW_FIELD_DISCRIMINATOR_KEY = "fieldType";

const _ReviewFieldDbParser = z.object({
  fieldType: z.nativeEnum(ReviewFieldSchemaType),
});

const ReviewHeadlineReviewFieldDbParser = _ReviewFieldDbParser.extend({
  fieldType: z.literal(ReviewFieldSchemaType.REVIEW_HEADLINE),
  text: z.string(),
});

const ReviewBodyReviewFieldDbParser = _ReviewFieldDbParser.extend({
  fieldType: z.literal(ReviewFieldSchemaType.REVIEW_BODY),
  text: z.string(),
});

const ReviewRatingReviewFieldDbParser = _ReviewFieldDbParser.extend({
  fieldType: z.literal(ReviewFieldSchemaType.REVIEW_RATING),
  rating: z.nativeEnum(ReviewRating),
});

export const ReviewFieldDbParser = z.discriminatedUnion(
  REVIEW_FIELD_DISCRIMINATOR_KEY,
  [
    ReviewHeadlineReviewFieldDbParser,
    ReviewBodyReviewFieldDbParser,
    ReviewRatingReviewFieldDbParser,
  ],
);

export type ReviewField = z.infer<typeof ReviewFieldDbParser>;

export const ReviewDbParser = z.object({
  _id: zExt.objectId(),
  teamId: zExt.objectId(),
  status: z.nativeEnum(ReviewStatus),
  verifiedPurchase: z.boolean(),
  shopifyProductId: z.string(),
  customer: z.object({
    email: z.string(),
  }),
  reviewFields: z.array(ReviewFieldDbParser),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type IReview = z.infer<typeof ReviewDbParser>;

export const ReviewDbParserArray = z.array(ReviewDbParser);
