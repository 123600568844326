// extracted by mini-css-extract-plugin
export var bubble = "_2o-0";
export var container = "_2o-3";
export var contrastBorder = "_2o-4";
export var greyOut = "_2o-2";
export var initialsWrapper = "_2o-1";
export var large = "_2o-9";
export var medium = "_2o-8";
export var mediumSmall = "_2o-7";
export var micro = "_2o-5";
export var small = "_2o-6";
export var tiny = "_2o-b";
export var xTiny = "_2o-a";