import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { resendLabel, WidgetClient } from "@redotech/redo-api-client/widget";
import { getReturner, Return } from "@redotech/redo-model/return";
import { PickupContext } from "@redotech/redo-return-app/contexts/PickupContext";
import { PickupFormCard } from "@redotech/redo-return-app/order/return-flow/review/pickup";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { RedoClientContext } from "@redotech/redo-web/client";
import { LabelTheme } from "@redotech/redo-web/labeled-input";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { groupInput, input } from "@redotech/ui/form";
import { memo, useContext, useEffect } from "react";
import * as returnCss from "../return.module.css";

export const ResendModal = memo(function ResendModal({
  return: return_,
  open,
  onClose,
  reload,
  widgetSlug,
}: {
  open: boolean;
  onClose(): void;
  return: Return;
  reload?(): void;
  widgetSlug?: string;
}) {
  const EmailInput = useInput(
    groupInput({
      email: input<string>(),
    }),
    {
      email: (return_ && getReturner(return_).email) || "",
    },
  );

  const client = useRequiredContext(RedoClientContext);
  const [resendLoad, doResend] = useTriggerLoad((signal) =>
    alertOnFailure("Resending label failed")(async () => {
      amplitude.logEvent("resend-label-support", {
        team: return_.team._id,
        returnId: return_._id,
      });
      const widgetClient = new WidgetClient(
        client,
        widgetSlug || return_.team.widget_slug,
      );
      try {
        await resendLabel(widgetClient, {
          returnId: return_.id,
          signal,
          emailOverride: return_?.pickup ? undefined : EmailInput.value.email,
        });
      } catch (err: any) {
        // If the error was caused by the merchant missing their harmonized system codes (NoHSCodesError),
        // throw a specific error instead of just "Request failed with status code ####"
        if (err.response?.data?.description?.includes("No HS codes")) {
          throw new Error(err.response.data.description);
        }
        throw err;
      }
      reload && reload();
      return true;
    }),
  );
  const handleSend = useHandler(() => doResend());

  const { input: pickupInput } = useContext(PickupContext);

  useEffect(() => {
    if (return_.pickup && pickupInput) {
      pickupInput.setValue({
        finalized: false,
        pickup: {
          location: return_.pickup.pickupLocation.packageLocation,
          date: undefined!,
          specialInstructions: "",
          textReminder: false,
          pickupPayer: undefined!,
        },
      });
    }
  }, []);

  const footer = resendLoad.value ? (
    <div className={returnCss.actionButtons}>
      <Button
        className={returnCss.modalButton}
        onClick={onClose}
        theme={ButtonTheme.OUTLINED}
      >
        Ok
      </Button>
    </div>
  ) : (
    <div className={returnCss.actionButtons}>
      <Button
        className={returnCss.modalButton}
        disabled={resendLoad.pending}
        onClick={onClose}
        theme={ButtonTheme.OUTLINED}
      >
        Cancel
      </Button>
      <Button
        className={returnCss.modalButton}
        disabled={
          resendLoad.value ||
          (return_.pickup && !!pickupInput.inputs?.pickup?.allErrors.length)
        }
        onClick={handleSend}
        pending={resendLoad.pending}
        theme={ButtonTheme.PRIMARY}
      >
        Send
      </Button>
    </div>
  );
  return (
    <Modal
      footer={footer}
      onClose={onClose}
      open={open}
      paddingAmount={PaddingAmount.NONE}
      showFooterBorder
      size={ModalSize.SMALL}
      title="Resend shipping label"
    >
      <div className={returnCss.paddedModalContent}>
        {resendLoad.value ? (
          <p>
            The shipping label has been sent to{" "}
            {return_?.pickup
              ? getReturner(return_).email
              : EmailInput.value.email}
            .
          </p>
        ) : (
          <>
            <p>
              This will send an email with the shipping label(s) to the
              customer.
            </p>
            <FormTextInput
              input={EmailInput.inputs.email}
              label="Email Address"
              labelTheme={LabelTheme.THIN}
            />
            {return_.pickup ? (
              <>
                <p>
                  The customer paid for package pickup. Fill out the information
                  below to continue:
                </p>
                {pickupInput.inputs?.pickup?.input && (
                  <PickupFormCard isMerchantDashboard />
                )}
              </>
            ) : null}
          </>
        )}
      </div>
    </Modal>
  );
});
