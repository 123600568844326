import { useRequiredContext } from "@redotech/react-util/context";
import { ConversationPlatform } from "@redotech/redo-model/conversation";
import { Flex } from "@redotech/redo-web/flex";
import { memo } from "react";
import { TeamContext } from "../../app/team";
import { RedoMerchantClientContext } from "../../client/context";
import {
  EmailSubjectMacroAutomationPill,
  StatusMacroAutomationPill,
  TagMacroAutomationPill,
} from "./macro-automation-pill";
import { AddInternalNoteAutomation } from "./macro-automations/add-internal-note";
import { MacroAutomations } from "./perform-macro-automations";

export function macroAutomationsNonEmpty(
  automations: MacroAutomations,
): boolean {
  const combinedMacros =
    automations.statusToSet ||
    automations.tagsToAdd?.length ||
    automations.emailSubjectToChange ||
    automations.noteToAddContent ||
    automations.noteToAddHtmlContent ||
    automations.shouldAddNote;
  return !!combinedMacros;
}

export const MacroAutomationsList = memo(function MacroAutomationsList({
  macroAutomations,
  setMacroAutomations,
  platform,
}: {
  macroAutomations: MacroAutomations;
  setMacroAutomations: (
    setStateCallback: (prev: MacroAutomations) => MacroAutomations,
  ) => void;
  platform: ConversationPlatform;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const team = useRequiredContext(TeamContext);

  return (
    <Flex wrap="wrap">
      {macroAutomations.statusToSet && (
        <StatusMacroAutomationPill
          snoozeDuration={macroAutomations.snoozeDuration}
          statusToSet={macroAutomations.statusToSet}
          xClicked={() => {
            setMacroAutomations((old) => {
              return {
                ...old,
                statusToSet: undefined,
                snoozeDuration: undefined,
              };
            });
          }}
        />
      )}
      {macroAutomations.tagsToAdd?.map((tag) => {
        return (
          <TagMacroAutomationPill
            key={tag}
            tag={tag}
            xClicked={() => {
              setMacroAutomations((old) => {
                return {
                  ...old,
                  tagsToAdd: old.tagsToAdd?.filter((oldTag) => oldTag !== tag),
                };
              });
            }}
          />
        );
      })}
      {macroAutomations.emailSubjectToChange &&
        platform === ConversationPlatform.EMAIL && (
          <EmailSubjectMacroAutomationPill
            emailSubject={macroAutomations.emailSubjectToChange}
            xClicked={() => {
              setMacroAutomations((old) => {
                return {
                  ...old,
                  emailSubjectToChange: undefined,
                };
              });
            }}
          />
        )}
      <AddInternalNoteAutomation.PendingOnMessageTag
        client={client}
        noteToAddContent={macroAutomations.noteToAddContent ?? ""}
        noteToAddHtmlContent={macroAutomations.noteToAddHtmlContent ?? ""}
        noteToAddUsersMentioned={macroAutomations.noteToAddUsersMentioned ?? []}
        setNoteToAddContent={(content) => {
          setMacroAutomations((old) => {
            return {
              ...old,
              noteToAddContent: content,
            };
          });
        }}
        setNoteToAddHtmlContent={(htmlContent) => {
          setMacroAutomations((old) => {
            return {
              ...old,
              noteToAddHtmlContent: htmlContent,
            };
          });
        }}
        setNoteToAddUsersMentioned={(usersMentioned) => {
          setMacroAutomations((old) => {
            return {
              ...old,
              noteToAddUsersMentioned: usersMentioned,
            };
          });
        }}
        setShouldAddNote={(value) => {
          setMacroAutomations((old) => {
            return {
              ...old,
              shouldAddNote: value,
            };
          });
        }}
        shouldAddNote={macroAutomations.shouldAddNote ?? false}
        team={team}
      />
    </Flex>
  );
});
