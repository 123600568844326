import { CreateViewBody } from "@redotech/redo-model/create-view-body";
import { UpdateViewBody } from "@redotech/redo-model/update-view-body";
import { View } from "@redotech/redo-model/view";
import { RedoMerchantClient } from ".";
import { viewJsonFormat } from "../../../model/src/view";

export const createView = async (
  client: RedoMerchantClient,
  body: CreateViewBody,
  signal?: AbortSignal,
) => {
  const v2Header = { "x-api-version": "2" };
  const response = await client.client.post("team/view", body, {
    headers: { ...v2Header, ...client.authorization() },
    signal,
  });
  return viewJsonFormat.read(response.data);
};

export const updateView = async (
  client: RedoMerchantClient,
  { viewId, body }: { body: UpdateViewBody; viewId: string },
  signal?: AbortSignal,
) => {
  const v2Header = { "x-api-version": "2" };
  const response = await client.client.put(`team/view/${viewId}`, body, {
    headers: { ...v2Header, ...client.authorization() },
    signal,
  });
  const updatedView = response.data;
  return viewJsonFormat.read(updatedView);
};

export const deleteView = async (
  client: RedoMerchantClient,
  {
    id,
    signal,
  }: {
    id: string;
    signal?: AbortSignal;
  },
) => {
  await client.client.delete(`team/view/${id}`, {
    headers: client.authorization(),
    signal,
  });
};

export const getTeamViews = async (
  client: RedoMerchantClient,
  {
    signal,
  }: {
    signal: AbortSignal;
  },
): Promise<View[]> => {
  const v2Header = { "x-api-version": "2" };
  const response = await client.client.get("team/views", {
    headers: { ...v2Header, ...client.authorization() },
    signal,
  });

  const rawData = response.data.views;
  const views = rawData.map((view: any) => {
    return viewJsonFormat.read(view);
  });
  return views;
};

export const getUserViews = async (
  client: RedoMerchantClient,
  {
    signal,
  }: {
    signal: AbortSignal;
  },
): Promise<View[]> => {
  const v2Header = { "x-api-version": "2" };
  const response = await client.client.get("user/support-private-views", {
    headers: { ...v2Header, ...client.authorization() },
    signal,
  });
  const rawData = response.data.views;
  const views = rawData.map((view: any) => {
    return viewJsonFormat.read(view);
  });
  return views;
};
