// extracted by mini-css-extract-plugin
export var animationContainer = "_7g-0";
export var footer = "_7g-1";
export var gray = "_7g-2";
export var lineItem = "_7g-3";
export var lineItemImage = "_7g-4";
export var lineItemInfo = "_7g-5";
export var lineItemText = "_7g-6";
export var lineItems = "_7g-7";
export var modalContent = "_7g-8";
export var options = "_7g-9";
export var orderName = "_7g-a";
export var refundAmount = "_7g-b";