import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { Return } from "@redotech/redo-model/return";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import ChevronDownSvg from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUpSvg from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { Flex } from "@redotech/redo-web/flex";
import MailIcon from "@redotech/redo-web/icon-old/mail.svg";
import RefreshIcon from "@redotech/redo-web/icon-old/refresh.svg";
import { ExternalLink } from "@redotech/redo-web/link";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Text } from "@redotech/redo-web/text";
import * as capitalize from "lodash/capitalize";
import { memo, useContext, useEffect, useState } from "react";
import { TeamContext } from "../../app/team";
import { ResendModal } from "../../return/return-modals/resend-modal";
import { ResetModal } from "../../return/return-modals/reset-modal";
import { returnKindNameReturn, returnStatusName } from "../../return/util";
import { ProductDetailCard } from "../product-detail-card";
import * as orderDetailsCss from "./order-details/order-details.module.css";
import * as returnDetailCss from "./return-details.module.css";

export const ReturnDetails = memo(function ReturnDetails({
  returns,
  reloadReturns,
  pending,
}: {
  returns: Return[];
  reloadReturns: () => void;
  pending: boolean;
}) {
  const team = useRequiredContext(TeamContext);
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [returnForAction, setReturnForAction] = useState<Return>();

  const { formatCurrency } = useContext(CurrencyContext);

  const handleResetClose = useHandler(() => {
    setResetModalOpen(false);
    reloadReturns();
  });

  const [isEachReturnCollapsed, setIsEachOrderCollapsed] = useState<boolean[]>(
    returns?.map(() => true) ?? [],
  );

  useEffect(() => {
    setIsEachOrderCollapsed(returns?.map(() => true) ?? []);
  }, [returns]);

  return pending ? (
    <LoadingRedoAnimation />
  ) : returns ? (
    <Flex dir="column">
      {returns
        .slice()
        .reverse()
        .map((returnRecord, i) => {
          const canReset = returnRecord.status !== "complete";
          const [isCollapsed, setIsCollapsed] = [
            isEachReturnCollapsed[i],
            (isCollapsed: boolean) =>
              setIsEachOrderCollapsed([
                ...isEachReturnCollapsed.slice(0, i),
                isCollapsed,
                ...isEachReturnCollapsed.slice(i + 1),
              ]),
          ];
          return (
            <Flex
              align="stretch"
              className={orderDetailsCss.orderCard}
              dir="column"
              grow="1"
              key={`returncard${i}`}
              pb="md"
              pl="md"
              pr="md"
              pt="xs"
            >
              <Flex align="stretch" dir="column">
                <Flex align="center" grow="1" justify="space-between">
                  <Text fontSize="xs" fontWeight="medium">
                    <>
                      <ExternalLink
                        showIcon={false}
                        url={`/stores/${team._id}/${returnRecord.type}s/${returnRecord._id}`}
                      >
                        {capitalize(returnRecord.type)}
                      </ExternalLink>{" "}
                      for {returnRecord.orders[0].name}
                    </>
                  </Text>
                  <Flex gap="md">
                    <Button
                      className={orderDetailsCss.orderCollapseButton}
                      onClick={() => setIsCollapsed(!isCollapsed)}
                      size={ButtonSize.NANO}
                      theme={ButtonTheme.GHOST}
                    >
                      <Flex
                        align="center"
                        className={orderDetailsCss.orderCollapseButtonIcon}
                        justify="center"
                      >
                        {!isCollapsed ? <ChevronUpSvg /> : <ChevronDownSvg />}
                      </Flex>
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
              <Flex wrap="nowrap">
                <div className={orderDetailsCss.orderPillsContainer}>
                  <RedoBadge
                    color="gray"
                    size="sm"
                    text={returnStatusName(returnRecord.status)}
                  />
                </div>
              </Flex>
              {isCollapsed ? (
                <Flex gap="xl" wrap="nowrap">
                  <Flex align="stretch" dir="column" grow="1">
                    <Text
                      color="var(--redo-colors-text-text-tertiary-600"
                      fontSize="xs"
                      fontWeight="medium"
                    >
                      Total items
                    </Text>
                    <Text
                      color="var(--redo-colors-text-text-primary-900"
                      fontSize="xs"
                      fontWeight="medium"
                    >
                      {returnRecord.products.length}
                    </Text>
                  </Flex>
                  <Flex align="stretch" dir="column" grow="1">
                    <Text
                      color="var(--redo-colors-text-text-tertiary-600"
                      fontSize="xs"
                      fontWeight="medium"
                    >
                      Total amount
                    </Text>
                    <Text
                      color="var(--redo-colors-text-text-primary-900"
                      fontSize="xs"
                      fontWeight="medium"
                    >
                      {formatCurrency(
                        returnRecord.products.reduce(
                          (acc, product) =>
                            acc + Number(product.price) * product.quantity,
                          0,
                        ),
                      )}
                    </Text>
                  </Flex>
                </Flex>
              ) : (
                <div className={returnDetailCss.returnDetails}>
                  <div className={returnDetailCss.products}>
                    {returnRecord.products.map((product) => {
                      return (
                        <div
                          className={returnDetailCss.productDetails}
                          key={product._id}
                        >
                          <ProductDetailCard
                            imageName={product.product_title}
                            imageSrc={product.images?.[0] ?? ""}
                            item={product.product_title}
                            price={formatCurrency(Number(product.price))}
                            quantity={product.quantity}
                            variant={product.variant_title}
                          />

                          <div
                            className={returnDetailCss.productPillsContainer}
                          >
                            {product.reason && (
                              <div className={returnDetailCss.pillWrapper}>
                                <RedoBadge
                                  color="gray"
                                  size="sm"
                                  text={product.reason}
                                />
                              </div>
                            )}
                            <div className={returnDetailCss.pillWrapper}>
                              <RedoBadge
                                color="brand"
                                size="sm"
                                text={returnKindNameReturn(returnRecord)}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={returnDetailCss.actions}>
                    <Button
                      onClick={() => {
                        setReturnForAction(returnRecord);
                        setResendModalOpen(true);
                      }}
                      size={ButtonSize.MICRO}
                      theme={ButtonTheme.OUTLINED}
                    >
                      <div className={returnDetailCss.actionButton}>
                        <MailIcon className={returnDetailCss.icon} />
                        Resend label
                      </div>
                    </Button>
                    {canReset && (
                      <Button
                        onClick={() => {
                          setReturnForAction(returnRecord);
                          setResetModalOpen(true);
                        }}
                        size={ButtonSize.MICRO}
                        theme={ButtonTheme.OUTLINED}
                      >
                        <div className={returnDetailCss.actionButton}>
                          <RefreshIcon className={returnDetailCss.icon} />
                          Reset
                        </div>
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </Flex>
          );
        })}
      {resendModalOpen && returnForAction && (
        <ResendModal
          onClose={() => setResendModalOpen(false)}
          open={resendModalOpen}
          return={returnForAction}
          widgetSlug={team.widget_slug}
        />
      )}
      {resetModalOpen && returnForAction && (
        <ResetModal
          onClose={handleResetClose}
          open={resetModalOpen}
          preventNavigate
          return={returnForAction}
        />
      )}
    </Flex>
  ) : null;
});
