import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useScrolled } from "@redotech/react-util/scroll";
import {
  ConversationPlatform,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { Permission, permitted } from "@redotech/redo-model/user";
import CheckIcon from "@redotech/redo-web/arbiter-icon/check.svg";
import Edit04Icon from "@redotech/redo-web/arbiter-icon/edit-04.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { DropdownOption } from "@redotech/redo-web/dropdown";
import ArchiveIcon from "@redotech/redo-web/icon-old/archive.svg";
import EnvelopeOpen from "@redotech/redo-web/icon-old/envelope-open.svg";
import EnvelopeIcon from "@redotech/redo-web/icon-old/mail.svg";
import MergeIcon from "@redotech/redo-web/icon-old/merge.svg";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import SnoozeClockIcon from "@redotech/redo-web/icon-old/snooze-clock.svg";
import SpamIcon from "@redotech/redo-web/icon-old/spam.svg";
import TagIcon from "@redotech/redo-web/icon-old/tag.svg";
import UndoArrow from "@redotech/redo-web/icon-old/undo-arrow.svg";
import UserPlusIcon from "@redotech/redo-web/icon-old/user-plus.svg";
import { ActionPortalContext } from "@redotech/redo-web/page";
import { TableRef } from "@redotech/redo-web/table";
import { sinkPromise } from "@redotech/util/promise";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { ReloadConversationTagsContext } from "../../app/conversation-tags-context";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { RedoMerchantClientContext } from "../../client/context";
import { MerchantAppEventServerContext } from "../../events/merchant-app-event-server-provider";
import { ArchiveTicketModal } from "../conversation-actions/archive-ticket-modal";
import { AssignTicketModal } from "../conversation-actions/assign-ticket-modal";
import { ChangeSubjectModal } from "../conversation-actions/change-subject-modal";
import {
  CloseTicketModal,
  handleCloseTickets,
} from "../conversation-actions/close-ticket-modal";
import { MarkSpamModal } from "../conversation-actions/mark-spam-modal";
import { MergeModal } from "../conversation-actions/merge-modal";
import { ReopenTicketModal } from "../conversation-actions/reopen-ticket-modal";
import { SnoozeModal } from "../conversation-actions/snooze-modal";
import { TagsModal } from "../conversation-actions/tags-modal";
import { ConversationFetcher } from "../conversation-fetcher";
import { ActiveViewContext } from "../conversations-table-filters/active-view-context";
import {
  FiltersContext,
  SetFiltersContext,
} from "../conversations-table-filters/filters-context";
import {
  markConversationAsRead,
  markConversationAsUnread,
} from "../conversations/mark-conversation-read-manager";
import { CreateConversationModal } from "../create-conversation-modal";
import {
  ConversationsTable,
  ConversationTableDropdownAction,
} from "./conversations-table";
import { ConversationsTableHeader } from "./conversations-table-header";
import { ConversationsTableSelectionContext } from "./conversations-table-selection";
import * as conversationsTableCss from "./conversations-table.module.css";

export const FullConversationsTable = memo(function FullConversationsTable({
  setActiveConversation,
  fetcher,
  blockRefresh,
  setBlockRefresh,
  pageLoaded,
}: {
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  fetcher: ConversationFetcher;
  blockRefresh: boolean;
  setBlockRefresh: (block: boolean) => void;
  pageLoaded: boolean;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const filters = useContext(FiltersContext);
  const setFilters = useContext(SetFiltersContext);
  const view = useContext(ActiveViewContext);
  const actionsPortal = useContext(ActionPortalContext);
  const reloadConversationTags = useContext(ReloadConversationTagsContext);
  const team = useContext(TeamContext);
  const user = useRequiredContext(UserContext);
  const tableSelection = useRequiredContext(ConversationsTableSelectionContext);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [reopenModalOpen, setReopenModalOpen] = useState(false);
  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [markSpamModalOpen, setMarkSpamModalOpen] = useState(false);
  const [changeSubjectModalOpen, setChangeSubjectModalOpen] = useState(false);

  const [createConversationModalOpen, setCreateConversationModalOpen] =
    useState(false);

  const [snoozeModalOpen, setSnoozeModalOpen] = useState(false);
  // Stores conversation that needs an action (e.g. close, snooze, etc.) from the main view page
  const [conversationNeedingAction, setConversationNeedingAction] =
    useState<ExpandedConversation>();

  const navigate = useNavigate();
  const scrolled = useScrolled(document.documentElement);
  const scrollAreaRef = useRef(document.documentElement);

  const canReply =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);
  const canCloseTicket =
    !!user && permitted(user.permissions, Permission.CLOSE_CONVERSATION);
  const canAssignTag =
    !!user && permitted(user.permissions, Permission.ASSIGN_TAG);
  const canArchiveTicket =
    !!user && permitted(user.permissions, Permission.ARCHIVE_CONVERSATION);
  const canEditAssignee =
    !!user && permitted(user.permissions, Permission.EDIT_ASSIGNEE);

  const tableRef = useRef<TableRef<ExpandedConversation>>({
    /** see useImperativeHandle in table.tsx, which wires this up */
    refresh: async () => {},
    items: [],
  });

  const eventServer = useRequiredContext(MerchantAppEventServerContext);

  useEffect(() => {
    const unlistenCallback = eventServer.subscribe(async () => {
      if (blockRefresh) {
        return;
      }
      sinkPromise(tableRef.current?.refresh() ?? Promise.resolve());
    });
    return () => unlistenCallback();
  }, [blockRefresh]);

  const dropdownActions = (
    conversation: ExpandedConversation,
    setDropdownOpen: (open: boolean) => void,
  ) => {
    const items: ConversationTableDropdownAction[] = [
      {
        show: canCloseTicket && conversation.status !== "closed",
        component: (
          <DropdownOption
            action={async () => {
              setDropdownOpen(false);
              setConversationNeedingAction(conversation);
              if (
                !!team &&
                (localStorage.getItem("redo.doNotShowCloseTicketModal") ===
                  "true" ||
                  conversation.platform !== ConversationPlatform.REDO_CHAT)
              ) {
                await handleCloseTickets({
                  client: client,
                  conversations: [conversation],
                  setActiveConversation,
                  team,
                  inDetailView: false,
                  cleanupFn: singleCleanupFn,
                });
              } else {
                amplitude.logEvent("view-closeConversationModal", {
                  mode: "single",
                  conversationIds: [conversation._id],
                  channels: [conversation.platform],
                });
                setCloseModalOpen(true);
              }
            }}
            key="close"
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <CheckIcon className={conversationsTableCss.icon} />
              Close
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canCloseTicket && conversation.status === "closed",
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-reopenConversationModal", {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setDropdownOpen(false);

              setConversationNeedingAction(conversation);
              setReopenModalOpen(true);
            }}
            key="reopen"
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <UndoArrow className={conversationsTableCss.icon} />
              Reopen ticket
            </div>
          </DropdownOption>
        ),
      },
      {
        show: conversation.read ?? false,
        component: (
          <DropdownOption
            action={async () => {
              setDropdownOpen(false);
              await handleMarkedUnread(conversation);
              sinkPromise(tableRef.current.refresh());
            }}
            key="mark-unread"
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <EnvelopeIcon className={conversationsTableCss.icon} />
              Mark unread
            </div>
          </DropdownOption>
        ),
      },
      {
        show: !conversation.read,
        component: (
          <DropdownOption
            action={async () => {
              setDropdownOpen(false);
              await onConversationsViewed(conversation);
              sinkPromise(tableRef.current.refresh());
            }}
            key="mark-read"
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <EnvelopeOpen className={conversationsTableCss.icon} />
              Mark read
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canReply,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-snoozeConversationModal", {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setDropdownOpen(false);

              setConversationNeedingAction(conversation);
              setSnoozeModalOpen?.(true);
            }}
            key="snooze"
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <SnoozeClockIcon className={conversationsTableCss.icon} />
              Snooze
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canCloseTicket,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-mergeConversationModal", {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setDropdownOpen(false);
              setConversationNeedingAction(conversation);
              setMergeModalOpen(true);
            }}
            key="merge"
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <MergeIcon className={conversationsTableCss.icon} />
              Merge
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canArchiveTicket,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-archiveConversationModal", {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setDropdownOpen(false);

              setConversationNeedingAction(conversation);
              setArchiveModalOpen(true);
            }}
            key="delete"
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <ArchiveIcon className={conversationsTableCss.icon} />
              Delete
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canEditAssignee,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-assignConversationModal", {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setDropdownOpen(false);

              setConversationNeedingAction(conversation);
              setAssignModalOpen(true);
            }}
            key="assign"
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <UserPlusIcon className={conversationsTableCss.icon} />
              Assign
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canAssignTag,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent(`view-tagDropdown`, {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setDropdownOpen(false);

              setConversationNeedingAction(conversation);
              setTagModalOpen(true);
            }}
            key="tags"
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <TagIcon className={conversationsTableCss.icon} />
              {(conversation.tagIds || []).length > 0
                ? "Edit tags"
                : "Add tags"}
            </div>
          </DropdownOption>
        ),
      },
      {
        show:
          canCloseTicket &&
          conversation.platform === ConversationPlatform.EMAIL,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-markSpamModal", {
                mode: "single",
                conversationIds: [conversation._id],
              });
              setDropdownOpen(false);
              setConversationNeedingAction(conversation);
              setMarkSpamModalOpen(true);
            }}
            key="mark-spam"
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <SpamIcon className={conversationsTableCss.icon} />
              Mark spam
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canReply && conversation.platform === ConversationPlatform.EMAIL,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-changeSubjectModal", {
                mode: "single",
                conversationIds: [conversation._id],
              });
              setDropdownOpen(false);
              handleChangeSubject(conversation);
            }}
            key="change-subject"
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <Edit04Icon className={conversationsTableCss.icon} />
              Change subject
            </div>
          </DropdownOption>
        ),
      },
    ];
    return items;
  };

  const handleMarkedUnread = async (
    conversationToMark: ExpandedConversation | undefined,
  ) => {
    if (conversationToMark && team) {
      await markConversationAsUnread(
        client,
        conversationToMark,
        team,
        user._id,
      );
      setActiveConversation(undefined);
    }
  };

  const onConversationsViewed = async (conversation: ExpandedConversation) => {
    if (!team) {
      return;
    }
    return await markConversationAsRead(client, conversation, team, user._id);
  };

  const handleNavigateAfterCreate = async ({
    conversationId,
  }: {
    conversationId: string;
  }) => {
    amplitude.logEvent("create-conversation");
    sinkPromise(tableRef.current.refresh());
    navigate(
      `/stores/${team?._id}/support/table/all?activeConversationId=${conversationId}`,
    );
  };

  const handleChangeSubject = (conversation: ExpandedConversation) => {
    setConversationNeedingAction(conversation);
    setChangeSubjectModalOpen(true);
  };

  const singleCleanupFn = (clearSelected?: boolean) => {
    sinkPromise(tableRef.current.refresh());
    setConversationNeedingAction((prev) => {
      if (prev && clearSelected) {
        tableSelection.removeFromSelection([prev._id]);
      }
      return undefined;
    });
  };

  useEffect(() => {
    if (!tagModalOpen) {
      reloadConversationTags();
    }
  }, [tagModalOpen]);

  useEffect(() => {
    amplitude.logEvent("view-supportTable", { view });
  }, []);

  return (
    <>
      <div className={conversationsTableCss.contentContainer}>
        <ConversationsTableHeader
          pageLoaded={pageLoaded}
          scrolled={scrolled}
          setActiveConversation={setActiveConversation}
          setBlockRefresh={setBlockRefresh}
          tableRef={tableRef}
        />
        <ConversationsTable
          dropdownActions={dropdownActions}
          externalSetSort={(newSort) =>
            setFilters({ ...filters, sort: newSort })
          }
          externalSort={filters.sort || undefined}
          fetcher={fetcher}
          filters={filters}
          handleCardClick={setActiveConversation}
          onConversationsViewed={onConversationsViewed}
          scrollAreaRef={scrollAreaRef}
          tableRef={tableRef}
          view={view}
        />
      </div>
      <CreateConversationModal
        handleNavigate={handleNavigateAfterCreate}
        open={createConversationModalOpen}
        setOpen={setCreateConversationModalOpen}
      />

      {closeModalOpen && conversationNeedingAction && (
        <CloseTicketModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          inDetailView={false}
          open={closeModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setCloseModalOpen}
        />
      )}
      {reopenModalOpen && conversationNeedingAction && (
        <ReopenTicketModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          inDetailView={false}
          open={reopenModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setReopenModalOpen}
        />
      )}
      {snoozeModalOpen && conversationNeedingAction && (
        <SnoozeModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          inDetailView={false}
          open={snoozeModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setSnoozeModalOpen}
        />
      )}
      {archiveModalOpen && conversationNeedingAction && (
        <ArchiveTicketModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          inDetailView={false}
          open={archiveModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setArchiveModalOpen}
        />
      )}
      {mergeModalOpen && conversationNeedingAction && (
        <MergeModal
          conversations={[conversationNeedingAction]}
          open={mergeModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setMergeModalOpen}
        />
      )}
      {assignModalOpen && conversationNeedingAction && (
        <AssignTicketModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          open={assignModalOpen}
          setOpen={setAssignModalOpen}
        />
      )}
      {tagModalOpen && conversationNeedingAction && (
        <TagsModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          mode="add"
          open={tagModalOpen}
          setOpen={setTagModalOpen}
        />
      )}
      {markSpamModalOpen && conversationNeedingAction && (
        <MarkSpamModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          inDetailView={false}
          open={markSpamModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setMarkSpamModalOpen}
        />
      )}
      {changeSubjectModalOpen && (
        <ChangeSubjectModal
          conversation={conversationNeedingAction!}
          resolve={() => setChangeSubjectModalOpen(false)}
        />
      )}

      {actionsPortal &&
        canReply &&
        createPortal(
          <Button
            onClick={() => {
              amplitude.logEvent("view-createConversationModal");
              setCreateConversationModalOpen(true);
            }}
            size={ButtonSize.SMALL}
            theme={ButtonTheme.GHOST}
          >
            <div className={conversationsTableCss.actionButton}>
              <PlusIcon className={conversationsTableCss.icon} />
              Create new ticket
            </div>
          </Button>,
          actionsPortal,
        )}
    </>
  );
});
