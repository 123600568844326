// extracted by mini-css-extract-plugin
export var actionButton = "_2s-1";
export var actionButtons = "_2s-2";
export var actions = "_2s-0";
export var alignCenter = "_2s-e";
export var backWrapper = "_2s-3";
export var checkboxContainer = "_2s-4";
export var contentContainer = "_2s-5";
export var contentHeader = "_2s-6";
export var conversationsTableWrapper = "_2s-q";
export var draftMessagePreview = "_2s-k";
export var draftReplyBadgeContainer = "_2s-j";
export var dropdownItem = "_2s-9";
export var dropdownItemsContainer = "_2s-8";
export var dropdownTitle = "_2s-a";
export var filter = "_2s-b";
export var gray = "_2s-f";
export var headerCheckboxContainer = "_2s-c";
export var icon = "_2s-g";
export var menuContainer = "_2s-h";
export var oneLine = "_2s-i";
export var optionsButton = "_2s-l";
export var plus = "_2s-m";
export var portalButtonsWrapper = "_2s-d";
export var red = "_2s-n";
export var scrolledTop = "_2s-7";
export var search = "_2s-o";
export var selectedConversationsCount = "_2s-p";
export var spinner = "_2s-r";
export var strong = "_2s-s";
export var summaryCell = "_2s-t";
export var tagContainer = "_2s-u";
export var threeDots = "_2s-v";
export var twoLines = "_2s-w";
export var unread = "_2s-x";