import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import ToolIcon from "@redotech/redo-web/arbiter-icon/tool-02.svg";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import { memo } from "react";
import { StepType, StepTypeDetailsProps } from "./step";

export interface State {
  customTitle?: string;
}

const Details = memo(function Details({
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  return null;
});

export const SUBMIT_REPAIR: StepType<State, ModelStep.SubmitRepair> = {
  Details,
  customTitle: (state) => state.customTitle,
  title: "Submit Repair",
  valid: () => true,
  Icon: ToolIcon,
  description(state: State) {
    return "Submit a repair request";
  },
  downstream() {
    return [];
  },
  empty: {},
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state) {
    return state;
  },
  toModel(state: State): ModelStep.SubmitRepair {
    return {
      customTitle: state.customTitle,
      type: ModelStep.SUBMIT_REPAIR,
    };
  },
  fromModel(model: ModelStep.SubmitRepair): State {
    return {
      customTitle: model.customTitle,
    };
  },
};
