import { useRequiredContext } from "@redotech/react-util/context";
import { Order } from "@redotech/redo-model/order";
import { ReturnType } from "@redotech/redo-model/return";
import { Permission, permitted } from "@redotech/redo-model/user";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import ChevronDownSvg from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUpSvg from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { EditOrderModal } from "@redotech/redo-web/edit-order-modal";
import { Flex } from "@redotech/redo-web/flex";
import { ExternalLink } from "@redotech/redo-web/link";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Text } from "@redotech/redo-web/text";
import { memo, useContext, useEffect, useState } from "react";
import { TeamContext } from "../../../app/team";
import { UserContext } from "../../../app/user";
import { RedoMerchantClientContext } from "../../../client/context";
import { updateOrder } from "../../../client/shopify";
import { MerchantCreateReturnModal } from "../../../return/claim-return-shared";
import { ProductDetailCard } from "../../product-detail-card";
import { CancelOrderModal } from "../cancel-order-modal";
import { DuplicateOrderModal } from "../duplicate-order-modal";
import { RefundOrderModal } from "../refund-order-modal";
import { OrderDetailsOrderActions } from "./order-details-order-actions";
import { OrderDetailsShippingMethod } from "./order-details-shipping-method";
import { OrderDetailsTrackingInformation } from "./order-details-tracking-information";
import * as orderDetailsCss from "./order-details.module.css";

export const OrderDetails = memo(function OrderDetails({
  orders,
  reloadOrders,
  pending,
  orderNeedingAction,
  setOrderNeedingAction,
}: {
  orders: Order[] | undefined;
  reloadOrders: () => void;
  pending: boolean;
  orderNeedingAction?: Order;
  setOrderNeedingAction: (order: Order) => void;
}) {
  const [isEachOrderCollapsed, setIsEachOrderCollapsed] = useState<boolean[]>(
    orders?.map(() => true) ?? [],
  );

  useEffect(() => {
    setIsEachOrderCollapsed(orders?.map(() => true) ?? []);
  }, [orders]);

  const team = useRequiredContext(TeamContext);

  const user = useContext(UserContext);
  const [createClaimModalOpen, setCreateClaimModalOpen] = useState(false);
  const [duplicateOrderModalOpen, setDuplicateOrderModalOpen] = useState(false);
  const [refundOrderModalOpen, setRefundOrderModalOpen] = useState(false);
  const [createType, setCreateType] = useState<ReturnType>("return");
  const [searchParams, setSearchParams] = useState<{ [key: string]: string }>(
    {},
  );
  const [currentView, setCurrentView] = useState<string>();
  const [editOrderModalOpen, setEditOrderModalOpen] = useState(false);
  const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);
  const canManageOrder =
    !!user && permitted(user.permissions, Permission.MANAGE_ORDER);

  useEffect(() => {
    const url = new URL(window.location.href);
    const urlSearchParams = new URLSearchParams(url.search);
    const viewName = url.pathname.split("/").pop() ?? null;
    setSearchParams(Object.fromEntries(urlSearchParams.entries()));
    setCurrentView(viewName ?? undefined);
  }, []);

  useEffect(() => {
    if (orderNeedingAction) {
      document
        .getElementById(`order-${orderNeedingAction.shopify_id}-details`)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [pending]);

  const formatSentenceCase = (str: string) => {
    return str[0].toUpperCase() + str.slice(1).replace(/_/g, " ");
  };

  const filteredFulfillments = (order: Order) => {
    const fulfillmentsWithFilteredLineItems = order.shopify.fulfillments.filter(
      (fulfillment) => {
        return {
          ...fulfillment,
          line_items: fulfillment.line_items.filter(
            (line_item) =>
              line_item.vendor?.toLowerCase() != "re:do" &&
              line_item.vendor?.toLowerCase() != "redo",
          ),
        };
      },
    );
    return fulfillmentsWithFilteredLineItems.filter((fulfillment) => {
      return fulfillment.line_items.length > 0;
    });
  };

  const client = useRequiredContext(RedoMerchantClientContext);

  const formatShippingAddress = (shippingLines: Record<string, any>) => {
    return (
      <>
        <Text fontSize="xs" fontWeight="medium" textColor="primary">
          {shippingLines.address1}
        </Text>
        <Text fontSize="xs" fontWeight="medium" textColor="primary">
          {shippingLines.address2}
        </Text>
        <Text fontSize="xs" fontWeight="medium" textColor="primary">
          {`${shippingLines.city}, ${shippingLines.province_code}  ${shippingLines.zip}, ${shippingLines.country_code}`}
        </Text>
      </>
    );
  };

  return pending ? (
    <LoadingRedoAnimation />
  ) : orders ? (
    <>
      {orders
        .slice()
        .reverse()
        .map((order, i) => {
          const fulfillments = filteredFulfillments(order);
          const [isCollapsed, setIsCollapsed] = [
            isEachOrderCollapsed[i],
            (isCollapsed: boolean) =>
              setIsEachOrderCollapsed([
                ...isEachOrderCollapsed.slice(0, i),
                isCollapsed,
                ...isEachOrderCollapsed.slice(i + 1),
              ]),
          ];
          return (
            <Flex
              className={orderDetailsCss.orderCard}
              dir="column"
              key={`ordercard${i}`}
              pb="md"
              pl="md"
              pr="md"
              pt="xs"
            >
              <Flex align="stretch" dir="column">
                <Flex align="center" justify="space-between">
                  <Text fontSize="xs" fontWeight="medium">
                    <>
                      Order{" "}
                      <ExternalLink
                        showIcon={false}
                        url={`https://${team.storeUrl}/admin/orders/${
                          order.shopify_id || ""
                        }`}
                      >
                        {order.shopify.name}
                      </ExternalLink>
                    </>
                  </Text>
                  <Flex gap="md">
                    {canManageOrder && (
                      <OrderDetailsOrderActions
                        order={order}
                        setCancelOrderModalOpen={setCancelOrderModalOpen}
                        setCreateClaimModalOpen={setCreateClaimModalOpen}
                        setCreateType={setCreateType}
                        setDuplicateOrderModalOpen={setDuplicateOrderModalOpen}
                        setEditOrderModalOpen={setEditOrderModalOpen}
                        setOrderNeedingAction={setOrderNeedingAction}
                        setRefundOrderModalOpen={setRefundOrderModalOpen}
                      />
                    )}

                    <Button
                      className={orderDetailsCss.orderCollapseButton}
                      onClick={() => setIsCollapsed(!isCollapsed)}
                      size={ButtonSize.NANO}
                      theme={ButtonTheme.GHOST}
                    >
                      <Flex
                        align="center"
                        className={orderDetailsCss.orderCollapseButtonIcon}
                        justify="center"
                      >
                        {!isCollapsed ? <ChevronUpSvg /> : <ChevronDownSvg />}
                      </Flex>
                    </Button>
                  </Flex>
                </Flex>
                <Flex wrap="nowrap">
                  <div className={orderDetailsCss.orderPillsContainer}>
                    {order.shopify.financial_status && (
                      <div className={orderDetailsCss.pillWrapper}>
                        <RedoBadge
                          color={
                            order.shopify.financial_status === "paid"
                              ? "success"
                              : "error"
                          }
                          size="sm"
                          text={formatSentenceCase(
                            order.shopify.financial_status,
                          )}
                        />
                      </div>
                    )}
                    <div className={orderDetailsCss.pillWrapper}>
                      <RedoBadge
                        color={
                          order.shopify?.fulfillment_status === "fulfilled"
                            ? "success"
                            : "warning"
                        }
                        size="sm"
                        text={
                          order.shopify?.fulfillment_status
                            ? formatSentenceCase(
                                order.shopify.fulfillment_status,
                              )
                            : "Unfulfilled"
                        }
                      />
                    </div>
                    {order.shopify?.cancelled_at !== null && (
                      <div className={orderDetailsCss.pillWrapper}>
                        <RedoBadge color="error" size="sm" text="Canceled" />
                      </div>
                    )}
                    {order.protected && (
                      <div className={orderDetailsCss.pillWrapper}>
                        <RedoBadge
                          color="blueLight"
                          size="sm"
                          text="Returns Coverage"
                        />
                      </div>
                    )}
                    {order.packageProtected && (
                      <div className={orderDetailsCss.pillWrapper}>
                        <RedoBadge
                          color="blueLight"
                          size="sm"
                          text="Package Protection"
                        />
                      </div>
                    )}
                  </div>
                </Flex>
                <Flex
                  dir={isCollapsed ? "row" : "column"}
                  gap={isCollapsed ? undefined : "md"}
                  wrap="nowrap"
                >
                  <Flex align="stretch" dir="column" grow="1">
                    <Text
                      fontSize="xs"
                      fontWeight="medium"
                      textColor="tertiary"
                    >
                      Ordered on
                    </Text>
                    <Text fontSize="xs" fontWeight="medium" textColor="primary">
                      {new Date(order.shopifyCreatedAt).toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                    </Text>
                  </Flex>
                  <Flex align="stretch" dir="column" grow="1">
                    <Text
                      fontSize="xs"
                      fontWeight="medium"
                      textColor="tertiary"
                    >
                      Total
                    </Text>
                    <Text fontSize="xs" fontWeight="medium" textColor="primary">
                      {CURRENCY_FORMAT().format(
                        order.shopify.total_price_usd ||
                          order.shopify.total_price,
                      )}
                    </Text>
                  </Flex>
                </Flex>
                {!isCollapsed && (
                  <div
                    className={orderDetailsCss.orderDetails}
                    id={`order-${order.shopify_id}-details`}
                  >
                    <OrderDetailsShippingMethod order={order} />
                    {fulfillments.length > 0 && (
                      <div className={orderDetailsCss.fulfillments}>
                        {fulfillments.map((fulfillment, index) => {
                          return (
                            <Flex
                              className={orderDetailsCss.fulfillmentDetails}
                              dir="column"
                              gap="md"
                              key={fulfillment.id}
                            >
                              {fulfillments.length > 1 ? (
                                <Text
                                  fontSize="xs"
                                  fontWeight="medium"
                                  textColor="primary"
                                >
                                  Package {index + 1}
                                </Text>
                              ) : (
                                <Text
                                  fontSize="xs"
                                  fontWeight="medium"
                                  textColor="primary"
                                >
                                  {`Package ${index}`}
                                </Text>
                              )}
                              <Flex dir="column" gap="xxs">
                                {fulfillment.shipment_status && (
                                  <div className={orderDetailsCss.pillWrapper}>
                                    <Flex>
                                      <RedoBadge
                                        color={
                                          fulfillment.shipment_status ===
                                          "delivered"
                                            ? "success"
                                            : fulfillment.shipment_status ===
                                                "shipped"
                                              ? "blueLight"
                                              : fulfillment.shipment_status ===
                                                  "in_transit"
                                                ? "blueLight"
                                                : "warning"
                                        }
                                        size="sm"
                                        text={formatSentenceCase(
                                          fulfillment.shipment_status,
                                        )}
                                      />
                                    </Flex>
                                  </div>
                                )}
                                <OrderDetailsTrackingInformation
                                  fulfillment={fulfillment}
                                  index={index}
                                  order={order}
                                />
                              </Flex>
                              <div className={orderDetailsCss.detailColumn}>
                                <Text
                                  fontSize="xs"
                                  fontWeight="medium"
                                  textColor="tertiary"
                                >
                                  Shipping address:
                                </Text>
                                <Text
                                  color="var(--redo-colors-text-text-primary-900"
                                  fontSize="xs"
                                  fontWeight="medium"
                                >
                                  {order.shopify?.shipping_address
                                    ? formatShippingAddress(
                                        order.shopify.shipping_address,
                                      )
                                    : "None"}
                                </Text>
                              </div>
                            </Flex>
                          );
                        })}
                      </div>
                    )}
                    <div className={orderDetailsCss.lineItems}>
                      <div className={orderDetailsCss.products}>
                        {order.shopify.line_items.map((lineItem) => {
                          return (
                            <div
                              className={orderDetailsCss.product}
                              key={lineItem.id}
                            >
                              <ProductDetailCard
                                imageName={lineItem.name}
                                imageSrc={lineItem.image?.src || ""}
                                item={lineItem.name}
                                price={CURRENCY_FORMAT(
                                  order.shopify.presentment_currency,
                                ).format(Number(lineItem.price))}
                                quantity={lineItem.quantity}
                                variant={lineItem.variant_title || ""}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </Flex>
            </Flex>
          );
        })}
      {orderNeedingAction && (
        <>
          {createClaimModalOpen && (
            <MerchantCreateReturnModal
              open={createClaimModalOpen}
              relocationInfo={{
                preselectedOrder: orderNeedingAction,
                currentView: currentView,
                returnQueryParams:
                  createType === "return" ? searchParams : undefined,
              }}
              returnType={createType}
              setOpen={setCreateClaimModalOpen}
            />
          )}
          <EditOrderModal
            onOrdersChange={reloadOrders}
            open={editOrderModalOpen}
            order={orderNeedingAction}
            setOpen={setEditOrderModalOpen}
            updateOrder={(data: any) => updateOrder(client, data)}
          />
          <CancelOrderModal
            onOrdersChange={reloadOrders}
            open={cancelOrderModalOpen}
            order={orderNeedingAction}
            setOpen={setCancelOrderModalOpen}
          />
          <DuplicateOrderModal
            open={duplicateOrderModalOpen}
            order={orderNeedingAction}
            setOpen={setDuplicateOrderModalOpen}
          />
          <RefundOrderModal
            onOrdersChange={reloadOrders}
            open={refundOrderModalOpen}
            order={orderNeedingAction}
            setOpen={setRefundOrderModalOpen}
          />
        </>
      )}
    </>
  ) : null;
});
