import { CustomerReturnCreatedAttachmentGenerationParameters } from "./email-builder";
import { ITracker, Order, SesEvent } from "./order";
import { getReturner, Return, ReturnAddress, ReturnWarning } from "./return";
import { TimelineEvent } from "./timeline";

export enum TrackableType {
  ORDER = "order",
  RETURN = "return",
}

/**
 * Interface for a set of packages that can be tracked.
 * Essentially a stripped-down union of the Order and Return models,
 * molded into the ideal shape for order tracking use cases.
 *
 * @remarks
 * This interface is intended for use with the Order Tracking and Return Tracking products,
 * so that the emails and SMS messages can be generated agnostically.
 */
export interface BaseTrackable {
  /**
   * The Redo ID of the order or return (MongoDB ObjectID)
   */
  id: string;
  customer: {
    shopifyId?: string;
    email: string;
    name?: string;
    firstName?: string;
    lastName?: string;
  };
  fulfillments: TrackableFulfillment[];
  lineItems: TrackableLineItem[];
  currentEmailFlows: {
    emailFlowId: string;
    currentStep: number;
    continueDate: Date;
    fulfillmentId?: string;
  }[];

  /**
   * The time this object was created in Redo's system
   */
  createdAt: Date;

  /**
   * On orders, this is field is just a reference to itself.
   * On returns, it references the original order.
   */
  originalOrder: OriginalOrder;
  teamId: string;
  discount?: {
    id?: string;
    code?: string;
    description?: string;
    expirationDateTime?: string;
    status?: string;
  };
  trackingEmailsSent?: {
    emailId: string;
    status?: string;
    sentAt: string;
    s3URL?: string;
    trigger?: string;
  }[];
}

export interface TrackableFulfillment {
  id: string;
  /**
   * Easypost trackers. This list may be empty.
   */
  trackers: ITracker[];
  lineItems: TrackableLineItem[];
  isShipped: boolean;
  updatedAt?: Date;
  tracking_numbers?: string[];
  status: string;
  shipment_status?: string;
  tracking_company?: string;
  tracking_url?: string;
}

/**
 * Similar interfaces already exist for line items,
 * but for maximum control and speed, I'm making a new one (Josh)
 */
export interface TrackableLineItem {
  id: number;
  variantId?: string;
  productId?: string;
  title: string;
  variantTitle: string;
  quantity: number;
  price: number;
  isRedo: boolean;
  requiresShipping: boolean;
  image: {
    src: string;
  };
  tags: string[];
  properties: {
    [key: string]: string;
  };
  sku: string;
  green_return: boolean;
}

interface OriginalOrder {
  id: string;
  shopify: ShopifyOrder;
}

/**
 * Shopify tracking info. Not as useful as Easypost data but guaranteed to exist.
 */
export interface ShopifyFulfillment {
  id: string;
  updatedAt?: Date;
  shipmentStatus: string;
  isShipped: boolean;
  tracking: {
    /*
     * Tracking numbers as entered in Shopify.
     * This list will always have at least one entry (although it may be null)
     * In rare cases, it may have more than one.
     */
    numbers: (string | null)[];
    /*
     * Tracking URLs as entered in Shopify.
     * This list will always have at least one entry (although it may be null)
     * In rare cases, it may have more than one.
     */
    urls: (string | null)[];
    company: string | null;
  };
}

/**
 * Similar interfaces already exist for shopify order data,
 * but for maximum control and speed, I'm making a new one (Josh)
 */ export interface ShopifyOrder {
  id: string;
  number: number;
  name: string;
  createdAt: Date;
  contact_email: string;
  /*
   * Intended for estimating delivery dates only, not general use.
   * Favor Trackable.lineItems instead, and avoid adding unnecessary fields here.
   */
  lineItems: {
    productId: string | null;
  }[];
  fulfillments: ShopifyFulfillment[];
  customer: {
    id: string;
  } | null;
  shippingLines: {
    title: string;
    price: number;
    discountedPrice: number;
    discountedPriceSet: {
      presentment_money: {
        amount: number;
      };
    };
    carrierIdentifier: string;
    code: string;
  }[];
  priceBeforeDiscounts: number;
  currentSubtotalPriceSet: {
    presentment_money: {
      amount: number;
    };
  };
  discounts: {
    individualDiscounts: {
      discountType: "fixed_amount" | "percentage";
      amount: number;
    }[];
  };
  presentmentCurrency: string;
  totalTax: number;
  totalTaxSet?: {
    presentment_money: {
      amount: number;
    };
  };
  totalPrice: number;
  totalPriceSet: {
    presentment_money: {
      amount: number;
    };
  };
  shippingAddress?: {
    name: string;
    address1: string;
    address2: string;
    city: string;
    province: string;
    provinceCode: string;
    zip: string;
    country: string;
    countryCode: string;
    phone: string;
  };
  billingAddress?: {
    name: string;
    address1: string;
    address2: string;
    city: string;
    province: string;
    zip: string;
    country: string;
    countryCode: string;
    phone: string;
  };
  sourceName?: string;
  source?: string;
  financialStatus?:
    | "authorized"
    | "paid"
    | "partially_paid"
    | "partially_refunded"
    | "pending"
    | "refunded"
    | "voided";
  note?: string;
  tags: string[];
}

export type Trackable = BaseTrackable &
  (
    | {
        type: TrackableType.ORDER;
      }
    | {
        type: TrackableType.RETURN;
        returnProducts: Return["products"];
        status: Return["status"];
        shipment: Return["shipment"];
        shipments: Return["shipments"];
        reShipments: Return["reShipments"];
        customerReturnCreatedAttachmentGenerationParameters: CustomerReturnCreatedAttachmentGenerationParameters; // best name ever
        rejectReason?: string;
        pickup?: Return["pickup"];
        returnType: Return["type"];
        markedForManualReview?: boolean;
        draftOrderURL?: string;
        warnings?: ReturnWarning[];
        inStoreReturn?: boolean;
        shipping_address?: ReturnAddress;
        merchant_address?: ReturnAddress;
        exchangeOrder: any[];
        form_label?: string;
        postage_label?: string;
        happyReturnsData?: any;
      }
  );

export function orderToTrackable(order: Order): Trackable {
  const shopifyOrder = mapShopify(order);
  const lineItems =
    order.shopify.line_items?.map(orderLineItemToTrackableLineItem) ?? [];
  return {
    type: TrackableType.ORDER,
    id: order._id.toString(),
    customer: {
      shopifyId: order.shopify.customer?.id,
      email: order.shopify.email,
      name: order.customer_name,
      firstName: order.shopify.customer?.first_name,
      lastName: order.shopify.customer?.last_name,
    },
    fulfillments:
      order.provider === "commentsold"
        ? []
        : order.shopify.fulfillments.map((fulfillment) => ({
            id: fulfillment.id.toString(),
            trackers: order.trackers
              .filter((t) => {
                try {
                  return (
                    t.fulfillmentID.toString() === fulfillment.id.toString()
                  );
                } catch (e) {
                  console.error(
                    `Error comparing tracker fulfillment ID ${t.fulfillmentID} to fulfillment ID ${fulfillment.id}, order ID ${order._id}`,
                  );
                  return false;
                }
              })
              .map((t) => t._tracker),
            // fulfillment line items have less info than root line items
            lineItems: fulfillment.line_items.map(
              (lineItem) =>
                lineItems.find(
                  (li) =>
                    li.title === lineItem.title &&
                    li.variantTitle === lineItem.variant_title,
                ) ?? orderLineItemToTrackableLineItem(lineItem),
            ),
            isShipped: !!fulfillment.shipment_status,
            updatedAt: fulfillment.updated_at
              ? new Date(fulfillment.updated_at)
              : undefined,
            tracking_numbers: fulfillment.tracking_numbers,
            status: fulfillment.status,
            shipment_status: fulfillment.shipment_status ?? "unknown",
            tracking_company: fulfillment.tracking_company ?? undefined,
            tracking_url: fulfillment.tracking_url ?? undefined,
          })),
    lineItems:
      order.shopify.line_items?.map(orderLineItemToTrackableLineItem) ?? [],
    createdAt: new Date(order.createdAt),
    originalOrder: {
      id: order._id.toString(),
      shopify: shopifyOrder,
    },
    currentEmailFlows:
      order.currentEmailFlows?.map((f) => ({
        ...f,
        continueDate: new Date(f.continueDate),
      })) ?? [],
    teamId: order.team.toString(),
    discount: order.discount,
    trackingEmailsSent: order.trackingEmailsSent,
  };
}

export function returnToTrackable(return_: Return, order: Order): Trackable {
  // returns don't track the same details about line items,
  // so we have to get the ones from the order object
  const returnProducts = return_.products ?? [];
  const lineItems = (order.shopify.line_items ?? [])
    .filter((lineItem) =>
      return_.products.some(
        (product) => product.line_item_id.toString() === lineItem.id.toString(),
      ),
    )
    .map(orderLineItemToTrackableLineItem);

  const shipments =
    return_.shipments?.length > 0
      ? return_.shipments
      : return_.shipment
        ? [return_.shipment]
        : [];

  const returner = getReturner(return_);
  if (!returner.email) {
    throw new Error("No email found on returner");
  }

  return {
    returnProducts,
    type: TrackableType.RETURN,
    status: return_.status,
    returnType: return_.type,
    exchangeOrder: return_.exchangeOrder,
    id: return_._id.toString(),
    customer: {
      email: returner.email!,
      name: returner.name,
      firstName: returner.firstName || "",
      lastName: returner.lastName || "",
    },
    fulfillments: shipments.map((shipment) => ({
      id: shipment._shipment.id,
      trackers: [shipment._shipment.tracker],
      // returns do not track lineItems per shipment,
      // so we'll just put all lineItems on all shipments
      lineItems,
      isShipped: !["unknown", "pre_transit"].includes(
        shipment._shipment.status,
      ),
      updatedAt: shipment._shipment.updated_at
        ? new Date(shipment._shipment.updated_at)
        : undefined,
      status: shipment._shipment.status,
      shipment_status: shipment._shipment.status,
    })),
    lineItems,
    createdAt: new Date(return_.createdAt),
    originalOrder: {
      id: order._id.toString(),
      shopify: mapShopify(order),
    },
    currentEmailFlows:
      return_.currentEmailFlows?.map((f) => ({
        ...f,
        continueDate: new Date(f.continueDate),
      })) ?? [],
    teamId: order.team.toString(),
    discount: return_.discount,
    shipment: return_.shipment,
    shipments: return_.shipments,
    reShipments: return_.reShipments,
    // populating the following interface made me deeply concerned about the quality of the return product code
    customerReturnCreatedAttachmentGenerationParameters: {
      shipments: return_.shipments.map((shipment) => ({
        postage_label: shipment.postage_label,
        form_label: shipment.form_label!,
        _shipment: shipment._shipment,
      })),
      variables: {
        postage_label: return_.postage_label, // why are there two postage label fields??
        form_label: return_.form_label, // why are there two form label fields??
        commercialInvoice: return_.shipment?._shipment?.forms?.find(
          (form: any) => {
            return form.form_type === "commercial_invoice";
          },
        )?.form_url,
      },
      pickup_details: return_.shipment?.pickup,
    },
    rejectReason: return_.rejectReason ?? "No reason provided",
    trackingEmailsSent: return_.trackingEmailsSent,
    pickup: return_.pickup,
    markedForManualReview: return_.markedForManualReview,
    draftOrderURL: return_.draftOrderURL,
    warnings: return_.warnings,
    inStoreReturn: return_.inStoreReturn,
    merchant_address: return_.merchant_address,
    shipping_address: return_.shipping_address,
    form_label: return_.form_label,
    postage_label: return_.postage_label,
    happyReturnsData: return_.happyReturnsData,
  };
}

export function mapShopify(order: Order): ShopifyOrder {
  const { shipping_address, billing_address, ...shopify } = order.shopify;
  const shippingAddress = shipping_address
    ? {
        name: shipping_address.name,
        address1: shipping_address.address1,
        address2: shipping_address.address2,
        city: shipping_address.city,
        province: shipping_address.province,
        provinceCode: shipping_address.province,
        zip: shipping_address.zip,
        country: shipping_address.country,
        countryCode: shipping_address.country_code,
        phone: shipping_address.phone,
      }
    : undefined;
  const billingAddress = billing_address
    ? {
        name: billing_address.name,
        address1: billing_address.address1,
        address2: billing_address.address2,
        city: billing_address.city,
        province: billing_address.province,
        zip: billing_address.zip,
        country: billing_address.country,
        countryCode: billing_address.country_code,
        phone: billing_address.phone,
      }
    : undefined;
  return {
    id: shopify.id?.toString(),
    number: shopify.order_number,
    name: shopify.name,
    contact_email: shopify.contact_email,
    createdAt: new Date(shopify.created_at),
    customer: shopify.customer
      ? {
          id: shopify.customer.id,
        }
      : null,
    fulfillments:
      shopify.source === "commentsold"
        ? []
        : shopify.fulfillments.map((fulfillment) => ({
            id: fulfillment.id.toString(),
            updatedAt: fulfillment.updated_at
              ? new Date(fulfillment.updated_at)
              : undefined,
            shipmentStatus: fulfillment.shipment_status || "unknown",
            isShipped: !["unknown", "pre_transit"].includes(
              fulfillment.shipment_status || "unknown",
            ),
            tracking: {
              numbers: fulfillment.tracking_numbers,
              urls: fulfillment.tracking_urls,
              company: fulfillment.tracking_company,
            },
          })),
    lineItems: shopify.line_items.map((line) => ({
      productId: line.product_id?.toString() ?? null,
    })),
    shippingLines: shopify.shipping_lines.map((line: any) => ({
      title: line.title,
      price: parseFloat(line.price),
      discountedPrice: parseFloat(line.discounted_price),
      discountedPriceSet: line.discounted_price_set,
      carrierIdentifier: line.carrier_identifier,
      code: line.code,
    })),
    presentmentCurrency: shopify.presentment_currency,
    priceBeforeDiscounts: parseFloat(shopify.total_line_items_price),
    currentSubtotalPriceSet: shopify.current_subtotal_price_set,
    discounts: {
      individualDiscounts: shopify.discount_applications?.map(
        ({ value, value_type }: any) => ({
          discountType: value_type,
          amount: parseFloat(value),
        }),
      ),
    },
    totalTax: parseFloat(shopify.total_tax),
    totalTaxSet: shopify.total_tax_set || null,
    totalPrice: parseFloat(shopify.total_price),
    totalPriceSet: shopify.total_price_set,
    shippingAddress,
    billingAddress,
    note: shopify.note,
    tags: shopify.tags?.split(",").map((tag: string) => tag.trim()) ?? [],
    sourceName: shopify.source_name,
    source: shopify.source,
    financialStatus: shopify.financial_status as
      | "authorized"
      | "paid"
      | "partially_paid"
      | "partially_refunded"
      | "pending"
      | "refunded"
      | "voided"
      | undefined,
  };
}

function orderLineItemToTrackableLineItem(
  lineItem: Order["shopify"]["line_items"][number],
): TrackableLineItem {
  return {
    id: lineItem.id,
    title: lineItem.title,
    productId: lineItem.product_id?.toString() ?? undefined,
    variantId: lineItem.variant_id?.toString() ?? undefined,
    variantTitle: lineItem.variant_title,
    quantity: lineItem.quantity,
    price: parseFloat(lineItem.price),
    isRedo: ["re:do", "redo"].includes(lineItem.vendor),
    requiresShipping: lineItem.requires_shipping,
    image: lineItem.image?.src
      ? lineItem.image
      : {
          src: `https://via.placeholder.com/150?text=${lineItem.title.toLowerCase().includes("return") ? "Return" : lineItem.title}`,
        },
    tags: lineItem.tags ?? [],
    properties: lineItem.properties.reduce(
      (acc, prop) => {
        acc[prop.name] = prop.value;
        return acc;
      },
      {} as Record<string, string>,
    ),
    sku: lineItem.sku,
    green_return: lineItem.green_return,
  };
}

/** Intended for use on Order and Return models, not general use */
export interface ITrackable {
  _id: string;
  timeline: TimelineEvent[];
  trackers: {
    _tracker: ITracker;
    fulfillmentID: string;
  }[];
  trackingTimeline: any[]; // TODO: define this type
  trackingAnalytics: {
    email: SesEvent[];
    page: {
      url: string;
      eventType: "ad" | "upsell";
      image?: string;
      createdAt: string;
    }[];
  };
  trackingTextsSent?: {
    sid: string;
    mms: boolean;
    sentAt: string;
  }[];
  trackingEmailsSent?: {
    emailId: string;
    status?: string;
    sentAt: string;
    s3URL?: string;
    trigger?: string;
  }[];
  trackingBillingStatus?: "billed" | "free";
  discount?: {
    id: string;
    description: string;
    code: string;
    expirationDateTime: string;
  };
}

export interface CostSummary {
  shippingProduct: string;
  shippingProductCost: string;
  taxCost: string;
  totalCost: string;
  priceBeforeDiscounts: string;
  discountStrings: string[];
}
